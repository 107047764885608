import { IProfileApiResponse, IGetTokenResponse, profileApiResponseAdapter, IProfileApiResponseAdapted } from './user.model.store'
import { IApiClientResponse, TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'
import { setup } from '../../utils/setup'
import axios, {AxiosResponse} from 'axios'

export const useProfileService = () => {
    
    const profileApi = useApiClient()

    const getProfile = async (): TApiClientResponse<IProfileApiResponse> => {
        const profileResponse = await profileApi.get<IProfileApiResponse>(`${setup.apiBaseUrl}/v1/profile`)
        return profileResponse
    }

    const getToken = async (email: string, pass: string): TApiClientResponse<string> => {


        const response: IApiClientResponse<string> = await axios.post<IGetTokenResponse>(`${setup.apiBaseUrl}/auth`, {
            password: pass,
            username: email,
        })
        .then(response=>{

            const successResponse:  IApiClientResponse<string> = {
                isSuccess: true,
                isError: false,
                data: response.data.token,
                status: response.status,
                method: 'POST',
                url: `${setup.apiBaseUrl}/auth`,
                body: {     
                    password: pass,
                    username: email
                }
            }

            return successResponse
        })
        .catch(error=>{
            const errorResponse: IApiClientResponse<string> = {
                isSuccess: false,
                isError: true,
                data: '',
                status: response.status,
                method: 'POST',
                url: `${setup.apiBaseUrl}/auth`,
                body: {     
                    password: pass,
                    username: email
                }
            }
            return errorResponse
        })


        return response
    }


    return { getProfile, getToken }
}


