
import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonInput, IonItem, IonLabel, IonLoading, IonRow, IonSelect, IonSelectOption, IonTitle, useIonViewDidEnter } from '@ionic/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { FormGeneratorComponent, getValueToForm } from '../components/FormGenerator/FormGenerator.component'
import { IFormGeneratorComponent } from '../components/FormGenerator/FormGenerator.interface'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import { ISendRequestParam } from '../stores/cuit/cuit.service.store'
import dataLayer from '../ga.js'

import {
    IBankMessage,
    IMessage,
} from '../stores/cuit/cuit.use.store'
import { IUserStore } from '../stores/user/user.use.store'
import { INotificationStore } from '../stores/notification/notification.use.store'

interface ICuitPage {
    userStore: IUserStore
    notificationStore: INotificationStore
    isShowSantanderForm: boolean
    fullName: string
    cuit: string
    cuitValidateMessage: IMessage
    verificationBankFirstPolicyMessage: IBankMessage
    verificationAcceptanceRequirementsFirstMessage: IMessage
    verificationAcceptanceRequirementsNoWarrantyMessage: IMessage
    verificationTrackMessage: IMessage
    onSendRequest: (param: ISendRequestParam) => Promise<void>
    sendSecondVerificationBankFirstPolicy: (param: ISantanderSecondRequest) => Promise<void>
}

interface ISantanderSecondRequest {
    cuit: string
    finnancial_debt: number
    avg_billing: number
    rented_has: number
    own_has: number
    new_line: number
}


const SantanderResultCard = (props:{
    title: 'APTO' | 'NO APTO' | 'ERROR' | ''
    carrilTitle: 'Verde' | 'Rojo' | 'Amarillo' | ''
    message: string
})=> {

    const getCardColor = () => 
        props.title === 'APTO' ? 'success' :
        props.title === 'NO APTO' ? 'danger' : 
        props.title === 'ERROR' ? 'light' : 'light'

    const getCarrilColor = () =>
        props.carrilTitle === 'Verde' ? 'success' :
        props.carrilTitle === 'Rojo' ? 'danger' :
        props.carrilTitle === 'Amarillo' ? 'warning' : 'light'

    return (
        <IonCard color={getCardColor()}>
            <IonCardHeader>
                <IonCardTitle>
                    <IonRow>
                        <IonCol>
                            Banco Prendario: {props.title}
                        </IonCol>
                        <IonCol className="ion-text-right">
                            {props.carrilTitle && (
                                <IonBadge color={getCarrilColor()}>Carril: {props.carrilTitle}</IonBadge>
                            )}
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRow>
                    <IonCol>{props.message}</IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    )
}

interface ISantanderFormCard {
    // carrilTitle: 'Verde' | 'Rojo' | 'Amarillo' | ''
    onSubmit: (param: {
        finnancial_debt: number
        avg_billing: number
        rented_has: number
        own_has: number
        new_line: number
    })=> void
}

const SantanderFormCard = (props: ISantanderFormCard) => {

    const [formData, setFormData] = React.useState<IFormGeneratorComponent['form']>([
        {
            id: 1,
            type: 'price',
            label: 'Deuda total actual en pesos',
            value: '',
            regex: '',
            placeholder: 'Campo requerido...',
            required: true,
            error: false
        },
        {
            id: 2,
            type: 'price',
            label: 'Facturación Promedio anual en pesos',
            value: '',
            regex: '',
            placeholder: 'Campo requerido...',
            required: true,
            error: false
        },
        {
            id: 3,
            type: 'price',
            label: 'Hectareas rentadas',
            value: '',
            regex: '',
            placeholder: 'Campo requerido...',
            required: true,
            error: false
        },
        {
            id: 4,
            type: 'price',
            label: 'Hectareas propias',
            value: '',
            regex: '',
            placeholder: 'Campo requerido...',
            required: true,
            error: false
        },
        {
            id: 5,
            type: 'price',
            label: '¿Cuánto quiere solicitar en pesos?',
            value: '',
            regex: '',
            placeholder: 'Campo requerido...',
            required: true,
            error: false
        }
    ])

    const onSubmitHandler = () => {
        props.onSubmit({
            finnancial_debt: Number(getValueToForm(formData, 1)),
            avg_billing: Number(getValueToForm(formData, 2)),
            rented_has: Number(getValueToForm(formData, 3)),
            own_has: Number(getValueToForm(formData, 4)),
            new_line: Number(getValueToForm(formData, 5)),
        })
    }

    // const getCarrilColor = () =>
    //     props.carrilTitle === 'Verde' ? 'success' :
    //     props.carrilTitle === 'Rojo' ? 'danger' :
    //     props.carrilTitle === 'Amarillo' ? 'warning' : 'ligth'

    return (
        <IonCard>
        <IonCardHeader>
            <IonCardTitle>
                <IonRow>
                    <IonCol>Banco Prendario: APTO</IonCol>
                    <IonCol className="ion-text-right">
                        {/* <IonBadge color={getCarrilColor()}>Carril: {props.carrilTitle}</IonBadge> */}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonTitle color="medium">Se necesita mas información</IonTitle>
                    </IonCol>
                </IonRow>
            </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
            <FormGeneratorComponent
                form={formData}
                setForm={setFormData}
                submitText="Comprobar"
                onSubmit={() => onSubmitHandler()}
                columns={1}
            />
        </IonCardContent>
        </IonCard>
    )
}

const CuitCard = (props: {message: IMessage}) => { // mas adelante le pongo el status
    return (
        <IonCard color={props.message.status}>
            <IonCardHeader>
                    <IonCardTitle>
                       {props.message.title}
                    </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {props.message.message}
            </IonCardContent>
        </IonCard>
    )
}

const CuitForm = (props:{ 
    onSubmit: (
        param: { 
            cuit: string, 
            activity: 'Contratista' | 'Productor' |'Ambos'
        }) => void
})=> {
    
    const [formData, setFormData] = React.useState<IFormGeneratorComponent['form']>([
        {
            id: 0,
            type: 'title',
            text: 'Comprobación CUIT'
        },
        {
            id: 1,
            label: 'CUIT/CUIL',
            placeholder: 'Campo requerido...',
            type: 'cuilcuit',
            required: true,
            error: false,
            value: '',
        },
        {
            id: 2,
            label: 'Actividad del Solicitante',
            placeholder: 'Campo requerido',
            type: 'options',
            required: true,
            regex: '',
            error: false,
            value: '',
            options: [
                {
                    label: 'Contratista',
                    value: 'Contratista'
                },
                {
                    label: 'Productor',
                    value: 'Productor'
                },
                {
                    label: 'Ambos',
                    value: 'Ambos'
                },
            ],
        },
    ])

    const onSubmitHandler = ()=> {        
        props.onSubmit({
             cuit:  getValueToForm(formData, 1),
             activity:  getValueToForm(formData, 2) === 'Contratista' ? 'Contratista' :
                getValueToForm(formData, 2) === 'Productor' ? 'Productor' : 'Ambos'
        })
    }

    return (
        <FormGeneratorComponent
            form={formData}
            setForm={setFormData}
            submitText="Comprobar"
            onSubmit={() => onSubmitHandler()}
            columns={2}
        />
    )
}

export const CuitPage = (props: ICuitPage) => {

    useIonViewDidEnter(()=>{
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'credit check',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });
    })

    const history = useHistory()
    
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isShowTitle, setIsShowTitle] = React.useState<boolean>(false)
    const [isShowControlls, setIsShowControlls] = React.useState<boolean>(false)
    
    const [formData, setFormData] = React.useState<{cuit:string, activity: 'contratista' | 'productor'|'ambos'}>()

    const onSubmitHandler = async (param: ISendRequestParam)=> {
        setFormData({
            ...param, 
            activity: param.activity === 'Productor' ? 'productor' :
            param.activity === 'Contratista' ? 'contratista' :
            param.activity === 'Ambos' ? 'ambos' : 'ambos'
        })
        setIsShowTitle(true)
        setIsLoading(true)
        await props.onSendRequest({...param, activity: param.activity})
        setIsLoading(false)
        setIsShowControlls(true)
    }

    const onRedirectToOnBoardingWithQueryParams = () => {
        history.push(`/onBoarding?cuit=${formData?.cuit}&activity=${formData?.activity}`)
    }

    const onSubmitFromSantanderCardHandler = async (param: {  
        finnancial_debt: number
        avg_billing: number
        rented_has: number
        own_has: number
        new_line: number
    }) => {
        setIsLoading(true)
        if(formData) await props.sendSecondVerificationBankFirstPolicy({...param, cuit: formData.cuit})
        setIsLoading(false)
    }
    
    return (    
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Cargando...'}
            />

            <IonRow>
               <IonCol size="12" sizeMd="3" sizeLg="3"/>
               <IonCol size="12" sizeMd="6" sizeLg="6">
                    <CuitForm onSubmit={onSubmitHandler}/>
                    
                    {isShowTitle && (props.fullName || props.cuit) ? (
                        <h5 className="ion-text-wrap ion-text-center" style={{padding:'10px'}}>
                            {props.fullName} ({props.cuit})
                        </h5>
                    ) : null}

                    {props.cuitValidateMessage.message && <CuitCard message={props.cuitValidateMessage}/>}
                    
                    {props.verificationBankFirstPolicyMessage.message && !props.isShowSantanderForm && (
                        <SantanderResultCard
                            title={props.verificationBankFirstPolicyMessage.title}
                            carrilTitle= {props.verificationBankFirstPolicyMessage.carrilTitle}
                            message={props.verificationBankFirstPolicyMessage.message}
                        />
                    )}

                    {props.isShowSantanderForm && <SantanderFormCard onSubmit={onSubmitFromSantanderCardHandler}/>}
                    {props.verificationAcceptanceRequirementsFirstMessage.message && <CuitCard message={props.verificationAcceptanceRequirementsFirstMessage}/>}
                    {props.verificationAcceptanceRequirementsNoWarrantyMessage.message && <CuitCard message={props.verificationAcceptanceRequirementsNoWarrantyMessage}/>}
                    {props.verificationTrackMessage.message && <CuitCard message={props.verificationTrackMessage}/>}
                    
                    {isShowControlls ?
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonButton color="light" onClick={()=>window.print()}>Imprimir</IonButton>
                                <IonButton color="primary" onClick={()=>onRedirectToOnBoardingWithQueryParams()}>Crear Simulación</IonButton>
                            </IonCol>
                        </IonRow>
                    : null}

               </IonCol>
               <IonCol size="12" sizeMd="3" sizeLg="3"/>
            </IonRow>
       
        </ContentWithNavigationLayout>
    )
}