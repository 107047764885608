import { IForm } from "../../components/FormGenerator/FormGenerator.interface"



export type TGetStatesWithTheirMunicipalitiesOfMexicoResponse = Array<{
    name: string,
    municipalities: Array<string>
}>
export interface ICategoriesResponse {
    "status": "success",
    "data": Array<{
        "id": number //1,
        "name": string //"MAQUINARIA",
        "is_warranted": boolean //true,
        "weigh": { "id": number, "term": number, "weigh": number }
        "product_types": Array<{ "id": number, "name": string }>
    }>
}



export interface ICheckPoliciesRequest {
    checkBureau: boolean
    sales: number
    current_debt: number // deuda_corriente: 4600000, ,
    not_current_debt: number  // deuda_nocorriente: 0,

    own_has: number //has_propias: 310,
    rented_has: number //has_arrendadas: 0,
    harvest_has: number //has_cosecha: 0,
    bundled_up_has: number //has_enfardado: 150,
    fertilization_has: number  //has_fertilizacion: 200, 
    fumigation_has: number // has_fumigacion: 0, 
    other_has: number // has_otro: 0, 
    chopped_has: number // has_picado: 0, 
    sowing_has: number // has_siembra: 400,

    kms_worked: number;

    product: {
        currency_id: number //1,
        category_id: number //1,
        categoria: string //MAQUINARIA,
        is_warranted: boolean //true,
        type_id: number //2,
        nombre: string//COSECHADORA,
        price: number | string//10000,
        condition: string//NUEVO
        year: number // estos campos tambien se tienen que ir
        brand: string
        model: string
        values: Array<{
            attribute_id: number
            value: string
        }>
    },
    customer: {
        ///// Argentina
        declared_activity?: string
        identification_number?: string
        ///// Mexico
        genero?: string
        primer_nombre?: string
        segundo_nombre?: string
        address?: string // address
        apellido_paterno?: string
        apellido_materno?: string
        numero_exterior?: string
        numero_interior?: string
        telefono_celular?: string
        codigo_postal?: string
        curp?: string
        razon_social?: string
        rfc?: string
        person_type?: 1 | 2 | 3
        state?: string
        municipalitie?: string
        fiscal_person_type: 'PM' | 'PF' | 'PFCAE'
    },
    filters: {
        currency_id: number
    }
}

export const checkPoliciesRequestFactory = (): ICheckPoliciesRequest => ({
    checkBureau: false,
    sales: 0,
    current_debt: 0,
    not_current_debt: 0,
    own_has: 0,
    rented_has : 0,
    harvest_has: 0,
    bundled_up_has: 0,
    fertilization_has: 0,
    fumigation_has: 0,
    other_has: 0,
    chopped_has: 0,
    sowing_has: 0,
    kms_worked: 0,

    product: {
        currency_id: 0,
        category_id: 0,
        categoria: '',
        is_warranted: false,
        type_id: 0,
        nombre: '',
        price: 0,
        condition: '',
        year: 0,
        brand: '',
        model: '',
        values: []
    },
    customer: {
        ///// Argentina
        identification_number: '',
        declared_activity: '',
        ///// Mexico
        genero: '',
        primer_nombre: '',
        segundo_nombre: '',
        address: '',
        apellido_paterno: '',
        apellido_materno: '',
        numero_exterior: '',
        numero_interior: '',
        telefono_celular: '',
        codigo_postal: '',
        curp: '',
        razon_social: '',
        rfc: '',
        person_type: 1,
        fiscal_person_type: 'PF'
    },
    filters: {
        currency_id: 0
    }
})

export interface ICheckPoliciesResponse {
    commercialPolicies: Array<IPolicie>,
    customer?: {
        ////// Argentina
        city?: string | null
        companies?: Array<any>
        exist?: boolean
        identification_number?: string
        name?: string
        state?: string | null
        type_person?: string
        zip_code?: string | null
        ////// Mexico
        apellido_materno?: string //"san"
        apellido_paterno?: string //"lar"
        address?: string //"calle"
        codigo_postal?: string //"1414"
        correo_electronico?: string //"leo@gab.com"
        curp?: string //"curp"
        numero_exterior?: string //"1000"
        primer_nombre?: string //"leo"
        // razon_social: string //"razon social"
        rfc?: string //"efc"
        segundo_nombre?: string //"gab"
        telefono_celular?: string //"1123232323"
        gender?: 'M' | 'F'
        razon_social: string
    },
    product?: {
        categoria: string//"MAQUINARIA"
        category_id: number//1
        condition: string//"NUEVO"
        currency_id: number//1
        is_warranted: boolean//true
        nombre: string //"TRACTOR"
        price: number | string //10000
        type_id: number //1
    }
}




export interface ICheckPoliciesAdaptedResponse {
    commercialPolicies: Array<IPolicieAdapted>,
    customer: {
        ////// Argentina
        city?: string | null
        companies?: Array<any>
        exist?: boolean
        identification_number?: string
        name?: string
        state?: string | null
        type_person?: string
        zip_code?: string | null
        ////// Mexico
        apellido_materno?: string //"san"
        apellido_paterno?: string //"lar"
        address?: string //"calle"
        codigo_postal?: string //"1414"
        correo_electronico?: string //"leo@gab.com"
        curp?: string //"curp"
        numero_exterior?: string //"1000"
        primer_nombre?: string //"leo"
        // razon_social: string //"razon social"
        rfc?: string //"efc"
        segundo_nombre?: string //"gab"
        telefono_celular?: string //"1123232323"
        gender?: 'M' | 'F'
        razon_social: string
    },
    product: {
        categoria: string//"MAQUINARIA"
        category_id: number//1
        condition: string//"NUEVO"
        currency_id: number//1
        is_warranted: boolean//true
        nombre: string //"TRACTOR"
        price: number | string //10000
        type_id: number //1
    }
}


interface ISussesRisk {
    Status: number
    result: any
    executionId: number
    id: number
}

interface IErrorRisk {
    id: number
    status: number
    executionId: null
    actividad: null
    message: string
    extraData: null
}


interface IRisk {
    id: number
    status: number
    message: string | null
    executionId: number
    extraData?: {
        amount: {
            currency: string,
            value: number
        },
        track: string
    }
    result: {
        bureau?: {
            afip_ERROR: string  
            bureau_ERROR: string
        }
    }
}

export interface IPolicieAdapted {
    "founder_category": string
    "asymetric_fees": boolean
    "fee_weight": number
    "has_credit_debit_expense"?: boolean | undefined
    "maximum_capacity_value"?: string | undefined
    "minimun_ammount_first_fee"?: string | undefined
    "minimun_date_first_fee"?: string | undefined
    "max_date_first_fee"?: string | undefined
    "max_amount_source"?: string | undefined

    "title"?: string
    "breakMode"?: boolean
    "breakModeRate"?: number
    "fee_tax_mode"?: 1 | 2
    "id": number //597,
    "description": string//"Politica 1",
    "valid_from": string //"2020-08-01 00:00:00",
    "valid_to": string//"2028-12-04 00:00:00",
    "brand_id": string | null //null,
    "product_type_id": number | null//1,
    "age_from": string | number | null//null,
    "age_to": string | number | null//null,
    "country": string | null//null,
    "state": string | null//null,
    "city": string | null//"Rio Cuarto",
    "term": number//360,
    "max_amount": number//6000,
    "tax": number//50, // este limita el rango del subcidio
    "origination_cost": number//1.3,
    "insurance": number//1.3,
    "insurance_provider": string | null//null,
    "subsidized_rate": number | null//null,
    "surcharge": number//0,
    "partner_id": string | number | null//null,
    "partner": string | null //null,
    "warranty_management": number//1000,
    "warranty_management_currency": number//1,
    "simulate_mode": string //"MC",
    "loan_capacity": number //60,
    "add_expenses": boolean //true,
    "enable_technical_insurance": boolean//true,
    "enable_life_insurance": boolean //false,
    "technical_insurance_rate": number//0,
    "bank_expeses": number //0,
    "credit_debit_expense": number //0,
    "fixed_fees": '' | 'Min' | 'Max'
    "fee_collect": number //1.3,
    "tax_iva": number //0,
    "currency": {
        "id": number //1,
        "currency": string //"Dólar",
        "acronym": string //"USD"
    },
    "risk_policies"?:  Array<ISussesRisk | IErrorRisk>,
    "secondary_risk_policies": Array<number>
    "periodicities": Array<0 | 1 | 2 | 3 | 4 >,
    "pure_fee_check": boolean //false,
    "risk": Array<IRisk>,
    "status": number //1,
    "split_expenses": boolean
    "type":  "PRENDARIO" | "A SOLA FIRMA" | null
    "message": string | null //null,
    "customerValidation": string | null //null
    "funding_partner_category": {
        "id": number,
        "name": string
    },
    "show_discount_rate": boolean
    "discount_rate": number
}

export interface IPolicie {
    "founder_category": undefined | string 
    "asymetric_fees"?: boolean
    "fee_weight"?: number
    "has_credit_debit_expense"?: boolean | undefined
    "maximum_capacity_value"?: string | undefined
    "minimun_ammount_first_fee"?: string | undefined
    "minimun_date_first_fee"?: string | undefined
    "max_date_first_fee"?: string | undefined
    "max_amount_source"?: string | undefined

    "title"?: string
    "breakMode"?: boolean
    "breakModeRate"?: number
    "fee_tax_mode"?: 1 | 2
    "id": number //597,
    "description": string//"Politica 1",
    "valid_from": string //"2020-08-01 00:00:00",
    "valid_to": string//"2028-12-04 00:00:00",
    "brand_id": string | null //null,
    "product_type_id": number | null//1,
    "age_from": string | number | null//null,
    "age_to": string | number | null//null,
    "country": string | null//null,
    "state": string | null//null,
    "city": string | null//"Rio Cuarto",
    "term": number//360,
    "max_amount": number//6000,
    "tax": number//50, // este limita el rango del subcidio
    "origination_cost": number//1.3,
    "insurance": number//1.3,
    "insurance_provider": string | null//null,
    "subsidized_rate": number | null//null,
    "surcharge": number//0,
    "partner_id": string | number | null//null,
    "partner": string | null //null,
    "warranty_management": number//1000,
    "warranty_management_currency": number//1,
    "simulate_mode": string //"MC",
    "loan_capacity": number //60,
    "add_expenses": boolean //true,
    "enable_technical_insurance": boolean//true,
    "enable_life_insurance": boolean //false,
    "technical_insurance_rate": number//0,
    "bank_expeses": number //0,
    "credit_debit_expense": number //0,
    "fixed_fees"?: '' | 'Min' | 'Max'
    "fee_collect": number //1.3,
    "tax_iva": number //0,
    "currency": {
        "id": number //1,
        "currency": string //"Dólar",
        "acronym": string //"USD"
    },
    "risk_policies"?:  Array<ISussesRisk | IErrorRisk>,
    "secondary_risk_policies": Array<number>
    "periodicities"?: Array<0 | 1 | 2 | 3 | 4>,
    "pure_fee_check": boolean //false,
    "risk": Array<IRisk>,
    "status": number //1, 0 es error, 1 apto, 2 no apto, 3 faltan datos
    
    
    "split_expenses": boolean,
    "type": "PRENDARIO" | "A SOLA FIRMA" | null
    "message": string | null //null,
    "customerValidation": string | null //null
    "funding_partner_category": {
        "id": number
        "name": string
    } | null

    "show_discount_rate": boolean
    "discount_rate": number
}

export const checkPoliciesFactory = (): IPolicieAdapted => ({
    "founder_category": "",
    "asymetric_fees": false,
    "fee_weight": 0.5,

    "has_credit_debit_expense": false,
    "maximum_capacity_value": '',
    "minimun_ammount_first_fee": '',
    "minimun_date_first_fee": '',
    "max_date_first_fee": '',
    "max_amount_source": '',

    "title": '',
    "breakMode": false,
    "breakModeRate": 0,
    "fee_tax_mode": 2,
    "id": 0,
    "description": '',
    "valid_from": '',
    "valid_to": '',
    "brand_id": '',
    "product_type_id": 0,
    "age_from": '',
    "age_to": '',
    "country": '',
    "state": '',
    "city": '',
    "term": 0,
    "max_amount": 0,
    "tax": 0,//50,
    "origination_cost": 0,
    "insurance": 0,
    "insurance_provider": '',
    "subsidized_rate": 0,
    "surcharge": 0,
    "partner_id": '',
    "partner": '',
    "warranty_management": 0,
    "warranty_management_currency": 0,
    "simulate_mode": '',
    "loan_capacity": 0,
    "add_expenses": false,
    "enable_technical_insurance": false,
    "enable_life_insurance": false,
    "technical_insurance_rate": 0,
    "bank_expeses": 0,
    "credit_debit_expense": 0,
    "fee_collect": 0,
    "fixed_fees" : '',
    "tax_iva": 0,
    "type": null,
    "currency": {
        "id": 0,
        "currency": '',
        "acronym": '',
    },
    "risk_policies": [],
    "secondary_risk_policies": [],
    "periodicities": [],
    "pure_fee_check": false,
    "risk": [],
    "status": 0,
    "split_expenses": false,
    "message": '',
    "customerValidation": '',
    "funding_partner_category": {
        "id": 0,
        "name": ""
    },
    "show_discount_rate": false,
    "discount_rate": 0
})

export const checkPoliciesResponseFactory = (): ICheckPoliciesAdaptedResponse => ({
    commercialPolicies: [],
    customer: {
        //////// ARG /////////////
        city: '',
        companies: [],
        exist: false,
        identification_number: '',
        name: '',
        state: '',
        type_person: '',
        zip_code: '',
        /////// MEX //////////////
        apellido_materno: '',
        apellido_paterno: '',
        address: '',
        codigo_postal: '',
        correo_electronico: '',
        curp: '',
        numero_exterior: '',
        primer_nombre: '',
        rfc: '',
        segundo_nombre: '',
        telefono_celular: '',
        gender: 'M',
        razon_social: ''
    },
    product: {
        categoria: '',
        category_id: 0,
        condition: '',  
        currency_id: 0,
        is_warranted: false,
        nombre: '',
        price: 0,
        type_id: 0
    }
})

export const checkPoliciesAdapter = (response: ICheckPoliciesResponse):ICheckPoliciesAdaptedResponse => ({
    commercialPolicies: response.commercialPolicies.map(comercialPolicy => {
        return {
            ...comercialPolicy,
            fixed_fees: comercialPolicy.fixed_fees ? comercialPolicy.fixed_fees : '',
            periodicities: comercialPolicy.periodicities ? comercialPolicy.periodicities : [1,2,3,4],
            founder_category: comercialPolicy.founder_category ?  comercialPolicy.founder_category : '',
            asymetric_fees: comercialPolicy.asymetric_fees ? comercialPolicy.asymetric_fees : false,
            fee_weight: comercialPolicy.fee_weight ? comercialPolicy.fee_weight : 0.5,
            funding_partner_category: {
                id:  comercialPolicy.funding_partner_category ? comercialPolicy.funding_partner_category.id : 0,
                name: comercialPolicy.funding_partner_category ? comercialPolicy.funding_partner_category.name : ''
            }
        }
    }),
    customer: response.customer ? response.customer : checkPoliciesResponseFactory().customer,
    product: response.product ? response.product : checkPoliciesResponseFactory().product
})

////////////////////////////////////////////

export interface ISimulateRequest {
    "simulateMode": string//"MC",
    "checkBureau": boolean
    "fees": string //"1",
    "quantityFees": number //2,
    "valueUnity": string //"250000",
    "amountRequest": string //"175000",
    "loanCapacity": number //70,
    "siembroTax": number | null //50,
    "siembroTaxNew": number | null //50,
    "splitExpenses": boolean //false,
    "clientTax": string | null //null,
    "lifeInsurance": number | null //100,
    "warrantyManagement": number //100,
    "dateFirstFee": string //"2020-11",
    "addExpenses": boolean //false,
    "enableLifeInsurance": boolean //true,
    "enableTechnicalInsurance": boolean //true,
    "originationRate": number  //100,
    "technicalInsuranceRate": number //12,
    "bankExpenses": number //100,
    "creditAndDebitExpenses": number//100,
    "feeCollect": number //100,
    "tax": number //21,
    "currency_id": number //2,
    "valueUnityCurrency": number //2,
    "hasCheckbook": boolean //false,
    "risk": Array<IRisk>,
    "commercialPolicyId": number //597,
    "riskStatus": number //1,
    "product": {
        "currency_id": number //2,
        "category_id": number //1,
        "categoria": string //"MAQUINARIA",
        "is_warranted": boolean //true,
        "type_id": number //1,
        "nombre": string //"TRACTOR",
        "price": string //"250000",
        "condition": string //"NUEVO"
        "brand": string,
        "model": string
        "values": Array<{
            attribute_id: number
            value: string
        }>
    },
    "customer": {

    
        "city"?: string| null
        "companies"?: Array<any>
        "exist"?: boolean
        "identification_number"?: string
        "name"?: string
        "state"?: string | null
        "type_person"?: string
        "zip_code"?: string | null
        "declared_activity": 'Productor' | 'Contratista' | 'Ambos'


        "primer_nombre"?: string //"Ricardo",
        "segundo_nombre"?: string //"Iorio",
        "calle"?: string //"Papa",
        "apellido_paterno"?: string //"Popo",
        "apellido_materno"?: string //"Toto",
        "numero_exterior"?: string //"300",
        "telefono_celular"?: string //"5541214512",
        "numero_interior"?: string //"2251",
        "codigo_postal"?: string //"65464",
        "curp"?: string //"MAHJ280603MSPRRV09",
        "razon_social"?: string //"Ninguna",
        "rfc"?: string //" MAHJ280603MSPRRV09",
        "genero"?: string //"masculino",
        "mas_rfc"?: string //"1",
        "clasificacion_fiscal"?: string //"Persona Fiscal"
        "fiscal_person_type": 'PM' | 'PF' | 'PFCAE'
    }
}

export interface ISimulateResponse {
    "date_simulate": string//"16/12/2020",
    "date_origination": string//"16/12/2020",
    "date_first_fee": string//"06/11/2020",
    "quantity_fees": number//2,
    "amount_max_to_loan": string//"175000",
    "commercial_policy_id": number,
    "loan": {
        "amount_to_receive": string
        "amount_to_transfer": string//"175000",
        "amount_with_origination_rate": string//"179837",
        "currency": {
            "id": number//2,
            "acronym": string//"MXN",
            "name": string//"Peso Mexicano",
            "exchange_rate": {}//1
        },
        "total": string//"179837",
        "fees": Array<{
            "balance": string
            "nro": number//1,
            "date": {
                "date": string//"2020-11-04 00:00:00.000000",
                "timezone_type": number//3,
                "timezone": string//"UTC"
            },
            "acronym": string//"MXN",
            "currency_id": number//2,
            "capital": string//"179837",
            "amortization": string//"88084",
            "pure_fee": string//"98129",
            "subtotal": string//"98075",
            "interest": string//"9991",
            "tax_interest": string//"0",
            "life_insurance": string//"0",
            "technical_insurance": string//"0.00",
            "tax_credit_debit": string//"0",
            "fee_collection": string//"118736",
            "value": string//"216864",
            "siembro_interes_fee": string//"0",
            "expenseTotal": number//0
            "rate_recovery_fee"?: string
        }>,
        "total_fees": string//"428685",
        "total_rate_recovery": string//"69"
    },
    "expense": {
        "origination": string//"0",
        "technical_insurance": string//"0",
        "warranty_management": string//"0",
        "life_insurance": string//"0",
        "tax_credit_debit_origination": string//"0",
        "total": number//4837,
        "total_rate_recovery": string//"69"
    },
    "unityCurrency": {
        "id": number//2,
        "acronym": string//"MXN",
        "name": string//"Peso Mexicano",
        "exchange_rate": {}//1
    },
    "splitExpenses": boolean//false,

    "subsidized_detail": {
        "fees": Array<{
            "value": string //"15543.00",
            "total": number //15542.5,
            "date_diff": number //0
        }>,
        "total": string //"53492",
        "total_with_out_expenseses": string //"51322",
        "amount": string // "8679",
        "percentage": string // "14.46"
    } | undefined,
    "id": number//26
}


export const simulateRequestFactory = (): ISimulateRequest => ({
    "simulateMode": '',
    "checkBureau": false,
    "fees": '0',
    "quantityFees": 0,
    "valueUnity": '',
    "amountRequest": '',
    "loanCapacity": 0,
    "siembroTax": 0,
    "siembroTaxNew": 0,
    "splitExpenses": false,
    "clientTax": '',
    "lifeInsurance": 0,
    "warrantyManagement": 0,
    "dateFirstFee": '',
    "addExpenses": false,
    "enableLifeInsurance": false,
    "enableTechnicalInsurance": false,
    "originationRate": 0,
    "technicalInsuranceRate": 0,
    "bankExpenses": 0,
    "creditAndDebitExpenses": 0,
    "feeCollect": 0,
    "tax": 0,
    "currency_id": 0,
    "valueUnityCurrency": 0,
    "hasCheckbook": false,
    "risk": [],
    "commercialPolicyId": 0,
    "riskStatus": 0,
    "product": {
        "currency_id": 0,
        "category_id": 0,
        "categoria": '',
        "is_warranted": false,
        "type_id": 0,
        "nombre": '',
        "price": '',
        "condition": "NUEVO",
        "brand": '',
        "model": '',
        "values": []
    },
    "customer": {
        //////Argentina
        "declared_activity": 'Ambos',
        "city":'',
        "companies": [],
        "exist": false,
        "identification_number": '',
        "name": '',
        "state": '',
        "type_person": '',
        "zip_code": '',
        //////// Mexico
        "primer_nombre": '',
        "segundo_nombre": '',
        "calle": '',
        "apellido_paterno": '',
        "apellido_materno": '',
        "numero_exterior": '',
        "telefono_celular": '',
        "numero_interior": '',
        "codigo_postal": '',
        "curp": '',
        "razon_social": '',
        "rfc": '',
        "genero": '',
        "mas_rfc": '',
        "clasificacion_fiscal": '',
        "fiscal_person_type": 'PF'
    }
})


export const simulateResponseFactory = (): ISimulateResponseAdapted => ({
    "date_simulate": '',
    "date_origination": '',
    "date_first_fee": '',
    "quantity_fees": 0,
    "amount_max_to_loan": '',
    "commercial_policy_id": 0,
    "loan": {
        "amount_to_receive": '0',
        "amount_to_transfer": '',
        "amount_with_origination_rate": '',
        "currency": {
            "id": 0,
            "acronym": '',
            "name": '',
            "exchange_rate": {}
        },
        "total": '',
        "fees": [],
        "total_fees": '',
        "total_rate_recovery": ''
    },
    "expense": {
        "origination": '',
        "technical_insurance": '',
        "warranty_management": '',
        "life_insurance": '',
        "tax_credit_debit_origination": '',
        "total": 0,
        "total_rate_recovery": ''
    },
    "unityCurrency": {
        "id": 0,
        "acronym": '',
        "name": '',
        "exchange_rate": {}
    },
    "splitExpenses": false,
    "subsidized_detail": {
        "fees": [],
        "total": '',
        "total_with_out_expenseses": '',
        "amount": '',
        "percentage": ''
    },
    "id": 0,
})


export interface ISimulateResponseAdapted extends ISimulateResponse {
    "subsidized_detail": {
        "fees": Array<{
            "value": string //"15543.00",
            "total": number //15542.5,
            "date_diff": number //0
        }>,
        "total": string //"53492",
        "total_with_out_expenseses": string //"51322",
        "amount": string // "8679",
        "percentage": string // "14.46"
    }
}



export const simulateResponseAdapter = (simulate: ISimulateResponse): ISimulateResponseAdapted => {

    const simulateAdapted = simulateResponseFactory()

    simulateAdapted.date_simulate = simulate.date_simulate
    simulateAdapted.date_origination = simulate.date_origination
    simulateAdapted.date_first_fee = simulate.date_first_fee
    simulateAdapted.quantity_fees = simulate.quantity_fees
    simulateAdapted.amount_max_to_loan = simulate.amount_max_to_loan
    simulateAdapted.commercial_policy_id = simulate.commercial_policy_id

    simulateAdapted.loan = {
        amount_to_receive: simulate.loan.amount_to_receive,
        amount_to_transfer: simulate.loan.amount_to_transfer,
        amount_with_origination_rate: simulate.loan.amount_with_origination_rate,
        currency: {
            id: simulate.loan.currency.id,
            acronym: simulate.loan.currency.acronym,
            name: simulate.loan.currency.name,
            exchange_rate: simulate.loan.currency.exchange_rate
        },
        total: simulate.loan.total,
        fees: simulate.loan.fees,
        total_fees: simulate.loan.total_fees,
        total_rate_recovery: simulate.loan.total_rate_recovery
    }

    simulateAdapted.expense = {
        origination: simulate.expense.origination,
        technical_insurance: simulate.expense.technical_insurance,
        warranty_management: simulate.expense.warranty_management,
        life_insurance: simulate.expense.life_insurance,
        tax_credit_debit_origination: simulate.expense.tax_credit_debit_origination,
        total: simulate.expense.total,
        total_rate_recovery: simulate.expense.total_rate_recovery
    }

    simulateAdapted.unityCurrency = {
        id: simulate.unityCurrency.id,
        acronym: simulate.unityCurrency.acronym,
        name: simulate.unityCurrency.name,
        exchange_rate: simulate.unityCurrency.exchange_rate
    }

    simulateAdapted.splitExpenses = simulate.splitExpenses

    simulateAdapted.subsidized_detail = {
        fees: simulate.subsidized_detail ? simulate.subsidized_detail.fees : [],
        total: simulate.subsidized_detail ? simulate.subsidized_detail.total: '',
        total_with_out_expenseses:simulate.subsidized_detail ? simulate.subsidized_detail.total_with_out_expenseses : '',
        amount: simulate.subsidized_detail ? simulate.subsidized_detail.amount : '',
        percentage: simulate.subsidized_detail ? simulate.subsidized_detail.percentage : ''
    }

    simulateAdapted.id = simulate.id

    return simulateAdapted
}

////////////////////////////////////////////////
export interface IMaxFeesRequest {
    "newFlow": boolean // true,
    "client_tax": number | null | boolean // false,
    "amountRequest": number // "321302",
    "splitExpenses": boolean // false,
    "commercialPolicy": {
        "fee_weight": number 
        "asymmetric_fees": boolean
        "id": number // 598,
        "description": string // "Siembro Prendario / PESOS 2 Años / Usados",
        "valid_from": string // "2018-01-01 00:00:00",
        "valid_to": string // "2099-01-01 00:00:00",
        "brand_id": string | null // null,
        "product_type_id": number | null // null,
        "age_from": number | null// 0,
        "age_to": number | null// 80,
        "country": string | null // null,
        "state": string | null // null,
        "city": string | null // null,
        "term": number // 720,
        "max_amount": number // 5565000,
        "tax": number // 53,
        "origination_cost": number // 3,
        "insurance": number // 0,
        "insurance_provider": string | null // null,
        "subsidized_rate": string | null // null,
        "surcharge": number // 0,
        "partner_id": string | null // null,
        "partner": string | null // null,
        "warranty_management": number // 100,
        "warranty_management_currency": number // 1,
        "simulate_mode": string // "MC",
        "loan_capacity": number // 60,
        "add_expenses": boolean // true,
        "enable_technical_insurance": boolean // true,
        "enable_life_insurance": boolean // false,
        "technical_insurance_rate": number // 1.3,
        "bank_expeses": number // 0,
        "credit_debit_expense": number // 0,
        "fee_collect": number // 0.36,
        "tax_iva": number // 21,
        "currency": {
            "id": number// 2,
            "currency": string // "Peso Argentino",
            "acronym": string // "ARS"
        },
        "pure_fee_check": boolean // false,
        "risk": Array<IRisk>,
        "status": number // 1,
        "message": string | null // null,
        "customerValidation": string | null // null
    },
    "currency_id": number // 1,
    "dateFirstFee": string  // "2021-3",
    "product": {
        "currency_id": number // 1,
        "price": string // "1000000",
        "productTypeId": number // 1,
        "values": Array<
            {
                "attribute_id": number // 1,
                "value": string // "NUEVO"
            }
        >
    },
    "warranty": {
        "values": {
            "product": number // 1,
            "currency_id": number // 1,
            "declared_price": string // "1000000",
            "reference_price": string // "1000000"
        }
    }
}

export const maxFeesRequestFactory = (): IMaxFeesRequest => ({
    "newFlow": false,
    "client_tax": false,
    "amountRequest": 0,
    "splitExpenses": false,
    "commercialPolicy": {     
        "fee_weight": 0,
        "asymmetric_fees": false,
        "id": 0,
        "description": '',
        "valid_from": '',
        "valid_to": '',
        "brand_id": null,
        "product_type_id": null,
        "age_from": 0,
        "age_to": 0,
        "country": null,
        "state": null,
        "city": null,
        "term": 0,
        "max_amount": 0,
        "tax": 0,
        "origination_cost": 0,
        "insurance": 0,
        "insurance_provider": null,
        "subsidized_rate": null,
        "surcharge": 0,
        "partner_id": null,
        "partner": null,
        "warranty_management": 0,
        "warranty_management_currency": 0,
        "simulate_mode": '',
        "loan_capacity": 0,
        "add_expenses": true,
        "enable_technical_insurance": true,
        "enable_life_insurance": false,
        "technical_insurance_rate": 0,
        "bank_expeses": 0,
        "credit_debit_expense": 0,
        "fee_collect": 0,
        "tax_iva": 0,
        "currency": {
            "id": 0,
            "currency": '',
            "acronym": ''
        },
        "pure_fee_check": false,
        "risk": [],
        "status": 0,
        "message": null,
        "customerValidation": null
    },
    "currency_id": 0,
    "dateFirstFee": '',
    "product": {
        "currency_id": 0,
        "price": '',
        "productTypeId": 0,
        "values": []
    },
    "warranty": {
        "values": {
            "product": 0,
            "currency_id": 0,
            "declared_price": '',
            "reference_price": ''
        }
    }
})

export const maxFeesFactory = (): IMaxFeesResponse => ({
    12: [],
    6: [],
    3: [],
    1: []
})

export interface IMaxFeesResponse {
    12: Array<{
        "avg_fee": number,
        "avg_fee_low_weight": number,
        "number": number // 1,
        "max_amount": number// 233067
    }>,
    6: Array<{
        "avg_fee": number,
        "avg_fee_low_weight": number,
        "number": number // 1,
        "max_amount": number // 233067
    }>,
    3: Array<{
        "avg_fee": number,
        "avg_fee_low_weight": number,
        "number": number// 1,
        "max_amount": number // 233067
    }>,
    1: Array<{
        "avg_fee": number,
        "avg_fee_low_weight": number,
        "number": number // 1,
        "max_amount": number // 233067
    }>
}
/////////////////////////////////
export interface IDownloadPDFRequest {
    "showApplicantData": boolean //true,
    "applicant": {
        "CUIT": string //"asdasd",
        "name": string //"asdasd",
        "email": string//"asdasd",
        "productCategory": string //"asdasd",
        "channel": string //"Concesionario"
    },
    "showOriginationCost": boolean // true,
    "showDataEntry": boolean // true,
    "showMoreInfo": boolean // true,
    "showSubsidizedInfo": boolean // false
}

export interface IDownloadPDFResponse {
    "status": string
    "data": string
}

//////////////

export interface IVerifyNipRequest {
    rfc: string
}

export interface IVerifyNipRequestResponse {
    verified: boolean
}

export interface IGenerateNipRequest {
    rfc: string
    email: string
}

export interface IGenerateNipResponse {}


export interface IValidateNipRequest {
    nip_number: string
    rfc: string
}

export interface IValidateNipResponse {}

//////////////

export type TGetDocumentationResponse = Array<{
    "name": string
    "status": number
}> 