import React, { useEffect, useState } from 'react';
import { ILoanRequest, loanRequestFactory } from '../stores/loanRequest/loanRequest.model.store'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout';
import { useHistory, useParams } from 'react-router';
import { LoanRequestDetailModule } from '../modules/LoanRequestDetail.module';
import { IonLoading, useIonViewDidEnter } from '@ionic/react';
import { IDownloandPdfRequest } from '../stores/simulate/simulate.model.store';
import dataLayer from '../ga.js'
import { IUserStore } from '../stores/user/user.use.store';
import { TActionResult } from '../utils/useStore.util';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { ISetupStore } from '../stores/setup/setup.interface.store';
import { ILoanRequestStore } from '../stores/loanRequest/loanRequest.interface.store';

interface ILoanRequestsProps {
    userStore: IUserStore
    notificationStore: INotificationStore
    loanRequestStore: ILoanRequestStore
    setupStore: ISetupStore
    onApiClientError: () => void
}

export const LoanRequestDetailPage: React.FC<ILoanRequestsProps> = (props: ILoanRequestsProps) => {

    const param = useParams<{id: string}>()
    const history = useHistory()

    const [isChangeUrl, setIsChangeUrl] = useState<boolean>(false)

    const [ isLoadingData, setIsLoadingData ] = React.useState<boolean>(false)
  
    React.useEffect(()=>{
        if(isChangeUrl) {
            loadLoanRequestDetailActionHandler(Number(param.id))
            setIsChangeUrl(false)
        }
    }, [isChangeUrl])


    useIonViewDidEnter(()=>{
        setIsChangeUrl(true)
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'loanRequest detail',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });
    })


    const onRedirectToOnBoardingHandler = () => {
        history.push('/onBoarding')
    }

    const getCurrentLoanRequest = ()=> {
        const currentLoanRequestFinded = props.loanRequestStore.loanRequests.find(loanRequest => loanRequest.id === Number(param.id))
        if(currentLoanRequestFinded) return currentLoanRequestFinded
        return loanRequestFactory()
    }

    const loadLoanRequestsActionHandler = async () => {
        setIsLoadingData(true)
        const result = await props.loanRequestStore.loadLoanRequestsAction()
        setIsLoadingData(false)
        if(result.isError) props.onApiClientError()
        return result
    }
    
    const loadLoanRequestDetailActionHandler = async (id: number) => {
        setIsLoadingData(true)
        const result = await props.loanRequestStore.loadLoanRequestDetailAction(id)
        setIsLoadingData(false)
        if(result.isError) props.onApiClientError()
        return result
    }

    const downloadLoanRequestFeePDFAction = async (id: number, param: IDownloandPdfRequest) => {
        setIsLoadingData(true)
        const result = await props.loanRequestStore.downloadLoanRequestFeePDFAction(id, param)
        setIsLoadingData(false)
        if(result.isError) props.onApiClientError()
        return result
    }

    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoadingData}
                onDidDismiss={() => setIsLoadingData(false)}
                message={'Cargando...'}
            />

            <LoanRequestDetailModule
                loanRequest={getCurrentLoanRequest()}
                onDownloadFeePDF={downloadLoanRequestFeePDFAction}
                loadLoanRequestsAction={loadLoanRequestsActionHandler} // tengo que ver que hago con esta propiedad
                isMobile={props.setupStore.isMobile}
                onRedirectToOnBoarding={onRedirectToOnBoardingHandler}
                loanRequestId={Number(param.id)}
            />
        </ContentWithNavigationLayout>
    )
}