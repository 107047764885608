import { setup } from '../../utils/setup'
import { IApiClientResponse, TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'
import { getCountryAlias } from '../setup/setup.use.store'
import { ICategoryResponse, IFieldGroup, IGetDinamicFormsResponse, IGetDinamicFormsResponseAdapted, IProductTypeResponse, ISendSimulateForConvertRequest, ISendSimulateForConvertRequestMexico, ISendSimulateForConvertResponse, IStringField } from './convert.model.store'

export const useConvertService = () => {

    const baseApiClient = useApiClient()

    const getProductType = async (id: number): TApiClientResponse<IProductTypeResponse> => {
        const response = await baseApiClient.get<IProductTypeResponse>(`${setup.apiBaseUrl}/v1/product/type/${id}`)
        return response
    }

    const getProductCategories = async (): TApiClientResponse<ICategoryResponse> => {
        const response = await baseApiClient.get<ICategoryResponse>(`${setup.apiBaseUrl}/v1/product/category`)
        return response
    }

    const sendRequestSimulate = async (param: ISendSimulateForConvertRequest): TApiClientResponse<ISendSimulateForConvertResponse> => {
        const response = await baseApiClient.post<ISendSimulateForConvertResponse>(`${setup.apiBaseUrl}/v2.1/loan-request/store`, param)
        return response
    }

    // const sendRequestSimulateMexico = async (param: ISendSimulateForConvertRequestMexico): TApiClientResponse<ISendSimulateForConvertResponse> => {
    //     const response = await baseApiClient.post<ISendSimulateForConvertResponse>(`${setup.apiBaseUrl}/v2.1/loan-request/store`, param)
    //     return response
    // }

    
    const getDinamicForms = async (riskPolicieId: number): TApiClientResponse<IGetDinamicFormsResponse> => {
        const response = await baseApiClient.get<IGetDinamicFormsResponse>(`${setup.riskApiUrl}/riskPolicy/${riskPolicieId}`)
        return response
    }


    // const getDinamicFormsMexico = async (policieId: number): TApiClientResponse<IGetDinamicFormsResponseAdapted> => {
    //     const response = await baseApiClient.get<IGetDinamicFormsResponse>(`${setup.apiBaseUrl}/v2/commercial-policy/${policieId}/forms`)
    //     if (response.data.data) return {
    //         ...response,
    //         data: {
    //             data: response.data.data,
    //             status: response.data.status
    //         }
    //     } 
    //     else return {
    //         ...response,
    //         data: {
    //             data: [],
    //             status: response.data.status
    //         }
    //     }

    // }

    return { getProductType, getProductCategories, sendRequestSimulate, getDinamicForms }
}