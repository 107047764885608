import { useState, useEffect } from 'react';
import { useProfileService } from './user.service.store'
import { setup } from '../../utils/setup';
import { TActionResult, useStoreUtil } from '../../utils/useStore.util'
import { deleteToken } from '../../utils/token.util';
import { IProfileApiResponse, IProfileApiResponseAdapted, profileAdaptedFactory, profileApiResponseAdapter } from './user.model.store';
import { prettyFormat } from '@testing-library/react';


let isGlobalAuth: boolean = false

window.onbeforeunload = function onbeforeunloadHandler() : string | null {
    return isGlobalAuth ? "¿Seguro que quieres salir?" : null
}

export interface IUserStore {
    profile: IProfileApiResponseAdapted['data'],
    getProfileAction: ()=> TActionResult<boolean>
    autenticateDefaultUser: () => TActionResult<null>
    logOutUser: () => TActionResult<null>
    isSeparateExpensesEnabled: boolean
    isFinishedUserAuthenticate: boolean
    isUserAuntenticated: boolean
    isDefaultUserAuntenticated: boolean
    isFinishedDefaultUserAuthenticate: boolean
}

interface IUseUserParams {
    onSetUserEmail: (userEmail: string) => void
}

type TUseUserStore = (props: IUseUserParams) => IUserStore

export const useUserStore: TUseUserStore = (props) => {

    const profileService = useProfileService()

    const [profile, setProfile] = useState<IUserStore['profile']>(profileAdaptedFactory())
    const [isSeparateExpensesEnabled, setIsSeparateExpensesEnabled] = useState(false)
    
    const [isFinishedUserAuthenticate, setIsFinishedUserAuthenticate] = useState<boolean>(false)
    const [isUserAuntenticated, setIsUserAuthenticated] = useState<boolean>(false)
    const [isDefaultUserAuntenticated, setIsDefaultUserAuthenticated] = useState<boolean>(false)
    const [isFinishedDefaultUserAuthenticate, setIsFinishedDefaultUserAuthenticate] = useState<boolean>(false)

    const storeUtils = useStoreUtil()
    
    const getProfileAction = async () => {
        
        const profileResponse = await profileService.getProfile()
        if(profileResponse.isSuccess) {
            const profileResponseAdapted = profileApiResponseAdapter(profileResponse.data)
            setProfile(profileResponseAdapted.data)    

            if(profileResponse.data.data.availableServices !== null) {
                const serviceFinded = profileResponseAdapted.data.availableServices.find(serviceEnabled => serviceEnabled === 'SEPARA_GASTOS')
                setIsSeparateExpensesEnabled(!!serviceFinded)
            }
            
            props.onSetUserEmail(profileResponse.data.data.email)
            setIsUserAuthenticated(true)
            setIsFinishedUserAuthenticate(true)
            isGlobalAuth = true
            return storeUtils.createSuccessResult(true)
        }else if(profileResponse.status === 401) {
            setIsUserAuthenticated(false)
            setIsFinishedUserAuthenticate(true)
            return storeUtils.createSuccessResult(false)
        }
        
        return storeUtils.createErrorResult(false)
    }

    const autenticateDefaultUser = async (): TActionResult<null> => {
        logOutUser()
        const response = await profileService.getToken('merchant@siembro.com', '123456')
        if(response.isError) {
            setIsDefaultUserAuthenticated(true)
            setIsFinishedDefaultUserAuthenticate(true)
            return storeUtils.createErrorResult(null)
        }

        setup.token = response.data
        setIsDefaultUserAuthenticated(true)
        setIsFinishedDefaultUserAuthenticate(true)
        return storeUtils.createSuccessResult(null)
    }


    const logOutUser = async () => {
        deleteToken(setup.cookieAuthKey, setup.cookieAuthDomain)
        return storeUtils.createErrorResult(null)
    }

    return {
        profile,
        getProfileAction,
        autenticateDefaultUser,
        isSeparateExpensesEnabled,
        isFinishedUserAuthenticate,
        isUserAuntenticated,
        isDefaultUserAuntenticated,
        isFinishedDefaultUserAuthenticate,
        logOutUser
    }
}