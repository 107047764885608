import { useEffect, useState } from 'react'
import { timer } from '../utils/timer'
import styled from 'styled-components'
import { IonButton, IonCol, IonRow } from '@ionic/react'
import logo from '../assets/small-logo.svg'

const InstallAppBackDropModalShadow = styled.div<{ isOpen: boolean }>`
    display: flex;
    opacity: ${props => props.isOpen ? '1' : '0'};
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0,0,0,0.3);
    /* height: 100vh;
    width: 100%; */
    margin: 0px;
    top: 57px;
    right: 0px;
    z-index: 700;
    transition: opacity .2s ease-in;
`

export const InstallAppModalWrapper = styled.div`
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 5px grey;
    max-height: 90%;
    @media (min-width: 400px) {
        width: 400px;
    }
    @media (prefers-color-scheme: dark) {
        background: #222;
    }
`

export const InstallAppModalHeader = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding:0px;
    margin:0px;
`

export const InstallAppModalContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 80vh;
    width: 100%;
    padding:0px;
    margin:0px;
`

export const InstallAppModalCloseButton = styled.button`
    background: none;
    border: none;
    padding: 10px;
    font-size: 25px;
    color:#bbb;
    cursor: pointer;
    font-weight: lighter;
    &:before {
        content: "✖";
    }
    @media (prefers-color-scheme: dark) {
        color: #ccc;
    }
`

export const Text = styled.p`
    color: #333;
    padding: 10px;
    @media (prefers-color-scheme: dark) {
        color: #ccc;
    }
`

export const Title = styled.h1`
    color: #333; 
    marginLeft: 20px; 
    textAlign: center; 
    width: 100%;
    fontSize: 18px;
    marginTop: 10px;
    text-align: center;
    padding-left: 50px;
    @media (prefers-color-scheme: dark) {
        color: #ccc;
    }
`

export const InstallAppModal = (props: {
    isOpen: boolean,
    onClose: () => void
    onIntall: () => void
}) => {

    const [isRendered, setIsRendered] = useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = useState<boolean>(false)

    useEffect(() => {
        if (props.isOpen !== isRendered) changeAnimation(props.isOpen)
    }, [props.isOpen])

    const changeAnimation = async (isOpen: boolean) => {
        if (props.isOpen) {
            setIsRendered(true)
            await timer(100)
            setIsOpenAnimation(true)
        } else {
            setIsOpenAnimation(false)
            await timer(600)
            setIsRendered(false)
        }

    }

    const onClickHandler = () => {
        props.onClose()
    }

    return (
        <div>
            {isRendered ? (
                <InstallAppBackDropModalShadow isOpen={isOpenAnimation}>
                    <InstallAppModalWrapper>
                        <InstallAppModalHeader>
                            <InstallAppModalCloseButton onClick={onClickHandler}></InstallAppModalCloseButton>
                            <Title>Instala Siembro en tu Dispositivo</Title>
                        </InstallAppModalHeader>
                        <InstallAppModalContent>
                            <IonRow style={{margin: '0px', padding: '0px'}}>
                                <IonCol className="ion-text-center" size="12">
                                    <Text>Entérate de las novedades directo en tu dispositivo sin abrir la APP. Solo tienes que instalar la APP ¿Lo hacemos?</Text>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-text-center" size="12">
                                    <IonButton color="primary" fill="clear" onClick={() => props.onIntall()}>SI, INSTALAR</IonButton>
                                    <br />
                                </IonCol>
                            </IonRow>

                        </InstallAppModalContent>
                    </InstallAppModalWrapper>
                </InstallAppBackDropModalShadow>
            ) : null}
        </div>
    )
}



////////////////

export const algo = () => {



}