import { IApiClientResponse, TApiClientResponse, useApiClient } from "../../utils/ApiClient.util"
import { setup } from "../../utils/setup"
import { INotificationModel } from "./notification.model.store"

export const useNotificationService = () => {

    const apiClient = useApiClient()

    const getNotificationList = async (): TApiClientResponse<Array<INotificationModel>> => {
        const response = await apiClient.get<Array<INotificationModel>>(`${setup.apiUrl}/v1/notifications`)
        return response

        // return {
        //     isSuccess: true,
        //     isError: false,
        //     data: notificationListMock,
        //     status: 200,
        //     method: 'GET',
        //     url: '',
        //     body: {}
        // }
    }

    const updateNotificaction = async (notification: INotificationModel): TApiClientResponse<null> => {
        const response = await apiClient.put<null>(`${setup.apiUrl}/v1/notifications/${notification.id}`, notification)
        return response

        // return {
        //     isSuccess: true,
        //     isError: false,
        //     data: null,
        //     status: 200,
        //     method: 'PUT',
        //     url: '',
        //     body: {}
        // }
    }

    const deleteNotification = async (notification: INotificationModel): TApiClientResponse<null>=> {
        const response = await apiClient.del<null>(`${setup.apiUrl}/v1/notifications/${notification.id}`)
        return response

        // return {
        //     isSuccess: true,
        //     isError: false,
        //     data: null,
        //     status: 200,
        //     method: 'DELETE',
        //     url: '',
        //     body: {}
        // }
    }

    return { getNotificationList, deleteNotification, updateNotificaction }
}



// const notificationListMock: Array<INotificationModel> = [
//     {
//         'id': '1',
//         'title': 'lalalala',
//         'content': 'lelelele',
//         'date': new Date(),
//         'read': false,
//     },
//     {
//         'id': '2',
//         'title': 'lalalala2',
//         'content': 'lelelele2',
//         'date': new Date(),
//         'read': false,
//     },
//     {
//         'id': '3',
//         'title': 'lalalala3',
//         'content': 'lelelele3',
//         'date': new Date(),
//         'read': false,
//     },
//     {
//         'id': '4',
//         'title': 'lalalala',
//         'content': 'lelelele',
//         'date': new Date(),
//         'read': true,
//     },
//     {
//         'id': '5',
//         'title': 'lalalala2',
//         'content': 'lelelele2',
//         'date': new Date(),
//         'read': true,
//     },
//     {
//         'id': '6',
//         'title': 'lalalala3',
//         'content': 'lelelele3',
//         'date': new Date(),
//         'read': true,
//     }
// ]
