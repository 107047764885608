import { IonCol, IonLabel, IonRow, IonSegment, IonSegmentButton } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import React from 'react'
import { SearchProductFormSelect } from '../SearchProductForm/SearchProductForm.element'
import { OptionButton, OptionButtonContainer, Row, Cell, Options, Option, InputWrapper, Label, Order, ShowWhenIsNotSmallMobile, ShowWhenIsMobileAndDesktop } from './ComercialPolicyMainFilter.element'

interface IOptionButtonComponentProps {
    onClick: (value: string) => void
    selected: string
    options: Array<{ label: string, value: string }>
}
const OptionButtonComponent: React.FC<IOptionButtonComponentProps> = (props) => {

    return (
        <OptionButtonContainer>
            {props.options.map(option => (
                <OptionButton className={`option-${option.value}`} selected={props.selected === option.value} key={option.value} onClick={() => props.onClick(option.value)}>{option.label}</OptionButton>
            ))}
        </OptionButtonContainer>
    )
}

interface IComercilPolicyMainFilterProps {
    statusFilter: 'ALL' | 'ENABLED'
    currencyFilter: 'USD' | 'PESOS'
    onStatusFilterChange: (value: 'ALL' | 'ENABLED') => void
    onCurrencyFilterChange: (value: 'USD' | 'PESOS') => void
    orderValue: 'More convenient' | 'Best rate' | 'Best amount'
    onChangeOrderValue: (newValue: 'More convenient' | 'Best rate' | 'Best amount') => void
    countryAlias: 'ar' | 'mx'
}

export const ComercialPolicyMainFilter: React.FC<IComercilPolicyMainFilterProps> = (props) => {

    const onStatusFilterChangeHandler = (value: string) => {
        return (value === 'ALL' || value === 'ENABLED') && props.onStatusFilterChange(value)
    }

    const onCurrencyFilterChangeHandler = (value: string) => (value === 'USD' || value === 'PESOS') && props.onCurrencyFilterChange(value)

    const onChangeOrderValueHandler = (value: string) => {
        if (value === 'More convenient') return props.onChangeOrderValue('More convenient')
        if (value === 'Best rate') return props.onChangeOrderValue('Best rate')
        if (value === 'Best amount') return props.onChangeOrderValue('Best amount')
    }

    return (
        <>
            <ShowWhenIsNotSmallMobile>
                <Row align='center' style={{ padding: '20px 0px' }}>
                    <Cell align="left" width={33.33}>
                        <Order className='selectOrderOption' value={props.orderValue} onChange={e => onChangeOrderValueHandler(e.target.value)}>
                            <option value={'More convenient'}>Mas conveniente</option>
                            <option value={'Best rate'}>Mejor tasa</option>
                            <option value={'Best amount'}>Mejor monto</option>
                        </Order>
                    </Cell>
                    <Cell align="center" width={33.33}>
                        <OptionButtonComponent options={[{ value: 'ALL', label: 'TODOS' }, { value: 'ENABLED', label: 'SOLO APTOS' }]} selected={props.statusFilter} onClick={onStatusFilterChangeHandler} />
                    </Cell>
                    {props.countryAlias === 'ar' ? (
                        <Cell align="right" width={33.33}>
                            <OptionButtonComponent options={[{ value: 'USD', label: 'USD' }, { value: 'PESOS', label: 'PESOS' }]} selected={props.currencyFilter} onClick={onCurrencyFilterChangeHandler} />
                        </Cell>
                    ) : (null)}
                </Row>
            </ShowWhenIsNotSmallMobile>
            <ShowWhenIsMobileAndDesktop>
                <Row align='center' style={{ padding: '20px 0px' }}>
                    <Cell align="left" width={50}>
                        <OptionButtonComponent options={[{ value: 'ALL', label: 'TODOS' }, { value: 'ENABLED', label: 'SOLO APTOS' }]} selected={props.statusFilter} onClick={onStatusFilterChangeHandler} />
                    </Cell>
                    {props.countryAlias === 'ar' ? (
                        <Cell align="right" width={50}>
                            <OptionButtonComponent options={[{ value: 'USD', label: 'USD' }, { value: 'PESOS', label: 'PESOS' }]} selected={props.currencyFilter} onClick={onCurrencyFilterChangeHandler} />
                        </Cell>
                    ) : (null)}

                </Row>
            </ShowWhenIsMobileAndDesktop>
        </>
    )
}