import { TForm } from "FormGenerator"

export type TAttributes = {
  "attribute_id": number
  "parent_attribute_id": number | null
  "name": string //"Condicion",
  "title": string //"Estado",
  "placeholder": string //"Seleccioná el Estado",
  "type": "number" | "string" | "option",
  "order": 1,
  "is_required": boolean,
  "options": Array<{
    "option_id": number,
    "value": string,
    "attribute_id": number,
    "parent_option_id": number | null
  }>
}

export interface IProductTypeResponse {
  status: string,
  data: {
    "id": number,
    "name": string,
    "attributes": Array<TAttributes>
  }
}

/////////////////////////////////////////////////////

export interface ICategory {
  "id": number,
  "name": string,
  "is_warranted": boolean,
  "weigh": { "id": number, "term": number, "weigh": number },
  "product_types": Array<{ "id": number, "name": string }>
}

export interface ICategoryResponse {
  "status": string,
  "data": Array<ICategory>
}

///////////////////////////////////////////


export interface IInputForConvert {
  "key": string,
  "value": string,
  "type": "string" | "number" | "json"
}


export interface ISendSimulateForConvertRequestMexico {
  "simulation_id": number //28,
  "product": {
      "values": Array<{
          "attribute_id": number //1,
          "value": string //"NUEVO"
      }>,
      "currency_id": number //2,
      "productTypeId": number //1,
      "is_warranted": boolean //true,
      "nombre": string // "//TRACTOR",
      "price": string //"250000"
  },
  "loanRequest": {
      "feeWeight": string // "0,4",
      "frecuency": number // 3,
      "term": number // "12 meses",
      "warranty_type": string // "prenda",
      "loanRequestType": string // "MAQUINRIA",
      "requestAmount": number // 25000
  },
  "score": number // 1,
  "warranties": Array<{
      "currency_id": number //2,
      "declared_price": string //"250000",
      "productTypeId": number //1,
      "values": Array<{
          "attribute_id": number //1,
          "value": string //"NUEVO"
      }>
  }>
  "forms": Array<{
      "id": number,
      "inputs": Array<{
          "id": number,
          "label": string,
          "values": Array<number | string>
      }>
  }>
}
export interface ISendSimulateForConvertRequest {
  "simulation_id": number //28,
  "product": {
    "values": Array<{
      "attribute_id": number //1,
      "value": string //"NUEVO"
    }>,
    "currency_id": number //2,
    "productTypeId": number //1,
    "is_warranted": boolean //true,
    "nombre": string // "//TRACTOR",
    "price": string //"250000"
  },
  "loanRequest": {
    "feeWeight": string // "0,4",
    "frecuency": number // 3,
    "term": number // "12 meses",
    "warranty_type": string // "prenda",
    "loanRequestType": string // "MAQUINRIA",
    "requestAmount": number // 25000
  },
  "score": number // 1,
  "warranties": Array<{
    "currency_id": number //2,
    "declared_price": string //"250000",
    "productTypeId": number //1,
    "values": Array<{
      "attribute_id": number //1,
      "value": string //"NUEVO"
    }>
  }>
  "inputs": Array<IInputForConvert>
}

export interface ISendSimulateForConvertResponse {
  "loan_request_id": number
  "partner_name": string
  "contact_id": number
}

export const productTypeRequestFactory = () => ({
  id: 0,
  name: '',
  attributes: []
})


//////////////

export interface IOptionField {
  type: "option"
  id: number
  description: string
  name: string
  label: string
  required: boolean
  onboarding: boolean
  list: Array<{
    label: string
    value: string
    next_step: number
  }>
}

export interface IStringField {
  type: "string"
  id: number
  description: string
  name: string
  label: string
  required: boolean
  onboarding: boolean
}

export interface IJsonField {
  type: "json"
  id: number
  description: string
  name: string
  label: string
  required: boolean
  onboarding: boolean
}

export interface INumberField {
  type: "number"
  id: number
  description: string
  name: string
  label: string
  required: boolean
  onboarding: boolean
}

export interface IFieldGroup {
  id: number
  description: string
  name: string
  type: "normal"
  hidden: false
  next_step: number
  fields: Array<INumberField | IStringField | IOptionField | IJsonField>
}

export interface IGetDinamicFormsResponse {
  "id": number
  "name": string
  "is_active": boolean,
  "description": string,
  "active_trace_id": number
  "fields_group": Array<IFieldGroup>
  "schema": Array<{
      "id": number,
      "description": string,
      "name": string,
      "type": string,
      "label": string,
      "options": Array<string>
  }>,
}


interface IDinamicForm {
  id: number
  name: string
  inputs: TForm
}

export interface IGetDinamicFormsResponse {
  data?: Array<IDinamicForm>
  status: "success"
}

export interface IGetDinamicFormsResponseAdapted {
  data: Array<IDinamicForm>
  status: "success"
}