const getCookieHelper = (cname: string): string => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getTokenToCookie = (key: string) => {
    return getCookieHelper(key)
}
const setTokenToLocalStorage = (token: string) => localStorage.setItem('token', token)

const getTokenToLocalStorage = ()=> {
    const token = localStorage.getItem('token')
    return token ? token : ''
}

const deleteTokenToLocalStorage = ()=> localStorage.removeItem('token')
const deleteTokenToCookie = (cookieName: string, cookieDomain: string) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    document.cookie = `${cookieName}=; domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const setToken= (key: string) => getTokenToCookie(key) && setTokenToLocalStorage(getTokenToCookie(key))
export const getToken = (key: string): string => getTokenToCookie(key) || getTokenToLocalStorage()

export const deleteToken = (cookieName: string, cookieDomain: string) => {
    deleteTokenToLocalStorage()
    deleteTokenToCookie(cookieName, cookieDomain)
}
