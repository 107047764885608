import { setup } from '../../utils/setup'
import { TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'

export interface ISendRequestParam {
    cuit: string, 
    activity: 'Contratista' | 'Productor' |'Ambos'
}


interface IVerificationAcceptanceRequirementsNoWarranty {
    "data": {
        "status": 'ACEPTADO' | 'DENEGADO' | 'ERROR',
        "message":string,
        "limits":Array<{"acronym":string,"value":number,"currency_id": number}> // mostrar esto
        "executionId": string
    }
}

interface IVerificationTrack {
    "data":{
        "carril": "Amarillo " | "Rojo " | "Verde " | "Error",
        "message": string 
        "executionId": string
    }
}


export interface ICuitValidate {
    "data": { 
        "name": string, 
        "reject"?: {
            "message": string,
        },
        "activity_info"?:{
            "afip_activity": string//"Productor",
            "afip_activity_id": number // 11119,
            "days_passed": number //2653
        }
    }
}


interface IVerificationBankFirstPolicy {
    "status": string,
    "data": {
        "executionId": string,
        "message": string,
        "status": 'ACEPTADO' | 'DENEGADO' | 'ERROR'
    }
}

interface ISecondVerificationBankFirstPolicy{
    "status": string,
    "data": {
        "executionId"?: string //"6862612",
        "status": "ACEPTADO" | "RECHAZADO" | "ERROR"
        "message": string // "  No cumple con los ratios esperados, con campo propio"
    }
}




interface IVerificationAcceptanceRequirementsFirst {
    "data":{
        "executionId": string,
        "message"?: string,
        "status": 'ACEPTADO' | 'DENEGADO' | 'ERROR'
    }
}

export const useCuitService = ()=> {
    const baseApiClient = useApiClient()
    
    const getVerificationBankFirstPolicy = async (param: { cuit: string }): TApiClientResponse<IVerificationBankFirstPolicy> => {
        const response = await baseApiClient.post<IVerificationBankFirstPolicy>(`${setup.apiBaseUrl}/v2/risk/bank/first-policy`, param)
        return response
    }

    const getSecondVerificationBankFirstPolicy = async (param: {
        cuit: string
        finnancial_debt: number
        avg_billing: number
        rented_has: number
        own_has: number
        new_line: number
    }): TApiClientResponse<ISecondVerificationBankFirstPolicy> => {        
        const response = await baseApiClient.post<ISecondVerificationBankFirstPolicy>(`${setup.apiBaseUrl}/v2/risk/bank/second-policy`, param)
        return response
    }

    const getCuitValidateAction = async (param: { cuit: string }) : TApiClientResponse<ICuitValidate> => {
        const response = await baseApiClient.post<ICuitValidate>(`${setup.apiBaseUrl}/v2/cuit/validate`, param)
        return response
    }
    
    const getVerificationAcceptanceRequirementsFirst = async (param: {
        action: "creditcheck"
        afip_activity: string
        afip_activity_id: number | string
        cuit: string
        days_passed: number
        declared_activity: ISendRequestParam['activity']
        full_name: string
    }): TApiClientResponse<IVerificationAcceptanceRequirementsFirst> => {
        const response = await baseApiClient.post<IVerificationAcceptanceRequirementsFirst>(`${setup.apiBaseUrl}/v2/risk/acceptance-requirements/first`, param)
        return response
    }

    const getVerificationAcceptanceRequirementsNoWarranty = async (param: { 
        afip_activity_id: string
        cuit: string
        full_name: string
        own_farm: number
        declared_activity: 'Productor' | 'Contratista' | 'Ambos'
    }): TApiClientResponse<IVerificationAcceptanceRequirementsNoWarranty> => {
        const response = await baseApiClient.post<IVerificationAcceptanceRequirementsNoWarranty>(`${setup.apiBaseUrl}/v2/risk/acceptance-requirements/no-warranty`, param)
        return response
    }

    const getVerificationTrack = async (param: {
        afip_activity: string
        alias: string
        cuit: string
        declared_activity: 'Productor' | 'Contratista' | 'Ambos'
    }) : TApiClientResponse<IVerificationTrack> => {
        const response = await baseApiClient.post<IVerificationTrack>(`${setup.apiBaseUrl}/v2/risk/track`, param)
        return response
    }


    return {
        getSecondVerificationBankFirstPolicy,
        getVerificationBankFirstPolicy,
        getCuitValidateAction,
        getVerificationAcceptanceRequirementsFirst,
        getVerificationAcceptanceRequirementsNoWarranty,
        getVerificationTrack,
    }
}
