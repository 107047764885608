
export interface IActionResult<IData> {
    isSuccess: boolean
    isError: boolean
    data: IData
}


export type TActionResult<IData> = Promise<IActionResult<IData>>

export const useStoreUtil = () => ({
    createSuccessResult: <IData>(data: IData): IActionResult<IData> => ({
        isSuccess: true,
        isError: false,
        data: data
    }),

    createErrorResult: <IData>(data: IData): IActionResult<IData> => ({
        isSuccess: false,
        isError: true,
        data: data
    })
})