import { IonList, IonLabel, IonItem, IonCard, IonHeader, IonToolbar, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonButtons } from '@ionic/react';
import React, { useState } from 'react';
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'
import { dateFormaterUtil } from '../utils/dateFormater.util';
import { addSeparatorsToNumber } from '../utils/number.util';

interface IFeeDetailComponentProps {
    fee: ILoanRequest['fee']
    loanRequestId: ILoanRequest['id']
    showPDFButton: boolean
    simulationId: number
    onDownloadPDF(id: number): void
    feeTaxMode: 1 | 2
}

// todo: indicar la cantidad de dias que faltan

export const FeeDetailComponent: React.FC<IFeeDetailComponentProps> = ({ fee, showPDFButton, onDownloadPDF, loanRequestId, simulationId, feeTaxMode }) => {
    
    const [ isDownloadingPDF, setIsDownloadingPDF ] = useState<boolean>(false)

    const onClickDownloadPDFHandler = async () => {
        if(isDownloadingPDF) return
        setIsDownloadingPDF(true)
        await onDownloadPDF(simulationId)
        setIsDownloadingPDF(false)
        return
    }

    return (
        <div>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="primary" onClick={()=>onClickDownloadPDFHandler()}>
                        <IonButton disabled={isDownloadingPDF} color="primary">{isDownloadingPDF ?  'Descargando...' : 'Descargar PDF' }</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {
                fee.fees.map(fee => (
                    <IonCard key={fee.id}>

                        <IonCardHeader>
                            <IonCardSubtitle>A PAGAR EL {dateFormaterUtil(fee.date)}</IonCardSubtitle>
                            <IonCardTitle>{fee.acronym} {addSeparatorsToNumber(fee.amount)}</IonCardTitle>
                        </IonCardHeader>

                        <IonList>
                            <IonItem lines="inset">
                                <IonLabel>Amortización</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.amortization)}</p>
                            </IonItem>
                            <IonItem lines="inset">
                                <IonLabel>Intereses</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.interest)}</p>
                            </IonItem>

                            <IonItem lines="inset">
                                <IonLabel>Sub Total</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.sub_total)}</p>
                            </IonItem>

                            <IonItem lines="inset">
                                <IonLabel>IVA{feeTaxMode === 1 ? '(recupero)' : ''}</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(feeTaxMode === 1 ? fee.rate_recovery_fee : feeTaxMode === 2 ? fee.tax_interest : 0)}</p>
                            </IonItem>

                            <IonItem lines="inset">
                                <IonLabel>Cuota Pura</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.pure_fee)}</p>
                            </IonItem>


                            <IonItem lines="inset">
                                <IonLabel>Seguro técnico</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.technical_insurance)}</p>
                            </IonItem>

                            <IonItem lines="inset">
                                <IonLabel>Cobranza</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.fee_collection)}</p>
                            </IonItem>
                        </IonList>
                    </IonCard>
                ))
            }
        </div>
    );
};