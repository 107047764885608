import React, { useState } from 'react';
import { ISimulateListResponse, IProductTypeResponse, ICategoryResponse, ISimulate } from '../stores/simulate/simulate.model.store'
import { DownloadSimulateModalComponent, TOnSubmitParam } from '../components/DownloadSimulateModal.component'
import { IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonRow, useIonViewDidEnter } from '@ionic/react';
import { add, arrowDown, arrowForward, arrowUp } from 'ionicons/icons';
import { NoDataComponent } from '../components/NoData.component';
import { ISimulateToConvertModalComponent, SimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component';

import { ITableGeneratorComponent, TableGeneratorComponent } from '../components/TableGenerator.component'
import { addSeparatorsToNumber } from '../utils/number.util';
import { IGetDinamicFormsResponse, IGetDinamicFormsResponseAdapted, ISendSimulateForConvertResponse } from '../stores/convert/convert.model.store';
import { TActionResult } from '../utils/useStore.util';
import { FormConsumerComponentProps } from 'FormConsumer';
import { IInputNumber, IInputOptions, IInputText, ITitleElement } from 'FormGenerator';

interface IOnBoardingModule {
    simulateList: ISimulateListResponse['data']
    productCategories: ICategoryResponse['data']
    productTypeForSendConvertRequest: IProductTypeResponse['data']
    warrantyProductTypeForConvert: IProductTypeResponse['data']
    formSectionList: FormConsumerComponentProps['formSectionList']
    // dinamicFormsResponse: IGetDinamicFormsResponseAdapted['data']

    convertResponse: ISendSimulateForConvertResponse
    isMobile: boolean
    countryAlias: 'ar' | 'mx'

    getSimulatesList(): Promise<void>
    onDownload(id: number, options: TOnSubmitParam): void
    onDelete(id: number): Promise<void>
    getProductTypeForConvert(id: number): void
    onGetWarrantyProductTypeForConvert(id: number): void
    // convertSimulateToLoanRequestForMexico(
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponse['data'],
    //     simulate: ISimulate

    // ): TActionResult<null>
    convertSimulateToLoanRequest(
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList'],
        simulate: ISimulate
    ): Promise<void>
    getDinamicForms(policieId: number): TActionResult<null>
    // getDinamicFormsMexico(policieId: number): TActionResult<null>
    onRedirectToLoanRequestDetail: (loanRequestId: number) => void
    onRedirectToEditSimulate: (simulateId: number) => void
    onRedirectToSimulateDetail: (simulateId: number) => void
    onRedirectToOnBoaring: () => void
}

export type TOnDownload = TOnSubmitParam

export const SimulatesModule = (props: IOnBoardingModule) => {

    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState<boolean>(false)
    const [isOpenSendSimulateForConvertModal, setIsOpenSendSimulateForConvertModal] = useState<boolean>(false)
    const [simulateIdTarget, setSimulateIdTarget] = useState<number>(0)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [isLoadedData, setIsLoadedData] = useState<boolean>(false)
    const [isOpenConvertSimulateAlert, setIsOpenConvertSimulateAlert] = useState<boolean>(false)
    const [isOpenRemoveAlert, setIsOpenRemoveAlert] = useState<boolean>(false)

    useIonViewDidEnter(() => {
        onInit()
    })

    const onInit = async () => {
        setIsLoadingData(true)
        await props.getSimulatesList()
        setIsLoadedData(true)
        setIsLoadingData(false)
    }

    const onDeleteFromListHandler = async (id: number) => {
        setSimulateIdTarget(id)
        setIsOpenRemoveAlert(true)
    }

    const onCloseFromRemoveAlertHandler = () => {
        setIsOpenRemoveAlert(false)
    }
    const onSubmitFromRemoveAlertHandler = async () => {
        setIsLoadingData(true)
        await props.onDelete(simulateIdTarget)
        setIsOpenRemoveAlert(false)
        setIsLoadingData(false)
    }

    const onEditFromListHandler = (id: number) => {
        props.onRedirectToEditSimulate(id)
    }

    const onRedirectToSimulateDetailHandler = (id: number) => {
        props.onRedirectToSimulateDetail(id)
    }

    const onDownloadFromSimulateListHandler = (id: number) => {
        setSimulateIdTarget(id)
        setIsOpenDownloadModal(true)
    }

    const onSubmitFromDownloadSimulateModalHandler = (params: TOnDownload) => {
        props.onDownload(simulateIdTarget, params)
        setIsOpenDownloadModal(false)
    }

    const onCloseFromDownloadSimulateModalHandler = () => {
        setIsOpenDownloadModal(false)
    }

    const onCloseSendSimulateForConvertModalHandler = () => {
        setIsOpenSendSimulateForConvertModal(false)
    }

    const onOpenConvertModalHandler = async (idParam: number) => { // sta mal el nombre de esto
        setSimulateIdTarget(idParam)
        const simulateFinded = props.simulateList.find(simulate => simulate.id === idParam)
        if (!simulateFinded) return
        setIsLoadingData(true)
        await props.getProductTypeForConvert(simulateFinded.product.type_id)
        // props.getDinamicForms(simulateFinded.commercial_policy_id)

        // if(props.countryAlias === 'mx') await props.getDinamicFormsMexico(simulateFinded.commercial_policy_id)

        setIsLoadingData(false)
        setIsOpenSendSimulateForConvertModal(true)
    }

    const onGetWarrantyInputsHandler = async (productTypeId: number) => {
        setIsLoadingData(true)
        await props.onGetWarrantyProductTypeForConvert(productTypeId)
        setIsLoadingData(false)
    }

    const getProductTypeInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {

        return props.productTypeForSendConvertRequest.attributes.map(attribute => {
            if (attribute.type === 'string') {
                const inputText: IInputText = {
                    id: String(attribute.attribute_id),
                    type: 'text',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    disabled: false,
                    hidden: false,
                    error: false,
                    maxLength: 80,
                    minLength: 1,
                    size: 6,
                    regex: ''
                }

                return inputText
            }
            else if (attribute.type === 'option') {

                const inputOptions: IInputOptions = {
                    id: String(attribute.attribute_id),
                    type: 'options',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo requerido',
                    required: attribute.is_required,
                    options: attribute.options.map(option => ({
                        value: option.value,
                        label: option.value
                    })),
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6
                }

                return inputOptions
            }
            else if (attribute.type === 'number') {
                const inputNumber: IInputNumber = {
                    id: String(attribute.attribute_id),
                    type: 'number',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    error: false,
                    disabled: false,
                    hidden: false,
                    minValue: 0,
                    maxValue: 999999999999,
                    size: 6,
                }

                return inputNumber
            }

            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                size: 12,
                hidden: false,
                value: ''
            }

            return titleInput
        })
    }

    const getWarrantyInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {
        return props.warrantyProductTypeForConvert.attributes.map(attribute => {
            if (attribute.type === 'string') {
                const inputText: IInputText = {
                    id: String(attribute.attribute_id),
                    type: 'text',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    error: false,
                    maxLength: 80,
                    minLength: 1,
                    disabled: false,
                    hidden: false,
                    size: 6,
                    regex: ''
                }

                return inputText
            }
            else if (attribute.type === 'option') {
                const inputOptions: IInputOptions = {
                    id: String(attribute.attribute_id),
                    type: 'options',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo requerido',
                    required: attribute.is_required,
                    options: attribute.options.map(option => ({
                        value: option.value,
                        label: option.value
                    })),
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6
                }

                return inputOptions
            }

            else if (attribute.type === 'number') {
                const inputNumber: IInputNumber = {
                    id: String(attribute.attribute_id),
                    type: attribute.type,
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6,
                    minValue: 0,
                    maxValue: 9999999999999999
                }

                return inputNumber
            }

            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                size: 12,
                hidden: false,
                value: ''
            }

            return titleInput
        })
    }

    // const onSubmitForMexicoHandler = async (
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponse['data'],
    // ) => {
    //     const simulateFinded = props.simulateList.find(simulate => simulate.id === simulateIdTarget)
    //     if (!simulateFinded) return
    //     setIsLoadingData(true)
    //     await props.convertSimulateToLoanRequestForMexico(
    //         productFormData,
    //         warrantyFormData,
    //         warrantyDetailFormData,
    //         dinamicFormsData,
    //         simulateFinded
    //     )
    //     setIsOpenSendSimulateForConvertModal(false)
    //     setIsLoadingData(false)
    //     setIsOpenConvertSimulateAlert(true)
    // }

    const onSubmitForConvertHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList']
    ) => {
        const simulateFinded = props.simulateList.find(simulate => simulate.id === simulateIdTarget)
        if (!simulateFinded) return
        setIsLoadingData(true)
        await props.convertSimulateToLoanRequest(
            productFormData,
            warrantyFormData,
            warrantyDetailFormData,
            formSectionList,
            simulateFinded
        )
        setIsOpenSendSimulateForConvertModal(false)
        setIsLoadingData(false)
        setIsOpenConvertSimulateAlert(true)
    }

    const onViewDetailSimulateConvertedHandler = () => {
        props.onRedirectToLoanRequestDetail(props.convertResponse.loan_request_id)
    }

    const getCurrentCategoryName = (): string => {
        const simulateFinded = props.simulateList.find(simulate => simulate.id === simulateIdTarget)
        if (!simulateFinded) return ''
        return simulateFinded.product.categoria
    }

    const getCurrentProductTypeName = (): string => {
        const simulateFinded = props.simulateList.find(simulate => simulate.id === simulateIdTarget)
        if (!simulateFinded) return ''
        return simulateFinded.product.nombre
    }

    const onActionTableHandler = (action: string, key: string) => {
        if (action === 'up') return onOpenConvertModalHandler(Number(key))
        if (action === 'down') onDownloadFromSimulateListHandler(Number(key))
        if (action === 'right') onRedirectToSimulateDetailHandler(Number(key))
        if (action === 'delete') return onDeleteFromListHandler(Number(key))
        if (action === 'edit') return onEditFromListHandler(Number(key))
    }

    const onRedirectToOnBoaringHandler = () => props.onRedirectToOnBoaring()

    const onSubmitHandlerFromNoDataComponent = () => props.onRedirectToOnBoaring()

    const getPreloadDataForDownloadModal = () => {
        const simulateFinded = props.simulateList.find(simulate => simulate.id === simulateIdTarget)

        if (!simulateFinded) return {
            identification: '',
            name: '',
            email: '',
            productCategory: '',
            channel: ''
        }

        return {
            identification: simulateFinded.customer.identification_number,
            name:
                simulateFinded.customer.name ? simulateFinded.customer.name :
                    simulateFinded.customer.primer_nombre ?
                        `${simulateFinded.customer.primer_nombre} ${simulateFinded.customer.segundo_nombre} ${simulateFinded.customer.apellido_paterno} ${simulateFinded.customer.segundo_nombre}`
                        : ''
            ,
            email: '',
            productCategory: simulateFinded.product.nombre,
            channel: ''
        }
    }

    const createSkeletonRows = () => {
        let skeletonRows: ITableGeneratorComponent['bodyList'] = []
        for (let i = 0; i <= 15; i++) {
            skeletonRows.push([
                {
                    type: 'skeleton',

                },
                {
                    type: 'skeleton',
                },
                {
                    type: 'skeleton',
                },
                {
                    type: 'skeleton',
                },
                {
                    type: 'skeleton',
                },
                {
                    type: 'skeleton',

                }
            ])
        }

        return skeletonRows

    }

    const getBodyList: ITableGeneratorComponent['bodyList'] =
        isLoadingData ? createSkeletonRows()
            : props.simulateList.map(simulate => ([
                {
                    type: 'text',
                    text: simulate.customer.name ? simulate.customer.name
                        : simulate.customer.primer_nombre && simulate.customer.apellido_paterno ? `${simulate.customer.primer_nombre} ${simulate.customer.apellido_paterno}`
                            : ''
                },
                {
                    type: 'text',
                    text: `${simulate.data.date_simulate}`
                },
                {
                    type: 'text',
                    text: `#${simulate.id}`
                },
                {
                    type: 'text',
                    text: `${simulate.currency}`
                },
                {
                    type: 'text',
                    text: `${addSeparatorsToNumber(simulate.amount)}`
                },

                {
                    type: 'actions',
                    key: String(simulate.id),
                    actions: [
                        {
                            type: 'delete',
                            title: 'Eliminar',
                            text: 'Eliminar',
                            fill: 'clear',
                            color: 'primary'
                        },
                        {
                            type: 'down',
                            title: 'Descargar PDF',
                            text: 'PDF',
                            fill: 'clear',
                            color: 'primary'
                        },
                        {
                            type: 'up',
                            title: 'Guardar Solicitud',
                            text: 'Guardar Solicitud',
                            fill: 'clear',
                            color: 'primary'
                        },
                        {
                            type: 'right',
                            title: 'Ver Detalle',
                            text: 'Detalle',
                            fill: 'clear',
                            color: 'primary'
                        },
                        {
                            type: 'edit',
                            title: 'Editar Simulación',
                            text: 'Editar',
                            fill: 'clear',
                            color: 'primary'
                        }
                    ]
                }
            ]))

    return (
        <>
            <IonAlert
                isOpen={isOpenConvertSimulateAlert}
                onDidDismiss={() => setIsOpenConvertSimulateAlert(false)}
                header={'Simulación Convertida'}
                backdropDismiss={false}
                message={`Numero de la operación: ${props.convertResponse.loan_request_id}`}
                buttons={[{
                    text: 'Ver Detalle',
                    cssClass: 'secondary',
                    handler: onViewDetailSimulateConvertedHandler
                },
                {
                    text: 'Cerrar',
                    cssClass: 'secondary',
                    handler: () => { }
                }
                ]}
            />

            <IonAlert
                isOpen={isOpenRemoveAlert}
                onDidDismiss={onCloseFromRemoveAlertHandler}
                header={'Eliminar Simulación'}
                backdropDismiss={false}
                message={`¿Esta seguro de eliminar la simulación #${simulateIdTarget} ?`}
                buttons={[{
                    text: 'Eliminar',
                    cssClass: 'secondary',
                    handler: () => onSubmitFromRemoveAlertHandler()
                },
                {
                    text: 'Cerrar',
                    cssClass: 'secondary',
                    handler: () => onCloseFromRemoveAlertHandler()
                }
                ]}
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoadingData}
                onDidDismiss={() => setIsLoadingData(false)}
                message={'Cargando...'}
            />

            {!props.simulateList.length && isLoadedData && !isLoadingData ? (
                <NoDataComponent
                    title="No hay Simulaciones"
                    message="Puedes empezar a crear Simulaciones y estos se verán reflejados aquí"
                    button="Crear"
                    href="/onBoarding"
                    onSubmit={onSubmitHandlerFromNoDataComponent}
                />
            ) : null}

            <DownloadSimulateModalComponent
                isOpen={isOpenDownloadModal}
                isShowSubsidaryCheckBox={true}
                onClose={onCloseFromDownloadSimulateModalHandler}
                onSubmit={onSubmitFromDownloadSimulateModalHandler}
                applicantPreLoadData={getPreloadDataForDownloadModal()}
                hasDynamicForms={false}
            />
            <SimulateToConvertModalComponent
                isOpen={isOpenSendSimulateForConvertModal}
                onClose={onCloseSendSimulateForConvertModalHandler}
                productCategoryName={getCurrentCategoryName()}
                productTypeName={getCurrentProductTypeName()}

                productFormDataInputs={getProductTypeInputs()}
                warrantyDetailFormDataInputs={getWarrantyInputs()}
                categories={props.productCategories}
                onGetWarrantyInputs={onGetWarrantyInputsHandler}
                onSubmit={onSubmitForConvertHandler}
                formSectionList={props.formSectionList}
            // dinamicShemaFormsResponse={props.dinamicFormsResponse}
            // countryAlias={props.countryAlias}
            // onSubmitForMexico={onSubmitForMexicoHandler}

            />

            {!props.isMobile ? (
                <TableGeneratorComponent
                    headerList={[
                        'Solicitante',
                        'Fecha',
                        'ID',
                        'Divisa',
                        'Monto Solicitado',
                        'Acciones'
                    ]}
                    bodyList={getBodyList}
                    onAction={onActionTableHandler}
                />
            ) : null}

            {
                props.isMobile && props.simulateList.map(simulate => (
                    <IonCard key={`simulateNumber${simulate.id}`}>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size='12'>
                                    <IonLabel><b>ID</b></IonLabel>
                                </IonCol>
                                <IonCol size='12' className='ion-text-left'>
                                    #{simulate.id}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='12'>
                                    <IonLabel><b>Solicitante</b></IonLabel>
                                </IonCol>
                                <IonCol size='12' className='ion-text-left'>
                                    {simulate.customer.name && simulate.customer.name && (
                                        `${simulate.customer.name} ${simulate.customer.name}`
                                    )}
                                    {simulate.customer.primer_nombre && simulate.customer.apellido_paterno && (
                                        `${simulate.customer.primer_nombre} ${simulate.customer.apellido_paterno}`
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='12'>
                                    <IonLabel><b>Fecha</b></IonLabel>
                                </IonCol >
                                <IonCol size='12' className='ion-text-left'>
                                    {simulate.data.date_simulate}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='12'>
                                    <IonLabel><b>Divisa</b></IonLabel>
                                </IonCol>
                                <IonCol size='12' className='ion-text-left'>
                                    {simulate.currency}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='12'>
                                    <IonLabel><b>Monto Solicitado</b></IonLabel>
                                </IonCol>
                                <IonCol size='12' className='ion-text-left'>
                                    {simulate.amount}
                                </IonCol>
                            </IonRow>

                            <br/>

                            <IonRow>
                                {/* <IonCol>
                                <IonLabel>Acciones</IonLabel>
                            // </IonCol> */}

                                <IonCol size='12'>
                                    <IonButton fill='clear' color="primary" onClick={() => onDeleteFromListHandler(simulate.id)} title="Eliminar">Eliminar</IonButton>
                                    <IonButton fill='clear' color="primary" onClick={() => onRedirectToSimulateDetailHandler(simulate.id)} title="Ver Detalle">Ver Detalle</IonButton>
                                    <IonButton fill='clear' color="primary" onClick={() => onDownloadFromSimulateListHandler(simulate.id)} title="Descargar PDF">Descargar PDF</IonButton>
                                    <IonButton fill='clear' color="primary" onClick={() => onOpenConvertModalHandler(simulate.id)} title="Enviar Solicitud">Enviar Solicitud</IonButton>
                                </IonCol>

                                {/* <IonCol>

                                </IonCol>

                                <IonCol>
                                </IonCol>

                                <IonCol>
                                </IonCol> */}
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                ))
            }
            <br /><br /><br /><br />
            <IonFab onClick={() => onRedirectToOnBoaringHandler()} vertical="bottom" horizontal="end" slot="fixed" style={{ margin: '0px 10px' }}>
                <IonFabButton>
                    <IonIcon icon={add}></IonIcon>
                </IonFabButton>
            </IonFab>
        </>
    )
}