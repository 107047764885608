import React from 'react'
import { IonApp, IonLoading, IonRouterOutlet, IonToast } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react';

/* Theme variables */
import './theme/variables.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

////////////////////////////////////////////

import { useUserStore } from './stores/user/user.use.store'
import { useLoanRequestStore } from './stores/loanRequest/loanRequst.use.store'
import { useSimulateStore } from './stores/simulate/simulate.use.store'
import { useOnBoardingStore } from './stores/onBoarding/onBoarding.use.store'
import { useSetupStore } from './stores/setup/setup.use.store'
import { useCuitStore } from './stores/cuit/cuit.use.store'
import { LoanRequestDetailPage } from './pages/LoanRequestDetail.page'
import { useConvertStore } from './stores/convert/convert.use.store'

///////////////////////////////////////////////////
///////////////////////////////////////////////////
import { Menu } from './components/Menu.component'
///////////////////////////////////////////////////
import { ProfilePage } from './pages/Profile.page'
import { LoanRequestsPage } from './pages/LoanRequests.page'
import { SimulatesPage } from './pages/Simulates.page'
import { SimulateDetailPage } from './pages/SimulateDetail.page'
import { OnBoardingPage } from './pages/OnBoarding.page'
import { AuthErrorPage } from './pages/AuthError.page'
import { CuitPage } from './pages/Cuit.page'
import { useLogStore } from './stores/log/log.use.store'
import { timer } from './utils/timer'
import B2CPage from './pages/B2C.page';
import { CreditsPage } from './pages/CreditList.page';
import { useCreditStore } from './stores/credit/credit.use.store';
import { CreditDetailPage } from './pages/CreditDetail.page';
import { useNotificationStore } from './stores/notification/notification.use.store';
import { useAppStore } from './stores/app/app.use.store';
import { InstallAppModal } from './elements/installApp.element';
import { getConfig } from './utils/getConfig';
import { props } from 'cypress/types/bluebird';
import { apps } from 'ionicons/icons';
//////////////////////////////////////////////////



const App: React.FC = () => {

  const [isOpenApiErrorToast, setIsOpenApiErrorToast] = React.useState<boolean>(false)

  const onApiClientErrorHandler = async () => {
    timer(300)
    setIsOpenApiErrorToast(true)
  }

  const appStore = useAppStore()
  const setupStore = useSetupStore()
  const logStore = useLogStore()
  const userStore = useUserStore({ onSetUserEmail: logStore.onSetUserEmail })
  const loanRequestsStore = useLoanRequestStore()
  const simulateStore = useSimulateStore()
  const onBoardingStore = useOnBoardingStore({ profileEmail: userStore.profile.email })
  const creditStore = useCreditStore()
  const notificationStore = useNotificationStore()
  const cuitStore = useCuitStore({
    isAvailableBankPolicyService: userStore.profile.availableServices === null ? false : userStore.profile.availableServices.find(service => service === 'BANK_POLICY') ? true : false
  })
  const convertStore = useConvertStore()

  React.useEffect(() => { onInit() }, [])

  const onInit = async () => {

    appStore.showLoading()
    setupStore.getSetupData()
    await logStore.init()
    const getProfileActionResult = await userStore.getProfileAction()

    if (getProfileActionResult.isSuccess && getProfileActionResult.data === true) {
      await notificationStore.getNotificationList()
      // appStore.createServiceWorker()
      appStore.suscribePushNotification()
      //appStore.appInstallerController()
    }

    if (getProfileActionResult.isSuccess && getProfileActionResult.data === false) {
      window.location.replace(getConfig().loginUrl);
    }

    appStore.hideLoading()
  }

  return (
    <Sentry.ErrorBoundary showDialog>
      <IonApp>
        <IonToast
          isOpen={isOpenApiErrorToast}
          color="danger"
          onDidDismiss={() => setIsOpenApiErrorToast(false)}
          message="Error en los servidores, por favor intentalo en unos minutos..."
          duration={5000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={appStore.isLoading}
          onDidDismiss={() => { }}
          message={'Cargando...'}
        />

        <IonReactRouter>
          {userStore.isFinishedUserAuthenticate && userStore.isUserAuntenticated ? (<>
            <InstallAppModal
              isOpen={appStore.isOpenInstallModal}
              onClose={appStore.closeInstallModal}
              onIntall={appStore.installApp}
            />
            <Menu userName={userStore.profile.email} countryAlias={setupStore.countryAlias} appStore={appStore} />
            <IonRouterOutlet id="main">
              <Route path="/profile" exact>
                <ProfilePage
                  userStore={userStore}
                  notificationStore={notificationStore}
                />
              </Route>
              <Route path="/loanRequests/" exact>
                <LoanRequestsPage
                  userStore={userStore}
                  loanRequestStore={loanRequestsStore}
                  setupStore={setupStore}
                  onApiClientError={onApiClientErrorHandler}
                  notificationStore={notificationStore}
                />
              </Route>
              <Route path="/loanRequests/:id" exact>
                <LoanRequestDetailPage
                  userStore={userStore}
                  setupStore={setupStore}
                  loanRequestStore={loanRequestsStore}
                  onApiClientError={onApiClientErrorHandler}
                  notificationStore={notificationStore}
                />
              </Route>

              <Route path="/simulator" exact>
                <SimulatesPage
                  notificationStore={notificationStore}
                  userStore={userStore}
                  setupStore={setupStore}
                  simulateStore={simulateStore}
                  convertStore={convertStore}
                  onApiClientError={onApiClientErrorHandler}
                />
              </Route>

              <Route path="/simulator/edit/:id" exact>
                <OnBoardingPage
                  action='edit'
                  setupStore={setupStore}
                  userStore={userStore}
                  simulateStore={simulateStore}
                  notificationStore={notificationStore}
                  onboardingStore={onBoardingStore}
                  convertStore={convertStore}
                  onApiClientError={onApiClientErrorHandler}
                />
              </Route>
              <Route path="/simulator/:id" exact>
                <SimulateDetailPage
                  countryAlias={setupStore.countryAlias}
                  userStore={userStore}
                  setupStore={setupStore}
                  notificationStore={notificationStore}
                  convertStore={convertStore}
                  simulateStore={simulateStore}
                  onApiClientError={onApiClientErrorHandler}
                />
              </Route>
              <Route path="/onBoarding" exact>
                <OnBoardingPage
                  action='create'
                  userStore={userStore}
                  notificationStore={notificationStore}
                  onboardingStore={onBoardingStore}
                  convertStore={convertStore}
                  setupStore={setupStore}
                  simulateStore={simulateStore}
                  onApiClientError={onApiClientErrorHandler}
                />
              </Route>
              <Route path="/cuit" exact>
                <CuitPage
                  userStore={userStore}
                  notificationStore={notificationStore}
                  isShowSantanderForm={cuitStore.isShowSantanderForm}
                  onSendRequest={cuitStore.sendRequest}
                  fullName={cuitStore.fullName}
                  cuit={cuitStore.cuit}
                  cuitValidateMessage={cuitStore.cuitValidateMessage}
                  verificationBankFirstPolicyMessage={cuitStore.verificationBankFirstPolicyMessage}
                  verificationAcceptanceRequirementsFirstMessage={cuitStore.verificationAcceptanceRequirementsFirstMessage}
                  verificationAcceptanceRequirementsNoWarrantyMessage={cuitStore.verificationAcceptanceRequirementsNoWarrantyMessage}
                  verificationTrackMessage={cuitStore.verificationTrackMessage}
                  sendSecondVerificationBankFirstPolicy={cuitStore.sendSecondVerificationBankFirstPolicy}
                />
              </Route>

              <Route path="/credits" exact>
                <CreditsPage
                  creditStore={creditStore}
                  userStore={userStore}
                  onApiClientError={onApiClientErrorHandler}
                  setupStore={setupStore}
                  notificationStore={notificationStore}
                />
              </Route>
              <Route path="/credits/:id" exact>
                <CreditDetailPage
                  creditStore={creditStore}
                  userStore={userStore}
                  onApiClientError={onApiClientErrorHandler}
                  notificationStore={notificationStore} />
              </Route>

              <Redirect from="/" to="/loanRequests" exact />
            </IonRouterOutlet>
          </>) : userStore.isFinishedUserAuthenticate && !userStore.isUserAuntenticated ? (<>
            <IonRouterOutlet>
              <Route path="/B2C" exact>
                <B2CPage userStore={userStore} onApiClientError={onApiClientErrorHandler}>
                  <OnBoardingPage
                    notificationStore={notificationStore}
                    userStore={userStore}
                    onboardingStore={onBoardingStore}
                    convertStore={convertStore}
                    action='create'
                    setupStore={setupStore}
                    simulateStore={simulateStore}
                    onApiClientError={onApiClientErrorHandler}
                  />
                </B2CPage>
              </Route>
              <Route>
                <AuthErrorPage notificationStore={notificationStore} userStore={userStore} />
              </Route>
            </IonRouterOutlet>
          </>) : null}
        </IonReactRouter>
      </IonApp>
    </Sentry.ErrorBoundary>
  )
}

export default App;
