import { IonList, IonItemDivider, IonItem, IonLabel, IonCheckbox, IonTitle } from '@ionic/react'
import React from 'react'
import { CheckBoxFilter, CheckBoxList, CheckBoxTitle } from './ComercialPolicyCheckBoxFilter.element'

interface IComercialPolicyTypeComponentProps {
    comercialPolicyTypeValues: {
        oneSignature: boolean
        garment: boolean
    }
    onChangeComercialPolicyTypeValues: (values: {
        oneSignature: boolean
        garment: boolean
    }) => void
}

export const ComercialPolicyTypeFilterComponent: React.FC<IComercialPolicyTypeComponentProps> = (props) => {

    const oneSignatureHandler = () => {
        props.onChangeComercialPolicyTypeValues({
            ...props.comercialPolicyTypeValues,
            oneSignature: !props.comercialPolicyTypeValues.oneSignature
        })
    }

    const onChangeGarmentHandler = () => {
        props.onChangeComercialPolicyTypeValues({
            ...props.comercialPolicyTypeValues,
            garment: !props.comercialPolicyTypeValues.garment,
        })
    }

    return (
        <CheckBoxList>
            <CheckBoxTitle>Tipo de Garantía</CheckBoxTitle>
            <CheckBoxFilter label="Sola Firma" checked={props.comercialPolicyTypeValues.oneSignature} onClick={() => oneSignatureHandler()} />
            <CheckBoxFilter label="Prendario" checked={props.comercialPolicyTypeValues.garment} onClick={() => onChangeGarmentHandler()} />
        </CheckBoxList>
    )
}