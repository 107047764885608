import { IonSelectOption, IonItem, IonLabel, IonInput, IonSelect, IonCol, IonRow } from "@ionic/react"
import { compassOutline } from "ionicons/icons";
import React from "react"
import { TemporaryInput, TemporaryItem, TemporaryLabel, TemporaryOption, TemporaryOptions } from "../../elements/form.element";
import { getCountryAlias } from "../../stores/setup/setup.use.store";
import { deleteSpecialCharactersToNumberString } from "../../utils/number.util";
import { IForm, TFormItem, IVinculedOptionsGenerator, IInputGenerator, IInputNumber } from "./FormGenerator.interface"
import { InputCheckBox, InputCheckBoxLabel, InputCheckBoxPlaceholder, InputCheckBoxRow } from "./InputGenerator.element";


const onChangeCheckboxMultiple = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
) => {

    const getNewValues = (valueList: string, newValue: string): string => {
        const arrayOfValues: Array<string> = JSON.parse(valueList)
        const valueFinded = arrayOfValues.find(value => value === newValue)
        if (valueFinded) return JSON.stringify(arrayOfValues.filter(value => value !== newValue))
        else return JSON.stringify([...arrayOfValues, newValue])
    }

    let newFormData: IForm = []
    newFormData = form.map(input => input.id === id && input.type === 'checkBoxMultiple' ? {
        ...input,
        value: getNewValues(input.value, value),
        error: input.required && getNewValues(input.value, value) === '[]'
    } : input)
    setData(newFormData)
}


export const getIsValidCuitCuil = (cuit: string): boolean => {


    if (cuit.length != 11) {
        return false;
    }

    var acumulado: number = 0;
    var digitos: Array<string> = cuit.split("");
    var digito: string | undefined = digitos.pop();

    for (var i = 0; i < digitos.length; i++) {
        acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
    }

    var verif = 11 - (acumulado % 11);
    if (verif == 11) {
        verif = 0;
    } else if (verif == 10) {
        verif = 9;
    }

    return Number(digito) == verif;
}


const onChangeInputOptionsHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {
    let newFormData: IForm = []
    newFormData = form.map(input => input.id === id && input.type === 'options' ? {
        ...input,
        value: value,
        error: input.required && !value
    } : input)
    setData(newFormData)
}
///////////////////////////////////////////

const onChangeInputCuilCuitHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {


    let newFormData: IForm = []

    const addError = (required: boolean, value: string): boolean => {

        if (required && value === '') {
            return true
        }
        if (value !== '' && getIsValidCuitCuil(value.replace("-", "").replace("-", "")) === false) {
            return true
        }

        return false
    }


    newFormData = form.map(input => input.id === id && input.type === 'cuilcuit' ? {
        ...input,
        value: value,
        error: addError(input.required, value)
    } : input)

    setData(newFormData)
}


const onChangeInputTextHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type'],
) => {
    let newFormData: IForm = []

    const addError = (required: boolean, minlength: number, value: string, regex: string): boolean => {
        if (required && value === '') return true
        if (value !== '' && value.length < minlength) return true
        if (regex && value.replace(new RegExp('[a-zA-Z0-9]', 'g'), '')) return true
        return false
    }

    newFormData = form.map(input => input.id === id && input.type === 'text' ? {
        ...input,
        value: value,
        error: addError(input.required, input.minlength, value, input.regex)
    } : input)

    setData(newFormData)
}


export const getIsErrorInputNumber = (input: IInputNumber): boolean => {

    if (input.required && input.value === '') return true
    if (input.value !== '' && input.minValue !== undefined && input.minValue > Number(input.value)) return true

    return false
}

const onChangeInputNumberHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {

    let newFormData: IForm = []
    const valueFormated = deleteSpecialCharactersToNumberString(value)

    newFormData = form.map(input => input.id === id && input.type === 'number' ? {
        ...input,
        value: valueFormated,
        error: getIsErrorInputNumber({ ...input, value: valueFormated })
    } : input)

    setData(newFormData)
}

const onChangeInputPriceHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {
    let newFormData: IForm = []
    const valueFormated = deleteSpecialCharactersToNumberString(value)

    newFormData = form.map(input => input.id === id && input.type === 'price' ? {
        ...input,
        value: addNewPriceFormatToOldPriceValue(valueFormated),
        error: input.required && !valueFormated
    } : input)

    setData(newFormData)
}

const onChangeVinculatorOptionsInputHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {

    let newFormData: IForm = []

    newFormData = form.map(input => {
        if (input.type === 'vinculatorOptions' && input.id === id) {
            return {
                ...input,
                value: value,
                error: !!(input.required && !value)
            }
        }

        if (input.type === 'vinculedOptions' && input.vinculedOptionsId === id) {
            return {
                ...input,
                value: '',
                error: !!(input.required && !value)
            }
        }

        return input
    })

    setData(newFormData)
}

const onChangeVinculedOptionsInputHandler = (
    id: number,
    value: string,
    form: IForm,
    setData: Function,
    inputType: TFormItem['type']
) => {
    let newFormData: IForm = []
    if (inputType === 'vinculedOptions' && value === '') return // esto es asi porque al cambiar 2 campos distintos al mismo tiempo, el handler se ejecuta 2 veces, uno por cada input

    newFormData = form.map(input => input.id === id && input.type === 'vinculedOptions' ? {
        ...input,
        value: value,
        error: input.required && !value
    } : input)

    setData([...newFormData])
}

const VinculedOptionsGenerator = (props: IVinculedOptionsGenerator) => {

    const getOptionList = () => {
        const vinculatorOptionFinded = props.form.find(input => input.id === props.inputVinculed.vinculedOptionsId)
        if (!vinculatorOptionFinded) return []
        if (vinculatorOptionFinded.type !== 'vinculatorOptions') return []
        const vinculerOptionFinded = vinculatorOptionFinded.options.find(option => option.value === vinculatorOptionFinded.value)
        if (!vinculerOptionFinded) return []
        return vinculerOptionFinded.optionsToVincule
    }




    return (
        <>
            {!props.inputVinculed.value ? <option style={{ display: 'none' }} value="" disabled hidden>{props.inputVinculed.placeholder}</option> : null}
            {getOptionList().map((option, index) => <TemporaryOption key={`select${index}`} value={option.value}>{option.label}</TemporaryOption>)}
        </>
    )
}

////////////////////////////////////
const addCommaToStringNumber = (number: string) => {
    const characterList = number.split('')
    const characterListReversed = characterList.reverse()

    const characteListReversedWithPointSeparator: Array<string> = characterListReversed.reduce((acumulator: Array<string>, currentValue: string, index: number) => {
        if (index !== 0 && index % 3 === 0) return [...acumulator, ',', currentValue]
        else return [...acumulator, currentValue]
    }, [] as Array<string>)

    return characteListReversedWithPointSeparator.reverse().join('')
}

const deleteCommaToStringNumber = (number: string) => number.split(',').join('')

export const addNewPriceFormatToOldPriceValueForMexico = (number: string) => {
    const pointFinded = number.includes('.')

    const integer = deleteSpecialCharacters(deleteTextToNumber(number.split('.')[0]))
    const float = deleteSpecialCharacters(deleteTextToNumber(number.split('.')[1] ? number.split('.')[1] : ''))

    const integerFormated = addCommaToStringNumber(deletePointersToNumber(deleteCommaToStringNumber(integer)))
    const floatFormated = deletePointersToNumber(deleteCommaToStringNumber(float))

    if (pointFinded && !floatFormated) return `${integerFormated}.`
    if (pointFinded && floatFormated) return floatFormated ? `${integerFormated}.${floatFormated}` : integerFormated
    return integerFormated
}

export const addNewPriceFormatToOldPriceValueForArgentina = (number: string) => {
    const commaFinded = number.includes(',')

    const integer = deleteSpecialCharacters(deleteTextToNumber(number.split(',')[0]))
    const float = deleteSpecialCharacters(deleteTextToNumber(number.split(',')[1] ? number.split(',')[1] : ''))

    const integerFormated = addPointsToStringNumber(deletePointersToNumber(deleteCommaToStringNumber(integer)))
    const floatFormated = deletePointersToNumber(deleteCommaToStringNumber(float))

    if (commaFinded && !floatFormated) return `${integerFormated},`
    if (commaFinded && floatFormated) return floatFormated ? `${integerFormated},${floatFormated}` : integerFormated
    return integerFormated
}


///////////////////////////////////////////////

export const addNewPriceFormatToOldPriceValue = (number: string): string => {
    if (getCountryAlias() === 'ar') return addNewPriceFormatToOldPriceValueForArgentina(number)
    if (getCountryAlias() === 'mx') return addNewPriceFormatToOldPriceValueForMexico(number)
    return number
}

export const deleteTextToNumber = (number: string): string => number.replace(/[a-z]/g, '').replace(/[A-Z]/g, '')

export const deleteSpecialCharacters = (number: string) => number.split(/!@#$^&%*()+=-[]\/{}|:<>?,./g).join('')

export const addPointsToStringNumber = (number: string): string => {

    const characterList = number.split('')
    const characterListReversed = characterList.reverse()

    const characteListReversedWithPointSeparator: Array<string> = characterListReversed.reduce((acumulator: Array<string>, currentValue: string, index: number) => {
        if (index !== 0 && index % 3 === 0) return [...acumulator, '.', currentValue]
        else return [...acumulator, currentValue]
    }, [] as Array<string>)

    return characteListReversedWithPointSeparator.reverse().join('')
}

export const deletePointersToNumber = (number: string) => {
    const pieces = number.split('.')
    const resultingString = pieces.join('')
    return resultingString
}


export const addPriceFormatToNumberValue = (number: string): string => {

    const integerString = number.split('.')[0]
    const floatString = number.split('.')[1] ? number.split('.')[1] : ''

    const integerStringFormated =
        getCountryAlias() === 'ar' ? addPointsToStringNumber(integerString) :
            getCountryAlias() === 'mx' ? addCommaToStringNumber(integerString)
                : integerString

    const floatStringFormated =
        getCountryAlias() === 'ar' && floatString ? `,${floatString}` :
            getCountryAlias() === 'mx' && floatString ? `.${floatString}`
                : floatString

    return integerStringFormated + floatStringFormated
}

const getIsSelected = (valueList: string, optionValue: string): boolean => {
    const arrayOfValues: Array<string> = JSON.parse(valueList)
    const valuefinded = arrayOfValues.find(value => value === optionValue)
    if (valuefinded) return true
    return false
}


const getInputClassNameForTest = (label: string, id: number) => {
    return `input(${label.split(' ').join('-')})-${id}`
}

const getCheckBoxClassNameForTest = (input: TFormItem, checkBoxLabel: string): string => {
    if (input.type === 'checkBoxMultiple') {
        return `${getInputClassNameForTest(input.label, input.id)}-checkBox-(${checkBoxLabel})`
    } else {
        return ''
    }
}

export const InputGenerator = (props: IInputGenerator) => {


    return (
        <>
            {props.input.type === 'none' && (
                <div></div>
            )}
            {props.input.type === 'checkBoxMultiple' && (
                <IonRow style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    <IonCol size="12">
                        <IonLabel position="stacked" className="ion-text-wrap">{props.input.label}</IonLabel>
                    </IonCol>
                    <br></br>
                    {props.input.options.map((option, indexOption) => (
                        <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={indexOption}>
                            <InputCheckBoxRow>
                                <InputCheckBox className={`ion-text-end ${getCheckBoxClassNameForTest(props.input, option.label)}`} onClick={(e) => onChangeCheckboxMultiple(props.input.id, option.value, props.form, props.setData)} isChecked={getIsSelected(props.input.type === 'checkBoxMultiple' ? props.input.value : '[]', option.value)} />
                                <InputCheckBoxLabel>{option.value}</InputCheckBoxLabel>
                            </InputCheckBoxRow>
                        </IonCol>
                    ))}
                    <IonCol size="12">

                        {props.input.value === '[]' ? (
                            <InputCheckBoxPlaceholder isError={props.input.error}>{props.input.placeholder}</InputCheckBoxPlaceholder>
                        ) : null}

                    </IonCol>

                </IonRow>
            )}

            {(props.input.type === 'cuilcuit') && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    <IonItem>
                        <IonLabel position="stacked" className="ion-text-wrap">{props.input.label} {/*<a style={{color: 'red'}}>(Cantidad minima 8)</a>*/}</IonLabel>
                        <IonInput
                            type="text"
                            className={`ion-text-end ${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            required={props.input.required}
                            color={props.input.error ? 'danger' : 'dark'}
                            maxlength={13}
                            onIonChange={e => onChangeInputCuilCuitHandler(props.input.id, e.detail.value ? e.detail.value : '', props.form, props.setData, props.input.type)}
                        />
                    </IonItem>
                </IonCol>
            )}
            {(props.input.type === 'text' || props.input.type === 'email') && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label} </TemporaryLabel>
                        <TemporaryInput
                            type={props.input.type}
                            className={`${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            required={props.input.required}
                            error={props.input.error}
                            // maxlength={props.input.type === 'text' ? props.input.maxlength : undefined}
                            onChange={e => onChangeInputTextHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                        />
                    </TemporaryItem>
                </IonCol>
            )}
            {props.input.type === 'price' && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%', borderBottom: '1px solid white' }} key={`inputNumber${props.id}Index${props.index}`}>
                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label} {/*<a style={{color: 'red'}}>(Cantidad minima 8)</a>*/}</TemporaryLabel>
                        <TemporaryInput
                            type="text"
                            className={`${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            required={props.input.required}
                            error={props.input.error}
                            onChange={e => onChangeInputPriceHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                        />
                    </TemporaryItem>
                </IonCol>
            )}
            {props.input.type === 'number' && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label} {/*<a style={{color: 'red'}}>(Cantidad minima 8)</a>*/}</TemporaryLabel>
                        <TemporaryInput
                            type="text"
                            min="0"
                            className={`ion-text-end ${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            required={props.input.required}
                            error={props.input.error}
                            onChange={e => onChangeInputNumberHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                        />
                    </TemporaryItem>
                </IonCol>
            )}
            {props.input.type === 'options' && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    {/* <IonItem>
                        <IonLabel position="stacked" className="ion-text-wrap">{props.input.label}</IonLabel>
                        <IonSelect
                            value={props.input.value}
                            className={`ion-text-end ${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            // interface="popover"
                            placeholder={props.input.placeholder}
                            color={props.input.error ? 'danger' : 'dark'}
                            onIonChange={e => onChangeInputOptionsHandler(props.input.id, e.detail.value ? e.detail.value : '', props.form, props.setData, props.input.type)}
                        >
                            {props.input.options.map((option, index) => <IonSelectOption key={`select${index}`} value={option.value}>{option.label}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem> */}
                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label}</TemporaryLabel>
                        <TemporaryOptions
                            isError={props.input.error}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            onChange={e => onChangeInputOptionsHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                            disabled={false}
                        >
                            {!props.input.value ? <option style={{ display: 'none' }} value="" disabled hidden>{props.input.placeholder}</option> : null}
                            {props.input.options.map((option, index) => <TemporaryOption key={`select${index}`} value={option.value}>{option.label}</TemporaryOption>)}
                        </TemporaryOptions>
                    </TemporaryItem>

                </IonCol>
            )}
            {props.input.type === 'vinculatorOptions' && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    {/* <IonItem>
                        <IonLabel position="stacked" className="ion-text-wrap">{props.input.label}</IonLabel>
                        <IonSelect
                            value={props.input.value}
                            className={`ion-text-end ${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            // interface="popover"
                            placeholder={props.input.placeholder}
                            color={props.input.error ? 'danger' : 'dark'}
                            onIonChange={e => onChangeVinculatorOptionsInputHandler(props.input.id, e.detail.value ? e.detail.value : '', props.form, props.setData, props.input.type)}
                        >
                            {props.input.options.map((option, index) => <IonSelectOption key={`select${index}`} value={option.value}>{option.label}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem> */}

                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label}</TemporaryLabel>
                        <TemporaryOptions
                            isError={props.input.error}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            onChange={e => onChangeVinculatorOptionsInputHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                            disabled={false}
                        >
                            {!props.input.value ? <option style={{ display: 'none' }} value="" disabled hidden>{props.input.placeholder}</option> : null}
                            {props.input.options.map((option, index) => <TemporaryOption key={`select${index}`} value={option.value}>{option.label}</TemporaryOption>)}
                        </TemporaryOptions>
                    </TemporaryItem>
                </IonCol>
            )}

            {props.input.type === 'vinculedOptions' && (
                <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{ padding: '1% 5%' }} key={`inputNumber${props.id}Index${props.index}`}>
                    {/* <IonItem>
                        <IonLabel position="stacked" className="ion-text-wrap">{props.input.label}</IonLabel>
                        <IonSelect
                            value={props.input.value}
                            className={`ion-text-end ${getInputClassNameForTest(props.input.label, props.input.id)}`}
                            // interface="popover"
                            placeholder={props.input.placeholder}
                            color={props.input.error ? 'danger' : 'dark'}
                            onIonChange={e => onChangeVinculedOptionsInputHandler(props.input.id, e.detail.value ? e.detail.value : '', props.form, props.setData, props.input.type)}
                        >
                            <VinculedOptionsGenerator
                                inputVinculed={props.input}
                                form={props.form}
                            />
                        </IonSelect>
                    </IonItem> */}

                    <TemporaryItem>
                        <TemporaryLabel>{props.input.label}</TemporaryLabel>
                        <TemporaryOptions
                            isError={props.input.error}
                            value={props.input.value}
                            placeholder={props.input.placeholder}
                            onChange={e => onChangeVinculedOptionsInputHandler(props.input.id, e.target.value ? e.target.value : '', props.form, props.setData, props.input.type)}
                            disabled={false}
                        >
                            <VinculedOptionsGenerator
                                inputVinculed={props.input}
                                form={props.form}
                            />
                        </TemporaryOptions>
                    </TemporaryItem>
                </IonCol>
            )}
        </>
    )
}