
export const dateFormaterUtil = (date: string): string => {
  const dateStringToInvertArrayString = date.slice(0,10).split('-').reverse()
  return `${dateStringToInvertArrayString[0]}-${dateStringToInvertArrayString[1]}-${dateStringToInvertArrayString[2]}`
}

export const getLimitDateForCalendar = (date: string, periodoDePago: number): string => {
  
  const currentYear = Number(date.slice(0,10).split('-')[0])
  const currentMonth = Number(date.slice(0,10).split('-')[1])
  
  let newMonthLimit = 0
  let newYearLimit = 0

  if(currentMonth + periodoDePago <= 12) {
    newMonthLimit = currentMonth + periodoDePago
    newYearLimit = currentYear
  }
  else {
    newMonthLimit = (currentMonth + periodoDePago) - 12
    newYearLimit = currentYear + 1
  }

  return `${newYearLimit}-${newMonthLimit<12 ?`0${newMonthLimit}`: newMonthLimit}-04`
}