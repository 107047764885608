import { TForm, getValueToForm } from "FormGenerator"

export const getUpdateFormDataWithProductCondition = (formData: TForm): TForm => {
    const productConditionValue = getValueToForm(formData, '24') as 'NUEVO' | 'USADO' | ''

    if (productConditionValue === 'NUEVO' || '') {
        return formData.filter(field => field.id !== '0.3')
    }

    if (productConditionValue === 'USADO') {
        const productConditionValueIndex = formData.findIndex(field => field.id === '0.3')

        if (productConditionValueIndex === -1) { // no existe el campo

            const formDataExpanded: TForm = formData.reduce((accumulator: TForm, currentField) => {
                if (currentField.id !== '24') {
                    return [...accumulator, currentField]
                }

                if (currentField.id === '24') {
                    return [...accumulator, currentField, {
                        id: '0.3',
                        label: 'Año de Fabricación',
                        placeholder: 'Campo requerido...',
                        type: 'number',
                        required: true,
                        error: false,
                        disabled: false,
                        hidden: false,
                        value: '',
                        maxValue: new Date().getFullYear(),
                        minValue: 1900,
                        size: 6
                    }]
                }

                return accumulator
            }, [] as TForm)

            return formDataExpanded
        }
    }

    return formData
}


export const getUpdateFormDataWithProductInputs = async(
    newFormData: TForm,
    oldFormData: TForm,
    onGetProductForm: (productId: number)=>Promise<TForm>
): Promise<TForm> => {
    
    const newProductValue = getValueToForm(newFormData, '21')
    const oldProductValue = getValueToForm(oldFormData, '21')
            
    if(newProductValue !== oldProductValue) {
        newFormData = newFormData.filter(input=>Number(input.id) < 1000)
        if(newProductValue !== '') {
            const productFormObtained = await onGetProductForm(Number(newProductValue))
            newFormData = newFormData.reduce<TForm>((acumulator, current)=>{
                if(current.id === '21') {
                    acumulator.push(current)
                    productFormObtained.forEach(productInput=> acumulator.push(productInput))
                } else {
                    acumulator.push(current)
                }
                return acumulator
            }, [])
        }
    }

    return newFormData
}
