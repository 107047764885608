import React, { useState, useEffect } from 'react'
import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { IDownloandPdfRequest } from '../stores/simulate/simulate.model.store'
import { getCountryAlias } from '../stores/setup/setup.use.store'
import { CheckBoxRowElement } from '../elements/form.element'

interface IDownloadSimulateModalComponent {
    isOpen: boolean
    onSubmit(param: IDownloandPdfRequest): void // esto lo tengo que cambiar !!! 
    onClose(): void
    isShowSubsidaryCheckBox: boolean
    applicantPreLoadData?: {
        identification: string,
        name: string,
        email: string,
        productCategory: string,
        channel: string
    },
    hasDynamicForms: boolean
}

export type TOnSubmitParam = IDownloandPdfRequest

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;


export const DownloadSimulateModalComponent: React.FC<IDownloadSimulateModalComponent> = (props) => {

    const getIsValidEmail = (email: string)=> emailPattern.test(email)
        

    const generateDefaultApplicantData = () : IDownloandPdfRequest['applicant'] => ({
        CUIT: props.applicantPreLoadData ? props.applicantPreLoadData.identification : '',
        name: props.applicantPreLoadData ? props.applicantPreLoadData.name : '',
        email: props.applicantPreLoadData ? props.applicantPreLoadData.email : '',
        productCategory: props.applicantPreLoadData ? props.applicantPreLoadData.productCategory : '',
        channel: "Concesionario",
    })

    const generateDefaultFormData =  () : IDownloandPdfRequest => ({
        showApplicantData: false,
        applicant: generateDefaultApplicantData(),
        showOriginationCost: false,
        showDataEntry: false,
        showMoreInfo: true,
        showSubsidizedInfo: false,
        showForms: false
    })
    
    const [ formData, setFormData ] = useState<IDownloandPdfRequest>(generateDefaultFormData())   
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true)

    useEffect(() => {
        if (!props.isOpen) resetFormData()
    }, [props.isOpen])

    useEffect(()=>{ 
        resetFormData()
    }, [props.applicantPreLoadData])
    
    const resetFormData = () => setFormData(generateDefaultFormData())


    const onChangeCheckBoxHandler = (key: keyof IDownloandPdfRequest) => {
        const formDataCopy = { ...formData }
        
        if( key !== 'applicant') formDataCopy[key] = !formDataCopy[key]

        // if(!formDataCopy.showApplicantData) formDataCopy.applicant = generateDefaultApplicantData()
        
        setFormData(formDataCopy)
    }

    const onChangeInputHandler = (key: keyof IDownloandPdfRequest['applicant'], value: string) => {
        const formDataCopy = { ...formData }
        formDataCopy.applicant[key] = value
        setFormData(formDataCopy)

        if(!formDataCopy.applicant.email) {
            setIsValidEmail(true)
            return 
        }

        if(getIsValidEmail(formDataCopy.applicant.email)) setIsValidEmail(true)
        else setIsValidEmail(false)
    }

    const onSubmitHandler = () =>  props.onSubmit(formData)

    const getIsDisabledSubmit = () => !isValidEmail

    return (
        <IonModal
            isOpen={props.isOpen}
            swipeToClose={true}
            onDidDismiss={() => props.onClose}
            backdropDismiss={false}
            cssClass="my-small-modal"
        >
            <IonHeader>
                <IonToolbar>
                    <IonRow>
                        <IonCol size="6"><IonTitle>Detalles a incluir en PDF</IonTitle></IonCol>
                    </IonRow>
                    <IonButtons slot="end">
                        <IonButton onClick={props.onClose}>CERRAR</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <CheckBoxRowElement
                        label="Incuir Gastos de Otorgamiento"
                        onClick={() => onChangeCheckBoxHandler('showOriginationCost')}
                        value={formData.showOriginationCost} 
                    />
                    {props.isShowSubsidaryCheckBox && (
                        <CheckBoxRowElement
                            label="Incluir detalle de subsidio"
                            onClick={() => onChangeCheckBoxHandler('showSubsidizedInfo')}
                            value={formData.showSubsidizedInfo} 
                        />
                    )}
              
                    <CheckBoxRowElement
                        label="Incluir datos del solicitante"
                        onClick={() => onChangeCheckBoxHandler('showApplicantData')}
                        value={formData.showApplicantData} 
                    />
                    
                    {props.hasDynamicForms ? (
                          <CheckBoxRowElement
                          label="Incluir Formularios Dinámicos"
                          onClick={() => onChangeCheckBoxHandler('showForms')}
                          value={formData.showForms} 
                        />
                    ) : null}


                    {formData.showApplicantData && (<>
                        <IonItem>
                            {getCountryAlias()==='ar' ? (
                                <IonLabel>CUIT:</IonLabel>
                            ):(
                                <IonLabel>CURP:</IonLabel>
                            )}
                            <IonInput maxlength={14} required className="ion-text-end" value={formData.applicant.CUIT} onIonChange={e => onChangeInputHandler('CUIT', e.detail.value ? e.detail.value : '')}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel>Nombre y Apellido:</IonLabel>
                            <IonInput required className="ion-text-end" value={formData.applicant.name} onIonChange={e => onChangeInputHandler('name', e.detail.value ? e.detail.value : '')}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel >Concesionario:</IonLabel>
                            <IonInput className="ion-text-end" value={formData.applicant.channel} onIonChange={e => onChangeInputHandler('channel', e.detail.value ? e.detail.value : '')}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel >Email:</IonLabel><IonLabel style={{ color: 'red' }}>{!isValidEmail ? 'invalido' : ''}</IonLabel>
                            <IonInput className="ion-text-end" type="email" value={formData.applicant.email} onIonChange={e => onChangeInputHandler('email', e.detail.value ? e.detail.value : '')}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel >Compra a Realizar:</IonLabel>
                            <IonInput className="ion-text-end" value={formData.applicant.productCategory} onIonChange={e => onChangeInputHandler('productCategory', e.detail.value ? e.detail.value : '')}></IonInput>
                        </IonItem>




                              
                            {/* <IonItem lines="inset">
                                <IonLabel>Total</IonLabel>
                                <p>{fee.acronym} {addSeparatorsToNumber(fee.fee_collection)}</p>
                            </IonItem> */}
                    </>)}
                </IonList>
            </IonContent>
            <IonFooter>
                <IonRow>
                    <IonCol class="ion-text-end">
                        <IonButton disabled={getIsDisabledSubmit()} color="primary" onClick={onSubmitHandler}>Descargar</IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonModal>
    )
} 