import { addPriceFormatToNumberValue } from "../components/FormGenerator/InputGenerator.component";
import { getCountryAlias } from "../stores/setup/setup.use.store";

export const addSeparatorsToNumber = (number: number) => addPriceFormatToNumberValue(String(number))

export const deleteFloatToPriceNumberString = (numberString: string): string => {
    if (getCountryAlias() === 'ar') {
        return numberString.split(',')[0]
    }

    if (getCountryAlias() === 'mx') {
        return numberString.split('.')[0]
    }

    return ''
}

export const deleteSpecialCharactersToNumberString = (numberString: string): string =>{
    return numberString.toLocaleLowerCase().split('')
        .map(character => 
            ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
            .find(characterForDelete=>characterForDelete===character) ? character : '' 
        )
        .join('')
}
