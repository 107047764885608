import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonLabel, IonProgressBar, IonRow, IonSkeletonText } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import React from 'react'

export const LoanRequestCardSekeletonComponent: React.FC  = () => {

    return (


        <IonCard>
            <IonCardContent>
                <IonRow className="ion-align-items-center">
                    <IonCol size="2">
                        <IonSkeletonText animated style={{ width: '100%' }} />
                    </IonCol>
                    <IonCol size="8">
                        <IonSkeletonText animated style={{ width: '200px' }} />
                    </IonCol>
                    <IonCol size="2" className="ion-text-end">
                            <IonSkeletonText animated style={{ width: '50px', display: 'inline-block' }} />

                    </IonCol>
                </IonRow>

                <IonRow className="ion-align-items-center">
        
                    <IonCol size="12" size-md="4" size-lg="4">
                        <IonRow className="ion-align-items-center" style={{ flexDirection: 'row-reverse' }}>
                            <IonCol size="4" className="ion-text-end">
                                <h1 style={{ margin: "0px", display: 'inline' }}>
                                    <IonSkeletonText animated style={{ width: '100%' }} />
                                </h1>
                            </IonCol>
                            <IonCol size="8">
                                <h6 style={{ margin: "0px", display: 'inline' }}><IonSkeletonText animated style={{ width: '100%' }} /></h6>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <br></br>
                <IonProgressBar><IonSkeletonText animated style={{ width: '100%' }} /></IonProgressBar>
                <br></br>
                <IonButton color="primary"><IonSkeletonText animated style={{ width: '150px' }} /></IonButton>
            </IonCardContent>
        </IonCard>
    )
}

