import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonTitle } from '@ionic/react'
import { text } from 'ionicons/icons'
import React from 'react'

export const ComercialPolicyNoResultComponent = () => {

    return (
        <div style={{padding: '20px', color: 'grey', textAlign: 'center'}}>
            <h3><b>No hemos encontrado ofertas para este producto</b></h3>
            <br></br>
            <b>Intenta cambiando la moneda, los filtros, o producto.</b>
        </div>
    )
}