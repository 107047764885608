import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

import { useState, useEffect } from 'react'
import { urlBase64ToUint8Array } from './app.helper.store';
import { useAppService } from './app.service.store';
import { getConfig } from '../../utils/getConfig';

export interface IAppStore {
    isLoading: boolean
    isInstalled: boolean
    isOpenInstallModal: boolean
    showLoading: () => void
    hideLoading: () => void
    installApp: () => void
    closeInstallModal: () => void
    createServiceWorker: () => void
    unsubscribePushNotification: () => void
    suscribePushNotification: () => void
    appInstallerController: () => void
}

export const useAppStore = (): IAppStore => {

    const appService = useAppService()

    const [isLoading, setIsLoading] = useState<IAppStore['isLoading']>(false)
    const [isInstalled, setIsInstalled] = useState<IAppStore['isLoading']>(false)
    const [isOpenInstallModal, setIsOpenInstallModal] = useState<boolean>(false)

    useEffect(() => { onInit() }, [])

    const onInit = async () => {
    
        createServiceWorker()
        appInstallerController()

    }

    const suscribePushNotification = () => {
        navigator.serviceWorker.ready.then(function (registration) {
            // Use the PushManager to get the user's subscription to the push service.
            return registration.pushManager.getSubscription()
                .then(async function (subscription) {
                    // If a subscription was found, return it.
                    if (subscription) {
                        return subscription;
                    }

                    // Get the server's public key
                    const response = await appService.getVapidPublicKey()
                    const vapidPublicKey = await response.data
                    // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
                    // urlBase64ToUint8Array() is defined in /tools.js
                    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
                    //const convertedVapidKey = urlBase64ToUint8Array('BJ5IxJBWdeqFDJTvrZ4wNRu7UY2XigDXjgiUBYEYVXDudxhEs0ReOJRBcBHsPYgZ5dyV8VjyqzbQKS8V7bUAglk');

                    // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
                    // send notifications that don't have a visible effect for the user).
                    console.log("service worker register!!")
                    
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey
                    });
                });
        }).then(async function (subscription) {

            // Send the subscription details to the server using the Fetch API.
            const response = await appService.register(subscription)

        })
    }


    const unsubscribePushNotification = () => {
        navigator.serviceWorker.ready.then(function(reg) {
            reg.pushManager.getSubscription().then((subscription) => {
                if(subscription) subscription.unsubscribe().then(function(successful) {
                // You've successfully unsubscribed
                }).catch((e) => {
                // Unsubscribing failed
                })
            })
          });
    }


    const createServiceWorker = () => {
        serviceWorkerRegistration.register({
            onUpdate: async (registration: any) => {
                // We want to run this code only if we detect a new service worker is
                // waiting to be activated.
                // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
                if (registration && registration.waiting) {
                    await registration.unregister();
                    // Makes Workbox call skipWaiting()
                    registration.waiting.postMessage({ type: "SKIP_WAITING" });
                    // Once the service worker is unregistered, we can reload the page to let
                    // the browser download a fresh copy of our app (invalidating the cache)
                    window.location.reload();
                }
            },
        });

        /////////////////////
        // esto es para el push, deberia estar en una funcion aparte, no aca dentro
        // navigator.serviceWorker.ready.then(function (registration) {
        //         // Use the PushManager to get the user's subscription to the push service.
        //         return registration.pushManager.getSubscription()
        //             .then(async function (subscription) {
        //                 // If a subscription was found, return it.
        //                 if (subscription) {
        //                     return subscription;
        //                 }

        //                 // Get the server's public key
        //                 const response = await appService.getVapidPublicKey()
        //                 const vapidPublicKey = await response.data
        //                 // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
        //                 // urlBase64ToUint8Array() is defined in /tools.js
        //                 const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
        //                 //const convertedVapidKey = urlBase64ToUint8Array('BJ5IxJBWdeqFDJTvrZ4wNRu7UY2XigDXjgiUBYEYVXDudxhEs0ReOJRBcBHsPYgZ5dyV8VjyqzbQKS8V7bUAglk');

        //                 // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
        //                 // send notifications that don't have a visible effect for the user).
        //                 console.log("service worker register!!")
                        
        //                 return registration.pushManager.subscribe({
        //                     userVisibleOnly: true,
        //                     applicationServerKey: convertedVapidKey
        //                 });
        //             });
        //     }).then(async function (subscription) {

        //         // Send the subscription details to the server using the Fetch API.
        //         const response = await appService.register(subscription)

        //     })
    }

    ///////////////////

    const showLoading: IAppStore['showLoading'] = () => setIsLoading(true)
    const hideLoading: IAppStore['hideLoading'] = () => setIsLoading(false)
    const closeInstallModal = () => setIsOpenInstallModal(false)

    const appInstallerController = async () => {
        let isUsingInstalledApp = window.matchMedia('(display-mode: standalone)').matches
        const installedDate: string | null = window.localStorage.getItem('installedDate') 
         
        window.addEventListener('appinstalled', (evt) => {
            window.localStorage.setItem('installedDate', new Date().toJSON())
        });
        
        if (isUsingInstalledApp) {
            setIsInstalled(true)
            setIsOpenInstallModal(false)
            return
        }

        if (installedDate) {
            setIsInstalled(false)
            setIsOpenInstallModal(false)
            return
        }
     
        setIsInstalled(false)
        setIsOpenInstallModal(true)
        prepareInstall()
        return
        
    }


    const prepareInstall = () => {
        const installBtnElement = document.createElement('button')
        installBtnElement.className = 'install-btn'
        installBtnElement.style.display = 'none'
        document.body.appendChild(installBtnElement)

        /////////

        var promptEvent: any;

        // Capture event and defer
        window.addEventListener('beforeinstallprompt', function (e) {
            e.preventDefault();
            promptEvent = e;
            listenToUserAction();
        });

        // listen to install button click
        function listenToUserAction() {
            const installBtn: any = document.querySelector(".install-btn");
            console.log(installBtn)
            installBtn.addEventListener("click", presentAddToHome);
        }

        // present install prompt to user
        function presentAddToHome() {

            console.log('lolazo')

            // ///////// Cerrar el menu
            // const button = document.querySelector(".MainMenuButton") as HTMLElement
            // if (button instanceof HTMLElement) {
            //   button.click();
            // }
            // //////////

            promptEvent.prompt();  // Wait for the user to respond to the prompt
            promptEvent.userChoice
                .then((choice: any) => {
                    if (choice.outcome === 'accepted') {
                        console.log('User accepted');
                        setIsInstalled(true)
                    } else {
                        console.log('User dismissed');
                        setIsInstalled(false)
                    }
                })
                .catch((e: any) => {
                    console.log(e)
                })

        }

    }

    const installApp = async () => {
        const button = document.querySelector(".install-btn") as HTMLElement
        if (button instanceof HTMLElement) {
            button.click();
        }
        setIsInstalled(true)
        setIsOpenInstallModal(false)
    }

    return {
        isLoading,
        isInstalled,
        isOpenInstallModal,
        closeInstallModal,
        showLoading,
        hideLoading,
        installApp,
        createServiceWorker,
        unsubscribePushNotification,
        suscribePushNotification,
        appInstallerController
    }
}