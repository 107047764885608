import { findIndex } from 'cypress/types/lodash';
import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import { timer } from '../../utils/timer';

const BackDropLoaderShadow = styled.div<{ isOpen: boolean }>`
    display: flex;
    opacity: ${props => props.isOpen ? '1' : '0'};
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100%;
    margin: 0px;
    top: 0;
    left: 0;
    z-index: 999;
    transition: opacity .2s ease-in;
`

export const LoaderSpinner = styled.div`
    
    border: 16px solid #ccc;
    border-radius: 50%;
    border-top: 16px solid grey;
    width: 60px;
    height: 60px;

    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    
    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`

export const LoaderContainer = styled.div`
    background: #eee;
    box-shadow: 3px 3px 5px grey;
    width: 500px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;
    z-index: 999;

`

export const LoaderText = styled.p`

    color: #577;
    font-size: 17px;
`

export const LoaderBarContainer = styled.div`
    width: 100%;
    border:1px solid grey;
`

export const LoaderBarContent = styled.div<{porcent: number}>`
    width: ${props => props.porcent}%;
    padding: 10px;
    background-color: #577;
    transition: width 0.3s;

`

const textList: Array<string> = [
    'Buscando las mejores opciones para esta compra…',
    'Calificando al cliente…',
    'Calculando parámetros del crédito…',
    'Ordenando opciones mas convenientes…'
]

export interface ComercialPolicyListLoaderComponentProps {
    isOpen: boolean
}

export const ComercialPolicyListLoaderComponent: FC<ComercialPolicyListLoaderComponentProps> = (props) => {

    const [isRendered, setIsRendered] = React.useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = React.useState<boolean>(false)
    const [currentText, setCurrentText] = useState<string>('Buscando las mejores opciones para esta compra…')
    const [currentPorcent, setCurrentPorcent] = useState<number>(0)

    useEffect(() => {

        if (props.isOpen !== isRendered) changeAnimation(props.isOpen)
        if (props.isOpen) changeText(currentText)
        if (!props.isOpen) setCurrentPorcent(0)
        if (!props.isOpen) setCurrentText('Buscando las mejores opciones para esta compra…')

    }, [currentText, props.isOpen])

    const changeText = (currentText: string) => {
        
        setTimeout(() => {
            const textIndexFinded = textList.findIndex(text => text === currentText)
            const porcent = (textIndexFinded + 1) * 25
            setCurrentPorcent(porcent)

            if (textList[textIndexFinded + 1]) {
                setCurrentText(textList[textIndexFinded + 1])
            } else {
                setCurrentText(textList[0])
            }
        }, 2500)
    }


    const changeAnimation = async (isOpen: boolean) => {
        if (props.isOpen) {
            setIsRendered(true)
            await timer(50)
            setIsOpenAnimation(true)
        } else {
            setIsOpenAnimation(false)
            await timer(300)
            setIsRendered(false)
        }
    }

    return (
        <div>
            {isRendered ? (
                <BackDropLoaderShadow isOpen={isOpenAnimation}>
                    <LoaderContainer>
                        <LoaderText>{currentText}</LoaderText>
                        {/* <LoaderSpinner /> */}

                        <LoaderBarContainer>
                            <LoaderBarContent porcent={currentPorcent}/>
                        </LoaderBarContainer>
                    </LoaderContainer>
                </BackDropLoaderShadow>
            ) : null}
        </div>
    )
}