import React from 'react'
import { setup } from '../../utils/setup'
import { useSetupService } from './setup.service.store'
import { getToken, setToken } from '../../utils/token.util'


import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import { ISetupStore, TCountryState } from './setup.interface.store';


export const getCountryAlias = (): TCountryState => {
    const environmentVar = setup.country

    if (environmentVar === 'Argentina') return 'ar'
    if (environmentVar === 'México') return 'mx'

    return 'ar'
}

export const useSetupStore = (): ISetupStore => {

    const setupService = useSetupService()

    const [isMobile, setIsMobile] = React.useState<ISetupStore['isMobile']>(false)
    const [countryAlias, setCountryAlias] = React.useState<ISetupStore['countryAlias']>(getCountryAlias())

    React.useEffect(() => {
        responsiveHandler()
    }, [])

    window.onresize = () => {
        const height = window.innerHeight
        const width = window.innerWidth
        if (width <= 900 && !isMobile) setIsMobile(true)
        else if (width > 900 && isMobile) setIsMobile(false)
    }

    const responsiveHandler = () => {
        const height = window.innerHeight
        const width = window.innerWidth
        if (width < 1024 && !isMobile) setIsMobile(true)
        else if (width >= 1024 && isMobile) setIsMobile(false)
    }

    const getSetupData: ISetupStore['getSetupData'] = () => {
        const response = setupService.getSetupData()

        setup.loggingApiKey = response.loggingApiKey
        setup.projectId = response.projectId
        setup.apiBaseUrl = response.apiBaseUrl
        setup.apiBaseUrlControlPanel = response.apiBaseUrlControlPanel
        setup.riskApiUrl = response.riskApiUrl
        setup.cookieAuthKey = response.cookieAuthKey
        setup.cookieAuthDomain = response.cookieDomain
        setup.loginUrl = response.loginUrl
        setup.country = response.country
        setup.token = getToken(setup.cookieAuthKey)
        setup.environment = response.environment
        setup.sentryDsn = response.sentryDsn
        setup.bureauApiUrl = response.bureauApiUrl
        setup.apiUrl = response.apiUrl

        setToken(setup.cookieAuthKey)
        setCountryAlias(getCountryAlias())

        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: response.sentryDsn,
                integrations: [new Integrations.BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
            });
        }
    }

    return {
        isMobile,
        countryAlias,
        getSetupData
    }
}