import { getValueToForm, TForm } from "FormGenerator"

const newInputsShemeForContractor: TForm = [
    {
        id: '41',
        type: 'checkBoxGroup',
        label: 'Servicios',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        hidden: false,
        options: [
            { value: 'Siembra', label: 'Siembra',  size: 6 },
            { value: 'Cosecha', label: 'Cosecha', size: 6 },
            { value: 'Picado', label: 'Picado', size: 6 },
            { value: 'Fumigación', label: 'Fumigación', size: 6 },
            { value: 'Fertilización', label: 'Fertilización', size: 6 },
            { value: 'Enfardado/Rollos', label: 'Enfardado/Rollos', size: 6 },
            { value: 'Otro', label: 'Otro', size: 6 }
        ],
        error: false,
        size: 12,
    },
]

const newInputsShemeForProductor: TForm = [
    {
        id: '31',
        type: 'number',
        label: 'Cantidad de has arrendadas',
        value: '',
        maxValue: 999999999999999,
        minValue: 0,
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        size: 6,
        disabled: false,
        hidden: false,
    },
    {
        id: '32',
        type: 'number',
        label: 'Cantidad de has propias',
        value: '',
        maxValue: 999999999999999,
        minValue: 0,
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        size: 6,
        disabled: false,
        hidden: false,
    }
]

const newInputsShemeForTransportist: TForm = [
    {
        id: '61',
        label: '¿Cantidad de km de flete que presta en un año?',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        size: 6,
        disabled: false,
        hidden: false,
        format: 'ar'
    }
]

/////////////////////////////////////

export const removeAllExtraInputs = (formData: TForm): TForm => {
    return formData.filter(input => Number(input.id) < 30 || Number(input.id) > 1000)
}

export const generateProcessedFormDataForTransportist = (newFormData: TForm): TForm => {
    const kmByYearInputFinded = newFormData.find(input => input.id === '61')
    if (kmByYearInputFinded) return [kmByYearInputFinded] 
    const newInputsForAdd: TForm = [...newInputsShemeForTransportist]
    return [...newInputsForAdd]
}

export const generateProcessedFormDataForProductor = (newFormData: TForm): TForm => {

    const numberOfHectaresLeasedInputFinded = newFormData.find(input => input.id === '31')
    const numberOfOwnHectaresInputFinded = newFormData.find(input => input.id === '32')

    if (numberOfHectaresLeasedInputFinded && numberOfOwnHectaresInputFinded) return [numberOfHectaresLeasedInputFinded,numberOfOwnHectaresInputFinded]
    
    const newInputsForAdd: TForm = [...newInputsShemeForProductor]
    return [...newInputsForAdd]
}

export const generateProcessedFormDataForContratist = (newFormData: TForm): TForm => {
    
    const servicesCheckBoxGroupInputFinded = newFormData.find(input => input.id === '41')
    const siembraHectaresInputFinded = newFormData.find(input => input.id === '51')
    const cosechaHectaresInputFinded = newFormData.find(input => input.id === '52')
    const picadoHectaresInputFinded = newFormData.find(input => input.id === '53')
    const fumigacionHectaresInputFinded = newFormData.find(input => input.id === '54')
    const fertilizacionHectaresInputFinded = newFormData.find(input => input.id === '55')
    const enfardadoRollosHectaresInputFinded = newFormData.find(input => input.id === '56')
    const otrosHectaresInputFinded = newFormData.find(input => input.id === '57')

    let arrayOfValues: Array<string> = [] 
    let newFormDataForProcess: TForm = []


    if (servicesCheckBoxGroupInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, servicesCheckBoxGroupInputFinded]
    } else {
        newFormDataForProcess = [...newFormDataForProcess, ...newInputsShemeForContractor]
    }

    const servicesInputFinded = newFormData.find(input => input.id === '41')
    if (servicesInputFinded && servicesInputFinded.type === 'checkBoxGroup') {
        arrayOfValues = servicesInputFinded.value.split(',')
    }

    const siembraAdded = arrayOfValues.find(value => value === 'Siembra')
    const cosechaAdded = arrayOfValues.find(value => value === 'Cosecha')
    const picadoAdded = arrayOfValues.find(value => value === 'Picado')
    const fumigacionAdded = arrayOfValues.find(value => value === 'Fumigación')
    const fertilizacionAdded = arrayOfValues.find(value => value === 'Fertilización')
    const enfardadoRollosAdded = arrayOfValues.find(value => value === 'Enfardado/Rollos')
    const otroAdded = arrayOfValues.find(value => value === 'Otro')


    if (siembraAdded && !siembraHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '51',
            label: 'Hectáreas siembra',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (siembraAdded && siembraHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, siembraHectaresInputFinded]
    }

    if (cosechaAdded && !cosechaHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '52',
            label: 'Hectáreas cosecha',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (cosechaAdded && cosechaHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, cosechaHectaresInputFinded]
    }

    if (picadoAdded && !picadoHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '53',
            label: 'Hectáreas picado',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (picadoAdded && picadoHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, picadoHectaresInputFinded]
    }

    if (fumigacionAdded && !fumigacionHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '54',
            label: 'Hectáreas fumigación',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (fumigacionAdded && fumigacionHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, fumigacionHectaresInputFinded]
    }

    if (fertilizacionAdded && !fertilizacionHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '55',
            label: 'Hectáreas fertilización',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (fertilizacionAdded && fertilizacionHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, fertilizacionHectaresInputFinded]
    }

    if (enfardadoRollosAdded && !enfardadoRollosHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess,
        {
            id: '56',
            label: 'Hectáreas enfardado/rollos',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (enfardadoRollosAdded && enfardadoRollosHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, enfardadoRollosHectaresInputFinded]
    }

    if (otroAdded && !otrosHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, {
            id: '57',
            label: 'Hectáreas otro',
            placeholder: 'Campo requerido...',
            type: 'number',
            required: true,
            error: false,
            value: '',
            maxValue: 9999999999,
            minValue: 0,
            size: 6,
            disabled: false,
            hidden: false,
        }]
    } else if (otroAdded && otrosHectaresInputFinded) {
        newFormDataForProcess = [...newFormDataForProcess, otrosHectaresInputFinded]
    }
    return [...newFormDataForProcess]
}

export const getUpdateFormDataWithClientTypeCondition = (newFormData: TForm): TForm => {

    const newActivityTypeValue = getValueToForm(newFormData, '2')

    if (newActivityTypeValue === 'contratista') {
        return [...removeAllExtraInputs(newFormData),...generateProcessedFormDataForContratist(newFormData)]
    }

    if (newActivityTypeValue === 'productor') {
        return [...removeAllExtraInputs(newFormData), ...generateProcessedFormDataForProductor(newFormData)]
    }

    if (newActivityTypeValue === 'productor/contratista') {
        return [
            ...removeAllExtraInputs(newFormData),
            ...generateProcessedFormDataForProductor(newFormData),
            ...generateProcessedFormDataForContratist(newFormData)
        ]
    }

    if (newActivityTypeValue === 'transportista') {
        return [...removeAllExtraInputs(newFormData), ...generateProcessedFormDataForTransportist(newFormData)]
    }

    if (newActivityTypeValue === 'otro') {

        return [...removeAllExtraInputs(newFormData)]
    }

    return [...removeAllExtraInputs(newFormData)]
}
