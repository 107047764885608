import { useState, useEffect } from 'react'
import { ISimulateStore } from './simulate.interface.store'
import { ISimulateListResponse, IDownloandPdfRequest, IProductTypeResponse, ICategoryResponse, productTypeRequestFactory, ISimulate } from './simulate.model.store'
import { useSimulateService } from './simulate.service.store'

export const useSimulateStore = (): ISimulateStore => {

    const simulateServices = useSimulateService()

    const [ simulateList, setSimulateList ] = useState<ISimulateListResponse['data']>([])
    
    const getSimulatesList: ISimulateStore['getSimulatesList'] = async () => {
        const response = await simulateServices.getSimulateList()
        if(response.isSuccess) {
            setSimulateList(response.data.data)
        } else {
            // props.onApiClientError(response)
        }
    }

    const getPDFUrl: ISimulateStore['getPDFUrl'] = async ( id, param) => {
        const response = await simulateServices.getPDFUrl(id, param)
        if(response.isSuccess) return response.data.data
        else {
            // props.onApiClientError(response)
            return ''
        }
    }

    const onDeleteSimulate: ISimulateStore['onDeleteSimulate'] = async (id) => {
        const response = await simulateServices.remove(id)
        if(response.isSuccess) {
            setSimulateList(simulateList.filter(simulate => simulate.id !== id))
        } else {
            // props.onApiClientError(response)
        }
    }

    return {
        simulateList,
        getSimulatesList,
        getPDFUrl,  
        onDeleteSimulate
    }
}