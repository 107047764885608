import { useState } from 'react'
import { useOnBoardingService } from './onBoarding.service.store'

import {
    ICheckPoliciesRequest,
    IMaxFeesRequest,
    ISimulateRequest,
    maxFeesFactory,
    ICategoriesResponse,
    IMaxFeesResponse,
    checkPoliciesResponseFactory,
    checkPoliciesFactory,
    checkPoliciesRequestFactory,
    simulateResponseFactory,
    IDownloadPDFRequest, simulateRequestFactory,
    ISimulateResponseAdapted,
    ICheckPoliciesAdaptedResponse,
    IPolicieAdapted,
    simulateResponseAdapter,
} from './onBoarding.model.store'
import { IProductTypeResponse, ISimulate } from '../simulate/simulate.model.store'
import { TForm } from 'FormGenerator'
import { getCountryAlias } from '../setup/setup.use.store'
import { getStepNumberOneScheme } from '../setup/setup.scheme.store'
import { TActionResult, useStoreUtil } from '../../utils/useStore.util'
import { setup } from '../../utils/setup'
import { IOnboardingStore } from './onBoarding.interface.store'


export const useOnBoardingStore = (props: { profileEmail: string }): IOnboardingStore => {

    const onboardingUtil = useStoreUtil()
    const onBoardingService = useOnBoardingService()

    const [maxFees, setMaxFees] = useState<IOnboardingStore['maxFees']>(maxFeesFactory())

    const [checkPoliciesRequest, setCheckPoliciesRequest] = useState<IOnboardingStore['checkPoliciesRequest']>(checkPoliciesRequestFactory())
    const [checkPolicies, setCheckPolicies] = useState<IOnboardingStore['checkPolicies']>(checkPoliciesResponseFactory())
    const [currentPolicie, setCurrentPolicie] = useState<IOnboardingStore['currentPolicie']>(checkPoliciesFactory())

    const [simulateRequest, setSimulateRequest] = useState<IOnboardingStore['simulateRequest']>(simulateRequestFactory())
    const [simulateResponse, setSimulateResponse] = useState<ISimulateResponseAdapted>(simulateResponseFactory())

    const [categories, setCategories] = useState<IOnboardingStore['categories']>([])

    const getCategories: IOnboardingStore['getCategories'] = async () => {
        const categoriesResponse = await onBoardingService.getCategories()
        if (categoriesResponse.isSuccess) {
            setCategories(categoriesResponse.data.data)
            return onboardingUtil.createSuccessResult(categoriesResponse.data.data)
        }

        return onboardingUtil.createErrorResult([])

    }

    const getProduct: IOnboardingStore['getProduct'] = async (productId: number) => {
        const productResponse = await onBoardingService.getProductType(productId)
        if (productResponse.isSuccess) return onboardingUtil.createSuccessResult(productResponse.data)
        else return onboardingUtil.createErrorResult(productResponse.data)
    }

    const getPolices: IOnboardingStore['getPolices'] = async (param, currencyFilter) => {
        const response = await onBoardingService.getPolices(param, currencyFilter)
        if (response.isSuccess) {
            setCheckPoliciesRequest(param)
            setCheckPolicies(response.data)
            return onboardingUtil.createSuccessResult(null)
        } else {
            return onboardingUtil.createErrorResult(null)
        }
    }

    const addCurrentPolicie: IOnboardingStore['addCurrentPolicie'] = (policieId: number) => {
        const policieFinded = checkPolicies.commercialPolicies.find(policie => policie.id === policieId)
        if (policieFinded) setCurrentPolicie(policieFinded)
    }

    const getFees: IOnboardingStore['getFees'] = async (param: IMaxFeesRequest) => {
        const response = await onBoardingService.getFees(param)
        if (response.isSuccess) {
            setMaxFees(response.data)
            return onboardingUtil.createSuccessResult(response.data)
        } else {
            return onboardingUtil.createErrorResult({
                12: [],
                6: [],
                3: [],
                1: []
            })
        }
    }

    const createSimulate: IOnboardingStore['createSimulate'] = async (param: ISimulateRequest) => {
        const response = await onBoardingService.createSimulate(param)
        if (response.isSuccess) {
            setSimulateRequest(param)
            setSimulateResponse(simulateResponseAdapter(response.data))
            return onboardingUtil.createSuccessResult(null)
        } else {
            return onboardingUtil.createErrorResult(null)
        }
    }

    const editSimulate: IOnboardingStore['editSimulate'] = async (paramRequest: ISimulateRequest, simulateId: number) => {
        const response = await onBoardingService.editSimulate(paramRequest, simulateId)
        if (response.isSuccess) {
            setSimulateRequest(paramRequest)
            setSimulateResponse(simulateResponseAdapter(response.data))
            return onboardingUtil.createSuccessResult(null)
        } else {
            return onboardingUtil.createErrorResult(null)
        }
    }

    const saveSimulate: IOnboardingStore['saveSimulate'] = async (simulateId: number) => {
        const response = await onBoardingService.saveSimulate(simulateId)
        if (response.isSuccess) {
            return onboardingUtil.createSuccessResult(null)
        }
        return onboardingUtil.createErrorResult(null)
    }

    const downloadPDF: IOnboardingStore['downloadPDF'] = async (
        id: number,
        pdfOptions: IDownloadPDFRequest
    ) => {
        const response = await onBoardingService.downloadPDF(id, pdfOptions)
        if (response.isSuccess) {
            return onboardingUtil.createSuccessResult(response.data.data)
        } else {
            return onboardingUtil.createErrorResult('')
        }
    }

    // me faltan resetiar mas cosas
    const resetCheckPoliciesRequest: IOnboardingStore['resetCheckPoliciesRequest'] = () => setCheckPoliciesRequest(checkPoliciesRequestFactory())
    const resetCheckPoliciesResponse: IOnboardingStore['resetCheckPoliciesResponse'] = () => setCheckPolicies(checkPoliciesResponseFactory())
    const resetCurrentPolicie: IOnboardingStore['resetCurrentPolicie'] = () => setCurrentPolicie(checkPoliciesFactory())
    const resetMaxFees: IOnboardingStore['resetMaxFees'] = () => setMaxFees(maxFeesFactory())

    const resetSimulateRequest: IOnboardingStore['resetSimulateRequest'] = () => setSimulateRequest(simulateRequestFactory())
    const resetSimulateResponse: IOnboardingStore['resetSimulateResponse'] = () => setSimulateResponse(simulateResponseFactory())

    /////////////////

    const verifyNip: IOnboardingStore['verifyNip'] = async (param) => {
        const response = await onBoardingService.verifyNip(param)
        if(response.status === 422) return onboardingUtil.createSuccessResult('rfc is not valid')
        if(response.isError) return onboardingUtil.createErrorResult('rfc is not valid')
        if(response.data.verified) return onboardingUtil.createSuccessResult('nip is verified')
        else return onboardingUtil.createSuccessResult('nip is not verified')
    }

    const generateNip: IOnboardingStore['generateNip'] = async (param) => {
        const response = await onBoardingService.generateNip(param)
        if(response.status === 422) return onboardingUtil.createSuccessResult('rfc is not valid')
        if(response.isError) return onboardingUtil.createErrorResult('rfc is not valid')
        return onboardingUtil.createSuccessResult('nip is generated')
    }

    const validateNip: IOnboardingStore['validateNip'] = async (param) => {
        const response = await onBoardingService.validateNip(param)
        if(response.status === 409) return onboardingUtil.createSuccessResult('nip is not valid')
        if(response.isError) return onboardingUtil.createErrorResult('nip is not valid')
        return onboardingUtil.createSuccessResult('nip is valid')
    }

    ////////

    const getPreloadDataSchemeWithCuitAndPersonType: IOnboardingStore['getPreloadDataSchemeWithCuitAndPersonType'] = async (cuit, activity) => {
        const newFormDataResult = await getPreloadDataScheme()
        if (newFormDataResult.isError) return onboardingUtil.createErrorResult([])
        const newForm = newFormDataResult.data.map(field => {
            if (field.id === '14.1' && field.type === 'cuilcuit') return { ...field, value: cuit }
            if (field.id === '2' && field.type === 'options') return { ...field, value: activity }
            return field
        })
        return onboardingUtil.createSuccessResult(newForm)
    }

    // todo: esto tiene que estar en un helper, no aca
    const getPreloadDataScheme: IOnboardingStore['getPreloadDataScheme'] = async () => {

        let newForm: TForm = []
        let newCategories: ICategoriesResponse['data'] = []

        newForm = getStepNumberOneScheme()

        if (categories.length) {
            newCategories = categories
        } else {
            const categoriesResponse = await getCategories()
            if (categoriesResponse.isError) return onboardingUtil.createErrorResult([])
            newCategories = categoriesResponse.data
        }

        if (newCategories.length) {
            newForm = newForm.map(input => {
                if (input.id !== '20' && input.id !== '21') return input
                if ( input.id == '20' && input.type === 'options') return {
                    ...input,
                    options: newCategories.map(option => ({
                        value: String(option.id),
                        label: option.name,
                    }))
                }
                if ( input.id == '21' && input.type === 'vinculedOptions') return {
                    ...input,
                    vinculedOptions: newCategories.map(option => ({
                        value: String(option.id),
                        options: option.product_types.map(productType => ({
                            value: String(productType.id),
                            label: productType.name
                        }))
                    }))
                }
                return input
            })
        }

        if (setup.country === 'México') {
            const getStatesWithTheirMunicipalitiesOfMexicoResponse =
                await onBoardingService.getStatesWithTheirMunicipalitiesOfMexico()

            if (getStatesWithTheirMunicipalitiesOfMexicoResponse.isError) return onboardingUtil.createErrorResult(newForm)

            newForm = newForm.map(input => {
                if (input.id === '1' && input.type === 'options') return {
                    ...input,
                    options: getStatesWithTheirMunicipalitiesOfMexicoResponse.data.map(state => ({
                        value: state.name,
                        label: state.name,
                        // optionsToVincule: state.municipalities.map(municipalitie => ({
                        //     value: municipalitie,
                        //     label: municipalitie
                        // }))
                    }))
                }

                if (input.id === '1.1' && input.type === 'vinculedOptions') return {
                    ...input,
                    vinculedOptions: getStatesWithTheirMunicipalitiesOfMexicoResponse.data.map(state => ({
                        value: state.name,
                        options: state.municipalities.map(municipalitie => ({
                            value: municipalitie,
                            label: municipalitie
                        }))
                    }))
                }
                
                else return input
            })

        }


        return onboardingUtil.createSuccessResult(newForm)
    }

    const getPreloadDataSchemeFromSimulate: IOnboardingStore['getPreloadDataSchemeFromSimulate'] = async (simulate) => {
        const result = await getPreloadDataScheme()
        if (result.isError) return onboardingUtil.createErrorResult([])

        let newForm: TForm = result.data

        if (getCountryAlias() === 'ar') {
            newForm = newForm.map(field => {
                if (field.id === '2' && field.type == 'options') return { ...field, value: simulate.customer.clasificacion_fiscal ? simulate.customer.clasificacion_fiscal : '' }
                if (field.id === '14.1' && field.type === 'cuilcuit') return { ...field, value: simulate.customer.identification_number }
                if (field.id === '20' && field.type === 'options') return { ...field, value: String(simulate.product.category_id) }
                if (field.id === '21' && field.type === 'vinculedOptions') return { ...field, value: String(simulate.product.type_id) }

                if (field.id === '21.1' && field.type === 'text') return { ...field, value: String(simulate.product.brand) }
                if (field.id === '21.2' && field.type === 'text') return { ...field, value: String(simulate.product.model) }

                if (field.id === '22' && field.type === 'price') return { ...field, value: simulate.product.price }
                if (field.id === '23' && field.type === 'options') return { ...field, value: String(simulate.product.currency_id) }
                if (field.id === '24' && field.type === 'options') return { ...field, value: String(simulate.product.condition) }
                return field
            })
        }

        if (getCountryAlias() === 'mx') {
            newForm = newForm.map(field => {


                if (field.id === '20' && field.type === 'options') return { ...field, value: String(simulate.product.category_id) }
                if (field.id === '21' && field.type === 'vinculedOptions') return { ...field, value: String(simulate.product.type_id) }

                if (field.id === '21.1' && field.type === 'text') return { ...field, value: String(simulate.product.brand) }
                if (field.id === '21.2' && field.type === 'text') return { ...field, value: String(simulate.product.model) }

                if (field.id === '22' && field.type === 'price') return { ...field, value: simulate.product.price }
                if (field.id === '23' && field.type === 'options') return { ...field, value: String(simulate.product.currency_id) }
                if (field.id === '24' && field.type === 'options') return { ...field, value: String(simulate.product.condition) }

                // customer
                if (field.id === '3' && field.type === 'text') return { ...field, value: String(simulate.customer.primer_nombre) }
                if (field.id === '4' && field.type === 'text') return { ...field, value: String(simulate.customer.segundo_nombre) }
                if (field.id === '5' && field.type === 'text') return { ...field, value: String(simulate.customer.apellido_paterno) }
                if (field.id === '6' && field.type === 'text') return { ...field, value: String(simulate.customer.apellido_materno) }

                // curp
                if (field.id === '14' && field.type === 'text') return { ...field, value: String(simulate.customer.identification_number) }

                return field
            })
        }

        return onboardingUtil.createSuccessResult(newForm)
    }


    return {
        getPreloadDataSchemeWithCuitAndPersonType,
        getPreloadDataSchemeFromSimulate,
        getPreloadDataScheme,

        maxFees,
        getCategories,
        getProduct,
        checkPolicies,
        getPolices,
        getFees,
        createSimulate,
        editSimulate,
        saveSimulate,
        downloadPDF,
        categories,
        currentPolicie,
        addCurrentPolicie,
        checkPoliciesRequest,
        simulateResponse,
        simulateRequest,

        resetMaxFees,
        resetCheckPoliciesRequest,
        resetCheckPoliciesResponse,
        resetCurrentPolicie,

        resetSimulateRequest,
        resetSimulateResponse,

        verifyNip,
        generateNip,
        validateNip,
    }
}