import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonModal, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react'

import { FormGeneratorComponent, getValueToForm } from './FormGenerator/FormGenerator.component'
import { IFormGeneratorComponent } from './FormGenerator/FormGenerator.interface';

interface INipFormComponentProps {
    onSubmit: (nip: string) => void
    onNext: () => void
    onResend: () => void
    isOpen: boolean
    email: string
    onClose: ()=> void
}

export const NipFormComponent = (props: INipFormComponentProps) => {

    const [formData, setFormData] = React.useState<IFormGeneratorComponent['form']>([
        {
            id: 2,
            type: 'number',
            label: 'CODIGO',
            value: '',
            placeholder: 'Campo Requerido',
            required: true,
            error: false,
        }
    ])

    const setFormDataHandler = (newFormData: IFormGeneratorComponent['form']) => {
        const nipValue = getValueToForm(newFormData, 2)
        if (nipValue.length < 5) setFormData(newFormData)
        else setFormData(newFormData.map(field => {
            if (field.id === 2) return { ...field, value: getValueToForm(formData, 2) }
            else return field
        }))
    }

    const onSubmitHandler = (formData: IFormGeneratorComponent['form']) => {
        const nipInputFinded = formData.find(input=> input.id === 2)
        if(nipInputFinded && nipInputFinded.type === 'number') props.onSubmit(nipInputFinded.value)
    }
    
    const onResendHandler = () => props.onResend()

    return (
        <IonModal
            isOpen={props.isOpen}
            swipeToClose={true}
            onDidDismiss={() => true}
            backdropDismiss={false}
            cssClass="my-small-modal"
        >
            <IonHeader>
                <IonToolbar>
                    <IonRow>
                        <IonCol size="6"><IonTitle></IonTitle></IonCol>
                    </IonRow>
                    <IonButtons slot="end">
                        <IonButton onClick={() => props.onClose()}>CERRAR</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <br></br><br></br>


                <IonRow><IonCol className="ion-text-center"><IonTitle>Ingresar el código enviado</IonTitle></IonCol></IonRow>
                <br></br>

                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonText>Enviamos un código de 4 digtos al email {props.email}, revisalo e ingresalo a continuación.</IonText>
                    </IonCol>
                </IonRow>
                <br></br><br></br>
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                        <FormGeneratorComponent
                            form={formData}
                            setForm={setFormDataHandler}
                            submitText="Siguiente"
                            onSubmit={() => onSubmitHandler(formData)}
                            columns={1}
                        />
                    </IonCol>
                    <IonCol></IonCol>
                </IonRow>
                {/* <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton fill="clear" color="primary" onClick={() => onResendHandler()}>Reenviar Código</IonButton>
                    </IonCol>
                </IonRow> */}
                {/* {props.isEnableNipNextStep ? ( */}
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton fill="clear" onClick={() => props.onNext()}>Continuar sin Bureau</IonButton>
                        </IonCol>
                    </IonRow>
                {/* ) : null} */}
            </IonContent>
        </IonModal>
    )
}