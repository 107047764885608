import { IonCard, IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import {ILoanRequest} from '../stores/loanRequest/loanRequest.model.store'

interface IWarrantyDetailComponentProps {
    warrantys: ILoanRequest['warrantys']
}

export const WarrantyDetailComponent: React.FC<IWarrantyDetailComponentProps> = ({ warrantys })=> {
    return (
        <>{
            warrantys.map(warranty => (
                <IonCard key={ 'warrantyNumber'+warranty.id}>{
                        warranty.fields.map( field =>(
                            <IonItem key={field.attribute_id}>
                                <IonLabel>{field.attribute_name === 'ingreso de Marca'? 'Marca' : field.attribute_name}:</IonLabel>
                                <p>{field.value}</p>
                            </IonItem>
                        ))
                }</IonCard>
            ))
        }</>
    )
}