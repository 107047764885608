import { IonCol, IonRow } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import React from 'react'
import { useHistory } from 'react-router'
import { NoDataComponent } from '../components/NoData.component'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import { INotificationStore } from '../stores/notification/notification.use.store'
import { IUserStore } from '../stores/user/user.use.store'
import { setup } from '../utils/setup'

interface IAuthErrorPageProps {
    notificationStore: INotificationStore
    userStore: IUserStore
}

export const AuthErrorPage: React.FC<IAuthErrorPageProps> = props => {
    const history = useHistory()
    
    const onSubmitHandlerFromNoDataComponent = () => {
        window.location.replace(setup.loginUrl);
    }
    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <IonRow>
                <IonCol size="0" size-md="4" size-lg="4"/>
                <IonCol size="12" size-md="4" size-lg="4">
                    <NoDataComponent
                        title= 'No estas Logueado!!!'
                        message= 'Debes acceder a tu cuenta para poder operar con la aplicacion'
                        button= 'Sign In'
                        href= {setup.loginUrl}
                        onSubmit={onSubmitHandlerFromNoDataComponent}
                    />
                </IonCol>
                <IonCol size="0" size-md="4" size-lg="4"/>
            </IonRow>
        </ContentWithNavigationLayout>
    )
}