import { TApiClientResponse, useApiClient } from "../../utils/ApiClient.util"
import { getConfig } from "../../utils/getConfig"

interface IGetSetupDataResponse {
    loggingApiKey: string
    projectId: string
    apiBaseUrl: string
    apiBaseUrlControlPanel: string
    riskApiUrl: string
    pushNotificationApiUrl: string
    cookieAuthKey: string
    cookieDomain: string
    loginUrl: string
    country: string
    sentryDsn: string
    environment: 'dev' | 'stage' | 'qa' | 'demo' | 'prod'
    bureauApiUrl: string
    apiUrl: string
}

export const useSetupService = () => {

    const getSetupData = ()  => getConfig()
    
    return { getSetupData }
}