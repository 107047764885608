import { ICreditModel } from "./credit.model.store"

const useCreditHelper = () => {
    const createCredit = (): ICreditModel => ({
        "id": 0,
        "loan_request": {
            "id": 0,
            "currency": "USD",
            "amount": 0,
        },
        "customer": {
            "alias": '',
            "tax_identification_number": ""
        },
        "balance": 0,
        "daysLate": 0,
        "installments":  [],
        "installments_total_amount": 0, 
        "payments": []
    })

    return { createCredit }
}


export { useCreditHelper }