import { IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon, IonTitle, IonToggle, IonSegment, IonSegmentButton, IonLabel, IonAlert } from '@ionic/react'
import { arrowDown, arrowUp, informationCircle } from 'ionicons/icons';
import moment from 'moment';
import React, { useState } from 'react'
import { IMaxFeesResponse } from '../../stores/onBoarding/onBoarding.model.store';
import { useDebounce } from '../../utils/debounce.use.util';
import { addSeparatorsToNumber, deleteSpecialCharactersToNumberString } from '../../utils/number.util';
import { TActionResult } from '../../utils/useStore.util';
import { getNumberValueToPriceInput } from '../FormGenerator/FormGenerator.component';
import { addNewPriceFormatToOldPriceValue, addPriceFormatToNumberValue } from '../FormGenerator/InputGenerator.component';
import { MyDateModalComponent } from '../MyDateModal.component';
import { getMaxAmountHandler, getMaxFeesNumber } from './src/components/OnBoardingStepNumberThreePureFee/OnBoardingStepNumberThreePureFee.helper';

export interface IFormData {
    amountToRequest: string
    firstFeeDate: string
    feeNumbers: string
    paymentPeriod: 0 | 1 | 2 | 3 | 4 // ninguno, mensual, trimestral, semestral, anual
    checkbook: boolean
    separateExpenses: boolean
    subsidize: boolean
    subsidyRate: string
}

interface IOnBoardingStepNumberThreePureFeeComponent {
    currentPolicyId: number
    currentCurrency: string
    maxAmount: number
    maxFees: IMaxFeesResponse
    maxSubsidyRate: number
    onSubmit(formData: IFormData): void
    getPureFees(mount: number, period: number, subsidaryTax: number, isSubsidary: boolean, firstFeeDate: string): TActionResult<IMaxFeesResponse>// me falta llamarlo
    paymentPeriod: Array<number>
    subsidyRate: number
    isBlockedSubsidyRate: boolean
    breakMode: boolean
    isSeparateExpensesEnabled: boolean
    isAymetricFees: boolean
    fixedFees: '' | 'Min' | 'Max'
}

export const OnBoardingStepNumberThreePureFeeComponent: React.FC<IOnBoardingStepNumberThreePureFeeComponent> = (props) => {

    const [amountToRequest, setAmountToRequest] = useState<string>('0')
    const [firstFeeDate, setFirstFeeDate] = useState<Date>()
    const [feeNumbers, setFeeNumbers] = useState<string>('1')
    const [paymentPeriod, setPaymentPeriod] = useState<0 | 1 | 2 | 3 | 4>(0)
    const [checkbook] = useState<boolean>(false)
    const [separateExpenses, setSeparateExpenses] = useState<boolean>(false)
    const [subsidize, setSubsidize] = useState<boolean>(false)
    const [subsidyRate, setSubsidyRate] = useState<string>('0')

    const [isMaxFeeDebouncing, setIsMaxFeeDebouncing] = useState<boolean>(false)
    const [isDisabledCalendar, setIsDisabledCalendar] = useState<boolean>(true)
    const [isOpenDateModal, setIsOpenDateModal] = useState<boolean>(false)

    const [isOpenInformationAlert, setIsOpenInformationAlert] = useState(false)

    const generateFormData = (): IFormData => ({
        amountToRequest: getNumberValueToPriceInput(amountToRequest),
        firstFeeDate: moment(firstFeeDate).format('YYYY-MM'),
        feeNumbers,
        paymentPeriod,
        checkbook,
        separateExpenses,
        subsidize: props.isBlockedSubsidyRate ? true : subsidize,
        subsidyRate: props.subsidyRate ? String(props.subsidyRate) : subsidyRate
    })

    const maxFeeDebounce = useDebounce(() => {
        callMaxFeesHandler(generateFormData())
        amountHandler(generateFormData().amountToRequest)
        setIsMaxFeeDebouncing(false)
    }, 2000)

    React.useEffect(() => {
        resetForm()
    }, [props.currentPolicyId])

    React.useEffect(() => {
        if (props.paymentPeriod.length === 1) {
            paymentPeriodHandler(String(props.paymentPeriod[0]))

            // callMaxFeesHandler(generateFormData())
            // amountHandler(generateFormData().amountToRequest)
            // setIsMaxFeeDebouncing(false)
        }


    }, [props.paymentPeriod])

    const resetForm = () => {
        setAmountToRequest('0')
        setFirstFeeDate(undefined)
        setFeeNumbers('1')
        setPaymentPeriod(0)
        setSeparateExpenses(false)
        setSubsidize(false)
        setSubsidyRate('0')
        setIsMaxFeeDebouncing(false)
        setIsDisabledCalendar(true)
        setIsOpenDateModal(false)
    }

    const getMounthNumberOfPeriod = (period: 0 | 1 | 2 | 3 | 4): number => {
        if (period === 1) return 1
        if (period === 2) return 3
        if (period === 3) return 6
        if (period === 4) return 12
        return 1
    }

    const getIsDisabledSubsidize = (): boolean => paymentPeriod === 0 || !!props.subsidyRate

    const callMaxFeesHandler = async (formData: IFormData) => {
        if (formData.paymentPeriod) {
            const result = await props.getPureFees(
                Number(getNumberValueToPriceInput(formData.amountToRequest)),
                Number(formData.paymentPeriod),
                Number(formData.subsidyRate),
                formData.subsidize,
                formData.firstFeeDate
            )

            if (props.fixedFees === 'Min') {
                setFeeNumbers('1')
            }

            if (props.fixedFees === 'Max') {
                if (formData.paymentPeriod === 1) {
                    setFeeNumbers(String(result.data[1].length))
                }
                if (formData.paymentPeriod === 2) {
                    setFeeNumbers(String(result.data[3].length))
                }
                if (formData.paymentPeriod === 3) {
                    setFeeNumbers(String(result.data[6].length))
                }
                if (formData.paymentPeriod === 4) {
                    setFeeNumbers(String(result.data[12].length))
                }
            }

            return result
            //amountHandler(String(amountToRequest))
        }

        
    }

    const feeUpArrowHandler = () => {
        setFeeNumbers(Number(feeNumbers) < getMaxFeesNumberByPeriod() ?
            String(Number(feeNumbers) + 1)
            : feeNumbers)
        amountHandler(String(amountToRequest))
    }

    const feeDownArrowHandler = () => {
        setFeeNumbers(Number(feeNumbers) > 1 ? String(Number(feeNumbers) - 1) : feeNumbers)
        amountHandler(amountToRequest)
    }

    const feeInputHandler = async (value: string) => {
        const valueParsed = Number(value)
        if (value === '') {
            setFeeNumbers('')
        } else if (valueParsed > getMaxFeesNumberByPeriod()) {
            setFeeNumbers(String(getMaxFeesNumberByPeriod() ? getMaxFeesNumberByPeriod() : 1))
        } else if (valueParsed < 1) {
            setFeeNumbers('1')
        } else {
            setFeeNumbers(value)
        }

        amountHandler(amountToRequest)
    }

    const generateValidatedAmountValue = (value: string): string => {
        const valueParsed: number = Number(getNumberValueToPriceInput(value))
        if (valueParsed > getMaxAmountbyFeeNumber()) return addPriceFormatToNumberValue(String(getMaxAmountbyFeeNumber()))
        else if (valueParsed < 0) return String(1)
        else return addNewPriceFormatToOldPriceValue(value)
    }


    ///////getMaxAmount//////////////

    const maxAmountHandler = () => {
        const newValue = addPriceFormatToNumberValue(String(getMaxAmountbyFeeNumber()))
        setAmountToRequest(addPriceFormatToNumberValue(String(getMaxAmountbyFeeNumber())))
        callMaxFeesHandler({ ...generateFormData(), amountToRequest: newValue })
    }

    /////////////////

    const amountHandler = (value: string): string => {
        const valueFormated: string = deleteSpecialCharactersToNumberString(value)
        const newValue = generateValidatedAmountValue(valueFormated)
        setAmountToRequest(newValue)
        return newValue
    }

    const amountHandlerWithMaxFeeCall = (value: string) => {
        const newValue = amountHandler(value)
        maxFeeDebounce({ ...generateFormData(), amountToRequest: newValue })
        setIsMaxFeeDebouncing(true)
        setImmediate(() => maxFeeDebounce({ ...generateFormData(), amountToRequest: newValue }))
    }

    const getMaxFeePeriodByPaymentPeriod = (): Array<{ number: number, max_amount: number, avg_fee: number, avg_fee_low_weight: number }> => {
        if (paymentPeriod === 0) return props.maxFees[1]
        if (paymentPeriod === 1) return props.maxFees[1]
        if (paymentPeriod === 2) return props.maxFees[3]
        if (paymentPeriod === 3) return props.maxFees[6]
        if (paymentPeriod === 4) return props.maxFees[12]
        return props.maxFees[1]
    }

    const getMaxAmountbyFeeNumber = (): number => {
        const feeFinded = getMaxFeePeriodByPaymentPeriod().find(fee => fee.number === Number(feeNumbers))
        if (feeFinded) return feeFinded.max_amount
        return 0
    }

    ///////////////////////

    const dateHandler = (date: Date) => {
        setIsOpenDateModal(false)
        setFirstFeeDate(date)
        callMaxFeesHandler(generateFormData())
        amountHandler(generateFormData().amountToRequest)
    }

    const paymentPeriodHandler = async (value: string | undefined) => {
        const newPaymentPeriodValue = value === '1' ? 1
            : value === '2' ? 2
                : value === '3' ? 3
                    : value === '4' ? 4
                        : 1
        const newFirstDateValue = new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(newPaymentPeriodValue), 'month').toJSON())


        setPaymentPeriod(newPaymentPeriodValue)
        getMounthNumberOfPeriod(newPaymentPeriodValue)

        setFirstFeeDate(
            new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(newPaymentPeriodValue), 'month').toJSON())
        )


        setIsDisabledCalendar(false)
        const result = await callMaxFeesHandler({ ...generateFormData(), amountToRequest: '0', paymentPeriod: newPaymentPeriodValue, firstFeeDate: newFirstDateValue.toJSON() })
        if(result === undefined || result.isError ) return
        if (props.fixedFees === 'Min') {
            setAmountToRequest(getMaxAmountHandler(newPaymentPeriodValue, result.data, 1))
            setFeeNumbers('1')

        }else {
            setAmountToRequest(getMaxAmountHandler(newPaymentPeriodValue, result.data, getMaxFeesNumber(result.data, newPaymentPeriodValue)))
            setFeeNumbers(String(getMaxFeesNumber(result.data, newPaymentPeriodValue)))
        }
    }

    const separateExpensesHandler = () => {
        setSeparateExpenses(!separateExpenses)
    }

    const subsidizeHandler = () => {
        const newSubsidizeValue = !subsidize
        setSubsidize(newSubsidizeValue)
        if (newSubsidizeValue) setSubsidyRate(String(props.maxSubsidyRate))
        else setSubsidyRate('0')
    }

    const onSubmitHandler = () => {
        props.onSubmit(generateFormData())
    }

    const subsidyRateInputHandler = (value: string | undefined) => {
        const valueParsed = Number(value)
        if (valueParsed <= props.maxSubsidyRate && valueParsed >= 0) setSubsidyRate(String(valueParsed))
        if (valueParsed > props.maxSubsidyRate) setSubsidyRate(String(props.maxSubsidyRate))
        if (valueParsed < 0) setSubsidyRate('0')
        setIsMaxFeeDebouncing(true)
        setImmediate(() => maxFeeDebounce(generateFormData()))
    }

    const subsidyRateUpArrowHandler = () => {
        const currentSubsidyRateParsedPlusOne = Number(subsidyRate) + 1
        if (currentSubsidyRateParsedPlusOne <= props.maxSubsidyRate) {
            setSubsidyRate(String(currentSubsidyRateParsedPlusOne))
            setIsMaxFeeDebouncing(true)
            setImmediate(() => maxFeeDebounce(generateFormData()))
        } else {
            setSubsidyRate(String(props.maxSubsidyRate))
        }
    }

    const subsidyRateDownArrowHandler = () => {
        const currentSubsidyRateParsedMinusOne = Number(subsidyRate) - 1
        if (currentSubsidyRateParsedMinusOne >= 0) {
            setSubsidyRate(String(currentSubsidyRateParsedMinusOne))
            setIsMaxFeeDebouncing(true)
            setImmediate(() => maxFeeDebounce(generateFormData()))
        } else {
            setSubsidyRate('0')
        }
    }

    const getMaxFeesNumberByPeriod = (): number => {
        if (paymentPeriod === 1) {
            return props.maxFees['1'].length
        }

        if (paymentPeriod === 2) {
            return props.maxFees['3'].length
        }

        if (paymentPeriod === 3) {
            return props.maxFees['6'].length
        }

        if (paymentPeriod === 4) {
            return props.maxFees['12'].length
        }

        return 0
    }

    const inputFirstFeeHandler = () => {
        setIsOpenDateModal(true)
    }
    
    const getIsDisabledSubmint = () => amountToRequest === '' || amountToRequest === '0' || isMaxFeeDebouncing || isDisabledCalendar

    const onClickInformationHandler = () => setIsOpenInformationAlert(true)


    const getAverageFee = (): {
        higherFee: string,
        lowerFee: string
    } => {
        const feeFinded = getMaxFeePeriodByPaymentPeriod().find(fee => fee.number === Number(feeNumbers))
        if (feeFinded) return {
            higherFee: addSeparatorsToNumber(feeFinded.avg_fee),
            lowerFee: addSeparatorsToNumber(feeFinded.avg_fee_low_weight)
        }
        else return {
            higherFee: '',
            lowerFee: ''
        }
    }

    return (
        <>
            <IonAlert
                isOpen={isOpenInformationAlert}
                onDidDismiss={() => setIsOpenInformationAlert(false)}
                header={''}
                message={"Beneficio de poder postergar el pago de los gastos a la primera cuota en lugar de al momento 0."}
                buttons={['Aceptar']}
            />
            <IonRow><br></br><br></br><br></br></IonRow>
            <IonRow>
                <IonCol className="ion-text-center"><IonTitle className="title-of-payment-period">ESTRUCTURA DEL CRÉDITO (#{props.currentPolicyId})</IonTitle></IonCol>
            </IonRow>
            <IonRow><br></br><br></br></IonRow>
            <IonRow>
                <IonCol></IonCol>
                <IonCol className="ion-text-center">
                    <IonLabel>Periodicidad de Pago</IonLabel>

                    {props.paymentPeriod.length === 1 ? (
                        <IonTitle>{
                            props.paymentPeriod[0] === 1 ? 'Mensual' :
                                props.paymentPeriod[0] === 2 ? 'Trimestral' :
                                    props.paymentPeriod[0] === 3 ? 'Semestral' :
                                        props.paymentPeriod[0] === 4 ? 'Anual' : ''
                        }</IonTitle>
                    ) : null}

                    {props.paymentPeriod.length > 1 ? (
                        <IonSegment scrollable value={String(paymentPeriod)} /*onIonChange={e => paymentPeriodHandler(e.detail.value)}*/ color="secondary">
                            {props.paymentPeriod.find(period => period === 1) && (
                                <IonSegmentButton data-testid="paymentMensualButton" onClick={e => paymentPeriodHandler("1")} value="1">
                                    <IonLabel>Mensual</IonLabel>
                                </IonSegmentButton>
                            )}

                            {props.paymentPeriod.find(period => period === 2) && (
                                <IonSegmentButton data-testid="paymentTrimestralButton" onClick={e => paymentPeriodHandler("2")} value="2">
                                    <IonLabel>Trimestral</IonLabel>
                                </IonSegmentButton>
                            )}

                            {props.paymentPeriod.find(period => period === 3) && (
                                <IonSegmentButton data-testid="paymentSemestralButton" onClick={e => paymentPeriodHandler("3")} value="3">
                                    <IonLabel>Semestral</IonLabel>
                                </IonSegmentButton>
                            )}

                            {props.paymentPeriod.find(period => period === 4) && (
                                <IonSegmentButton data-testid="paymentAnualButton" onClick={e => paymentPeriodHandler("4")} value="4">
                                    <IonLabel>Anual</IonLabel>
                                </IonSegmentButton>
                            )}

                        </IonSegment>
                    ) : null}

                </IonCol>
                <IonCol></IonCol>

            </IonRow>
            <IonRow><br></br></IonRow>

            <IonRow>
                <IonCol />
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Fecha primera cuota</IonLabel>
                        <IonInput class="first-installment-date-input" data-testid="dateInput" disabled={paymentPeriod === 0} value={moment(firstFeeDate).format('MM-YYYY')} readonly={true} onClick={() => inputFirstFeeHandler()} />
                        <MyDateModalComponent
                            isOpen={isOpenDateModal}
                            onClose={() => setIsOpenDateModal(false)}
                            onChange={dateHandler}
                            title="Fecha primera cuota"
                            value={firstFeeDate ? firstFeeDate : new Date()}
                            minDate={new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(paymentPeriod), 'month').toJSON())}
                            maxDate={new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(paymentPeriod) + 3, 'month').toJSON())}
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Cuotas</IonLabel>
                        {props.fixedFees === 'Min' || props.fixedFees === 'Max' ? (
                            <IonTitle>{feeNumbers}</IonTitle>
                        ) : null}
                        
                        {props.fixedFees === '' ? (
                            <>
                                <input
                                    data-testid="feeInput"
                                    disabled={paymentPeriod === 0}
                                    onChange={(e: any) => feeInputHandler(e.target && e.target.value ? e.target.value : '')}
                                    value={feeNumbers}
                                    type="number"
                                    placeholder="Campo Requerido"
                                    style={{ padding: '0px 0px 10px 0px' }}
                                    className="ion-text-end native-input sc-ion-input-md"
                                />
                                <IonButton className="fees-arrow-up-button" data-testid="feeUpButton" disabled={paymentPeriod === 0 || isMaxFeeDebouncing} onClick={() => feeUpArrowHandler()} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                <IonButton className="fees-arrow-down-button" data-testid="feeDownButton" disabled={paymentPeriod === 0 || isMaxFeeDebouncing} onClick={() => feeDownArrowHandler()} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                            </>
                        ) : null}

                    </IonItem>
                </IonCol>
                <IonCol />
            </IonRow>
            <IonRow><br></br><br></br><br></br></IonRow>
            {!props.breakMode && (
                <>
                    <IonRow>
                        <IonCol />
                        <IonCol>
                            <IonItem>
                                <IonLabel>Gastos en la primera cuota</IonLabel>

                                <IonButton className="expenses-in-the-first-installment-button-info" size="small" fill="clear" onClick={() => onClickInformationHandler()}><IonIcon icon={informationCircle} /></IonButton>
                                <IonToggle className="expenses-in-the-first-installment-toggle" data-testid="expensesToggle" disabled={paymentPeriod === 0 || !props.isSeparateExpensesEnabled} color="secondary" checked={separateExpenses} onIonChange={() => separateExpensesHandler()} />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Subsidiar</IonLabel>
                                {props.isBlockedSubsidyRate && (
                                    <IonToggle disabled={true} color="secondary" checked={true} />
                                )}
                                {!props.isBlockedSubsidyRate && (
                                    <IonToggle data-testid="subsidaryToggle" disabled={getIsDisabledSubsidize()} color="secondary" checked={subsidize} onClick={() => subsidizeHandler()} />
                                )}
                            </IonItem>
                        </IonCol>
                        <IonCol />
                    </IonRow>
                    <IonRow><br /></IonRow>

                    <IonRow>
                        <IonCol></IonCol>
                        <IonCol className="ion-text-center">
                            {props.isBlockedSubsidyRate && (
                                <IonItem>
                                    <IonLabel position="stacked">Salir a tasa %</IonLabel>
                                    <input
                                        disabled={true}
                                        value={props.subsidyRate}
                                        type="number"
                                        placeholder="Campo Requerido"
                                        style={{ padding: '0px 0px 10px 0px' }}
                                        className="ion-text-end native-input sc-ion-input-md"
                                    />
                                    <IonButton disabled={true} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                    <IonButton disabled={true} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                                </IonItem>
                            )}

                            {subsidize && !props.isBlockedSubsidyRate && (
                                <IonItem>
                                    <IonLabel position="stacked">Salir a tasa %</IonLabel>
                                    <input
                                        data-testid="subsidaryInput"
                                        disabled={getIsDisabledSubsidize()}
                                        onChange={(e: any) => subsidyRateInputHandler(e.target.value ? e.target.value : '')}
                                        value={subsidyRate}
                                        type="number"
                                        placeholder="Campo Requerido"
                                        style={{ padding: '0px 0px 10px 0px' }}
                                        className="ion-text-end native-input sc-ion-input-md"
                                    />
                                    <IonButton data-testid="subsidaryUpButton" disabled={getIsDisabledSubsidize()} onClick={() => subsidyRateUpArrowHandler()} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                    <IonButton data-testid="subsidaryDownButton" disabled={getIsDisabledSubsidize()} onClick={() => subsidyRateDownArrowHandler()} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                                </IonItem>
                            )}
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    <IonRow><br /></IonRow>
                </>
            )}

            <IonRow>
                <IonCol />
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Monto a Solicitar ({props.currentCurrency})</IonLabel>
                        <input
                            data-testid="amountInput"
                            value={amountToRequest}
                            placeholder="Campo Requerido"
                            onChange={(e: any) => amountHandlerWithMaxFeeCall(e?.target?.value ? e?.target?.value : '')}
                            type="text"
                            className="native-input sc-ion-input-md ion-text-end"
                            disabled={paymentPeriod === 0 }
                        />
                        <IonButton
                            data-testid="maxAmountButton"
                            disabled={paymentPeriod === 0 }
                            onClick={() => maxAmountHandler()}
                            color="light"
                            style={{ margin: '20px -15px 0px 0px' }}
                            slot="end"
                        >
                            Solicitar Máximo
                        </IonButton>
                    </IonItem>
                </IonCol>
                <IonCol />
            </IonRow>

            <IonRow><br></br><br></br><br></br></IonRow>
                


            {props.isAymetricFees && amountToRequest !== '' && amountToRequest !== '0' ? (
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                Cuota promedio menor:
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonTitle>${getAverageFee().lowerFee}</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                Cuota promedio mayor:
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonTitle>${getAverageFee().higherFee}</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol></IonCol>
                </IonRow>
            ) : null}

            {props.isAymetricFees === false && amountToRequest !== '' && amountToRequest !== '0' ? (
                <>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            Cuota promedio:
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonTitle>${getAverageFee().higherFee}</IonTitle>
                        </IonCol>
                    </IonRow>
                </>
            ): null}

            <IonRow>
                <IonCol className="ion-text-center">
                    <IonButton color="primary" data-testid="simulateButton" disabled={getIsDisabledSubmint()} onClick={() => onSubmitHandler()}>Simular</IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <br /><br /><br />
                </IonCol>
            </IonRow>
        </>
    )
}
