import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { checkmarkCircleOutline, lockClosed, cartOutline, calculator, personCircle, cash, codeDownload } from 'ionicons/icons';
import './Menu.component.css';
import { deleteToken } from '../utils/token.util';
import { setup } from '../utils/setup';
import { timer } from '../utils/timer';
import { IAppStore } from '../stores/app/app.use.store';
interface IMenu {
  userName: string
  countryAlias: 'ar' | 'mx'
  appStore: IAppStore
}

export const Menu = (props: IMenu) => {

  const history = useHistory()

  const onRedirectHandler = (url: string) => {
    const button = document.querySelector(".MainMenuButton") as HTMLElement
    if (button instanceof HTMLElement) {
      button.click();
    }
    setTimeout(() => {
      history.push(url)
    }, 100)
  }

  const onLogOutHandler = () => {
    deleteToken(setup.cookieAuthKey, setup.cookieAuthDomain)
    props.appStore.unsubscribePushNotification()
    window.location.href = setup.loginUrl
  }

  return (
    <IonMenu side="start" contentId="main" type="push">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.userName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem button onClick={() => onRedirectHandler("/loanRequests")}>
            <IonIcon slot="start" icon={cartOutline}></IonIcon>
            <IonLabel>
              Mis Solicitudes
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => onRedirectHandler("/simulator")}>
            <IonIcon slot="start" icon={calculator}></IonIcon>
            <IonLabel>
              Mis Simulaciones
            </IonLabel>
          </IonItem>

          <IonItem button onClick={() => onRedirectHandler("/credits")}>
            <IonIcon slot="start" icon={cash}></IonIcon>
            <IonLabel>
              Mis Creditos
            </IonLabel>
          </IonItem>

          {props.countryAlias === 'ar' ? (
            <IonItem button onClick={() => onRedirectHandler("/cuit")}>
              <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
              <IonLabel>
                Comprobar CUIT
              </IonLabel>
            </IonItem>
          ) : null}
        </IonList>
        <IonList>
          <IonItem button onClick={() => onRedirectHandler("/profile")}>
            <IonIcon slot="start" icon={personCircle}></IonIcon>
            <IonLabel>
              Perfil
            </IonLabel>
          </IonItem>

          {props.appStore.isInstalled === false ? (
            <IonItem button onClick={() => props.appStore.installApp()}>
              <IonIcon slot="start" icon={codeDownload}></IonIcon>
              <IonLabel>
                Instalar App
              </IonLabel>
            </IonItem>
          ) : null}

          <IonItem button onClick={() => onLogOutHandler()}>
            <IonIcon slot="start" icon={lockClosed}></IonIcon>
            <IonLabel>
              Cerrar Sesión
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  )
};


// <ion-icon name="cloud-download-outline"></ion-icon>
// <ion-icon name="code-download-outline"></ion-icon> este puede ser
// <ion-icon name="download-outline"></ion-icon>