import { setup } from '../../utils/setup'
import { TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'

import {
    ISimulateListResponse,
    IDownloandPdfRequest, 
    IDownloadPdfResponse, 
    ISaveSimulateResponse,
} from './simulate.model.store'

export const useSimulateService = ()=> {
    
    const baseApiClient = useApiClient()
    
    const getSimulateList = async (): TApiClientResponse<ISimulateListResponse> => {
        const response = await baseApiClient.get<ISimulateListResponse>(`${setup.apiBaseUrl}/v1/loan-simulator`)
        if(response.isSuccess) {
           return {
               ...response,
               data:{
                   ...response.data,
                   data: response.data.data && response.data.data.length ? response.data.data : []
                },
            } 
        }else return response
    }   

    const getPDFUrl = async ( id: number, param: IDownloandPdfRequest ) => {
        const response = await baseApiClient.post<IDownloadPdfResponse>(`${setup.apiBaseUrl}/v1/loan-simulator/pdf2/${id}`, param)
        return response
    }

    const remove = async (id: number) => {
        const response = await baseApiClient.del<ISaveSimulateResponse>(`${setup.apiBaseUrl}/v1/loan-simulator/${id}`)
        return response
    }

    // const saveSimulate = async (id: number) => {
    //     const response = await baseApiClient.put<ISaveSimulateResponse>(`v1/loan-simulator/${id}`, {})
    //     return response
    // }
    
    return { 
        getSimulateList, 
        getPDFUrl,  
        remove
    }
}