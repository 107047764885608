import { props } from 'cypress/types/bluebird';
import React from 'react';
import { ProfileDetailComponent, IProfileDetailComponentProps } from '../components/ProfileDetail.component'
import { IUserStore } from '../stores/user/user.use.store';


export interface IProfileModuleProps {
    userStore: IUserStore
}

export const ProfileModule: React.FC<IProfileModuleProps> = props => {
    return (
        <ProfileDetailComponent profile={props.userStore.profile}/>
    )
}