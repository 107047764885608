import React from 'react';
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'
import { LoanRequestModule } from '../modules/LoanRequests.module'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout';
import { useHistory } from 'react-router';
import { useIonViewDidEnter } from '@ionic/react';
import dataLayer from '../ga.js'
import { IUserStore } from '../stores/user/user.use.store';
import { TActionResult } from '../utils/useStore.util';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { ILoanRequestStore } from '../stores/loanRequest/loanRequest.interface.store';
import { ISetupStore } from '../stores/setup/setup.interface.store';

interface ILoanRequestsProps {
    userStore: IUserStore
    setupStore: ISetupStore
    onApiClientError: () => void
    notificationStore: INotificationStore
    loanRequestStore: ILoanRequestStore
}

export const LoanRequestsPage: React.FC<ILoanRequestsProps> = (props: ILoanRequestsProps) => {
    const history = useHistory()

    
    useIonViewDidEnter(async () => {
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'loanRequests list',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });

    })

    const onLoanRequestClickHandler = async (id: number) => history.push(`/loanRequests/${id}`)
    const onRedirectToOnBoardingHandler = () => history.push('/onBoarding')
    const onRedirectToSimulatorHandler = () => history.push('/simulator')

    const loadLoanRequestsActionHandler = async () => {
        const result = await props.loanRequestStore.loadLoanRequestsAction()
        if(result.isError) props.onApiClientError()
        return result
    }

    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <LoanRequestModule 
                loanRequests={props.loanRequestStore.loanRequests} 
                onLoanRequestClick={onLoanRequestClickHandler} 
                loadLoanRequestsAction={loadLoanRequestsActionHandler}
                isMobile={props.setupStore.isMobile}
                onRedirectToOnBoarding={onRedirectToOnBoardingHandler}
                onRedirectToSimulator={onRedirectToSimulatorHandler}
            />
        </ContentWithNavigationLayout>
    )
}