import React from 'react';
import { ProfileModule, IProfileModuleProps } from '../modules/Profile.module'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import dataLayer from '../ga.js'
import { useIonViewDidEnter } from '@ionic/react';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { IUserStore } from '../stores/user/user.use.store';

interface IProfilePageProps {
    userStore: IUserStore
    notificationStore: INotificationStore
}

export const ProfilePage: React.FC<IProfilePageProps> = (props)=> {
    
    
    useIonViewDidEnter(async ()=> {
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'profile',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });
    })


    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <ProfileModule userStore={props.userStore}/>
        </ContentWithNavigationLayout>
    )
}