import { IonButton, IonCard, IonCardContent, IonCol, IonItem, IonLabel, IonList, IonRow, IonTitle } from '@ionic/react'
import React from 'react'
import { ISimulate } from '../stores/simulate/simulate.model.store'
import { dateFormaterUtil } from '../utils/dateFormater.util'
import { TableGeneratorComponent } from '../components/TableGenerator.component'
import { addSeparatorsToNumber } from '../utils/number.util'

interface IOnBoardingDetailComponent {
    simulate: ISimulate
    isMobile: boolean

    onOpenDownloadModal(id: number): void
    onRemove(id: number): void
    onOpenConvertModal(id: number): void
}

export const SimulateDetailComponent: React.FC<IOnBoardingDetailComponent> = (props) => {

    const onDownloadHandler = () => {
        props.onOpenDownloadModal(props.simulate.id)
    }

    const onRemoveHandler = () => {
        props.onRemove(props.simulate.id)
    }

    const onOpenConvertModalHandler = () => {
        props.onOpenConvertModal(props.simulate.id)
    }

    return (
        <div>
            <br></br>
            <IonRow>
                <IonCol size="6"><IonTitle size="large">#{props.simulate.id}</IonTitle></IonCol>
                { props.simulate.customer.primer_nombre && props.simulate.customer.segundo_nombre && props.simulate.customer.apellido_paterno && props.simulate.customer.apellido_materno && props.simulate.customer.identification_number && (
                    <IonCol size="6"><IonTitle className={'ion-text-end'} size="small">{props.simulate.customer.primer_nombre} {props.simulate.customer.segundo_nombre} {props.simulate.customer.apellido_paterno} {props.simulate.customer.apellido_materno}({props.simulate.customer.identification_number})</IonTitle></IonCol>
                )}
                { props.simulate.customer.name && props.simulate.customer.identification_number && (
                    <IonCol size="6"><IonTitle className={'ion-text-end'} size="small">{props.simulate.customer.name}({props.simulate.customer.identification_number})</IonTitle></IonCol>
                )}
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonTitle size="small">{props.simulate.data.date_simulate}</IonTitle>
                </IonCol>
                <IonCol>
                    <IonTitle size="small" className={'ion-text-end'}>
                        {props.simulate.product.categoria} | ${props.simulate.product.price}{props.simulate.currency}
                    </IonTitle>
                </IonCol>
            </IonRow>
            <br></br>
            <h5 className="ion-text-center">Caída de cuotas</h5>
            {props.isMobile && props.simulate.data.loan.fees.map((fee, index) => (
                <IonCard key={`feeNumber${index}`}>
                    <IonCardContent>
                        <IonList>
                            <IonItem>
                                <IonLabel>Nro</IonLabel>
                                <p>{`#${fee.nro}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Vencimiento</IonLabel>
                                <p>{dateFormaterUtil(fee.date.date)}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Capital</IonLabel>
                                <p>{`${fee.acronym} ${fee.acronym} ${addSeparatorsToNumber(Number(fee.capital))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Interés</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.interest))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Sub Total</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.subtotal))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>I.V.A.(A RECUPERAR)</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(fee.tax_interest)}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Cuota pura</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.pure_fee))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Seguro</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.technical_insurance))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Cobranza</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.fee_collection))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Total a Pagar</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.pure_fee) + Number(fee.technical_insurance) + Number(fee.expenseTotal))}`}</p>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                    <br></br><br></br>
                </IonCard>
            ))}
       
            {!props.isMobile && (
                <TableGeneratorComponent
                    headerList={[
                        'Nro',
                        'Vencimiento',
                        'Capital',
                        'Interés',
                        'Sub Total',
                        'I.V.A.',
                        'Cuota pura',
                        'Seguro',
                        'Cobranza',
                        'Total a Pagar'
                    ]}

                    bodyList={props.simulate.data.loan.fees.map((fee, index) => ([
                        {
                            type: 'text',
                            text: `#${fee.nro}`
                        },
                        {
                            type: 'text',
                            text: dateFormaterUtil(fee.date.date)
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.amortization))}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.interest))}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.subtotal))}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(fee.tax_interest)}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.pure_fee))}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.technical_insurance))}`
                        },
                        {
                            type: 'text',
                            text: `${addSeparatorsToNumber(Number(fee.fee_collection))}`
                        },
                        {
                            type: 'text',
                            text: `${fee.acronym} ${addSeparatorsToNumber(Number(fee.value))}`
                        },
                    ]))}
                />
            )}

            <IonRow>
                <IonCol>
                    <h5 className="ion-text-center">Datos de la operación</h5>
                    <IonList>
                        <IonItem><IonLabel>Monto a solicitar por el cliente</IonLabel>{props.simulate.data.unityCurrency.acronym} {addSeparatorsToNumber(Number(props.simulate.amount))}</IonItem>
                        <IonItem><IonLabel>Gastos de la operación</IonLabel>{props.simulate.data.unityCurrency.acronym} {addSeparatorsToNumber(props.simulate.data.expense.total)}</IonItem>
                        <IonItem><IonLabel>Monto a percibir</IonLabel>{props.simulate.data.unityCurrency.acronym} {addSeparatorsToNumber(props.simulate.data.loan.amount_to_receive)}</IonItem>
                    </IonList>
                </IonCol>
                <IonCol>
                    <h5 className="ion-text-center">Más información</h5>
                    <IonList>
                        <IonItem><IonLabel>Costo del financiamiento</IonLabel>{props.simulate.tax}%</IonItem>
                        <IonItem><IonLabel>Fecha simulación</IonLabel>{props.simulate.data.date_simulate}</IonItem>
                        <IonItem><IonLabel>Fecha de otorgamiento</IonLabel>{props.simulate.data.date_origination}</IonItem>
                        {/* <IonItem><IonLabel>Plazo total financiamiento</IonLabel></IonItem> */}
                        <IonItem><IonLabel>Fecha primera cuota</IonLabel>{props.simulate.data.date_first_fee}</IonItem>
                        {/* <IonItem><IonLabel>Tipo de Crédito</IonLabel></IonItem>
                        <IonItem><IonLabel>Garantía</IonLabel></IonItem>
                        <IonItem><IonLabel>Comercio</IonLabel></IonItem> */}
                    </IonList>
                </IonCol>

                {/* <IonCol>
                            <IonTitle className="ion-text-center">Subsidio del comercio</IonTitle>
                            <IonList>
                                <IonItem><IonLabel>Precio del bien:</IonLabel>{simulate.data.unityCurrency.acronym} {simulate.product.price}</IonItem>
                                <IonItem><IonLabel>Monto a subsidiar:</IonLabel>{simulate.data.unityCurrency.acronym} {(Number(simulate.subsidized_tax)/100) * simulate.amount}</IonItem>
                                <IonItem><IonLabel>Porcentaje de Subsidio:</IonLabel>{simulate.subsidized_tax}%</IonItem>
                                <IonItem><IonLabel>Monto neto a liquidar al comercio:</IonLabel>{simulate.subsidized_detail}</IonItem>
                            </IonList>
                        </IonCol> */}
            </IonRow>
            <br></br><br></br><br></br>
            <IonRow>
                <IonCol className="ion-text-center">
                    <IonButton color="danger" onClick={onRemoveHandler}>
                        Eliminar
                    </IonButton>
                </IonCol>
                <IonCol className="ion-text-center">
                    <IonButton color="light" onClick={onDownloadHandler}>
                        Descargar PDF
                    </IonButton>
                </IonCol>
                <IonCol className="ion-text-center">
                    <IonButton color="primary" onClick={onOpenConvertModalHandler}>
                        Guardar Solicitud
                    </IonButton>
                </IonCol>
            </IonRow>
            <br></br><br></br><br></br>
        </div>
    )
}