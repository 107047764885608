import React, { useEffect, useState } from 'react';
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'
import { LoanRequestListComponent } from '../components/LoanRequestList.component'
import { IonContent, IonFab, IonFabButton, IonIcon, IonLoading, IonSkeletonText, useIonViewDidEnter } from '@ionic/react'
import { LoanRequestsTabFilterComponent, TSelected } from '../components/LoanRequestsTabFilters.component'
import { NoDataComponent } from '../components/NoData.component'
import { add } from 'ionicons/icons';
import { TActionResult } from '../utils/useStore.util';

interface ILoanRequestModule {
  loanRequests: Array<ILoanRequest>
  onLoanRequestClick(id: number): void
  loadLoanRequestsAction(): TActionResult<null>
  onRedirectToOnBoarding: () => void
  onRedirectToSimulator: () => void
  isMobile: boolean
}

export const LoanRequestModule: React.FC<ILoanRequestModule> = (props : ILoanRequestModule) => {

  useIonViewDidEnter(() => {
    initHandler()
  })

  const initHandler = async () => {
    setIsLoadingLoanRequest(true)
    await props.loadLoanRequestsAction()
    setIsLoadedData(true)
    setIsLoadingLoanRequest(false)
  }

  useEffect(()=>{
    const someActive = props.loanRequests.some(loanRequest=>loanRequest.atributteStatus === 'ACTIVA')
    if(someActive) setFilterSelected('ACTIVA')
    else setFilterSelected('TODAS')
  }, [props.loanRequests])

  const [filterSelected, setFilterSelected] = useState<TSelected>('TODAS')
  const [isLoadedData, setIsLoadedData] = useState<boolean>(false)
  const [isLoadingLoanRequest, setIsLoadingLoanRequest] = useState<boolean>(false)

  const onChangeFilterValue = async (value: TSelected) => setFilterSelected(value)

  const loanRequestFilter = () =>
    props.loanRequests.filter(loanRequest =>
      filterSelected === 'TODAS' || loanRequest.atributteStatus === filterSelected)

  const onLoanRequestClickHandler = async (id: number) => props.onLoanRequestClick(id)

  const onSubmitHandlerFromNoDataComponent = (url: string) => props.onRedirectToSimulator()

  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{ margin: '0px 10px' }} onClick={()=>props.onRedirectToOnBoarding()}>
          <IonFabButton>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
      </IonFab>

      <IonContent>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={isLoadingLoanRequest}
          onDidDismiss={() => setIsLoadingLoanRequest(false)}
          message={'Cargando...'}
        />
        <LoanRequestsTabFilterComponent onChangeValue={onChangeFilterValue} selectedValue={filterSelected} />
        <LoanRequestListComponent 
          isMobile={props.isMobile} 
          loanRequests={loanRequestFilter()} 
          onLoanRequestClick={onLoanRequestClickHandler} 
          isLoading={isLoadingLoanRequest}
        />
        {!props.loanRequests.length && isLoadedData && !isLoadingLoanRequest && (
          <NoDataComponent
            title="No hay Loan Requests"
            message="Puedes empezar a crear Loan Requests, y estos se verán reflejados aquí"
            button="Crear"
            href="/simulator"
            onSubmit={onSubmitHandlerFromNoDataComponent}
          />
        )}
      </IonContent>
    </>
  )
}