import React from 'react'
// todo: agregarle ciclos de vida
export const useDebounce = (callback: Function, delay: number) => {
    const latestCallback = React.useRef<any>();
    const latestTimeout = React.useRef<any>();
    React.useEffect(() => {
      latestCallback.current = callback;
    }, [callback])

    return <T>(param: T) => {

      if (latestTimeout.current) clearTimeout(latestTimeout.current);
      latestTimeout.current = setTimeout(() => latestCallback.current(param), delay);
    }
  };