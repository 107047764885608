import React from 'react'
import { SearchProductFormButton, SearchProductFormCell, SearchProductFormContainer, SearchProductFormInput, SearchProductFormLabel, SearchProductFormLink, SearchProductFormSelect, SearchProductFormText } from './SearchProductForm.element'
import {getNumberWithFormat, getNumberWithOutFormat} from 'FormGenerator'

export interface IProductData {
    categoryId: number
    productId: number
    brand: string
    model: string
    price: string
    currency: 'pesos' | 'dollars'
    condition: 'new' | 'used'
    year: number
}

interface ISearchProductFormComponentProps {
    categoryList: Array<{ id: number, label: string, productList: Array<{ id: number, label: string }> }>
    onSubmit: (productData: IProductData) => void
    formDataForSearch: IProductData
    setFormDataForSearch: (form: IProductData) => void
    onGoBack: ()=>void
    fullName: string
    identification: string,
    country: 'ar' | 'mx'
}

export const searchProductFormDataFactory = (): IProductData => ({
    categoryId: 0,
    productId: 0,
    brand: '',
    model: '',
    price: '',
    currency: 'pesos',
    condition: 'new',
    year: new Date().getFullYear()
})

export const SearchProductFormComponent: React.FC<ISearchProductFormComponentProps> = (props) => {

    const onChangeCategoryValueHandler = (value: string) => {
        props.setFormDataForSearch({ ...props.formDataForSearch, categoryId: Number(value), productId: 0 })
    }

    const onChangeProductValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, productId: Number(value) })
    // const onChangeBrandValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, brand: value })
    // const onChangeModelValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, model: value })
    const onChangePriceValueHandler = (value: string) => {
        const valueFormated = getNumberWithOutFormat(value, props.country)
        props.setFormDataForSearch({ ...props.formDataForSearch, price: getNumberWithFormat(valueFormated, props.country) })
    }
    const onChangeCurrencyValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, currency: value === 'pesos' ? 'pesos' : 'dollars' })
    // const onChangeConditionValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, condition: value === 'new' ? 'new' : 'used', year: new Date().getFullYear()})
    // const onChangeYearValueHandler = (value: string) => props.setFormDataForSearch({ ...props.formDataForSearch, year: Number(value) })

    const onSubmitHandler = () => props.onSubmit({ ...props.formDataForSearch })


    const getProductListWithCategorySelected = () => {
        if (props.formDataForSearch.categoryId === 0) return []
        const categoryFinded = props.categoryList.find(category => category.id === Number(props.formDataForSearch.categoryId))
        if (categoryFinded === undefined) return []
        return categoryFinded.productList
    }

    const getIsSubmitEnabled = (): boolean =>
        props.formDataForSearch.categoryId &&
        props.formDataForSearch.productId &&
        props.formDataForSearch.price &&
        props.formDataForSearch.currency ? true : false


    return (
        <SearchProductFormContainer>
            <SearchProductFormCell style={{width: '100%'}}>
                <SearchProductFormText>
                    {`${props.fullName}(${props.identification}) `}
                </SearchProductFormText>
                <SearchProductFormLink onClick={props.onGoBack}>
                    Cambiar
                </SearchProductFormLink>
            </SearchProductFormCell>
            <SearchProductFormCell style={{width: '50%'}}>
                <SearchProductFormLabel>Categoria:</SearchProductFormLabel>
                <SearchProductFormSelect disabled value={props.formDataForSearch.categoryId} onChange={e => onChangeCategoryValueHandler(e.target.value)}>
                    {props.formDataForSearch.categoryId === 0 ? <option value={0} disabled hidden></option> : null}
                    {props.categoryList.map(category => (
                        <option value={category.id} key={category.id}>{category.label}</option>
                    ))}
                </SearchProductFormSelect>
            </SearchProductFormCell>

            <SearchProductFormCell style={{width: '50%'}}>
                <SearchProductFormLabel>Producto:</SearchProductFormLabel>
                <SearchProductFormSelect disabled value={props.formDataForSearch.productId} onChange={e => onChangeProductValueHandler(e.target.value)}>
                    {props.formDataForSearch.productId === 0 ? <option value={0} disabled hidden></option> : null}
                    {getProductListWithCategorySelected().map(product => (
                        <option value={product.id} key={product.id}>{product.label}</option>
                    ))}
                </SearchProductFormSelect>
            </SearchProductFormCell>

            {/* <SearchProductFormCell style={{width: '25%'}}>
                <SearchProductFormLabel>Marca:</SearchProductFormLabel>
                <SearchProductFormInput type="text" value={props.formDataForSearch.brand} onChange={e => onChangeBrandValueHandler(e.target.value)} />
            </SearchProductFormCell>

            <SearchProductFormCell style={{width: '25%'}}>
                <SearchProductFormLabel>Modelo:</SearchProductFormLabel>
                <SearchProductFormInput type="text" value={props.formDataForSearch.model} onChange={e => onChangeModelValueHandler(e.target.value)} />
            </SearchProductFormCell>

        
            <SearchProductFormCell style={{width: '25%'}}>
                <SearchProductFormLabel>Condición:</SearchProductFormLabel>
                <SearchProductFormSelect value={props.formDataForSearch.condition} onChange={e => onChangeConditionValueHandler(e.target.value)}>
                    <option value={'new'}>Nuevo</option>
                    <option value={'used'}>Usado</option>
                </SearchProductFormSelect>
            </SearchProductFormCell>

            <SearchProductFormCell style={{width: '25%'}}>
                <SearchProductFormLabel>Año:</SearchProductFormLabel>
                <SearchProductFormInput disabled={props.formDataForSearch.condition === 'new'} type="number" value={props.formDataForSearch.year} onChange={e => onChangeYearValueHandler(e.target.value)} />
            </SearchProductFormCell> */}

            <div style={{width: '100%'}}/>

            <SearchProductFormCell  style={{width: '30%'}}>
                <SearchProductFormLabel>Moneda:</SearchProductFormLabel>
                <SearchProductFormSelect className="search-input-currency" value={props.formDataForSearch.currency} onChange={e => onChangeCurrencyValueHandler(e.target.value)}>
                    <option value={'pesos'}>Pesos</option>
                    <option value={'dollars'}>Dolares</option>
                </SearchProductFormSelect>
            </SearchProductFormCell>

            <SearchProductFormCell style={{width: '40%'}}>
                <SearchProductFormLabel>Precio:</SearchProductFormLabel>
                <SearchProductFormInput className="search-input-price" type="string" value={props.formDataForSearch.price} onChange={e => onChangePriceValueHandler(e.target.value)} />
            </SearchProductFormCell>

            <SearchProductFormCell  style={{width: '30%'}}>
                <SearchProductFormButton className="search-submit-button" disabled={getIsSubmitEnabled() === false} onClick={e => onSubmitHandler()}>Buscar</SearchProductFormButton>
            </SearchProductFormCell>
        </SearchProductFormContainer>
    )
}