import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonSegment, IonSegmentButton, IonLabel, IonRow, IonCol, IonBadge, IonItem, IonList, IonSkeletonText, IonListHeader, IonCard } from '@ionic/react';
import React, { useState } from 'react';
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'
import { FeeDetailComponent } from './FeesDetail.component'
import { WarrantyDetailComponent } from './WarrantyDetail.component'
import { FollowUpNotesDetailComponent } from './FollowUpNotesDetail.component'
import { addSeparatorsToNumber } from '../utils/number.util';
import { DocumentationComponent } from './ComercialPolicyList/DocumentationDetail.component';

interface ILoanRequestDetailComponentProps {
    loanRequest: ILoanRequest
    onDownloadFeePDF(id: number): void
    isLoadingDetail: boolean
}

type TComponentToDisplay = 'APPLICATION MANAGEMENT' | 'FEES' | 'WARRANTY'

export const LoanRequestDetailComponent: React.FC<ILoanRequestDetailComponentProps> = ({ 
    loanRequest, 
    onDownloadFeePDF,
    isLoadingDetail
}) => {
    const [componentToDisplay, setComponentToDisplay] = useState<TComponentToDisplay>('APPLICATION MANAGEMENT')

    const getCurrentStage = () => loanRequest && loanRequest.stageDays.find(stage => stage.current)

    const onClickTabHandler = (value: string) => {
        if (['APPLICATION MANAGEMENT', 'FEES', 'WARRANTY', 'DOCUMENTATION'].includes(value)) {
            setComponentToDisplay(value as TComponentToDisplay)
        }
    }

    return (
        <div>
                <IonRow>
                    <IonCol size="6"><IonTitle>#{loanRequest.id}</IonTitle></IonCol>
                    <IonCol size="6"><IonTitle>{loanRequest.contactCompany.alias}({loanRequest.contactCompany.cuitCuil})</IonTitle></IonCol>
                </IonRow>
                <IonRow>
                    <br></br>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonList>
                            <IonItem>
                                <IonBadge color="medium">{getCurrentStage()?.stageName}</IonBadge>
                            </IonItem>
                            <IonItem>
                                {loanRequest.createdAt}
                            </IonItem>
                        </IonList>
                    </IonCol>

                    <IonCol size="6">
                        <IonList>
                            <IonItem>
                                {loanRequest.product.productTypeName}
                            </IonItem>

                            <IonItem>
                            {loanRequest.product.currency} {addSeparatorsToNumber(loanRequest.requestAmount)}
                            </IonItem>
                        </IonList>
                    </IonCol>

                    {/* <IonCol size="12" size-md="6" size-lg="6">
                        <h5 className="ion-text-center">Datos de la operación</h5>
                        <IonItem><IonLabel>Monto a solicitar por el cliente</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Gastos de la operación</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Monto a percibir</IonLabel>{}</IonItem>
                    </IonCol>
                    <IonCol size="12" size-md="6" size-lg="6">
                        <h5 className="ion-text-center">Más información</h5>
                        <IonItem><IonLabel>Costo del financiamiento</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Fecha simulación</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Fecha de otorgamiento</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Fecha primera cuota</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Plazo total financiamiento</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Tipo de Crédito</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Garantía Prenda</IonLabel>{}</IonItem>
                        <IonItem><IonLabel>Comercio Concesionario</IonLabel>{}</IonItem>
                    </IonCol> */}
                </IonRow>
                <IonRow>
                    <br></br>
                </IonRow>
                <IonHeader>
                    <IonSegment onIonChange={e => onClickTabHandler(e.detail.value ? e.detail.value : '')} value={componentToDisplay} scrollable>
                        <IonSegmentButton value="APPLICATION MANAGEMENT">
                            <IonLabel>Gestion de Solicitud</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='FEES'>
                            <IonLabel>Cuotas</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='WARRANTY'>
                            <IonLabel>Garantia</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value='DOCUMENTATION'>
                            <IonLabel>Documentación</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonHeader>

                { isLoadingDetail ?
                    <IonCard>
                        <IonList>
                            <IonListHeader>
                                <IonLabel>
                                    <IonSkeletonText animated style={{ width: '20%' }} />
                                </IonLabel>
                            </IonListHeader>
                            <IonItem>
                                <IonLabel>
                                    <h3>
                                        <IonSkeletonText animated style={{ width: '50%' }} />
                                    </h3>
                                    <p>
                                        <IonSkeletonText animated style={{ width: '80%' }} />
                                    </p>
                                    <p>
                                        <IonSkeletonText animated style={{ width: '60%' }} />
                                    </p>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCard>
                    : componentToDisplay === 'APPLICATION MANAGEMENT' ? <FollowUpNotesDetailComponent followUpNotes={loanRequest ? loanRequest.followUpNotes: []} />
                    : componentToDisplay === 'FEES' ?
                        <FeeDetailComponent
                            fee={loanRequest.fee}
                            showPDFButton={!!loanRequest.simulation_id}
                            simulationId={loanRequest.simulation_id}
                            onDownloadPDF={onDownloadFeePDF}
                            loanRequestId={loanRequest.id}
                            feeTaxMode={loanRequest.commercial_policy?.fee_tax_mode === 1 ? 1  : 2 }

                        />
                    : componentToDisplay === 'WARRANTY' ? <WarrantyDetailComponent warrantys={loanRequest.warrantys} />
                    : componentToDisplay === 'DOCUMENTATION' ? <DocumentationComponent documentation={loanRequest.documentation}/>
                    : null
                }

        </div>
    );
};