import axios , { AxiosAdapter } from "axios"
import { IApiClientResponse, useApiClient } from "../../utils/ApiClient.util"
import { getConfig } from "../../utils/getConfig"
import { setup } from "../../utils/setup"

export const useAppService = () => {

    const apiClient = useApiClient()

    const getVapidPublicKey = async (): Promise<IApiClientResponse<string>> => {
        return {
            isSuccess: true,
            isError: false,
            data: 'BMZySPwbRvgJz30rSH2jdbwmVQD0lvjV6DcWqfWtCyNKZvL_Uem5QFJn00nQunGmS72H_242BQxM1SfHm-VNEn0',
            status: 200,
            method: 'GET',
            url: '',
            body: {},
        }
    }

    const register = async (subscription: PushSubscription) => {

        const suscriptionJson: any = subscription.toJSON()
        const response = axios({
            method: 'POST',
            url: `${setup.apiUrl}/v1/notifications/push-subscriptions`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'x-access-token': localStorage.getItem('token')
            },
            data: {
                "endpoint": suscriptionJson.endpoint,
                "public_key": suscriptionJson.keys.p256dh,
                "auth_token": suscriptionJson.keys.auth
            }
        })
        

        return response
    }


    return {getVapidPublicKey, register}
}