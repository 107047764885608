import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    border: 1px solid #000000;
    font-family: 'Open Sans', sans-serif !important;
`

export const Row = styled.div<{ align: 'right' | 'center' | 'left' }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: ${props => props.align === 'left' ? 'start' : props.align === 'center' ? 'center' : 'end'};
    align-items: ${props => props.align === 'left' ? 'start' : props.align === 'center' ? 'center' : 'end'};
`

export const Cell = styled.span<{ width: number, align: 'right' | 'center' | 'left' }>`
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width}%;
    justify-content: ${props => props.align === 'left' ? 'start' : props.align === 'center' ? 'center' : 'end'};
    align-items: ${props => props.align === 'left' ? 'start' : props.align === 'center' ? 'center' : 'end'};
`

export const OptionButtonContainer = styled.div`
    border: 1px solid #C9DBE6;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 0px;
    margin: 0px;
`

export const OptionButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    border: none;


    border-radius: 20px;
    background: ${props => props.selected ? '#176592' : 'none'};
    width: auto;
    color: ${props => props.selected ? 'white' : '#176592'};
    padding: 10px;
    white-space:nowrap;
    font-weight: bold;
    font-size: 80%;
    font-family: 'Open Sans', sans-serif;
`


///////


export const InputWrapper = styled.div`
    /* background: blue; */
    margin: 0% 1% 0.5% 1%;
    width: 98%;
    padding: 0px;
`


export const Label = styled.label<{ isError: boolean, disabled: boolean }>`
    width: 100%;
    display: block;
    color: ${props => props.isError ? 'red' : props.disabled ? 'grey' : 'black'};
    font-size: 13px;
    margin: 0%;
`

export const Options = styled.select<{ isError: boolean, value: string }>`
    color: ${props => props.isError ? 'red' : props.value ? 'black' : 'grey'};
    border: none;
    width: 100% !important;
    margin: 0px;
    outline: none;
    border-bottom: solid 1px ${props => props.isError ? 'red' : '#bbb'};
    padding: 9px 10px;
    font-size: 15px;
    background: none;
    display: block;
`

export const Option = styled.option`
    background: #eee;
    font-size: 15px;
    line-height : 25px;
`


export const Order = styled.select`
    width: auto;
    border:1px solid #ddd;
    padding: 7px;
    background: white;
    border-radius: 3px;
    font-size: 90%;    
    color: #868686;
`


export const ShowWhenIsSmallMobile = styled.div`
    @media (min-width: 451px) {
        display: none;
    }
`


export const ShowWhenIsNotSmallMobile = styled.div`
    @media (max-width: 450px) {
        display: none;
    }
`

export const ShowWhenIsMobileAndDesktop = styled.div`
    display: block;

    @media (min-width: 451px) {
        display: none;
    }
`