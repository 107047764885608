import { IonList, IonItemDivider, IonItem, IonLabel, IonCheckbox, IonTitle } from '@ionic/react'
import React from 'react'
import { CheckBoxFilter, CheckBoxList, CheckBoxTitle } from './ComercialPolicyCheckBoxFilter.element'

interface IComercialPolicyPaymentPeriodFilterComponentProps {

    isShowMonthly: boolean
    isShowQuarterly: boolean
    isShowBiannual: boolean
    isShowAnnual: boolean

    comercialPolicyPaymentPeriodValues: {
        monthly: boolean
        quarterly: boolean
        biannual: boolean
        annual: boolean
    }
    onChangeComercialPaymentPeriodValues: (values: {
        monthly: boolean
        quarterly: boolean
        biannual: boolean
        annual: boolean
    }) => void
}

export const ComercialPolicyPaymentPeriodFilterComponent: React.FC<IComercialPolicyPaymentPeriodFilterComponentProps> = (props) => {

    const onChangeHandler = (key: keyof IComercialPolicyPaymentPeriodFilterComponentProps['comercialPolicyPaymentPeriodValues']) => {
        props.onChangeComercialPaymentPeriodValues({
            ...props.comercialPolicyPaymentPeriodValues,
            [key]: !props.comercialPolicyPaymentPeriodValues[key]
        })
    }

    return (
        <CheckBoxList>
            <CheckBoxTitle>Frecuencia de Pago</CheckBoxTitle>
            {props.isShowMonthly ? (
                <CheckBoxFilter label="Mensual" checked={props.comercialPolicyPaymentPeriodValues.monthly} onClick={() => onChangeHandler('monthly')} />
            ): null}
            {props.isShowQuarterly ? (
                <CheckBoxFilter label="Trimestral" checked={props.comercialPolicyPaymentPeriodValues.quarterly} onClick={() => onChangeHandler('quarterly')} />
            ): null}

            {props.isShowBiannual ? (
                <CheckBoxFilter label="Semestral" checked={props.comercialPolicyPaymentPeriodValues.biannual} onClick={() => onChangeHandler('biannual')} />
            ): null}

            {props.isShowAnnual ? (
                <CheckBoxFilter label="Anual" checked={props.comercialPolicyPaymentPeriodValues.annual} onClick={() => onChangeHandler('annual')} />
            ): null}
        </CheckBoxList>
    )
}