export const setup = {
    nodeEnv: process.env.NODE_ENV as "development" | any,
    loggingApiKey: '',
    projectId: '',
    apiBaseUrl: '',
    apiBaseUrlControlPanel: '',
    riskApiUrl: '',
    cookieAuthKey: '',
    cookieAuthDomain: '',
    loginUrl: '',
    country: '',
    token: '',
    environment: 'dev',
    sentryDsn: '',
    bureauApiUrl: '',
    pushNotificationApiUrl: '',
    apiUrl: ''
}