// import { IFormGeneratorComponent, TFormItem } from '../../components/FormGenerator/FormGenerator.interface'
import { TForm } from 'FormGenerator'

import { setup } from '../../utils/setup'
import { getCountryAlias } from './setup.use.store'

// la funcion que devuelve el esquema, en realidad este debe decidir cual esquema entregar por pais, no los controladores ni los handles
// que me pario


export const getStepNumberOneScheme = (): TForm => {
    if(setup.environment !== 'dev' && setup.country === 'Argentina') return [...stepNumberOneSchemeForProdArgentina]
    else if(setup.environment !== 'dev' && setup.country === 'México') return [...stepNumberOneSchemeForProdMexico]
    else if(setup.environment === 'dev' && setup.country === 'Argentina') return [...stepNumberOneSchemeForDevArgentina]
    else if(setup.environment === 'dev' && setup.country === 'México') return [...stepNumberOneSchemeForDevMexico]
    else return [...stepNumberOneSchemeForProdArgentina]
}

export const stepNumberOneSchemeForProdArgentina : TForm = [
    {
        id: '0',
        type: 'title',
        value: 'SOBRE EL SOLICITANTE',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        label: 'Actividad del Solicitante',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        hidden: false,
        options: [
            {
                label: 'Contratista',
                value: 'contratista'
            },
            {
                label: 'Productor',
                value: 'productor'
            },
            {
                label: 'Productor/Contratista',
                value: 'productor/contratista'
            },
            {
                label: 'Transportista',
                value: 'transportista'
            },
            {
                label: 'Otro',
                value: 'otro'
            }
        ],
        disabled: false,
        size: 6
    },

    {
        id: '14.1',
        label: 'CUIT/CUIL',
        placeholder: 'Campo requerido...',
        type: 'cuilcuit',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '19',
        value: 'SOBRE EL PRODUCTO',
        type: 'title',
        hidden: false,
        size: 12
    },
    {
        id: '20',
        type: 'options',
        label: 'Categoría',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '21',
        type: 'vinculedOptions',
        label: 'Producto',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        vinculedId: '20',
        disabled: false,
        size: 6,
        hidden: false,
        vinculedOptions: []
    },
    // {
    //     id: 21.1,
    //     type: 'text',
    //     label: 'Marca',
    //     value: '',
    //     regex: '',
    //     placeholder: 'Campo requerido...',
    //     required: true,
    //     error: false,
    //     minlength: 1,
    //     maxlength: 100
    // },
    // {
    //     id: 21.2,
    //     type: 'text',
    //     label: 'Modelo',
    //     value: '',
    //     regex: '',
    //     placeholder: 'Campo requerido...',
    //     required: true,
    //     error: false,
    //     minlength: 1,
    //     maxlength: 100,
    // },
    {
        id: '22',
        label: 'Precio',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '23',
        label: 'Moneda',
        placeholder: 'Campo requerido...',
        type: 'options',
        required: true,
        error: false,
        value: '2',
        options: [
            {
                label: 'Pesos Argentinos',
                value: '2'
            },
            {
                label: 'Dólares Estadounidenses',
                value: '1'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    // {
    //     id: 24,
    //     label: 'Condición',
    //     placeholder: 'Campo requerido',
    //     type: 'options',
    //     required: true,
    //     regex: '',
    //     error: false,
    //     value: '',
    //     options: [
    //         {
    //             label: 'Nuevo',
    //             value: 'NUEVO'
    //         },
    //         {
    //             label: 'Usado',
    //             value: 'USADO'
    //         },
    //     ]
    // },
    {
        id: '25',
        value: 'Extra',
        type: 'title',
        hidden: false,
        size: 12
    },
    {
        id: '26',
        label: 'Ventas de los últimos 12 meses (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '27',
        label: 'Deudas con vto en los próximos 12 meses (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '28',
        label: 'Deudas con vto mayor a 12 meses  (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '29',
        value: '',
        type: 'title',
        hidden: false,
        size: 12
    },
]


export const stepNumberOneSchemeForDevArgentina : TForm = [
    {
        id: '0',
        type: 'title',
        value: 'SOBRE EL SOLICITANTE',
        hidden: false,
        size: 12,
    },
    {
        id: '2',
        label: 'Actividad del Solicitante',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: 'otro',
        options: [
            {
                label: 'Contratista',
                value: 'contratista'
            },
            {
                label: 'Productor',
                value: 'productor'
            },
            {
                label: 'Productor/Contratista',
                value: 'productor/contratista'
            },
            {
                label: 'Transportista',
                value: 'transportista'
            },
            {
                label: 'Otro',
                value: 'otro'
            }
        ],
        disabled: false,
        hidden: false,
        size: 6
    },

    {
        id: '14.1',
        label: 'CUIT/CUIL',
        placeholder: 'Campo requerido...',
        type: 'cuilcuit',
        required: true,
        error: false,
        value: '20339452866',
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '19',
        value: 'SOBRE EL PRODUCTO',
        type: 'title',
        hidden: false,
        size: 12
    },
    {
        id: '20',
        type: 'options',
        label: 'Categoría',
        value: '1',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '21',
        type: 'vinculedOptions',
        label: 'Producto',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        vinculedId: '20',
        disabled: false,
        hidden: false,
        size: 6,
        vinculedOptions: []
    },
    // {
    //     id: 21.1,
    //     type: 'text',
    //     label: 'Marca',
    //     value: 'Marca',
    //     regex: '',
    //     placeholder: 'Campo requerido...',
    //     required: true,
    //     error: false,
    //     minlength: 1,
    //     maxlength: 100
    // },
    // {
    //     id: 21.2,
    //     type: 'text',
    //     label: 'Modelo',
    //     value: 'Modelo',
    //     regex: '',
    //     placeholder: 'Campo requerido...',
    //     required: true,
    //     error: false,
    //     minlength: 1,
    //     maxlength: 100,
    // },
    {
        id: '22',
        label: 'Precio',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '5.000.000',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '23',
        label: 'Moneda',
        placeholder: 'Campo requerido...',
        type: 'options',
        required: true,
        error: false,
        value: '2',
        hidden: false,
        options: [
            {
                label: 'Pesos Argentinos',
                value: '2'
            },
            {
                label: 'Dólares Estadounidenses',
                value: '1'
            },
        ],
        disabled: false,
        size: 6
    },
    // {
    //     id: 24,
    //     label: 'Condición',
    //     placeholder: 'Campo requerido',
    //     type: 'options',
    //     required: true,
    //     regex: '',
    //     error: false,
    //     value: 'NUEVO',
    //     options: [
    //         {
    //             label: 'Nuevo',
    //             value: 'NUEVO'
    //         },
    //         {
    //             label: 'Usado',
    //             value: 'USADO'
    //         },
    //     ]
    // },
    {
        id: '25',
        value: 'Extra',
        type: 'title',
        hidden: false,
        size: 12
    },
    {
        id: '26',
        label: 'Ventas de los últimos 12 meses (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '5.000.000',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '27',
        label: 'Deudas con vto en los próximos 12 meses (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '0',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '28',
        label: 'Deudas con vto mayor a 12 meses  (pesos)',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '0',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'ar'
    },
    {
        id: '29',
        value: '',
        type: 'title',
        hidden: false,
        size: 12,
    },

]

export const stepNumberOneSchemeForProdMexico : TForm = [
    {
        id: '19',
        value: 'SOBRE EL PRODUCTO',
        type: 'title',
        hidden: false,
        size: 12
    },
    {
        id: '20',
        type: 'options',
        label: 'Tipo Producto',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '21',
        type: 'vinculedOptions',
        label: 'Producto',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        vinculedId: '20',
        hidden: false,
        disabled: false,
        size: 6,
        vinculedOptions: []
    },
    {
        id: '21.1',
        type: 'text',
        label: 'Marca',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 100,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '21.2',
        type: 'text',
        label: 'Modelo',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 100,
        hidden: false,
        disabled: false,
        size: 6,
        regex: ''
    },
    {
        id: '22',
        label: 'Precio',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'mx'
    },
    {
        id: '23',
        label: 'Moneda',
        placeholder: 'Campo requerido...',
        type: 'options',
        required: true,
        error: false,
        value: '2',
        options: [
            {
                label: 'Pesos Mexicanos',
                value: '2'
            },
            {
                label: 'Dólares Estadounidenses',
                value: '1'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '24',
        label: 'Condición del Producto',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        options: [
            {
                label: 'Nuevo',
                value: 'NUEVO'
            },
            {
                label: 'Usado',
                value: 'USADO'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },


    {
        id: '0.1',
        type: 'title',
        value: 'INFORMACIÓN FISCAL',
        hidden: false,
        size: 12
    },
    {
        id: '18',
        label: 'Clasificación Fiscal',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        options: [
            {
                label: 'Persona Física',
                value: 'PF'
            },
            {
                label: 'Persona Física con actividad Empresarial',
                value: 'PFCAE'
            },
            {
                label: 'Persona Moral',
                value: 'PM'
            },
        ],
        hidden: false,
        disabled: false,
        size: 6
    },
    {
        id: '0.2',
        label: 'Consulta Bureau',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: 'si',
        options: [
            {
                label: 'Si',
                value: 'si'
            },
            {
                label: 'No',
                value: 'no'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '14',
        label: 'CURP',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: false,
        regex: '^[a-zA-Z0-9]+$',
        error: false,
        value: '',
        maxLength: 18,
        minLength: 17,
        disabled: false,
        hidden: true,
        size: 6
    },
    {
        id: '16',
        label: 'RFC',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: false,
        regex: '^[a-zA-Z0-9]+$',
        error: false,
        value: '',
        maxLength: 14,
        minLength: 12,
        hidden: true,
        size: 6,
        disabled: false,
    },
    {
        id: '0',
        type: 'title',
        value: 'SOBRE EL SOLICITANTE',
        hidden: false,
        size: 12,
    },


    ///////////////////////////


    {
        id: '1',
        type: 'options',
        label: 'Estado',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '1.1',
        type: 'vinculedOptions',
        label: 'Municipio',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        vinculedId: '1',
        disabled: false,
        hidden: false,
        size: 6,
        vinculedOptions: []
    },

    //////////////////////////

    {
        id: '2',
        label: 'Actividad del Solicitante',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        options: [
            {
                label: 'Contratista',
                value: 'contratista'
            },
            {
                label: 'Productor',
                value: 'productor'
            },
            {
                label: 'Ambos',
                value: 'ambos'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '3',
        label: 'Primer Nombre',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: '',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '4',
        label: 'Segundo Nombre',
        placeholder: 'Campo no requerido...',
        type: 'text',
        required: false,
        error: false,
        value: '',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '5',
        label: 'Apellido Paterno',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: '',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '6',
        label: 'Apellido Materno',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: '',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '7',
        label: 'Género',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        options: [
            {
                label: 'Masculino',
                value: 'male'
            },
            {
                label: 'Femenino',
                value: 'femeale'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '8',
        label: 'Teléfono Móvil / Celular',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        value: '',
        disabled: false,
        hidden: false,
        size: 6,
        maxValue: 9999999999999,
        minValue: 0
    },
    {
        id: '10',
        label: 'Calle',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: '',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '11',
        label: 'Número Exterior',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        minValue: 0,
        maxValue: 99999999999999999999999,
        value: '',
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '12',
        label: 'Número Interior',
        placeholder: 'Campo no requerido...',
        type: 'number',
        required: false,
        error: false,
        value: '',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '13',
        label: 'Código postal',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        value: '',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '0.4',
        type: 'message',
        size: 12,
        hidden: false,
        value: 'Al realizar este paso acepto que se consulte mi información de bureau.'
    },
]


export const stepNumberOneSchemeForDevMexico : TForm = [
    {
        id: '19',
        value: 'SOBRE EL PRODUCTO',
        type: 'title',
        size: 12,
        hidden: false,
    },
    {
        id: '20',
        type: 'options',
        label: 'Tipo Producto',
        value: '1',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '21',
        type: 'vinculedOptions',
        label: 'Producto',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        vinculedId: '20',
        disabled: false,
        size: 6,
        hidden: false,
        vinculedOptions: []
    },
    {
        id: '21.1',
        type: 'text',
        label: 'Marca',
        value: 'Marca',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 100,
        disabled: false,
        size: 6,
        hidden: false,
        regex: ''
    },
    {
        id: '21.2',
        type: 'text',
        label: 'Modelo',
        value: 'modelo',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 100,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '22',
        label: 'Precio',
        placeholder: 'Campo requerido...',
        type: 'price',
        required: true,
        error: false,
        value: '5,000,000',
        disabled: false,
        hidden: false,
        size: 6,
        format: 'mx'
        
    },
    {
        id: '23',
        label: 'Moneda',
        placeholder: 'Campo requerido...',
        type: 'options',
        required: true,
        error: false,
        value: '2',
        options: [
            {
                label: 'Pesos Mexicanos',
                value: '2'
            },
            {
                label: 'Dólares Estadounidenses',
                value: '1'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '24',
        label: 'Condición del Producto',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: 'NUEVO',
        hidden: false,
        options: [
            {
                label: 'Nuevo',
                value: 'NUEVO'
            },
            {
                label: 'Usado',
                value: 'USADO'
            },
        ],
        disabled: false,
        size: 6
    },
    {
        id: '0.1',
        type: 'title',
        value: 'INFORMACIÓN FISCAL',
        hidden: false,
        size: 12
    },
    {
        id: '18',
        label: 'Clasificación Fiscal',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: '',
        options: [
            {
                label: 'Persona Física',
                value: 'PF'
            },
            {
                label: 'Persona Física con actividad Empresarial',
                value: 'PFCAE'
            },
            {
                label: 'Persona Moral',
                value: 'PM'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '0.2',
        label: 'Consulta Bureau',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: 'no',
        options: [
            {
                label: 'Si',
                value: 'si'
            },
            {
                label: 'No',
                value: 'no'
            },
        ],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '14',
        label: 'CURP',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: false,
        error: false,
        regex: '^[a-zA-Z0-9]+$',
        value: 'TUAC650812T81LRS00',
        maxLength: 18,
        minLength: 17,
        disabled: false,
        hidden: true,
        size: 6
    },
    {
        id: '16',
        label: 'RFC',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: false,
        regex: '^[a-zA-Z0-9]+$',
        error: false,
        value: '',
        maxLength: 14,
        minLength: 12,
        hidden: true,
        size: 6,
        disabled: false,
    },
    {
        id: '0',
        type: 'title',
        value: 'SOBRE EL SOLICITANTE',
        hidden: false,
        size: 12
    },
    {
        id: '1',
        type: 'options',
        label: 'Estado',
        value: 'AGUASCALIENTES',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        options: [],
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '1.1',
        type: 'vinculedOptions',
        label: 'Municipio',
        value: 'Aguascalientes',
        placeholder: 'Campo Requerido',
        required: true,
        error: false,
        vinculedId: '1',
        disabled: false,
        hidden: false,
        size: 6,
        vinculedOptions: []
    },
    {
        id: '2',
        label: 'Actividad del Solicitante',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        error: false,
        value: 'productor',
        hidden: false,
        options: [
            {
                label: 'Contratista',
                value: 'contratista'
            },
            {
                label: 'Productor',
                value: 'productor'
            },
            {
                label: 'Ambos',
                value: 'ambos'
            },
        ],
        disabled: false,
        size: 6
    },
    {
        id: '3',
        label: 'Primer Nombre',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: 'ESTHER',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '4',
        label: 'Segundo Nombre',
        placeholder: 'Campo no requerido...',
        type: 'text',
        required: false,
        error: false,
        value: 'ESTHER',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '5',
        label: 'Apellido Paterno',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: 'LEAL',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '6',
        label: 'Apellido Materno',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        error: false,
        value: 'MARTINEZ',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6,
        regex: ''
    },
    {
        id: '7',
        label: 'Género',
        placeholder: 'Campo requerido',
        type: 'options',
        required: true,
        hidden: false,
        error: false,
        value: 'femeale',
        options: [
            {
                label: 'Masculino',
                value: 'male'
            },
            {
                label: 'Femenino',
                value: 'femeale'
            },
        ],
        disabled: false,
        size: 6
    },
    {
        id: '8',
        label: 'Teléfono Móvil / Celular',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        value: '1123232323',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '10',
        label: 'Calle',
        placeholder: 'Campo requerido...',
        regex: '',
        type: 'text',
        required: true,
        error: false,
        value: 'calle',
        maxLength: 80,
        minLength: 1,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '11',
        label: 'Número Exterior',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        value: '13',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '12',
        label: 'Número Interior',
        placeholder: 'Campo no requerido...',
        type: 'number',
        required: false,
        error: false,
        value: '1',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '13',
        label: 'Código postal',
        placeholder: 'Campo requerido...',
        type: 'number',
        required: true,
        error: false,
        value: '1313',
        minValue: 0,
        maxValue: 99999999999999999999999,
        disabled: false,
        hidden: false,
        size: 6
    },
    {
        id: '0.4',
        type: 'message',
        size: 12,
        hidden: false,
        value: 'Al realizar este paso acepto que se consulte mi información de bureau.'
    },
]