import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router'

import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout';
import { OnBoardingModule, TOnGetPolicesParam, TCreateSimulateParam } from '../modules/OnBoarding.module'
import {
    ISimulateRequest,
    ICategoriesResponse,
    ICheckPoliciesRequest,
    IMaxFeesResponse,
    IMaxFeesRequest,
    IDownloadPDFRequest,
    ISimulateResponseAdapted,
    ICheckPoliciesAdaptedResponse,
    IPolicieAdapted,
    IVerifyNipRequest,
    IGenerateNipRequest,
    IValidateNipRequest,
} from '../stores/onBoarding/onBoarding.model.store'
import { IProductTypeResponse, ISimulate, ISimulateListResponse } from '../stores/simulate/simulate.model.store';
import { ISimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component';
import { getCountryAlias } from '../stores/setup/setup.use.store';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import dataLayer from '../ga.js'
import { IGetDinamicFormsResponse, IGetDinamicFormsResponseAdapted, ISendSimulateForConvertRequest, ISendSimulateForConvertRequestMexico, ISendSimulateForConvertResponse } from '../stores/convert/convert.model.store';
import { TForm, getValueToForm } from 'FormGenerator';
import { IUserStore } from '../stores/user/user.use.store';
import { TActionResult } from '../utils/useStore.util';
import { upperFirstLetterToAllWords } from '../utils/text';
import { FormConsumerComponentProps } from 'FormConsumer';
import { traduceDinamicFormsToFormSectionListForViewModule, traduceFormSectionListToInputForRequest } from '../components/SimulateToConvertModal/SimulateToConvertModal.helper';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { IConvertStore } from '../stores/convert/convert.interface.store';
import { IOnboardingStore } from '../stores/onBoarding/onBoarding.interface.store';
import { ISimulateStore } from '../stores/simulate/simulate.interface.store';
import { ISetupStore } from '../stores/setup/setup.interface.store';

interface IOnBoardingPage {
    userStore: IUserStore
    onboardingStore: IOnboardingStore
    convertStore: IConvertStore
    notificationStore: INotificationStore
    simulateStore: ISimulateStore
    setupStore: ISetupStore
    action: 'create' | 'edit'
    onApiClientError: () => void
}


export const OnBoardingPage = (props: IOnBoardingPage) => {

    const history = useHistory()
    const location = useLocation()
    const quryParams = new URLSearchParams(location.search)
    const cuitQueryParam = quryParams.get("cuit") || "";
    const activityQueryParam = quryParams.get("activity") || "" // todo: quiero sacar esto despues, porque es una negrada, y yo ya soy muy negro para sumarme mas negrura a mi vida
    const { id } = useParams<{ id: string }>()

    const [goBackUrl, setGoBackUrl] = React.useState<string>('')

    const [isRenderPage, setIsRenderPage] = React.useState<boolean>(false)

    useIonViewDidEnter(async () => {
        dataLayer.push({
            'event': 'virtualPageView',
            'page': {
                'title': 'onBoarding',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });

        setIsRenderPage(true)
    })

    useIonViewDidLeave(() => {
        setIsRenderPage(false)
    })

    const createGetPoliciesRequest = (formDataParam: TOnGetPolicesParam): ICheckPoliciesRequest | undefined => {

        let categorieId: number = 0
        let categoriaName: string = ''
        let isWarrantedFinded: boolean = false
        let typeId: number = 0
        let typeName: string = ''
        let productPrice: number | string = 0
        let productCondition: string = ''
        let currencyId: number = 0
        let productYear: number = getValueToForm(formDataParam, '0.3') ? Number(getValueToForm(formDataParam, '0.3')) : new Date().getFullYear()

        const categoriaFinded = props.onboardingStore.categories.find(category => category.id === Number(getValueToForm(formDataParam, '20')))
        if (!categoriaFinded) return
        categorieId = categoriaFinded.id
        categoriaName = categoriaFinded.name
        isWarrantedFinded = categoriaFinded.is_warranted
        typeId = Number(getValueToForm(formDataParam, '21'))

        const productTypeFinded = categoriaFinded.product_types.find(productType => productType.id === typeId)
        if (!productTypeFinded) return
        typeName = productTypeFinded.name

        productPrice = getCountryAlias() === 'ar' ? getValueToForm(formDataParam, '22') : Number(getValueToForm(formDataParam, '22'))
        productCondition = getValueToForm(formDataParam, '24')
        currencyId = Number(getValueToForm(formDataParam, '23'))

        const salesValue = getValueToForm(formDataParam, '26')
        const currentDebtValue = getValueToForm(formDataParam, '27')
        const notCurrentDebtValue = getValueToForm(formDataParam, '28')

        const rentedHasValue = getValueToForm(formDataParam, '31')
        const ownHasValue = getValueToForm(formDataParam, '32')

        const harvestHasValue = getValueToForm(formDataParam, '52')
        const bundledUpHasValue = getValueToForm(formDataParam, '56')
        const fumigationHasValue = getValueToForm(formDataParam, '54')
        const fertilizationHasValue = getValueToForm(formDataParam, '55')

        const otherHasValue = getValueToForm(formDataParam, '57')
        const choppedHasValue = getValueToForm(formDataParam, '53')
        const sowingHasValue = getValueToForm(formDataParam, '51')
        const kmsWorkedValue = getValueToForm(formDataParam, '61')
        const bureauValue = getValueToForm(formDataParam, '0.2')

        const personTypeValue = getValueToForm(formDataParam, '18')
        
        const checkPolicesRequest: ICheckPoliciesRequest = {
            checkBureau: !!(bureauValue === 'si'),
            sales: Number(salesValue),
            current_debt: Number(currentDebtValue),
            not_current_debt: Number(notCurrentDebtValue),
            own_has: Number(ownHasValue),
            rented_has: Number(rentedHasValue),
            harvest_has: Number(harvestHasValue),
            bundled_up_has: Number(bundledUpHasValue),
            fertilization_has: Number(fertilizationHasValue),
            fumigation_has: Number(fumigationHasValue),
            other_has: Number(otherHasValue),
            chopped_has: Number(choppedHasValue),
            sowing_has: Number(sowingHasValue),
            kms_worked: Number(kmsWorkedValue),
            product: {
                currency_id: currencyId,
                category_id: categorieId,
                categoria: categoriaName,
                is_warranted: isWarrantedFinded,
                type_id: typeId,
                nombre: typeName,
                price: productPrice,
                condition: productCondition,
                year: productYear,
                brand: upperFirstLetterToAllWords(getValueToForm(formDataParam, '21.1')),
                model: upperFirstLetterToAllWords(getValueToForm(formDataParam, '21.2')),
                values: formDataParam.filter(input=> Number(input.id) > 1000).map(input=>({
                    attribute_id: Number(String(input.id).slice(1,4)),
                    value: input.type === 'number' || input.type === 'text' || input.type === 'options' ? input.value : ''
                }))
            },
            customer: {
                declared_activity: getValueToForm(formDataParam, '2'),
                identification_number: getValueToForm(formDataParam, '14.1'),
                genero: getValueToForm(formDataParam, '7') === 'male' ? 'masculino' : 'femenino',
                primer_nombre: upperFirstLetterToAllWords(getValueToForm(formDataParam, '3')),
                segundo_nombre: upperFirstLetterToAllWords(getValueToForm(formDataParam, '4')),
                address: upperFirstLetterToAllWords(getValueToForm(formDataParam, '10')),
                apellido_paterno: upperFirstLetterToAllWords(getValueToForm(formDataParam, '5')),
                apellido_materno: upperFirstLetterToAllWords(getValueToForm(formDataParam, '6')),
                numero_exterior: getValueToForm(formDataParam, '11'),
                numero_interior: getValueToForm(formDataParam, '12'),
                telefono_celular: getValueToForm(formDataParam, '8'),
                codigo_postal: getValueToForm(formDataParam, '13'),
                curp: getValueToForm(formDataParam, '14').toUpperCase(),
                razon_social: getValueToForm(formDataParam, '15'),

                /// .lo tengo que generar o usar directamente el que ingreso la PM
                rfc: personTypeValue === 'PM' ? getValueToForm(formDataParam, '16') : getValueToForm(formDataParam, '14').toUpperCase().split('').reverse().filter((character, index)=>index>4).reverse().join(''),
                
                person_type: getValueToForm(formDataParam, '18') === '1' ? 1 : getValueToForm(formDataParam, '18') === '2' ? 2 : 3,
                state: getValueToForm(formDataParam, '1'),
                municipalitie: getValueToForm(formDataParam, '1.1'),
                fiscal_person_type: getValueToForm(formDataParam, '18') as 'PM' | 'PF' | 'PFCAE'
            },
            filters: {
                currency_id: currencyId
            }
        }

        return checkPolicesRequest
    }

    const onGetPolicesHandler = async (formDataParam: TOnGetPolicesParam, currencyFilter: 'USD' | 'PESOS') => {
        const getPoliciesRequest = createGetPoliciesRequest(formDataParam)
        if (getPoliciesRequest) {
            const result = await props.onboardingStore.getPolices(getPoliciesRequest, currencyFilter)
            if (result.isError) props.onApiClientError()
        }
    }

    const getFeesHandler = async (param: {
        mount: number,
        period: number,
        subsidaryTax: number,
        isSubsidary: boolean,
        firstFeeDate: string
    }) : TActionResult<IMaxFeesResponse> => {
        const maxFeesRequest: IMaxFeesRequest = {
            "newFlow": props.onboardingStore.currentPolicie.pure_fee_check,
            "client_tax": param.isSubsidary && param.subsidaryTax !== props.onboardingStore.currentPolicie.tax ? param.subsidaryTax : null,
            "amountRequest": param.mount,
            "splitExpenses": false, // no me acuerdo de donde saco estooooooooo
            "commercialPolicy": {
                "fee_weight": props.onboardingStore.currentPolicie.fee_weight,
                "asymmetric_fees": props.onboardingStore.currentPolicie.asymetric_fees,
                "id": props.onboardingStore.currentPolicie.id,
                "description": props.onboardingStore.currentPolicie.description,
                "valid_from": props.onboardingStore.currentPolicie.valid_from,
                "valid_to": props.onboardingStore.currentPolicie.valid_to,
                "brand_id": props.onboardingStore.currentPolicie.brand_id,
                "product_type_id": props.onboardingStore.currentPolicie.product_type_id,
                "age_from": Number(props.onboardingStore.currentPolicie.age_from) ? Number(props.onboardingStore.currentPolicie.age_from) : null,
                "age_to": Number(props.onboardingStore.currentPolicie.age_to) ? Number(props.onboardingStore.currentPolicie.age_to) : null,
                "country": props.onboardingStore.currentPolicie.country,
                "state": props.onboardingStore.currentPolicie.state,
                "city": props.onboardingStore.currentPolicie.city,
                "term": props.onboardingStore.currentPolicie.term,
                "max_amount": props.onboardingStore.currentPolicie.max_amount,
                "tax": props.onboardingStore.currentPolicie.tax,
                "origination_cost": props.onboardingStore.currentPolicie.origination_cost,
                "insurance": props.onboardingStore.currentPolicie.insurance,
                "insurance_provider": props.onboardingStore.currentPolicie.insurance_provider,
                "subsidized_rate": props.onboardingStore.currentPolicie.subsidized_rate ? String(props.onboardingStore.currentPolicie.subsidized_rate) : null,
                "surcharge": props.onboardingStore.currentPolicie.surcharge,
                "partner_id": props.onboardingStore.currentPolicie.partner_id === null ? null : String(props.onboardingStore.currentPolicie.partner_id),
                "partner": props.onboardingStore.currentPolicie.partner,
                "warranty_management": props.onboardingStore.currentPolicie.warranty_management,
                "warranty_management_currency": props.onboardingStore.currentPolicie.warranty_management_currency,
                "simulate_mode": props.onboardingStore.currentPolicie.simulate_mode,
                "loan_capacity": props.onboardingStore.currentPolicie.loan_capacity,
                "add_expenses": props.onboardingStore.currentPolicie.add_expenses,
                "enable_technical_insurance": props.onboardingStore.currentPolicie.enable_technical_insurance,
                "enable_life_insurance": props.onboardingStore.currentPolicie.enable_life_insurance,
                "technical_insurance_rate": props.onboardingStore.currentPolicie.technical_insurance_rate,
                "bank_expeses": props.onboardingStore.currentPolicie.bank_expeses,
                "credit_debit_expense": props.onboardingStore.currentPolicie.credit_debit_expense,
                "fee_collect": props.onboardingStore.currentPolicie.fee_collect,
                "tax_iva": props.onboardingStore.currentPolicie.tax_iva,
                "currency": {
                    "id": props.onboardingStore.currentPolicie.currency.id,
                    "currency": props.onboardingStore.currentPolicie.currency.currency,
                    "acronym": props.onboardingStore.currentPolicie.currency.acronym
                },
                "pure_fee_check": props.onboardingStore.currentPolicie.pure_fee_check,
                "risk": props.onboardingStore.currentPolicie.risk,
                "status": props.onboardingStore.currentPolicie.status,
                "message": props.onboardingStore.currentPolicie.message,
                "customerValidation": props.onboardingStore.currentPolicie.customerValidation
            },
            "currency_id": props.onboardingStore.currentPolicie.currency.id,
            "dateFirstFee": param.firstFeeDate.slice(0, 7),
            "product": {
                "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
                "price": String(props.onboardingStore.checkPoliciesRequest.product.price),
                "productTypeId": props.onboardingStore.currentPolicie.product_type_id ? props.onboardingStore.currentPolicie.product_type_id : 1,
                // "values": [{
                //     attribute_id: 1,
                //     value: props.checkPoliciesRequest.product.condition
                // }]
                "values": props.onboardingStore.checkPoliciesRequest.product.values
            },
            "warranty": {
                "values": {
                    "product": props.onboardingStore.checkPoliciesRequest.product.type_id,
                    "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
                    "declared_price": String(props.onboardingStore.checkPoliciesRequest.product.price),
                    "reference_price": String(props.onboardingStore.checkPoliciesRequest.product.price),
                }
            }
        }

        const result = await props.onboardingStore.getFees(maxFeesRequest)
        if (result.isError) props.onApiClientError()
        return result
    }

    const createSimulateHandler = async (param: TCreateSimulateParam) => {

        const requestSimulate: ISimulateRequest = {
            "simulateMode": "MC",
            "checkBureau": props.onboardingStore.checkPoliciesRequest.checkBureau,
            "fees": param.paymentPeriod === 1 ? "1" : param.paymentPeriod === 2 ? "3" : param.paymentPeriod === 3 ? '6' : '12',
            "quantityFees": Number(param.feeNumbers),
            "valueUnity": String(props.onboardingStore.checkPoliciesRequest.product.price), // es el precio del producto del formulario inicial
            "amountRequest": param.amountToRequest,
            "loanCapacity": props.onboardingStore.currentPolicie.loan_capacity,
            "siembroTax": props.onboardingStore.currentPolicie.tax,
            "siembroTaxNew": props.onboardingStore.currentPolicie.tax,
            "splitExpenses": param.separateExpenses,
            "clientTax": param.subsidize && Number(param.subsidyRate) !== props.onboardingStore.currentPolicie.tax ? param.subsidyRate : null,
            "lifeInsurance": props.onboardingStore.currentPolicie.insurance,
            "warrantyManagement": props.onboardingStore.currentPolicie.warranty_management,
            "dateFirstFee": param.firstFeeDate,
            "addExpenses": props.onboardingStore.currentPolicie.add_expenses,
            "enableLifeInsurance": props.onboardingStore.currentPolicie.enable_life_insurance,
            "enableTechnicalInsurance": props.onboardingStore.currentPolicie.enable_technical_insurance,
            "originationRate": props.onboardingStore.currentPolicie.origination_cost,
            "technicalInsuranceRate": props.onboardingStore.currentPolicie.technical_insurance_rate,
            "bankExpenses": props.onboardingStore.currentPolicie.bank_expeses,
            "creditAndDebitExpenses": props.onboardingStore.currentPolicie.credit_debit_expense,
            "feeCollect": props.onboardingStore.currentPolicie.fee_collect,
            "tax": props.onboardingStore.currentPolicie.tax,
            "currency_id": props.onboardingStore.currentPolicie.currency.id,
            "valueUnityCurrency": props.onboardingStore.checkPoliciesRequest.product.currency_id,
            "hasCheckbook": param.checkbook,
            "risk": props.onboardingStore.currentPolicie.risk,
            "commercialPolicyId": props.onboardingStore.currentPolicie.id,
            "riskStatus": props.onboardingStore.currentPolicie.status,
            "product": {
                "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
                "category_id": props.onboardingStore.checkPoliciesRequest.product.category_id,
                "categoria": props.onboardingStore.checkPoliciesRequest.product.categoria,
                "is_warranted": props.onboardingStore.checkPoliciesRequest.product.is_warranted,
                "type_id": props.onboardingStore.checkPoliciesRequest.product.type_id,
                "nombre": props.onboardingStore.checkPoliciesRequest.product.nombre,
                "price": String(props.onboardingStore.checkPoliciesRequest.product.price),
                "values": props.onboardingStore.checkPoliciesRequest.product.values,
                "condition": props.onboardingStore.checkPoliciesRequest.product.condition,
                "brand": props.onboardingStore.checkPoliciesRequest.product.brand,
                "model": props.onboardingStore.checkPoliciesRequest.product.model
            },
            "customer": {
                "declared_activity":
                    props.onboardingStore.checkPoliciesRequest.customer.declared_activity === 'contratista' ? 'Contratista' :
                        props.onboardingStore.checkPoliciesRequest.customer.declared_activity === 'productor' ? 'Productor' : 'Ambos',
                "city": props.setupStore.countryAlias === 'ar' ? props.onboardingStore.checkPolicies.customer.city : props.onboardingStore.checkPoliciesRequest.customer.municipalitie,
                "companies": props.onboardingStore.checkPolicies.customer.companies,
                "exist": props.onboardingStore.checkPolicies.customer.exist,
                "identification_number": props.onboardingStore.checkPolicies.customer.identification_number,
                "name": props.onboardingStore.checkPolicies.customer.name,
                "state": props.setupStore.countryAlias === 'ar' ? props.onboardingStore.checkPolicies.customer.state : props.onboardingStore.checkPoliciesRequest.customer.state,
                "type_person": props.onboardingStore.checkPolicies.customer.type_person,
                "zip_code": props.onboardingStore.checkPolicies.customer.zip_code,
                "primer_nombre": props.onboardingStore.checkPoliciesRequest.customer.primer_nombre,
                "segundo_nombre": props.onboardingStore.checkPoliciesRequest.customer.segundo_nombre,
                "calle": props.onboardingStore.checkPoliciesRequest.customer.address,
                "apellido_paterno": props.onboardingStore.checkPoliciesRequest.customer.apellido_paterno,
                "apellido_materno": props.onboardingStore.checkPoliciesRequest.customer.apellido_materno,
                "numero_exterior": props.onboardingStore.checkPoliciesRequest.customer.numero_exterior,
                "telefono_celular": props.onboardingStore.checkPoliciesRequest.customer.telefono_celular,
                "numero_interior": props.onboardingStore.checkPoliciesRequest.customer.numero_interior,
                "codigo_postal": props.onboardingStore.checkPoliciesRequest.customer.codigo_postal,
                "curp": props.onboardingStore.checkPoliciesRequest.customer.curp,
                "razon_social": props.onboardingStore.checkPoliciesRequest.customer.razon_social,
                "rfc": props.onboardingStore.checkPoliciesRequest.customer.rfc,
                "genero": props.onboardingStore.checkPoliciesRequest.customer.genero,
                "mas_rfc": "1",
                "fiscal_person_type": props.onboardingStore.checkPoliciesRequest.customer.fiscal_person_type,
                "clasificacion_fiscal"
                    : props.onboardingStore.checkPoliciesRequest.customer.person_type === 1 ? 'Persona Física'
                        : props.onboardingStore.checkPoliciesRequest.customer.person_type === 2 ? 'Persona Física con Actividad Empresarial'
                            : props.onboardingStore.checkPoliciesRequest.customer.person_type === 3 ? 'Persona Moral'
                                : 'Persona Fiscal'
            }
        }

        if (props.action === 'create') {
            const result = await props.onboardingStore.createSimulate(requestSimulate)
            if (result.isError) props.onApiClientError()
            return result
        }else{
            const result = await props.onboardingStore.editSimulate(requestSimulate, Number(id))
            if (result.isError) props.onApiClientError()
            return result
        }
    }

    const onDownloadPDFHandler = async (simulateId: number, params: IDownloadPDFRequest): TActionResult<string> => {
        const result = await props.onboardingStore.downloadPDF(simulateId, params)
        if (result.isSuccess) window.open(result.data, '_blank')
        else props.onApiClientError()
        return result
    }

    const generateWarranty = (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs']
    ) => {

        if (warrantyDetailFormData.length) return [{
            "currency_id": Number(getValueToForm(warrantyFormData, '0.4')),
            "declared_price": getValueToForm(warrantyFormData, '0.5'),
            "productTypeId": Number(getValueToForm(warrantyFormData, '0.3')),
            "values": warrantyDetailFormData.map(input => ({
                "attribute_id": Number(input.id),
                "value": getValueToForm(warrantyDetailFormData, input.id)
            }))
        }]

        return [{
            "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
            "declared_price": String(props.onboardingStore.checkPoliciesRequest.product.price),
            "productTypeId": props.onboardingStore.checkPoliciesRequest.product.type_id,
            "values": productFormData.filter(input => input.id !== '0.1').map(input => ({
                attribute_id: Number(input.id),
                value: getValueToForm(productFormData, input.id)
            })),
        }]
    }


    // const convertSimulateToLoanRequestForMexicoHandler =  async (
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponseAdapted['data']
    // ) => {
    //     // tengo que hacer un nuevo modelo para mexico wey
    //     const convertRequest: ISendSimulateForConvertRequestMexico = {
    //         "simulation_id": props.onboardingStore.simulateResponse.id,
    //         "product": {
    //             "values": productFormData.filter(input => input.id !== 0.1).map(input => ({
    //                 attribute_id: input.id,
    //                 value: getValueToForm(productFormData, input.id)
    //             })),
    //             "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
    //             "productTypeId": props.onboardingStore.checkPoliciesRequest.product.type_id,
    //             "is_warranted": props.onboardingStore.categories.find(categorie => categorie.id === props.onboardingStore.currentPolicie.product_type_id)?.is_warranted ? true : false,
    //             "nombre": '', /// no seeee
    //             "price": String(props.onboardingStore.checkPoliciesRequest.product.price)
    //         },
    //         "loanRequest": {
    //             "feeWeight": '0.5',
    //             "frecuency": Number(props.onboardingStore.simulateRequest.fees),
    //             "term": props.onboardingStore.simulateRequest.quantityFees,
    //             "warranty_type": '',
    //             "loanRequestType": String(props.onboardingStore.simulateRequest.product.category_id),
    //             "requestAmount": Number(props.onboardingStore.checkPoliciesRequest.product.price)
    //         },
    //         "score": 0,
    //         "warranties": generateWarranty(productFormData, warrantyFormData, warrantyDetailFormData),
    //         "forms": dinamicFormsData.map(form => ({
    //             "id": form.id,
    //             "inputs": form.inputs.map(input => ({
    //                 "id": input.id,
    //                 "label": input.type !== 'title' && input.type !== 'none' ? input.label : '',
    //                 "values": [input.type === 'text' ? upperFirstLetterToAllWords(getValueToForm(form.inputs, input.id)): getValueToForm(form.inputs, input.id) ]
    //             }))
    //         }))
    //     }

    //     const result = await props.convertStore.actions.sendRequestForConvertMexico(convertRequest)
    //     if(result.isError) props.onApiClientError()
    //     return result
    // }

    const convertSimulateToLoanRequestHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList']
    ) => {

        const convertRequest: ISendSimulateForConvertRequest = {
            "simulation_id": props.onboardingStore.simulateResponse.id,
            "product": {
                "values": productFormData.filter(input => input.id !== '0.1').map(input => ({
                    attribute_id: Number(input.id),
                    value: getValueToForm(productFormData, input.id)
                })),
                "currency_id": props.onboardingStore.checkPoliciesRequest.product.currency_id,
                "productTypeId": props.onboardingStore.checkPoliciesRequest.product.type_id,
                "is_warranted": props.onboardingStore.categories.find(categorie => categorie.id === props.onboardingStore.currentPolicie.product_type_id)?.is_warranted ? true : false,
                "nombre": '', /// no seeee
                "price": String(props.onboardingStore.checkPoliciesRequest.product.price)
            },
            "loanRequest": {
                "feeWeight": '0.5',
                "frecuency": Number(props.onboardingStore.simulateRequest.fees),
                "term": props.onboardingStore.simulateRequest.quantityFees,
                "warranty_type": '',
                "loanRequestType": String(props.onboardingStore.simulateRequest.product.category_id),
                "requestAmount": Number(props.onboardingStore.checkPoliciesRequest.product.price)
            },
            "score": 0,
            "warranties": generateWarranty(productFormData, warrantyFormData, warrantyDetailFormData),
            "inputs": traduceFormSectionListToInputForRequest(formSectionList, props.convertStore.states.dinamicFormsResponse)
        }

        const result = await props.convertStore.actions.sendRequestForConvert(convertRequest)
        if(result.isError) props.onApiClientError()
        return result
    }

    const onRedirectToSimulateDetailHandler = (id: number) => {
        history.push(`/simulator/${id}`)
    }

    const onRedirectToLoanRequestDetailHandler = (id: number) => {
        history.push(`/loanRequests/${id}`)
    }

    const onChangeToLastStepHandler = () => {
        setGoBackUrl('/simulator')
    }

    const verifyNipHandler =  async (param: IVerifyNipRequest): TActionResult<'nip is verified' | 'nip is not verified' | 'rfc is not valid'> => {
        
        const result = await props.onboardingStore.verifyNip(param)
        if(result.isError) {
            props.onApiClientError()
        } 

        return result
    }

    const generateNipHandler= async (param: IGenerateNipRequest): TActionResult<'nip is generated' | 'rfc is not valid'> => {
        const result = await props.onboardingStore.generateNip(param)
        if(result.isError) {
            props.onApiClientError()
        }

        return result
    }


    const validateNipHandler = async (param: IValidateNipRequest): TActionResult<'nip is valid' | 'nip is not valid'> => {
        const result = await props.onboardingStore.validateNip(param)
        if(result.isError) {
            props.onApiClientError()
        }
        return result
    } 

    const getCountryStepNumberOneShemeHandler = async (): TActionResult<TForm> => {

        if (props.action === 'create' && cuitQueryParam && activityQueryParam) {
            const result = await props.onboardingStore.getPreloadDataSchemeWithCuitAndPersonType(cuitQueryParam, activityQueryParam)
            if (result.isError) props.onApiClientError()
            return result
        }

        if (props.action === 'edit') {
            const simulateId = Number(id)
            const simulateFinded = props.simulateStore.simulateList.find(simulate => simulate.id === simulateId)

            if (simulateFinded) {
                const result =  await props.onboardingStore.getPreloadDataSchemeFromSimulate(simulateFinded)
                if(result.isError) props.onApiClientError()
                return result
            } else {
                const result = await props.onboardingStore.getPreloadDataScheme()
                if(result.isError) props.onApiClientError()
                return result
            }
        }

        return await props.onboardingStore.getPreloadDataScheme()
    }

    const getDinamicFormsHandler = async (riskId: number) => {
        const result = await props.convertStore.actions.loadDinamicForms(riskId)
        if(result.isError) props.onApiClientError()
        return result
    }

    // const loadDinamicFormsMexicoHandler = async (policieId: number) => {
    //     const result = await props.convertStore.actions.loadDinamicFormsMexico(policieId)
    //     if(result.isError) props.onApiClientError()
    //     return result
    // }

    return (
        <ContentWithNavigationLayout
            goBackText={'Volver al listado de Simulaciones'}
            goBackUrl={goBackUrl}
            notificationStore={props.notificationStore}
            userStore={props.userStore}
        >

            {isRenderPage ? (
                <OnBoardingModule
                    action={props.action}
                    userEmail={props.userStore.profile.email}
                    onGetPolices={onGetPolicesHandler}
                    categories={props.onboardingStore.categories}
                    policies={props.onboardingStore.checkPolicies}
                    maxFees={props.onboardingStore.maxFees}
                    getFees={getFeesHandler}
                    currentPolicie={props.onboardingStore.currentPolicie}
                    addCurrentPolicie={props.onboardingStore.addCurrentPolicie}
                    createSimulate={createSimulateHandler}
                    simulateResponse={props.onboardingStore.simulateResponse}
                    download={onDownloadPDFHandler}
                    saveSimulate={props.onboardingStore.saveSimulate}
                    productTypeForSendConvertRequest={props.convertStore.states.productTypeForSendConvertRequest}
                    getProductTypeForConvert={props.convertStore.actions.loadProductTypeForConvert}
                    warrantyProductTypeForConvert={props.convertStore.states.warrantyProductTypeForConvert}
                    onGetWarrantyProductTypeForConvert={props.convertStore.actions.loadWarrantyProductTypeForConvert}
                    convertSimulateToLoanRequest={convertSimulateToLoanRequestHandler}
                    // convertSimulateToLoanRequestForMexico={convertSimulateToLoanRequestForMexicoHandler}
                    checkPoliciesRequest={props.onboardingStore.checkPoliciesRequest}
                    getDinamicForms={getDinamicFormsHandler}
                    // getDinamicFormsMexico={loadDinamicFormsMexicoHandler}
                    formSectionList={traduceDinamicFormsToFormSectionListForViewModule(props.convertStore.states.dinamicFormsResponse)}
                    // dinamicFormsResponse={props.convertStore.states.dinamicShemaResponse}
                    convertResponse={props.convertStore.states.convertResponse}
                    onRedirectToSimulateDetail={onRedirectToSimulateDetailHandler}
                    onRedirectToLoanRequestDetail={onRedirectToLoanRequestDetailHandler}
                    isMobile={props.setupStore.isMobile}
                    simulateRequest={props.onboardingStore.simulateRequest}
                    onChangeToLastStep={onChangeToLastStepHandler}
                    countryAlias={props.setupStore.countryAlias}
                    resetMaxFees={props.onboardingStore.resetMaxFees}
                    resetCheckPoliciesRequest={props.onboardingStore.resetCheckPoliciesRequest}
                    resetCheckPoliciesResponse={props.onboardingStore.resetCheckPoliciesResponse}
                    resetCurrentPolicie={props.onboardingStore.resetCurrentPolicie}
                    resetDinamicFormsResponse={props.convertStore.actions.resetStore}
                    resetSimulateRequest={props.onboardingStore.resetSimulateRequest}
                    resetSimulateResponse={props.onboardingStore.resetSimulateResponse}
                    cuitQueryParam={cuitQueryParam}
                    activityQueryParam={activityQueryParam}

                    verifyNip={verifyNipHandler}
                    generateNip={generateNipHandler}
                    validateNip={validateNipHandler}

                    getCountryStepNumberOneSheme={getCountryStepNumberOneShemeHandler}
                    getProduct={props.onboardingStore.getProduct}
                    categoryList={props.onboardingStore.categories.map(categorie=>({
                        id: categorie.id, 
                        label: categorie.name, 
                        productList: categorie.product_types.map(product=>({id: product.id, label: product.name}))
                    }))}    
                />
            ) : null}

        </ContentWithNavigationLayout>
    )
}