import React from 'react'
import  styled from 'styled-components'


const CheckboxContainerStyled = styled.div`
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 20px;

    border-bottom: solid 1px #eee;
    &:hover {
        background:#eee;
    }
`

const CheckboxRowStyled = styled.button<{isChecked:boolean}>`
    color: white;
    font-size: 20px;
    outline: none;
    text-align: center;
    padding: 7px;
    border-radius: 2px;
    border: solid 2px;
    border-color:${(props)=> props.isChecked ? '#3880ff' : 'grey'};
    background: ${(props)=> props.isChecked ? '#3880ff' : 'white'};
    float: right;
`

const CheckboxLaberStyled = styled.label`
  margin-left: auto;
`

export const CheckBoxRowElement = (props: {label: string,value: boolean, onClick: () => void }) => (
    <CheckboxContainerStyled onClick={props.onClick}>
        <CheckboxLaberStyled>{props.label}</CheckboxLaberStyled>
        <CheckboxRowStyled isChecked={ props.value }></CheckboxRowStyled>
    </CheckboxContainerStyled>
)

export const TemporaryItem = styled.div`
    padding-top: 6px;
`

export const TemporaryLabel = styled.label`
    font-size: 12px;
    padding: 14px;
`

export const TemporaryInput = styled.input<{error: boolean}>`
    width: 100%;
    border: none;
    outline: none;
    color: ${props=>props.error ? 'red' : 'black'};
    text-align: right;
    padding: 8px 16px;
    font-size: 16px;
    margin: 0px;
    box-sizing: border-box;
    border-bottom: solid 1px ${props=> props.error ? 'red' : '#ddd'};
`



export const TemporaryOptions = styled.select<{isError: boolean, value: string}>`
    color: ${props=> props.isError ? 'red' : props.value ? 'black' : 'grey'};
    border: none;
    width: 100% !important;
    margin: 0px;
    outline: none;
    border-bottom: solid 1px ${props=> props.isError ? 'red' : '#ddd'};
    padding: 9px 10px;
    font-size: 15px;
    background: none;
    display: block;
`

export const TemporaryOption = styled.option`
    background: #fff;
    font-size: 15px;
    line-height : 25px;
`