import { IonAlert, IonButton, IonButtons, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonModal, IonRow, IonToolbar } from '@ionic/react'
import { optionsOutline } from 'ionicons/icons'
import React from 'react'

import { ICheckPoliciesAdaptedResponse, IPolicieAdapted } from '../../stores/onBoarding/onBoarding.model.store'
import { CheckPolicyResumeFilterComponent } from './CheckPolicyResumeFilter.component'
import { IProductData, SearchProductFormComponent } from '../SearchProductForm/SearchProductForm.component'
import { ComercialPolicyCardComponent } from './ComercialPolicyCard.component'
import { WrapperElement } from './ComercialPolicyList.element'
import { ComercialPolicyNoResultComponent } from './ComercialPolicyListNoResult.component'
import { ComercialPolicyMainFilter } from './ComercialPolicyMainFilter.component'
import { ComercialPolicyPaymentPeriodFilterComponent } from './ComercialPolicyPaymentPeriodFilter.component'
import { ComercialPolicyTermCapacityFilter } from './ComercialPolicyTermCapacityFilter.tsx'
import { ComercialPolicyTypeFilterComponent } from './ComercialPolicyTypeFilter.component'
import { divideListToTwoParts } from '../../utils/array'
import { ComercialPolicyListSkeleton } from './ComercialPolicyListSkeleton.component'
import { ComercialPolicyListMobileSkeleton } from './ComercialPolicyListMobileSkeleton.component'
import { Order, ShowWhenIsSmallMobile } from './ComercialPolicyMainFilter.element'

interface IComercialPolicyListComponentProps {
    id: string
    isMobile: boolean
    onGoBack: () => void
    onSearch: (form: IProductData, currencyFilter: 'USD' | 'PESOS') => void
    comercialPolicyList: ICheckPoliciesAdaptedResponse['commercialPolicies']
    countryAlias: 'ar' | 'mx'
    onSelectComercialPolicy: (id: number) => void
    categoryList: Array<{ id: number, label: string, productList: Array<{ id: number, label: string }> }>
    formDataForSearch: IProductData
    setFormDataForSearch: (form: IProductData) => void
    fullName: string
    identification: string
    isLoadingComercialPolicyList: boolean
}

export const ComercialPolicyListComponent: React.FC<IComercialPolicyListComponentProps> = (props) => {

    const [isOpenMessage, setIsOpenMessage] = React.useState<boolean>(false)
    const [titleMessage, setTitleMessage] = React.useState<string>('')
    const [textMessage, setTextMessage] = React.useState<string>('')
    const [executionIdListMessage, setExecutionIdListMessage] = React.useState<string>('')

    React.useEffect(()=> {
        const thereAreSomeSuitableComercialPolicy = props.comercialPolicyList.some(comercialPolicy=> comercialPolicy.status === 1)
        if( props.comercialPolicyList.length === 0) return setStatusFilter('ALL')
        if(thereAreSomeSuitableComercialPolicy) return setStatusFilter('ENABLED')
        else return setStatusFilter('ALL')
    }, [props.comercialPolicyList])

    const onMenssageClickHandler = (comercialPolicy: IPolicieAdapted) => {

        const title: string =
            comercialPolicy.status === 0 ? 'ERROR' :
                comercialPolicy.status === 1 ? 'APTO' :
                    comercialPolicy.status === 2 ? 'NO APTO' : ''

        const text = comercialPolicy.status === 0 ? 'No hemos podido acceder a la información crediticia del solicitante debido a alguna particularidad en este caso. Por favor informar a su ejecutivo de cuenta para asistirte. Adjunta una imagen de esta pantalla.'
            : comercialPolicy.message ? comercialPolicy.message : ''

        const executionIdListMesssage = comercialPolicy.risk.map((execution, index) => {
            if (index === 0) return `#${execution.executionId}`
            if (index > 0) return ` | #${execution.executionId}`
        })

        setTitleMessage(title)
        setTextMessage(text)
        setExecutionIdListMessage(executionIdListMesssage.join())
        setIsOpenMessage(true)
    }


    const [statusFilter, setStatusFilter] = React.useState<'ALL' | 'ENABLED'>('ALL')
    const [currencyFilter, setCurrencyFilter] = React.useState<'USD' | 'PESOS'>('PESOS')

    const [termFilter, setTermFilter] = React.useState<{ min: number, max: number }>({ min: 0, max: 0 })
    const [capacityFilter, setCapacityFilter] = React.useState<{ min: number, max: number }>({ min: 0, max: 0 })

    const [isOpenFiltersModal, setIsOpenFiltersModal] = React.useState<boolean>(false)

    const [comercialPolicyTypeFilter, setComercialPolicyTypeFilter] = React.useState<{
        garment: boolean
        oneSignature: boolean
    }>({
        garment: true,
        oneSignature: true
    })

    const [comercialPolicyPaymentPeriodFilter, setComercialPolicyPaymentPeriodFilter] = React.useState<{
        monthly: boolean
        quarterly: boolean
        biannual: boolean
        annual: boolean
    }>({
        monthly: true,
        quarterly: true,
        biannual: true,
        annual: true
    })

    const [orderValue, setOrderValue] = React.useState<'More convenient' | 'Best rate' | 'Best amount'>('More convenient')

    // React.useEffect(() => {
    //     setCurrencyFilter(props.defaultCurrency)
    // }, [props.defaultCurrency])

    const onStatusFilterChangeHandler = (value: string) => (value === 'ALL' || value === 'ENABLED') && setStatusFilter(value)
    const onCurrencyFilterChangeHandler = async (value: string) => {
        await props.onSearch(props.formDataForSearch, value === 'USD' || value === 'PESOS' ? value : 'USD')
        if(value === 'USD' || value === 'PESOS') setCurrencyFilter(value)
    }

    const onSelectComercialPolicyHandler = (id: number) => props.onSelectComercialPolicy(id)

    const onChangeTermValueHandler = (value: { min: number, max: number }) => setTermFilter({
        ...value,
        min: value.min >= termMinComputed ? value.min : termMinComputed,
        max: value.max <= termMaxComputed ? value.max : termMaxComputed
    })

    const onChangeCapacityValueHandler = (value: { min: number, max: number }) => setCapacityFilter({
        ...value,
        min: value.min >= capacityMinComputed ? value.min : capacityMinComputed,
        max: value.max <= capacityMaxComputed ? value.max : capacityMaxComputed
    })


    const onChangeComercialPolicyTypeValuesHandler = (values: {
        garment: boolean
        oneSignature: boolean
    }) => setComercialPolicyTypeFilter(values)

    const onChangeComercialPaymentPeriodValuesHandler = (values: {
        monthly: boolean
        quarterly: boolean
        biannual: boolean
        annual: boolean
    }) => setComercialPolicyPaymentPeriodFilter(values)

    const onSearchHandler = (form: IProductData) => {
        setIsOpenFiltersModal(false)
        props.onSearch(form, currencyFilter)
    }
    const onClickFilterFloatButtonHandler = () => {
        setIsOpenFiltersModal(true)
    }

    // const onChangeOrderValueHandler = (newValue: 'More convenient' | 'Best rate' | 'Best amount') => setOrderValue(newValue)

    const comercialPolicyListStatusFiltered = props.comercialPolicyList.filter(comercialPolicy => statusFilter === 'ALL' || statusFilter === 'ENABLED' && comercialPolicy.status === 1)

    const comercialPolicyListTypeFiltered = comercialPolicyListStatusFiltered.filter(policy =>
        (comercialPolicyTypeFilter.garment && policy.type === 'PRENDARIO') ||
        (comercialPolicyTypeFilter.oneSignature && policy.type === 'A SOLA FIRMA')
    )

    //////////// botones de priocidad de pago //////////

    const isShowMonthly: boolean = comercialPolicyListTypeFiltered.some(
        comercialPolicy => comercialPolicy.periodicities.some(periodicitie => periodicitie === 1)
    )
    const isShowQuarterly: boolean = comercialPolicyListTypeFiltered.some(
        comercialPolicy => comercialPolicy.periodicities.some(periodicitie => periodicitie === 2)
    )
    const isShowBiannual: boolean = comercialPolicyListTypeFiltered.some(
        comercialPolicy => comercialPolicy.periodicities.some(periodicitie => periodicitie === 3)
    )
    const isShowAnnual: boolean = comercialPolicyListTypeFiltered.some(
        comercialPolicy => comercialPolicy.periodicities.some(periodicitie => periodicitie === 4)
    )

    //////////

    const comercialPolicyListPaymentPeriodFiltered = comercialPolicyListTypeFiltered.filter(policy =>
        (comercialPolicyPaymentPeriodFilter.monthly && policy.periodicities.find(periodicity => periodicity === 1)) ||
        (comercialPolicyPaymentPeriodFilter.quarterly && policy.periodicities.find(periodicity => periodicity === 2)) ||
        (comercialPolicyPaymentPeriodFilter.biannual && policy.periodicities.find(periodicity => periodicity === 3)) ||
        (comercialPolicyPaymentPeriodFilter.annual && policy.periodicities.find(periodicity => periodicity === 4))
    )

    /////////////////

    const comercialPolicyListCapacityNormalized = comercialPolicyListPaymentPeriodFiltered.map(comercialPolicy => {
        return {
            ...comercialPolicy,
            loan_capacity: comercialPolicy.loan_capacity > 100 && props.countryAlias === 'ar' ? 100 : comercialPolicy.loan_capacity
        }
    })

    const comercialPolicyListCapacityFormated = comercialPolicyListCapacityNormalized.map(comercialPolicy => {
        return {
            ...comercialPolicy,
            loan_capacity: props.countryAlias === 'ar' ? comercialPolicy.loan_capacity : 100 - comercialPolicy.loan_capacity,
        }
    })

    /////////////////

    const comercialPolicyListCurrencyFiltered = comercialPolicyListCapacityFormated.filter(comercialPolicy =>
        (currencyFilter === 'USD' && comercialPolicy.currency.id === 1) ||
        (currencyFilter === 'PESOS' && comercialPolicy.currency.id === 2)
    )


    const mostMaxTerm: number = Math.max(...comercialPolicyListCurrencyFiltered.map(comercialPolicy => comercialPolicy.term))
    const mostMinTax: number = Math.min(...comercialPolicyListCurrencyFiltered.map(comercialPolicy => comercialPolicy.subsidized_rate !== null ? comercialPolicy.subsidized_rate : comercialPolicy.tax))
    const mostMaxAmount: number = Math.max(...comercialPolicyListCurrencyFiltered.map(comercialPolicy => comercialPolicy.max_amount))


    /////////////////



    /////////////////
    const capacityNumbers: Array<number> = comercialPolicyListCurrencyFiltered.map(policy => policy.loan_capacity)
    const capacityMinComputed: number = capacityNumbers.length ? Math.min(...capacityNumbers) : 0

    const capacityMaxComputed: number = comercialPolicyListCurrencyFiltered.reduce((acumulator, current) =>
        current.loan_capacity > acumulator ? current.loan_capacity : acumulator
        , 0 as number)

    /////////////

    const termNumbers: Array<number> = comercialPolicyListCurrencyFiltered.map(policy => policy.term)
    const termMinComputed: number = termNumbers.length ? Math.min(...termNumbers) : 0

    const termMaxComputed: number =
        (comercialPolicyListCurrencyFiltered.reduce((acumulator, current) =>
            current.term > acumulator ? current.term : acumulator
            , 0 as number))


    /////////////////////////

    const comercialPolicyListFilteredWithCapacityAndTerm = comercialPolicyListCurrencyFiltered.filter(policy =>
        (capacityFilter.min === 0 && capacityFilter.max === 0) && (termFilter.min === 0 && termFilter.max === 0)
        ||
        (policy.loan_capacity >= capacityFilter.min && policy.loan_capacity <= capacityFilter.max)
        &&
        (policy.term >= termFilter.min && policy.term <= termFilter.max)
    )

    const comercialPolicyListComputed = comercialPolicyListFilteredWithCapacityAndTerm



    const orderComercialPolicyList = (comercialPolicyList: ICheckPoliciesAdaptedResponse['commercialPolicies']): ICheckPoliciesAdaptedResponse['commercialPolicies'] => {

        const orderByTaxCallBack = (a: IPolicieAdapted, b: IPolicieAdapted) => {
            const taxA: number = a.subsidized_rate !== null ? a.subsidized_rate : a.tax
            const taxB: number = b.subsidized_rate !== null ? b.subsidized_rate : b.tax
            return taxA - taxB
        }

        const orderByBestAmountCallBack = (a: IPolicieAdapted, b: IPolicieAdapted) => b.max_amount - a.max_amount


        const orderByAllTheMost = (comercialPolicyList: ICheckPoliciesAdaptedResponse['commercialPolicies']): ICheckPoliciesAdaptedResponse['commercialPolicies'] => {

            return comercialPolicyList.sort((firstComercialPolicy, secondComercialPolicy) => {

                const firstComercialPolicyTax: number = firstComercialPolicy.subsidized_rate !== null ? firstComercialPolicy.subsidized_rate : firstComercialPolicy.tax
                const secondComercialPolicyTax: number = secondComercialPolicy.subsidized_rate !== null ? secondComercialPolicy.subsidized_rate : secondComercialPolicy.tax
                if (secondComercialPolicy.max_amount > firstComercialPolicy.max_amount) return 1
                if (secondComercialPolicy.max_amount === firstComercialPolicy.max_amount && secondComercialPolicy.term > firstComercialPolicy.term) return 1
                if (secondComercialPolicy.max_amount === firstComercialPolicy.max_amount &&
                    secondComercialPolicy.term === firstComercialPolicy.term &&
                    secondComercialPolicyTax < firstComercialPolicyTax
                ) return 1

                return -1

            })
        }

        if (orderValue === 'More convenient') {

            const dividedBySemestral = divideListToTwoParts<IPolicieAdapted>(comercialPolicyList, (comercialPolicy) => {
                if (comercialPolicy.periodicities.find(periodicitie => periodicitie === 3)) return true
                return false
            })

            const firstGroup = orderByAllTheMost(dividedBySemestral.aceptacedList)
            const secondGroup = orderByAllTheMost(dividedBySemestral.deniedList)

            return [...firstGroup, ...secondGroup]
        }

        if (orderValue === 'Best amount') {
            return comercialPolicyList.sort(orderByBestAmountCallBack)
        }

        if (orderValue === 'Best rate') {
            return comercialPolicyList.sort(orderByTaxCallBack)
        }

        return []
    }


    const onChangeOrderValueHandler = (value: string) => {
        if (value === 'More convenient') return setOrderValue('More convenient')
        if (value === 'Best rate') return setOrderValue('Best rate')
        if (value === 'Best amount') return setOrderValue('Best amount')
    }


    return (
        <div>
            <IonAlert
                isOpen={isOpenMessage}
                onDidDismiss={() => setIsOpenMessage(false)}
                // cssClass='my-custom-class'
                header={titleMessage}
                subHeader={textMessage}
                message={executionIdListMessage}
                buttons={['Cerrar']}
            />

            {props.isMobile ? (
                <IonFab onClick={() => onClickFilterFloatButtonHandler()} vertical="bottom" horizontal="end" slot="fixed" style={{ margin: '0px 10px', position: 'fixed' }}>
                    <IonFabButton>
                        <IonIcon icon={optionsOutline}></IonIcon>
                    </IonFabButton>
                </IonFab>
            ) : null}

            <IonModal
                isOpen={isOpenFiltersModal}
                swipeToClose={true}
                onDidDismiss={() => setIsOpenFiltersModal(false)}
                backdropDismiss={false}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpenFiltersModal(false)}>CERRAR</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonRow>
                        <IonCol style={{ padding: '20px' }}>
                            <SearchProductFormComponent
                                onGoBack={props.onGoBack}
                                fullName={props.fullName}
                                identification={props.identification}
                                categoryList={props.categoryList}
                                onSubmit={onSearchHandler}
                                formDataForSearch={props.formDataForSearch}
                                setFormDataForSearch={props.setFormDataForSearch}
                                country={props.countryAlias}
                            />

                            <br />

                            <ShowWhenIsSmallMobile>
                                <Order className='selectOrderOption' style={{ width: '100%' }} value={orderValue} onChange={e => onChangeOrderValueHandler(e.target.value)}>
                                    <option value={'More convenient'}>Mas conveniente</option>
                                    <option value={'Best rate'}>Mejor tasa</option>
                                    <option value={'Best amount'}>Mejor monto</option>
                                </Order>
                            </ShowWhenIsSmallMobile>

                            <div style={{ padding: '20px' }}>
                                <ComercialPolicyTermCapacityFilter
                                    countryAlias={props.countryAlias}
                                    termValue={termFilter}
                                    capacityValue={capacityFilter}
                                    termMax={termMaxComputed}
                                    termMin={termMinComputed}
                                    capacityMax={capacityMaxComputed}
                                    capacityMin={capacityMinComputed}
                                    onChangeTermValue={onChangeTermValueHandler}
                                    onChangeCapacityValue={onChangeCapacityValueHandler}
                                />
                                <ComercialPolicyTypeFilterComponent
                                    comercialPolicyTypeValues={comercialPolicyTypeFilter}
                                    onChangeComercialPolicyTypeValues={onChangeComercialPolicyTypeValuesHandler}
                                />

                                <ComercialPolicyPaymentPeriodFilterComponent
                                    isShowMonthly={isShowMonthly}
                                    isShowQuarterly={isShowQuarterly}
                                    isShowBiannual={isShowBiannual}
                                    isShowAnnual={isShowAnnual}
                                    comercialPolicyPaymentPeriodValues={comercialPolicyPaymentPeriodFilter}
                                    onChangeComercialPaymentPeriodValues={onChangeComercialPaymentPeriodValuesHandler}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>


            <WrapperElement isMobile={props.isMobile}>

                <IonRow>
                    <br></br>
                    {props.isMobile === false ? (
                        <IonCol sizeMd="12" sizeLg="4">
                            <SearchProductFormComponent
                                onGoBack={props.onGoBack}
                                fullName={props.fullName}
                                identification={props.identification}
                                categoryList={props.categoryList}
                                onSubmit={onSearchHandler}
                                formDataForSearch={props.formDataForSearch}
                                setFormDataForSearch={props.setFormDataForSearch}
                                country={props.countryAlias}
                            />
                            <br /><br />
                            <h5 style={{ color: '#707070', textAlign: 'center' }}>Filtrá las ofertas encontradas</h5>
                            <div style={{ padding: '20px' }}>
                                <ComercialPolicyTermCapacityFilter
                                    countryAlias={props.countryAlias}
                                    termValue={termFilter}
                                    capacityValue={capacityFilter}
                                    termMax={termMaxComputed}
                                    termMin={termMinComputed}
                                    capacityMax={capacityMaxComputed}
                                    capacityMin={capacityMinComputed}
                                    onChangeTermValue={onChangeTermValueHandler}
                                    onChangeCapacityValue={onChangeCapacityValueHandler}
                                />
                                <ComercialPolicyTypeFilterComponent
                                    comercialPolicyTypeValues={comercialPolicyTypeFilter}
                                    onChangeComercialPolicyTypeValues={onChangeComercialPolicyTypeValuesHandler}
                                />
                                <ComercialPolicyPaymentPeriodFilterComponent
                                    isShowMonthly={isShowMonthly}
                                    isShowQuarterly={isShowQuarterly}
                                    isShowBiannual={isShowBiannual}
                                    isShowAnnual={isShowAnnual}
                                    comercialPolicyPaymentPeriodValues={comercialPolicyPaymentPeriodFilter}
                                    onChangeComercialPaymentPeriodValues={onChangeComercialPaymentPeriodValuesHandler}
                                />
                            </div>

                        </IonCol>
                    ) : null}


                    <IonCol size="12" sizeMd="12" sizeLg="8">

                        <IonRow style={{ padding: '0px', margin: '0px' }}>
                            <IonCol style={{ padding: '0px', margin: '0px' }}>
                                <CheckPolicyResumeFilterComponent comercialPolicys={props.comercialPolicyList} countryAlias={props.countryAlias} />
                            </IonCol>
                        </IonRow>

                        <ComercialPolicyMainFilter
                            statusFilter={statusFilter}
                            currencyFilter={currencyFilter}
                            onStatusFilterChange={onStatusFilterChangeHandler}
                            onCurrencyFilterChange={onCurrencyFilterChangeHandler}
                            orderValue={orderValue}
                            onChangeOrderValue={onChangeOrderValueHandler}
                            countryAlias={props.countryAlias}
                        />
                        {props.isLoadingComercialPolicyList === false ? orderComercialPolicyList(comercialPolicyListComputed).map(comercialPolicy => (
                            <ComercialPolicyCardComponent
                                mostMinTax={mostMinTax}
                                mostMaxTerm={mostMaxTerm}
                                mostMaxAmount={mostMaxAmount}
                                onMessegaClick={onMenssageClickHandler}
                                isMobile={props.isMobile}
                                onSelectComercialPolicy={onSelectComercialPolicyHandler}
                                comercialPolicy={comercialPolicy}
                                countryAlias={props.countryAlias}
                                key={`${comercialPolicy.id}`}
                            />
                        )) : null}

                        {props.isLoadingComercialPolicyList === true && props.isMobile === false ? (
                            <ComercialPolicyListSkeleton />
                        ) : null}

                        {props.isLoadingComercialPolicyList === true && props.isMobile === true ? (
                            <ComercialPolicyListMobileSkeleton />
                        ) : null}

                        {comercialPolicyListComputed.length === 0 && props.isLoadingComercialPolicyList === false ? (
                            <ComercialPolicyNoResultComponent />
                        ) : null}

                    </IonCol>
                </IonRow>
            </WrapperElement>
        </div>
    )
}