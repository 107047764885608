import React from 'react';
import  { TForm, ITitleElement,IInputText, IInputNumber, IInputOptions, getValueToForm, getNumberWithFormat} from 'FormGenerator'
import { SteperGeneratorComponent } from '../components/SteperGenerator.component'
import { OnBoardingStepNumberOneComponent } from '../components/OnBoardingStepNumberOne/OnBoardingStepNumberOne.component'
import { OnBoardingStepNumberThreeNoPureFeeComponent, IFormData } from '../components/OnBoardingStepNumberThreeNoPureFee/OnBoardingStepNumberThreeNoPureFee.component'
import { IonAlert, IonCol, IonLoading, IonRow, IonTitle, useIonViewWillEnter } from '@ionic/react';
import {
    ICategoriesResponse,
    IMaxFeesResponse,
    IDownloadPDFRequest,
    ICheckPoliciesRequest,
    ISimulateResponseAdapted,
    ISimulateRequest,
    ICheckPoliciesAdaptedResponse,
    IPolicieAdapted,
    IVerifyNipRequest,
    IGenerateNipRequest,
    IValidateNipRequest,
} from '../stores/onBoarding/onBoarding.model.store';
import { OnBoardingStepNumberThreePureFeeComponent } from '../components/OnBoardingStepNumberThreePureFee/OnBoardingStepNumberThreePureFee.component'
import { OnBoardingStepNumberFourComponent } from '../components/OnBoardingStepNumberFour/OnBoardingStepNumberFour.component'
import { DownloadSimulateModalComponent } from '../components/DownloadSimulateModal.component'
import { SimulateToConvertModalComponent, ISimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component'


import { IProductTypeResponse, ISendSimulateResponse } from '../stores/simulate/simulate.model.store'
// import { IFormGeneratorComponent, IInputNumber, IInputOptions, IInputText } from '../components/FormGenerator/FormGenerator.interface';
import { ComercialPolicyListComponent } from '../components/ComercialPolicyList/ComercialPolicyList.component';
import { TActionResult } from '../utils/useStore.util';
import { IProductData, searchProductFormDataFactory } from '../components/SearchProductForm/SearchProductForm.component';
// import { addNewPriceFormatToOldPriceValue } from '../components/FormGenerator/InputGenerator.component';
import { ComercialPolicyListLoaderComponent } from '../components/ComercialPolicyList/ComercialPolicyListLoader.component';
import { FormConsumerComponentProps } from 'FormConsumer';


export type TOnGetPolicesParam = TForm

export type TCreateSimulateParam = IFormData
interface IOnBoardingModule {
    action: 'create' | 'edit'
    userEmail: string
    categories: ICategoriesResponse['data']
    policies: ICheckPoliciesAdaptedResponse
    currentPolicie: IPolicieAdapted
    maxFees: IMaxFeesResponse
    simulateResponse: ISimulateResponseAdapted
    productTypeForSendConvertRequest: IProductTypeResponse['data']
    warrantyProductTypeForConvert: IProductTypeResponse['data']
    checkPoliciesRequest: ICheckPoliciesRequest
    formSectionList: FormConsumerComponentProps['formSectionList']
    // dinamicFormsResponse: IGetDinamicFormsResponseAdapted['data']

    convertResponse: ISendSimulateResponse
    isMobile: boolean,
    countryAlias: 'ar' | 'mx'
    cuitQueryParam: string
    activityQueryParam: string
    simulateRequest: ISimulateRequest
    onGetPolices(param: TForm, currencyFilter: 'USD' | 'PESOS'): Promise<void>
    // onGetPolicesFromNip: () => TActionResult<null>
    getFees(param: {
        mount: number,
        period: number,
        subsidaryTax: number,
        isSubsidary: boolean,
        firstFeeDate: string
    }): TActionResult<IMaxFeesResponse>
    addCurrentPolicie(id: number): void
    createSimulate(param: IFormData): TActionResult<null>
    download(simulateId: number, params: IDownloadPDFRequest): TActionResult<string>
    saveSimulate(simulateId: number): TActionResult<null>
    getProductTypeForConvert(id: number): TActionResult<null>
    onGetWarrantyProductTypeForConvert(id: number): TActionResult<null>
    convertSimulateToLoanRequest(
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList']
    ): TActionResult<null>
    // convertSimulateToLoanRequestForMexico(
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponse['data']
    // ): TActionResult<null>
    getDinamicForms(policieId: number): TActionResult<null>
    getProduct: (productId: number) => TActionResult<IProductTypeResponse>
    // getDinamicFormsMexico(policieId: number): TActionResult<null>

    onRedirectToSimulateDetail(id: number): void
    onRedirectToLoanRequestDetail(id: number): void
    onChangeToLastStep: () => void

    resetMaxFees: () => void
    resetCheckPoliciesRequest: () => void
    resetCheckPoliciesResponse: () => void
    resetCurrentPolicie: () => void
    resetDinamicFormsResponse: () => void

    resetSimulateRequest: () => void
    resetSimulateResponse: () => void


    verifyNip: (param: IVerifyNipRequest) => TActionResult<'nip is verified' | 'nip is not verified' | 'rfc is not valid'>
    generateNip: (param: IGenerateNipRequest) => TActionResult<'nip is generated' | 'rfc is not valid'>
    validateNip: (param: IValidateNipRequest) => TActionResult<'nip is valid' | 'nip is not valid'>
    // riskProccessResendNipAction: () => TActionResult<1 | 2>


    getCountryStepNumberOneSheme: () => TActionResult<TForm>

    categoryList: Array<{id: number, label: string, productList: Array<{id: number, label: string}>}>
}

export const OnBoardingModule: React.FC<IOnBoardingModule> = (props) => {

    const [currentChildren, setCurrentChildreen] = React.useState<number>(0)
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)
    const [isLoadingComercialPolicyList, setIsLoadingComercialPolicyList] = React.useState<boolean>(false)

    const [isOpenDownloadModal, setIsOpenDownloadModal] = React.useState<boolean>(false)
    const [isOpenConvertModal, setIsOpenConvertModal] = React.useState<boolean>(false)

    const [isOpenSaveSimulateAlert, setIsOpenSaveSimulateAlert] = React.useState<boolean>(false)
    const [isOpenConvertSimulateAlert, setIsOpenConvertSimulateArlert] = React.useState<boolean>(false)

    const [isOpenNipAlert, setIsOpenNipAlert] = React.useState<boolean>(false)
    const [alertNipTitle, setAlertNipTitle] = React.useState<string>('')
    const [alertNipMessage, setAlertNipMessage] = React.useState<string>('')
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [formDataForSearch, setFormDataForSearch] = React.useState<IProductData>(searchProductFormDataFactory())
    const [formDataFromStepNumberOne, setFormDataFromStepNumberOne] = React.useState<TForm>([])
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [isOpenNipForm, setIsOpenNipForm] = React.useState<boolean>(false)

    const [nipEmail, setNipEmail] = React.useState<string>('')

    useIonViewWillEnter(async () => {

        setCurrentChildreen(0)
        setIsLoadingData(false)

        setIsOpenDownloadModal(false)
        setIsOpenConvertModal(false)

        setIsOpenSaveSimulateAlert(false)
        setIsOpenConvertSimulateArlert(false)

    })

    const onBackStepHandler = (step: number) => {

        window.scrollTo(0, 0);

        if (step === 0) {
            props.resetCheckPoliciesRequest()
            props.resetCheckPoliciesResponse()
            props.resetCurrentPolicie()
            props.resetMaxFees()
            props.resetDinamicFormsResponse()
            props.resetSimulateRequest()
            props.resetSimulateResponse()

            // setIsEnableNipNextStep(false)
        }
        if (step === 1) {
            props.resetCurrentPolicie()
            props.resetMaxFees()
            props.resetDinamicFormsResponse()
            props.resetSimulateRequest()
            props.resetSimulateResponse()
        }
        if (step === 2) {
            // props.resetMaxFees()
            props.resetDinamicFormsResponse()
            props.resetSimulateRequest()
            props.resetSimulateResponse()
        }

        setCurrentChildreen(step)
    }

    const onSearchHandler = async (form: IProductData, currencyFilter: 'USD' | 'PESOS') => {
        setIsLoadingComercialPolicyList(true)
        const newFormData: TForm =  [...formDataFromStepNumberOne].map(input=>{
            if(input.id === '20' && input.type === 'options') return {...input, value: String(form.categoryId)}
            if(input.id === '21' && input.type==='vinculedOptions') return {...input, value: String(form.productId)}
            if(input.id === '21.1' && input.type === 'text') return {...input, value: form.brand}
            if(input.id === '21.2' && input.type === 'text' ) return {...input, value: form.model}
            if(input.id === '22' && input.type === 'price') return {...input, value: String(form.price)}
            if(input.id === '23' && input.type === 'options') return {...input, value: form.currency === 'pesos' ? '2' : '1'}
            if(input.id === '24' && input.type === 'options') return {...input, value: form.condition === 'new' ? 'NUEVO' : 'USADO'}
            return input
        })
        setFormDataFromStepNumberOne(newFormData)
        await props.onGetPolices(newFormData, currencyFilter)
        setIsLoadingComercialPolicyList(false)
    }

    const onSubmitFirstStepHandler = async (form: TForm, nipServiceEnabled: boolean) => {
        setFormDataForSearch({
            categoryId:  Number(getValueToForm(form, '20')),
            productId: Number(getValueToForm(form, '21')),
            brand: getValueToForm(form, '21.1'),
            model: getValueToForm(form, '21.2'),
            price: getNumberWithFormat(getValueToForm(form, '22'), props.countryAlias),
            currency: getValueToForm(form, '23') === '2' ? 'pesos' : 'dollars',
            condition: getValueToForm(form, '24') === 'NUEVO' ? 'new' : 'used',
            year: getValueToForm(form, '24') === 'NUEVO' ? new Date().getFullYear() : Number(getValueToForm(form, '0.3'))
        })

        // setCurrencySelected('PESOS')

        if (props.countryAlias === 'ar') {
            setIsLoadingComercialPolicyList(true)
            await props.onGetPolices(form, "PESOS")
            setIsLoadingComercialPolicyList(false)
            setCurrentChildreen(currentChildren + 1)
            return
        }

        if (props.countryAlias === 'mx' && !nipServiceEnabled) {
            setIsLoadingComercialPolicyList(true)
            await props.onGetPolices(form, "PESOS")
            setIsLoadingComercialPolicyList(false)
            setCurrentChildreen(currentChildren + 1)
            return
        }

        if (props.countryAlias === 'mx' && nipServiceEnabled) {

            const personTypeValue = getValueToForm(form, '18')
            const rfcValue = 
                personTypeValue === 'PM' ?  getValueToForm(form, '16') 
                : getValueToForm(form, '14').toUpperCase().split('').reverse().filter((character, index)=>index>4).reverse().join('')
            
                const rfcEmail = props.userEmail
            setNipEmail(rfcEmail)

            setIsLoadingData(true)
            const proccessStepOneResult = await props.verifyNip({rfc: rfcValue})
            setIsLoadingData(false)


            if (proccessStepOneResult.isSuccess) {
                if (proccessStepOneResult.data === 'nip is verified') {

                    setIsLoadingComercialPolicyList(true)
                    await props.onGetPolices(form, "PESOS")
                    setIsLoadingComercialPolicyList(false)
                    setCurrentChildreen(currentChildren + 1)

                } else if (proccessStepOneResult.data === 'nip is not verified') {

                    setIsLoadingData(true)
                    const generateNipResult = await props.generateNip({
                        rfc: rfcValue,
                        email: rfcEmail
                    })
                    setIsLoadingData(false)

                    if(generateNipResult.isSuccess && generateNipResult.data === 'nip is generated') {
                        setIsOpenNipForm(true)
                    }

                    if(generateNipResult.isSuccess && generateNipResult.data === 'rfc is not valid') {
                        setAlertNipTitle('Error')
                        setAlertNipMessage('RFC no valido')
                        setIsOpenNipAlert(true)
                    }

                } else if(proccessStepOneResult.data === 'rfc is not valid') {
                    setAlertNipTitle('Error')
                    setAlertNipMessage('RFC no valido')
                    setIsOpenNipAlert(true)
                } else {
                    setAlertNipTitle('Error')
                    setAlertNipMessage('Hemos encontrado un problema al procesar riesgo, aguardar unos minutos y reintentar')
                    setIsOpenNipAlert(true)
                }

            } else {
                setAlertNipTitle('Error')
                setAlertNipMessage('Hay problemas con el servidor.')
                setIsOpenNipAlert(true)
                setIsLoadingData(false)
                return
            }
        }
    }

    const onSelectComercialPolicyHandler = (id: number) => {
        props.addCurrentPolicie(id)
        setCurrentChildreen(currentChildren + 1)
    }

    const onSubmitThreeStepHandler = async (formData: TCreateSimulateParam) => {
        setIsLoadingData(true)
        await props.createSimulate(formData)
        setIsLoadingData(false)
        setCurrentChildreen(currentChildren + 1)
        props.onChangeToLastStep()
    }

    const getFeesHandler = async (
        mount: number, 
        period: number, 
        subsidaryTax: number, 
        isSubsidary: boolean, 
        firstFeeDate: string
    ):  TActionResult<IMaxFeesResponse> => {
        setIsLoadingData(true)
        const result = await props.getFees({
            mount,
            period,
            subsidaryTax,
            isSubsidary,
            firstFeeDate
        }) 
        setIsLoadingData(false)
        return result
       
    }

    const onOpenDownloadModalHandler = async () => setIsOpenDownloadModal(true)
    const onCloseDownloadModalHandler = () => setIsOpenDownloadModal(false)
    const onSubmitSimulateModalHandler = async (param: IDownloadPDFRequest) => {
        setIsLoadingData(true)
        await props.download(props.simulateResponse.id, param)
        setIsLoadingData(false)
        setIsOpenDownloadModal(false)
    }

    const onOpenConvertModal = async () => {
        setIsLoadingData(true)
        const promises: Array<TActionResult<any>> = [props.getProductTypeForConvert(props.checkPoliciesRequest.product.type_id)]
        // if( props.countryAlias === 'ar' && props.currentPolicie.secondary_risk_policies[0]) promises.push(props.getDinamicForms(props.currentPolicie.secondary_risk_policies[0]))
        // if( props.countryAlias === 'mx' ) promises.push(props.getDinamicFormsMexico(props.currentPolicie.id))
        // const promisesResult = await Promise.all(promises)

        if( props.currentPolicie.secondary_risk_policies[0]) promises.push(props.getDinamicForms(props.currentPolicie.secondary_risk_policies[0]))
        const promisesResult = await Promise.all(promises)
        const somePromiseResultFailed = promisesResult.some(promiseResult=> promiseResult.isError)
        if(somePromiseResultFailed === false) setIsOpenConvertModal(true)
        setIsLoadingData(false)
    }

    const onCloseConvertModal = () => setIsOpenConvertModal(false)

    const onSaveHandler = async () => {
        setIsLoadingData(true)
        await props.saveSimulate(props.simulateResponse.id)
        setIsLoadingData(false)
        setIsOpenSaveSimulateAlert(true)
    }

    // const onSubmitForMexicoHandler = async (
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponse['data']
    // ) => {

    //     setIsLoadingData(true)
    //     await props.convertSimulateToLoanRequestForMexico(
    //         productFormData,
    //         warrantyFormData,
    //         warrantyDetailFormData,
    //         dinamicFormsData
    //     )
    //     setIsLoadingData(false)
    //     setIsOpenConvertModal(false)
    //     setIsOpenConvertSimulateArlert(true)
    // }

    const onSubmitForConvertHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList']
    ) => {

        setIsLoadingData(true)
        await props.convertSimulateToLoanRequest(
            productFormData,
            warrantyFormData,
            warrantyDetailFormData,
            formSectionList
        )
        setIsLoadingData(false)
        setIsOpenConvertModal(false)
        setIsOpenConvertSimulateArlert(true)
    }

    const getProductTypeInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {
        return props.productTypeForSendConvertRequest.attributes.map(attribute => {
            const valueFinded = props.simulateRequest.product.values.find(value=> value.attribute_id === attribute.attribute_id)
            const valueObtained: string = valueFinded ? valueFinded.value : ''
     
            if (attribute.type === 'number') return {
                id: String(attribute.attribute_id),
                type: 'number',
                label: attribute.title,
                value: valueObtained,
                maxValue: 99999999999999,
                minValue: 0,
                placeholder: attribute.is_required ?'Campo Requerido' : '',
                required: attribute.is_required,
                error: false,
                disabled: false,
                hidden: false,
                size: 6
            }

            else if (attribute.type === 'string') return {
                id: String(attribute.attribute_id),
                type: 'text',
                label: attribute.name,
                value: valueObtained,
                regex: '',
                placeholder: attribute.is_required ?'Campo Requerido' : '',
                required: attribute.is_required,
                options: [],
                error: false,
                maxLength: 80,
                minLength: 1,
                disabled: false,
                hidden: false,
                size: 6
            }

            else if (attribute.type === 'option') return {
                id: String(attribute.attribute_id),
                type: 'options',
                label: attribute.name,
                value: valueObtained,
                regex: '',
                placeholder: attribute.is_required ? 'Campo Requerido' : '',
                required: attribute.is_required,
                options: attribute.options.map(option => ({
                    value: option.value,
                    label: option.value
                })),
                error: false,
                disabled: false,
                hidden: false,
                size: 6
            }

            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                size: 12,
                value: '',
                hidden: false,
            }

            return titleInput
        })
    }

    const onGetWarrantyInputsHandler = async (productTypeId: number) => {
        setIsLoadingData(true)
        await props.onGetWarrantyProductTypeForConvert(productTypeId)
        setIsLoadingData(false)
    }

    const getWarrantyInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {

        return props.warrantyProductTypeForConvert.attributes.map(attribute => {

            if (attribute.type === 'string') return {
                id: String(attribute.attribute_id),
                type: 'text',
                label: attribute.name,
                value: '',
                regex: '',
                placeholder: 'Campo Requerido',
                required: attribute.is_required,
                error: false,
                disabled: false,
                hidden: false,
                maxLength: 80,
                minLength: 1,
                size: 6,
            }
            else if (attribute.type === 'option') return {
                id: String(attribute.attribute_id),
                type: 'options',
                label: attribute.name,
                value: '',
                placeholder: 'Campo requerido',
                required: attribute.is_required,
                options: attribute.options.map(option => ({
                    value: option.value,
                    label: option.value
                })),
                error: false,
                disabled: false,
                hidden: false,
                size: 6
            }
            else if (attribute.type === 'number') return {
                id: String(attribute.attribute_id),
                type: attribute.type,
                label: attribute.name,
                value: '',
                placeholder: 'Campo Requerido',
                required: attribute.is_required,
                disabled: false,
                hidden: false,
                options: [],
                error: false,
                minValue: 0,
                maxValue: 9999999999999999999999999999999,
                size: 6,
            }

            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                hidden: false,
                size: 12,
                value: ''
            }

            return titleInput

        })
    }

    const onViewDetailSimulateCreatedHandler = () => {
        props.onRedirectToSimulateDetail(props.simulateResponse.id)
    }

    const onViewDetailSimulateConvertedHandler = () => {
        props.onRedirectToLoanRequestDetail(props.convertResponse.loan_request_id)
    }


    //////////////////////////////////////////////////////////




    const onSubmitNipFormHandler = async (nip: string) => {

        const personTypeValue = getValueToForm(formDataFromStepNumberOne, '18')
        const rfcValue = 
            personTypeValue === 'PM' ?  getValueToForm(formDataFromStepNumberOne, '16') 
            : getValueToForm(formDataFromStepNumberOne, '14').toUpperCase().split('').reverse().filter((character, index)=>index>4).reverse().join('')
        
        setIsLoadingData(true)
        const riskProccessValidateNipResponse = await props.validateNip({
            nip_number: nip,
            rfc: rfcValue
        })
        setIsLoadingData(false)

        
        if (riskProccessValidateNipResponse.data === 'nip is not valid') {
            setAlertNipTitle('Error')
            setAlertNipMessage('El código ingresado no coincide')
            setIsOpenNipAlert(true)
            return
        }

        if(riskProccessValidateNipResponse.data === 'nip is valid') {
            setIsOpenNipForm(false)
            setIsLoadingComercialPolicyList(true)
            await props.onGetPolices(formDataFromStepNumberOne, 'PESOS')
            setIsLoadingComercialPolicyList(false)
            setCurrentChildreen(currentChildren + 1)
            return
        }

        if(riskProccessValidateNipResponse.isError) {
            setAlertNipTitle('Error')
            setAlertNipMessage('Hemos detectado un error')
            setIsOpenNipAlert(true)
            return
        }
    }

    const getIsShowSubsidaryCheckBoxForDownloadPDF = () =>
        (props.countryAlias == 'ar' && !!props.currentPolicie.breakMode)
        ||
        !!props.simulateResponse.subsidized_detail.amount



    const getCountryStepNumberOneShemeHandler = async (): TActionResult<TForm> => {
        setIsLoadingData(true)
        const schemeResult = await props.getCountryStepNumberOneSheme()
        setIsLoadingData(false)
        return schemeResult
    }

    const comercialPolicyComputed = props.policies.commercialPolicies


    const onGoBackFromComercialPolicyListComponentHandler = () => {
        onBackStepHandler(0)
    }


    const onNextFromNipHandler = async () => {
        setIsOpenNipForm(false)
        setIsLoadingComercialPolicyList(true)
        await props.onGetPolices(formDataFromStepNumberOne, "PESOS")
        setIsLoadingComercialPolicyList(false)
        setCurrentChildreen(currentChildren + 1)
    }


    const onResendNipHandler = async () => {
        setIsLoadingData(true)
        // aka estoy
        // const riskProccessResendNipResult = await props.riskProccessResendNipAction()
        // if (riskProccessResendNipResult.data === 1) {
        //     setAlertNipTitle('')
        //     setAlertNipMessage(`Hemos reenviado un código al ${props.checkPoliciesRequest.customer.telefono_celular}`)
        //     setIsOpenNipAlert(true)
        // } else if (riskProccessResendNipResult.data === 2) {
        //     setAlertNipTitle('')
        //     setAlertNipMessage(`Hemos enviado un código al correo ${props.userEmail}, comprueba tu casilla`)
        //     setIsOpenNipAlert(true)
        // }
        // setIsLoadingData(false)
        // return riskProccessResendNipResult
    }


    const onGetProductFormHandler = async (productId: number): Promise<TForm> => {
        setIsLoadingData(true)
        const result = await props.getProduct(productId)
        if(result.isError) {
            setIsLoadingData(false)
            return []
        }else {
            setIsLoadingData(false)
            return result.data.data.attributes.map(attribute=>{
            if(attribute.type === 'string') {
                const inputText: IInputText = {
                    id: String(1000 + attribute.attribute_id),
                    type: 'text',
                    label: attribute.title,
                    value: '',
                    placeholder: '', 
                    required: false,
                    error: false,
                    minLength: 1,
                    maxLength: 99999999,
                    size: 6,
                    hidden: false,
                    disabled: false,
                    regex: '',   
                }

                return inputText
            }
            if(attribute.type === 'number') {
                const inputNumber: IInputNumber = {
                    id: String(1000 + attribute.attribute_id),
                    type: 'number',
                    label: attribute.title,
                    value: '',
                    maxValue: 99999999999999,
                    minValue: 0,
                    placeholder: '',
                    required: false,
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6
                }
                return inputNumber
            }
            if(attribute.type === 'option') {
                const inputOption: IInputOptions = {
                    id: String(1000 + attribute.attribute_id),
                    type: 'options',
                    label: attribute.title,
                    value: '',
                    placeholder: '',
                    required: false,
                    error: false,
                    options: attribute.options.map(option=>({
                        value: option.value, 
                        label: option.value
                    })),
                    disabled: false,
                    hidden: false,
                    size: 6,
                }

                return inputOption
            }

            const inputText: IInputText = {
                id: String(1000 + attribute.attribute_id),
                type: 'text',
                label: attribute.title,
                value: '',
                placeholder: '', 
                required: false,
                error: false,
                minLength: 1,
                maxLength: 99999999,
                disabled: false,
                hidden: false,
                size: 6,
                regex: '',
            }

            return inputText
        })

    }
    }


    const getStepsComponents = () => {
        let steps = []

        steps.push(
            <OnBoardingStepNumberOneComponent
                isOpenNipForm={isOpenNipForm}
                onGetProductForm={onGetProductFormHandler}
                onCloseNipForm={() => setIsOpenNipForm(false)}
                onNextFromNip={onNextFromNipHandler}
                key="OnBoardingStepNumberOneComponent"
                countryAlias={props.countryAlias}
                onSubmit={onSubmitFirstStepHandler}
                onSubmitNipForm={onSubmitNipFormHandler}
                categories={props.categories}
                cuitQueryParam={props.cuitQueryParam}
                activityQueryParam={props.activityQueryParam}
                getCountryStepNumberOneSheme={getCountryStepNumberOneShemeHandler}
                onResendNip={onResendNipHandler}
                email={nipEmail}
                formData={formDataFromStepNumberOne}
                setFormData={setFormDataFromStepNumberOne}
            />
        )
    
        steps.push(
            <ComercialPolicyListComponent
                id={
                    props.policies.customer.name && props.policies.customer.identification_number ?
                        `${props.policies.customer.name}(${props.policies.customer.identification_number})`
                        : props.policies.customer.primer_nombre && props.policies.customer.apellido_paterno ?
                            `${props.policies.customer.primer_nombre} ${props.policies.customer.apellido_paterno}`
                            : ''
                }
                fullName={
                    props.policies.customer.name ? props.policies.customer.name :
                    props.policies.customer.primer_nombre && props.policies.customer.apellido_materno ? `${props.policies.customer.primer_nombre} ${props.policies.customer.apellido_materno}`
                    : ''
                }
                identification={
                    props.policies.customer.identification_number ? 
                    props.policies.customer.identification_number :  
                    props.policies.customer.curp ? props.policies.customer.curp : ''
                }

                isMobile={props.isMobile}
                onGoBack={onGoBackFromComercialPolicyListComponentHandler}
                onSearch={onSearchHandler}
                key="OnBoardingStepNumberTwoComponent"
                comercialPolicyList={comercialPolicyComputed}
                countryAlias={props.countryAlias}
                onSelectComercialPolicy={onSelectComercialPolicyHandler}
                categoryList={props.categoryList}
                formDataForSearch={formDataForSearch}
                setFormDataForSearch={setFormDataForSearch}
                isLoadingComercialPolicyList={isLoadingComercialPolicyList}
            />
        )

        if (props.currentPolicie.pure_fee_check) {
            steps.push(
                <OnBoardingStepNumberThreePureFeeComponent
                    key="OnBoardingStepNumberThreePureFeeComponent"
                    currentPolicyId={props.currentPolicie.id}
                    currentCurrency={props.currentPolicie.currency.acronym}
                    getPureFees={getFeesHandler}
                    onSubmit={onSubmitThreeStepHandler}
                    maxAmount={props.currentPolicie.max_amount}
                    maxFees={props.maxFees}
                    subsidyRate={props.currentPolicie.subsidized_rate ? Number(props.currentPolicie.subsidized_rate) : 0}
                    maxSubsidyRate={Number(props.currentPolicie.tax)}
                    isBlockedSubsidyRate={!(props.currentPolicie.subsidized_rate === null || props.currentPolicie.subsidized_rate === undefined)}
                    paymentPeriod={props.currentPolicie.periodicities}
                    breakMode={props.currentPolicie.breakMode ? props.currentPolicie.breakMode : false}
                    isSeparateExpensesEnabled={props.currentPolicie.split_expenses}
                    isAymetricFees={props.currentPolicie.asymetric_fees}
                    fixedFees={props.currentPolicie.fixed_fees}
                />
            )
        } else {
            steps.push(
                <OnBoardingStepNumberThreeNoPureFeeComponent
                    key="OnBoardingStepNumberThreeNoPureFeeComponent"
                    currentPolicyId={props.currentPolicie.id}
                    currentCurrency={props.currentPolicie.currency.acronym}
                    getPureFees={getFeesHandler}
                    onSubmit={onSubmitThreeStepHandler}
                    maxAmount={props.currentPolicie.max_amount}
                    maxFees={props.maxFees}
                    subsidyRate={props.currentPolicie.subsidized_rate ? Number(props.currentPolicie.subsidized_rate) : 0}
                    isBlockedSubsidyRate={!(props.currentPolicie.subsidized_rate === null || props.currentPolicie.subsidized_rate === undefined)}
                    maxSubsidyRate={Number(props.currentPolicie.tax)}
                    paymentPeriod={props.currentPolicie.periodicities}
                    breakMode={props.currentPolicie.breakMode ? props.currentPolicie.breakMode : false}
                    isSeparateExpensesEnabled={props.currentPolicie.split_expenses}
                    isAymetricFees={props.currentPolicie.asymetric_fees}
                    fixedFees={props.currentPolicie.fixed_fees}
                />
            )
        }

        steps.push(
            <OnBoardingStepNumberFourComponent
                key="OnBoardingStepNumberFourComponent"
                action={props.action}
                checkPoliticsRequest={props.checkPoliciesRequest}
                simulateResponse={props.simulateResponse}
                currentPolicie={props.currentPolicie}
                isMobile={props.isMobile}
                feeTaxMode={props.currentPolicie.fee_tax_mode ? props.currentPolicie.fee_tax_mode : 2}
                onOpenDownloadModal={onOpenDownloadModalHandler}
                onOpenConvertModal={onOpenConvertModal}
                onOpenSaveModal={onSaveHandler}
                countryAlias={props.countryAlias}
            />
        )


        return steps
    }

    return (
        <>
            <IonAlert
                isOpen={isOpenSaveSimulateAlert}
                onDidDismiss={() => setIsOpenSaveSimulateAlert(isOpenSaveSimulateAlert)}
                header={'Simulación generada'}
                backdropDismiss={false}
                message={`Numero de la operación: ${props.simulateResponse.id}`}
                buttons={[{
                    text: 'Ver Detalle',
                    cssClass: 'secondary',
                    handler: onViewDetailSimulateCreatedHandler
                },
                {
                    text: 'Cerrar',
                    cssClass: 'secondary',
                    handler: () => { }
                }
                ]}
            />

            <IonAlert
                isOpen={isOpenConvertSimulateAlert}
                onDidDismiss={() => setIsOpenConvertSimulateArlert(isOpenSaveSimulateAlert)}
                header={'Simulación Convertida'}
                backdropDismiss={false}
                message={`Numero de la operación: ${props.convertResponse.loan_request_id}`}
                buttons={[{
                    text: 'Ver Detalle',
                    cssClass: 'secondary',
                    handler: onViewDetailSimulateConvertedHandler
                },
                {
                    text: 'Cerrar',
                    cssClass: 'secondary',
                    handler: () => { }
                }
                ]}
            />

            <IonAlert
                isOpen={isOpenNipAlert}
                onDidDismiss={() => setIsOpenNipAlert(false)}
                header={alertNipTitle}
                backdropDismiss={false}
                message={alertNipMessage}
                buttons={[
                    {
                        text: 'Cerrar',
                        cssClass: 'secondary',
                        handler: () => {
                            // if(isAviableNipForm) {
                            //     setIsAviableNipForm(false)
                            //     setIsOpenNipForm(true)
                            // }{
                            //     setIsAviableNipForm(false)
                            //     setIsOpenNipForm(false)
                            // }
                        }
                    }
                ]}
            />

            <DownloadSimulateModalComponent
                isOpen={isOpenDownloadModal}
                onSubmit={onSubmitSimulateModalHandler}
                onClose={onCloseDownloadModalHandler}
                isShowSubsidaryCheckBox={getIsShowSubsidaryCheckBoxForDownloadPDF()}
                applicantPreLoadData={{
                    identification:
                        props.policies.customer.identification_number ? props.policies.customer.identification_number :
                            props.policies.customer.curp ? props.policies.customer.curp : '',
                    name:
                        props.policies.customer.name ? props.policies.customer.name :
                            props.policies.customer.primer_nombre && props.policies.customer.apellido_paterno ?
                                `${props.policies.customer.primer_nombre} ${props.policies.customer.apellido_paterno}` : '',
                    email: props.policies.customer.correo_electronico ? props.policies.customer.correo_electronico : '',
                    productCategory: props.policies.product.categoria,
                    channel: "Concesionario"
                }}
                hasDynamicForms={false}
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoadingData}
                onDidDismiss={() => setIsLoadingData(false)}
                message={'Cargando...'}
            />

            <ComercialPolicyListLoaderComponent isOpen={isLoadingComercialPolicyList}/>


            <SimulateToConvertModalComponent
                isOpen={isOpenConvertModal}
                onClose={onCloseConvertModal}
                productCategoryName={props.checkPoliciesRequest.product.categoria}
                productTypeName={props.checkPoliciesRequest.product.nombre}
                productFormDataInputs={getProductTypeInputs()}
                warrantyDetailFormDataInputs={getWarrantyInputs()}
                // onSubmitForMexico={onSubmitForMexicoHandler}
                onSubmit={onSubmitForConvertHandler}
                categories={props.categories}
                onGetWarrantyInputs={onGetWarrantyInputsHandler}
                formSectionList={props.formSectionList}
                // dinamicShemaFormsResponse={props.dinamicFormsResponse}
                // countryAlias={props.countryAlias}
            />


            <br></br>
            
            {props.policies.customer.razon_social ? (
                <IonRow>
                    <IonCol className="ion-text-center"><IonTitle>{props.policies.customer.name}</IonTitle></IonCol>
                </IonRow>
            ) : null}

            {props.policies.customer.identification_number ? (
                <IonRow>
                    <IonCol className="ion-text-center"><IonTitle>{props.policies.customer.identification_number}</IonTitle></IonCol>
                </IonRow>
            ) : null}

            {props.policies.customer.curp ? (
                <IonRow>
                    <IonCol className="ion-text-center"><IonTitle>{props.policies.customer.curp}</IonTitle></IonCol>
                </IonRow>
            ) : null}


            <br></br>
            <SteperGeneratorComponent
                currentStep={currentChildren}
                onBackStep={onBackStepHandler}
                isDisabledButtons={false}
            >
                {getStepsComponents()}
            </SteperGeneratorComponent>
        </>
    )
}