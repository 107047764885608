import { IonCard, IonCardContent, IonRow, IonCol, IonBadge, IonProgressBar, IonButton } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import React from 'react'
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'

interface ILoanRequestCardComponentProps {
    loanRequest: ILoanRequest
    onLoanRequestClick: (id: number) => void
}

const getStatusColorHelper = (status: string) => {
    return status === 'ACTIVA' ? 'success'
      : status === 'RECHAZADA' ? 'danger'
        : status === 'CADUCADA' ? 'warning'
          : 'light'
}

const getPercentHelper = (loanRequest: ILoanRequest) => {
    const currentStep = loanRequest.stageDays.find(step => step.current)
    if (!currentStep) return 0

    const currentPosition: number = loanRequest.circuit_info.stages.findIndex(stages => stages.name === currentStep.stageName) + 1
    const totalSteps = loanRequest.circuit_info.stages.length + 1
    const porcent = currentPosition / totalSteps

    return porcent
  }

export const LoanRequestCardComponent: React.FC<ILoanRequestCardComponentProps> = (props) => {
    return (

        <IonCard>
            <IonCardContent>
                <IonRow className="ion-align-items-center">
                    <IonCol size="10">
                        <IonCol size="auto">
                            <IonBadge color={getStatusColorHelper(props.loanRequest.atributteStatus)}>{props.loanRequest.atributteStatus}</IonBadge>
                        </IonCol>
                        <IonCol size="auto">
                            {props.loanRequest.createdAt} | {props.loanRequest.product.productTypeName}
                        </IonCol>
                    </IonCol>
                    <IonCol size="2" className="ion-text-end">
                        <b style={{ color: '#aaa' }}>Nº {props.loanRequest.id}</b>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-align-items-center">
                    <IonCol size="12" size-md="8" size-lg="8">
                        <IonRow>
                            <IonCol>
                                <h2 style={{ margin: "0px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={`${props.loanRequest.client.fullName}(${props.loanRequest.client.cuitCuil})`}>
                                    {`${props.loanRequest.contactCompany.alias}(${props.loanRequest.contactCompany.cuitCuil})`}
                                </h2>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol size="12" size-md="4" size-lg="4">
                        <IonRow className="ion-align-items-center" style={{ flexDirection: 'row-reverse' }}>
                            <IonCol size="auto" className="ion-text-end">
                                <h1 style={{ margin: "0px", display: 'inline' }}>
                                    ${props.loanRequest.requestAmount ? `${props.loanRequest.requestAmount}` : ''}
                                </h1>
                            </IonCol>
                            <IonCol size="auto">
                                <h6 style={{ margin: "0px", display: 'inline' }}>{props.loanRequest.product.currency}</h6>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <br></br>
                <IonProgressBar color={getStatusColorHelper(props.loanRequest.atributteStatus)} value={getPercentHelper(props.loanRequest)}></IonProgressBar>
                <br></br>
                <IonButton color="primary" onClick={() => props.onLoanRequestClick(props.loanRequest.id)}>Ver Detalle</IonButton>
            </IonCardContent>
        </IonCard>
    )
}