import { IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon, IonTitle, IonToggle, IonSegment, IonSegmentButton, IonLabel, IonAlert } from '@ionic/react'
import { arrowDown, arrowUp, informationCircle } from 'ionicons/icons';
import moment from 'moment';
import React, { useState } from 'react'
import { IMaxFeesResponse } from '../../stores/onBoarding/onBoarding.model.store';
import { useDebounce } from '../../utils/debounce.use.util';
import { addSeparatorsToNumber, deleteSpecialCharactersToNumberString } from '../../utils/number.util';
import { TActionResult } from '../../utils/useStore.util';
import { getNumberValueToPriceInput } from '../FormGenerator/FormGenerator.component';
import { addNewPriceFormatToOldPriceValue, addPriceFormatToNumberValue } from '../FormGenerator/InputGenerator.component';
import { MyDateModalComponent } from '../MyDateModal.component';

export interface IFormData {
    amountToRequest: string,
    firstFeeDate: string,
    feeNumbers: string,
    paymentPeriod: 0 | 1 | 2 | 3 | 4, // ninguno, mensual, trimestral, semestral, anual
    checkbook: boolean
    separateExpenses: boolean
    subsidize: boolean,
    subsidyRate: string
}

export interface IOnBoardingStepNumberThreeNoPureFeeComponent {
    currentPolicyId: number
    currentCurrency: string
    maxAmount: number
    maxFees: IMaxFeesResponse
    maxSubsidyRate: number
    onSubmit(formData: IFormData): void
    getPureFees(mount: number, period: number, subsidaryTax: number, isSubsidary: boolean, firstFeeDate: string): TActionResult<IMaxFeesResponse>
    paymentPeriod: Array<0 | 1 | 2 | 3 | 4>
    subsidyRate: number
    isBlockedSubsidyRate: boolean
    breakMode: boolean
    isSeparateExpensesEnabled: boolean
    isAymetricFees: boolean
    fixedFees: '' | 'Min' | 'Max'
}

export const OnBoardingStepNumberThreeNoPureFeeComponent: React.FC<IOnBoardingStepNumberThreeNoPureFeeComponent> = (props) => {

    const [amountToRequest, setAmountToRequest] = useState<string>('0')
    const [firstFeeDate, setFirstFeeDate] = useState<Date>()
    const [feeNumbers, setFeeNumbers] = useState<string>('')
    const [paymentPeriod, setPaymentPeriod] = useState<0 | 1 | 2 | 3 | 4>(0)
    const [checkbook] = useState<boolean>(false)
    const [separateExpenses, setSeparateExpenses] = useState<boolean>(false)
    const [subsidize, setSubsidize] = useState<boolean>(false)
    const [subsidyRate, setSubsidyRate] = useState<string>('0')

    const [isMaxFeeDebouncing, setIsMaxFeeDebouncing] = useState<boolean>(false)
    const [isDisabledCalendar, setIsDisabledCalendar] = useState<boolean>(true)
    const [isOpenDateModal, setIsOpenDateModal] = useState<boolean>(false)

    const [isOpenInformationAlert, setIsOpenInformationAlert] = useState(false)

    React.useEffect(()=>{
        if(props.paymentPeriod.length === 1) {
            setPaymentPeriod(props.paymentPeriod[0])
            setIsDisabledCalendar(false)
        }
    }, [props.paymentPeriod])

    const generateFormData = (): IFormData => ({
        amountToRequest: getNumberValueToPriceInput(amountToRequest), // esta mal, tengo que quitarle el formato
        firstFeeDate: moment(firstFeeDate).format('YYYY-MM'),
        feeNumbers,
        paymentPeriod,
        checkbook,
        separateExpenses,
        subsidize: props.isBlockedSubsidyRate ? true : subsidize,
        subsidyRate: props.subsidyRate ? String(props.subsidyRate) : subsidyRate
    })

    const maxFeeDebounce = useDebounce((formData: IFormData) => {
        callMaxFeesHandler(formData)
        setIsMaxFeeDebouncing(false)
    }, 2000)

    React.useEffect(() => {
        resetForm()
    }, [props.currentPolicyId])

    const resetForm = () => {
        // props.maxAmount
        // setAmountToRequest('0')
        maxAmountHandler()
        setFirstFeeDate(undefined)
        setFeeNumbers('')
        setPaymentPeriod(0)
        setSeparateExpenses(false)
        setSubsidize(false)
        setSubsidyRate('0')
        setIsMaxFeeDebouncing(false)
        setIsDisabledCalendar(true)
        setIsOpenDateModal(false)
    }

    const getMounthNumberOfPeriod = (period: 0 | 1 | 2 | 3 | 4): 1 | 3 | 6 | 12 => {
        if (period === 1) return 1
        if (period === 2) return 3
        if (period === 3) return 6
        if (period === 4) return 12
        return 1
    }

    const getIsDisabledSubsidize = (): boolean => paymentPeriod === 0 || !!props.subsidyRate

    const callMaxFeesHandler = async (formData: IFormData) => {
        
        if (Number(formData.amountToRequest) && formData.paymentPeriod) {

            const result = await props.getPureFees(
                Number(formData.amountToRequest),
                Number(formData.paymentPeriod),
                Number(formData.subsidyRate),
                formData.subsidize,
                formData.firstFeeDate
            )

            if (props.fixedFees === 'Min') {
                setFeeNumbers('1')
            }

            if (props.fixedFees === 'Max' || props.fixedFees === '') {
                if (formData.paymentPeriod === 1) {
                    setFeeNumbers(String(result.data[1].length))
                }
                if (formData.paymentPeriod === 2) {
                    setFeeNumbers(String(result.data[3].length))
                }
                if (formData.paymentPeriod === 3) {
                    setFeeNumbers(String(result.data[6].length))
                }
                if (formData.paymentPeriod === 4) {
                    setFeeNumbers(String(result.data[12].length))
                }
            }

            return result
        }
    }

    const feeUpArrowHandler = () => {
        setFeeNumbers(Number(feeNumbers) < getMaxFeesNumberByPeriod() ?
            String(Number(feeNumbers) + 1)
            : feeNumbers)

    }

    const feeDownArrowHandler = () => {
        setFeeNumbers(Number(feeNumbers) > 1 ? String(Number(feeNumbers) - 1) : feeNumbers)
    }

    const feeInputHandler = (value: string) => {
        const valueParsed = Number(value)
        if (value === '') {
            setFeeNumbers('')
        }
        else if (valueParsed > getMaxFeesNumberByPeriod()) {
            setFeeNumbers(String(getMaxFeesNumberByPeriod() ? getMaxFeesNumberByPeriod() : 1))
        } else if (valueParsed < 1) {
            setFeeNumbers('1')
        } else {
            setFeeNumbers(value)
        }
    }

    const maxAmountHandler = () => {
        if (props.paymentPeriod.length > 1) {
            setAmountToRequest(addPriceFormatToNumberValue(String(props.maxAmount)))
            setIsMaxFeeDebouncing(true)
            callMaxFeesHandler({...generateFormData(), amountToRequest: String(props.maxAmount)})
            setIsMaxFeeDebouncing(false)
        } else if (props.paymentPeriod.length === 1) {
            setAmountToRequest(addPriceFormatToNumberValue(String(props.maxAmount)))
            setIsMaxFeeDebouncing(true)
            setPaymentPeriod(props.paymentPeriod[0])
            callMaxFeesHandler({ ...generateFormData(), 
                amountToRequest: String(props.maxAmount),
                paymentPeriod: props.paymentPeriod[0]
            })           
            setIsMaxFeeDebouncing(false)
            setIsDisabledCalendar(false)
        }
    }

    const amountHandler = (value: string) => {
        const valueFormated: string = deleteSpecialCharactersToNumberString(value)
        const valueParsed: number = Number(getNumberValueToPriceInput(valueFormated))
        if (valueParsed > props.maxAmount) maxAmountHandler()
        else if (valueParsed < 0) setAmountToRequest(String(1))
        else setAmountToRequest(addNewPriceFormatToOldPriceValue(valueFormated))
        
        if (props.paymentPeriod.length > 1) {
            setIsMaxFeeDebouncing(true)
            setImmediate(() => {
                maxFeeDebounce({ ...generateFormData(), amountToRequest: valueParsed })
            })
        } else if (props.paymentPeriod.length === 1) {
            setIsMaxFeeDebouncing(true)
            setIsDisabledCalendar(false)

            setImmediate(() => {
                maxFeeDebounce({ ...generateFormData(), amountToRequest: valueParsed, paymentPeriod: props.paymentPeriod[0] })
            })
        }
    }

    const dateHandler = (date: Date) => {
        setIsOpenDateModal(false)
        setFirstFeeDate(date)
        callMaxFeesHandler(generateFormData())
    }

    const paymentPeriodHandler = async (value: string | undefined) => {

        const newPaymentPeriodValue = value === '1' ? 1
            : value === '2' ? 2
                : value === '3' ? 3
                    : value === '4' ? 4
                        : 1

        const newFirstDateValue = new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(newPaymentPeriodValue), 'month').toJSON())

        setPaymentPeriod(newPaymentPeriodValue)
        getMounthNumberOfPeriod(newPaymentPeriodValue)
        setFirstFeeDate(newFirstDateValue)
        
        setIsDisabledCalendar(false)
        callMaxFeesHandler({ ...generateFormData(), paymentPeriod: newPaymentPeriodValue, firstFeeDate: newFirstDateValue.toJSON() })    
    }

    const separateExpensesHandler = () => {
        setSeparateExpenses(!separateExpenses)
    }

    const subsidizeHandler = () => {
        const newSubsidizeValue = !subsidize
        setSubsidize(newSubsidizeValue)
        if (newSubsidizeValue) setSubsidyRate(String(props.maxSubsidyRate))
        else setSubsidyRate('0')
    }

    const onSubmitHandler = () => {
        props.onSubmit(generateFormData())
    }

    const subsidyRateInputHandler = (value: string | undefined) => {
        const valueParsed = Number(value)
        if (valueParsed <= props.maxSubsidyRate && valueParsed >= 0) setSubsidyRate(String(valueParsed))
        if (valueParsed > props.maxSubsidyRate) setSubsidyRate(String(props.maxSubsidyRate))
        if (valueParsed < 0) setSubsidyRate('0')
        setIsMaxFeeDebouncing(true)
        setImmediate(() => maxFeeDebounce(generateFormData()))
    }

    const subsidyRateUpArrowHandler = () => {
        const currentSubsidyRateParsedPlusOne = Number(subsidyRate) + 1
        if (currentSubsidyRateParsedPlusOne <= props.maxSubsidyRate) {
            setSubsidyRate(String(currentSubsidyRateParsedPlusOne))
            setIsMaxFeeDebouncing(true)
            setImmediate(() => maxFeeDebounce(generateFormData()))
        } else {
            setSubsidyRate(String(props.maxSubsidyRate))
        }
    }

    const subsidyRateDownArrowHandler = () => {
        const currentSubsidyRateParsedMinusOne = Number(subsidyRate) - 1
        if (currentSubsidyRateParsedMinusOne >= 0) {
            setSubsidyRate(String(currentSubsidyRateParsedMinusOne))
            setIsMaxFeeDebouncing(true)
            setImmediate(() => maxFeeDebounce(generateFormData()))
        } else {
            setSubsidyRate('0')
        }
    }

    const getMaxFeesNumberByPeriod = (): number => {
        if (paymentPeriod === 1) {
            return props.maxFees['1'].length
        }

        if (paymentPeriod === 2) {
            return props.maxFees['3'].length
        }

        if (paymentPeriod === 3) {
            return props.maxFees['6'].length
        }

        if (paymentPeriod === 4) {
            return props.maxFees['12'].length
        }

        return 0
    }

    const inputFirstFeeHandler = () => {
        setIsOpenDateModal(true)
    }

    const onClickInformationHandler = () => setIsOpenInformationAlert(true)


    const getIsDisabledSubmint = () => amountToRequest === '' || amountToRequest === '0' || isMaxFeeDebouncing || isDisabledCalendar
    
    const getMaxFeePeriodByPaymentPeriod = (): Array<{ number: number, max_amount: number, avg_fee: number, avg_fee_low_weight: number }> => {
        if (paymentPeriod === 0) return props.maxFees[1]
        if (paymentPeriod === 1) return props.maxFees[1]
        if (paymentPeriod === 2) return props.maxFees[3]
        if (paymentPeriod === 3) return props.maxFees[6]
        if (paymentPeriod === 4) return props.maxFees[12]
        return props.maxFees[1]
    }
    const getAverageFee = (): {
        higherFee: string,
        lowerFee: string
    } => {
        const feeFinded = getMaxFeePeriodByPaymentPeriod().find(fee => fee.number === Number(feeNumbers))
        if (feeFinded) return {
            higherFee: addSeparatorsToNumber(feeFinded.avg_fee),
            lowerFee: addSeparatorsToNumber(feeFinded.avg_fee_low_weight)
        }
        else return {
            higherFee: '',
            lowerFee: ''
        }
    }
    return (
        <>
            <IonAlert
                isOpen={isOpenInformationAlert}
                onDidDismiss={() => setIsOpenInformationAlert(false)}
                header={''}
                message={"Beneficio de poder postergar el pago de los gastos a la primera cuota en lugar de al momento 0."}
                buttons={['Aceptar']}
            />
            <IonRow><br></br><br></br><br></br></IonRow>
            <IonRow>
                <IonCol className="ion-text-center"><IonTitle className="title-of-payment-period">ESTRUCTURA DEL CRÉDITO (#{props.currentPolicyId})</IonTitle></IonCol>
            </IonRow>
            <IonRow><br></br><br></br><br></br></IonRow>
            <IonRow>
                <IonCol />
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Monto a Solicitar ({props.currentCurrency})</IonLabel>
                        <input
                            data-testid="amountInput"
                            value={amountToRequest}
                            placeholder="Campo Requerido"
                            onChange={(e: any) => amountHandler(e?.target?.value ? e?.target?.value : '')}
                            type="text"
                            className="native-input sc-ion-input-md ion-text-end"
                        />
                        <IonButton data-testid="maxAmountButton" onClick={() => maxAmountHandler()} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end">Solicitar Máximo</IonButton>
                    </IonItem>
                </IonCol>
                <IonCol />
            </IonRow>
            <IonRow><br></br><br></br><br></br></IonRow>
            <IonRow>
                <IonCol></IonCol>
                <IonCol className="ion-text-center">
                    <IonLabel>Periodicidad de Pago</IonLabel>
                    {props.paymentPeriod.length === 1 ? (
                        <IonTitle>{
                            props.paymentPeriod[0] === 1 ? 'Mensual' :
                                props.paymentPeriod[0] === 2 ? 'Trimestral' :
                                    props.paymentPeriod[0] === 3 ? 'Semestral' :
                                        props.paymentPeriod[0] === 4 ? 'Anual' : ''
                        }</IonTitle>
                    ) : null}

                    {props.paymentPeriod.length > 1 ? (
                        <IonSegment scrollable disabled={amountToRequest === "0"} value={String(paymentPeriod)} /*onIonChange={e => paymentPeriodHandler(e.detail.value)}*/ color="secondary">
                            {props.paymentPeriod.find(period => period === 1) && (
                                <IonSegmentButton data-testid="paymentMensualButton" onClick={e => paymentPeriodHandler("1")} value="1">
                                    <IonLabel>Mensual</IonLabel>
                                </IonSegmentButton>
                            )}
                            {props.paymentPeriod.find(period => period === 2) && (
                                <IonSegmentButton data-testid="paymentTrimestralButton" onClick={e => paymentPeriodHandler("2")} value="2">
                                    <IonLabel>Trimestral</IonLabel>
                                </IonSegmentButton>
                            )}
                            {props.paymentPeriod.find(period => period === 3) && (
                                <IonSegmentButton data-testid="paymentSemestralButton" onClick={e => paymentPeriodHandler("3")} value="3">
                                    <IonLabel>Semestral</IonLabel>
                                </IonSegmentButton>
                            )}
                            {props.paymentPeriod.find(period => period === 4) && (
                                <IonSegmentButton data-testid="paymentAnualButton" onClick={e => paymentPeriodHandler("4")} value="4">
                                    <IonLabel>Anual</IonLabel>
                                </IonSegmentButton>
                            )}
                        </IonSegment>
                    ) : null}
                </IonCol>
                <IonCol></IonCol>

            </IonRow>
            <IonRow><br></br><br></br><br></br></IonRow>

            <IonRow>
                <IonCol />
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Fecha primera cuota</IonLabel>
                        <IonInput class="first-installment-date-input" disabled={isDisabledCalendar} value={moment(firstFeeDate).format('MM-YYYY')} readonly={true} onClick={() => inputFirstFeeHandler()} />
                        <MyDateModalComponent
                            isOpen={isOpenDateModal}
                            onClose={() => setIsOpenDateModal(false)}
                            onChange={dateHandler}
                            title="Fecha primera cuota"
                            value={firstFeeDate ? firstFeeDate : new Date()}
                            minDate={new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(paymentPeriod), 'month').toJSON())}
                            maxDate={new Date(moment().add(1, 'days').add(getMounthNumberOfPeriod(paymentPeriod) + 3, 'month').toJSON())}
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Cuotas</IonLabel>
                        
                        {props.fixedFees === 'Min' || props.fixedFees === 'Max' ? (
                            <IonTitle>{feeNumbers}</IonTitle>
                        ) : null}

                        {props.fixedFees === '' ? (
                            <>
                                <input
                                    data-testid="inputFee"
                                    disabled={paymentPeriod === 0}
                                    onChange={(e: any) => feeInputHandler(e.target && e.target.value ? e.target.value : '')}
                                    value={feeNumbers}
                                    type="number"
                                    placeholder="Campo Requerido"
                                    style={{ padding: '0px 0px 10px 0px' }}
                                    className="ion-text-end native-input sc-ion-input-md"
                                />
                                <IonButton className="fees-arrow-up-button" data-testid="arrowUpFee" disabled={amountToRequest === "0" || paymentPeriod === 0} onClick={() => feeUpArrowHandler()} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                <IonButton className="fees-arrow-down-button" data-testid="arrowDownFee" disabled={amountToRequest === "0" || paymentPeriod === 0} onClick={() => feeDownArrowHandler()} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                            </>
                        ) : null}
                    </IonItem>
                </IonCol>
                <IonCol />
            </IonRow>

            {!props.breakMode && (
                <>
                    <IonRow><br></br><br></br><br></br></IonRow>
                    <IonRow>
                        <IonCol />
                        <IonCol>
                            <IonItem>
                                <IonLabel>Gastos en la primera cuota</IonLabel>
                                <IonButton className="expenses-in-the-first-installment-button-info" size="small" fill="clear" onClick={() => onClickInformationHandler()}><IonIcon icon={informationCircle} /></IonButton>
                                <IonToggle className="expenses-in-the-first-installment-toggle" disabled={amountToRequest === "0" || !props.isSeparateExpensesEnabled} color="secondary" checked={separateExpenses} onIonChange={() => separateExpensesHandler()} />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Subsidiar</IonLabel>
                                {!props.isBlockedSubsidyRate && (
                                    <IonToggle data-testid="subsidizeToggle" color="secondary" checked={subsidize} onClick={() => subsidizeHandler()} />
                                )}

                                {props.isBlockedSubsidyRate && (
                                    <IonToggle disabled={true} color="secondary" checked={true} />
                                )}
                            </IonItem>
                        </IonCol>
                        <IonCol />
                    </IonRow>
                    <IonRow><br /></IonRow>

                    <IonRow>
                        <IonCol></IonCol>
                        <IonCol className="ion-text-center">
                            {props.isBlockedSubsidyRate && (
                                <IonItem>
                                    <IonLabel position="stacked">Salir a tasa %</IonLabel>
                                    <input
                                        disabled={true}
                                        value={props.subsidyRate}
                                        type="number"
                                        style={{ padding: '0px 0px 10px 0px' }}
                                        className="ion-text-end native-input sc-ion-input-md"
                                    />
                                    <IonButton disabled={true} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                    <IonButton disabled={true} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                                </IonItem>
                            )}

                            {subsidize && !props.isBlockedSubsidyRate && (
                                <IonItem>
                                    <IonLabel position="stacked">Salir a tasa %</IonLabel>
                                    <input
                                        data-testid="subsidizeInput"
                                        onChange={(e: any) => subsidyRateInputHandler(e.target.value ? e.target.value : '')}
                                        value={subsidyRate}
                                        type="number"
                                        placeholder="Campo Requerido"
                                        style={{ padding: '0px 0px 10px 0px' }}
                                        className="ion-text-end native-input sc-ion-input-md"
                                    />
                                    <IonButton data-testid="subsidizeUpArrow" disabled={getIsDisabledSubsidize()} onClick={() => subsidyRateUpArrowHandler()} slot="end" color="light" style={{ margin: '20px 0px 0px 0px' }}><IonIcon icon={arrowUp} /></IonButton>
                                    <IonButton data-testid="subsidizeDownArrow" disabled={getIsDisabledSubsidize()} onClick={() => subsidyRateDownArrowHandler()} color="light" style={{ margin: '20px -15px 0px 0px' }} slot="end"><IonIcon icon={arrowDown} /></IonButton>
                                </IonItem>
                            )}
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    <IonRow><br /></IonRow>
                </>
            )}

            <IonRow><br></br><br></br><br></br></IonRow>

            {props.isAymetricFees ? (
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                Cuota promedio menor:
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonTitle>${getAverageFee().lowerFee}</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                Cuota promedio mayor:
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonTitle>${getAverageFee().higherFee}</IonTitle>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol></IonCol>
                </IonRow>
            ) : (
                <>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            Cuota promedio:
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonTitle>${getAverageFee().higherFee}</IonTitle>
                        </IonCol>
                    </IonRow>
                </>
            )}

            <IonRow>
                <IonCol className="ion-text-center">
                    <IonButton color="primary" data-testid="simulateButton" disabled={getIsDisabledSubmint()} onClick={() => onSubmitHandler()}>Simular</IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <br /><br /><br />
                </IonCol>
            </IonRow>
        </>
    )
}
