import { ILoanRequestResponse, loadRequestsResponseAdapter, ILoanRequest, warrantyResponseAdapter, IWarranty, IFollowUpNotes, IFee, feeResponseAdapter, IWarrantyResponse, IFeeResponse, IFollowUpNotesResponse } from './loanRequest.model.store'
import { IApiClientResponse, TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'
import { IDownloandPdfRequest } from '../simulate/simulate.model.store'
import { IDownloadPDFRequest, IDownloadPDFResponse, TGetDocumentationResponse } from '../onBoarding/onBoarding.model.store'
import { setup } from '../../utils/setup'

export const useLoanRquestService = () => {

    const baseApiClient = useApiClient()
    const baseApiClientControlPanel = useApiClient() // todo: eliminar uno de los clientes

    const getFollowUpNotesService = async (id: number): TApiClientResponse<Array<IFollowUpNotes>> => {
        const response = await baseApiClient.get<IFollowUpNotesResponse>(`${setup.apiBaseUrl}/v1/loan-request/${id}/follow-up-notes`)
        if (response.isSuccess) return { ...response, data: response.data.data }
        else return { ...response, data: response.data.data }
    }

    const getFeeService = async (id: number): TApiClientResponse<IFee> => {
        const response = await baseApiClientControlPanel.get<IFeeResponse>(`${setup.apiBaseUrlControlPanel}/v1/loan-request/${id}/fee`)
        if (response.isSuccess) return { ...response, data: feeResponseAdapter(response.data) }
        else return { ...response, data: feeResponseAdapter(response.data) }
    }

    // const getOriginationExpensService = async (id: number) : Promise<void> => {
    //     await baseApiClientControlPanel.get(`${setup.apiBaseUrlControlPanel}/v1/loan-request/${id}/origination-expense`)
    // }

    const getWarrantyService = async (id: number): TApiClientResponse<Array<IWarranty>> => {
        const response = await baseApiClientControlPanel.get<IWarrantyResponse>(`${setup.apiBaseUrlControlPanel}/v1/loan-request/${id}/warranty`)
        if (response.isSuccess) return { ...response, data: warrantyResponseAdapter(response.data.data.warranty) }
        else return { ...response, data: warrantyResponseAdapter(response.data.data.warranty) }
    }

    const getLoanRequests = async (): TApiClientResponse<Array<ILoanRequest>> => {
        const response = await baseApiClient.get<ILoanRequestResponse>(`${setup.apiBaseUrl}/v1/loan-request`)
        if (response.isSuccess) return { ...response, data: loadRequestsResponseAdapter(response.data) }
        return { ...response, data: loadRequestsResponseAdapter(response.data) }
    }

    const downloadLoanRequestFeePDF = async (simulteId: number, params: IDownloadPDFRequest): TApiClientResponse<IDownloadPDFResponse> => {
        const response = await baseApiClient.post<IDownloadPDFResponse>(
            `${setup.apiBaseUrl}/v1/loan-simulator/pdf2/${simulteId}`,
            params
        )
        return response
    }

    const getDocumentation = async (loanRequestId: number): TApiClientResponse<TGetDocumentationResponse> => {
        const response = await baseApiClient.get<TGetDocumentationResponse>(`${setup.apiBaseUrl}/v2.1/loan-request/${loanRequestId}/documents`)
        return response
    }

    const getLoanRquestDetail = async (id: number): TApiClientResponse<{
        warrantys: IWarranty[]
        fee: IFee
        followUpNotes: Array<IFollowUpNotes>
        documentation: TGetDocumentationResponse
    }> => {

        const [warrantysResponse, feeResponse, followUpNotesResponse, documentationResponse] = await Promise.all([
            getWarrantyService(id),
            getFeeService(id),
            getFollowUpNotesService(id),
            // getOriginationExpensService(id),
            getDocumentation(id)
        ])


        if (
            warrantysResponse.isSuccess &&
            feeResponse.isSuccess &&
            followUpNotesResponse.isSuccess &&
            documentationResponse.isSuccess
        ) {
            const response: IApiClientResponse<{
                warrantys: IWarranty[]
                fee: IFee
                followUpNotes: Array<IFollowUpNotes>
                documentation: TGetDocumentationResponse
            }> = {

                isSuccess: true,
                isError: false,
                data: {
                    warrantys: warrantysResponse.data,
                    fee: feeResponse.data,
                    followUpNotes: followUpNotesResponse.data,
                    documentation: documentationResponse.data,
                },
                status: 200,
                method: 'GET',
                url: 'varius',
                body: {}
            }

            return response
        } 
        
        
         
        else return {
            isSuccess: false,
            isError: true,
            data: {
                warrantys: warrantysResponse.data,
                fee: feeResponse.data,
                followUpNotes:followUpNotesResponse.data,
                documentation:documentationResponse.data,
            },
            status: 500,
            method: 'GET',
            url: 'varius',
            body: {}
        } 

    }

    return { getLoanRquestDetail, getLoanRequests, downloadLoanRequestFeePDF, getDocumentation }
}