import { IonLoading, useIonViewDidEnter } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import React from 'react'
import { useHistory } from 'react-router'
import { CreditCardComponent } from '../components/CreditCard.component'
import { ITableGeneratorComponent, TableGeneratorComponent } from '../components/TableGenerator.component'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import { ICreditStore } from '../stores/credit/credit.interface.store'
import { INotificationStore } from '../stores/notification/notification.use.store'
import { ISetupStore } from '../stores/setup/setup.interface.store'
import { IUserStore } from '../stores/user/user.use.store'

interface ICreditsPageProps {
    creditStore: ICreditStore
    setupStore: ISetupStore
    userStore: IUserStore
    notificationStore: INotificationStore
    onApiClientError: () => void
}

const CreditsPage: React.FC<ICreditsPageProps> = props => {

    const history = useHistory()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    useIonViewDidEnter(() => {
        onInit()
    })

    const onInit = async () => {
        setIsLoading(true)
        const result = await props.creditStore.getCreditList()
        if (result.isError) props.onApiClientError()
        setIsLoading(false)
    }

    const onActionTableHandler = (action: string, key: string) => history.push(`/credits/${Number(key)}`)

    const getHeaderForTable = () => ['ID solicitud', 'CUIT Soliciante', 'Nombre Solicitante', 'Total del credito', 'Acciones']

    const getListForTable = (): ITableGeneratorComponent['bodyList'] => props.creditStore.creditList.map(credit => [
        {
            type: 'text',
            text: `#${credit.id}`
        },
        {
            type: 'text',
            text: `${credit.customer.tax_identification_number}`
        },
        {
            type: 'text',
            text: `#${credit.customer.alias}`
        },
        {
            type: 'text',
            text: `#${credit.loan_request.amount} ${credit.loan_request.currency}`
        },
        {
            type: 'actions',
            key: String(credit.id),
            actions: [{
                type: 'detail',
                title: 'Ver Detalle',
                text: 'Detalle',
                fill: 'clear',
                color: 'primary'
            }]
        }
    ])

    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <IonLoading
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Cargando...'}
            />
            {props.setupStore.isMobile ? (
                <>
                    {props.creditStore.creditList.map(credit => <CreditCardComponent onClick={(creditId) => onActionTableHandler('', String(creditId))} credit={credit} key={credit.id} />)}
                </>
            ) : (
                <TableGeneratorComponent
                    headerList={getHeaderForTable()}
                    bodyList={getListForTable()}
                    onAction={onActionTableHandler}
                />
            )}
        </ContentWithNavigationLayout>
    )
}

export { CreditsPage }