import { IMaxFeesResponse } from "../../../../../stores/onBoarding/onBoarding.model.store"
import { addPriceFormatToNumberValue } from "../../../../FormGenerator/InputGenerator.component"

export const getMaxFeePeriodByPaymentPeriod = (paymentPeriod: 0 | 2 | 1 | 3 | 4, maxFees: IMaxFeesResponse): Array<{ number: number, max_amount: number, avg_fee: number, avg_fee_low_weight: number }> => {
    if (paymentPeriod === 0) return maxFees[1]
    if (paymentPeriod === 1) return maxFees[1]
    if (paymentPeriod === 2) return maxFees[3]
    if (paymentPeriod === 3) return maxFees[6]
    if (paymentPeriod === 4) return maxFees[12]
    return maxFees[1]
}

export const getMaxAmountbyFeeNumber = (paymentPeriod: 0 | 2 | 1 | 3 | 4,  maxFees: IMaxFeesResponse, feeNumbers: number): number => {
    const feeFinded = getMaxFeePeriodByPaymentPeriod(paymentPeriod, maxFees).find(fee => fee.number === feeNumbers)
    if (feeFinded) return feeFinded.max_amount
    return 0
}


export const getMaxAmountHandler = (paymentPeriod: 0 | 2 | 1 | 3 | 4,  maxFees: IMaxFeesResponse, feeNumbers: number): string => {
    return addPriceFormatToNumberValue(String(getMaxAmountbyFeeNumber(paymentPeriod,  maxFees, feeNumbers)))
}


export const getMaxFeesNumber = (fees: IMaxFeesResponse, paymentPeriod: 1 | 2 | 3 | 4 ): number => {

    if (paymentPeriod === 1) {
        return fees[1].length
    }
    if (paymentPeriod === 2) {
        return fees[3].length
    }
    if (paymentPeriod === 3) {
        return fees[6].length
    }
    if (paymentPeriod === 4) {
        return fees[12].length
    }

    return 0
}
