import { IonCard, IonCardHeader, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonRow, IonSegment, IonSegmentButton, IonTitle, useIonViewDidEnter } from '@ionic/react'
import React from 'react'
import { useParams } from 'react-router'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import { useCreditHelper } from '../stores/credit/credit.helper.store'
import { ICreditStore } from '../stores/credit/credit.interface.store'
import { ICreditModel } from '../stores/credit/credit.model.store'
import { dateFormaterUtil } from '../utils/dateFormater.util'
import { TableGeneratorComponent, ITableGeneratorComponent } from '../components/TableGenerator.component'
import { INotificationStore } from '../stores/notification/notification.use.store'
import { IUserStore } from '../stores/user/user.use.store'


interface ICreditDetailPageProps {
    creditStore: ICreditStore
    notificationStore: INotificationStore
    userStore: IUserStore
    onApiClientError: () => void
}

const CreditDetailPage: React.FC<ICreditDetailPageProps> = props => {

    const params = useParams<{ id: string }>()

    const creditHelper = useCreditHelper()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [currentCredit, setCurrentCredit] = React.useState<ICreditModel>(creditHelper.createCredit())
    const [showComponent, setShowComponent] = React.useState<'Fees' | 'Payments'>('Fees')

    useIonViewDidEnter(() => {
        onInit()
    })

    const onInit = async () => {
        setIsLoading(true)
        const result = await props.creditStore.getCreditDetail(Number(params.id))
        if (result.isError) props.onApiClientError()
        setCurrentCredit(result.data)
        setIsLoading(false)
    }

    const getHeaderForFeesTable = (): Array<string> => ['Nro', 'Vencimiento', 'Capital', 'Interés', 'Sub Total', 'Cuota Pura', 'Cobranza', 'Total a Pagar']
    const getBodyForFeesTable = (): ITableGeneratorComponent['bodyList'] => currentCredit.installments.map(installment => [
        {
            type: 'text',
            text: `${installment.number}`
        },
        {
            type: 'text',
            text: `${dateFormaterUtil(installment.date)}`
        },
        {
            type: 'text',
            text: `${installment.detail.capital}`
        },
        {
            type: 'text',
            text: `${installment.detail.interest}`
        },
        {
            type: 'text',
            text: `${installment.detail.subtotal}`
        },
        {
            type: 'text',
            text: `${installment.detail.pure_fee}`
        },
        {
            type: 'text',
            text: `${installment.detail.fee_collection}`
        },
        {
            type: 'text',
            text: `${installment.amount}`
        }
    ])

    
    const getHeaderForPaymentsTable = () => ['Fecha', 'Monto', 'Metodo', 'Tipo de Cambio']

    const getBodyForPaymentsTable = (): ITableGeneratorComponent['bodyList'] => currentCredit.payments.map(payment => [
        {
            type: 'text',
            text: `${dateFormaterUtil(payment.date.toJSON())}`
        },
        {
            type: 'text',
            text: `${payment.net_amount}`
        },
        {
            type: 'text',
            text: `${payment.method}`
        },
        {
            type: 'text',
            text: `${payment.exchange_rate}`
        }
    ])

    const onClickSectionHandler = (value: String) => {
        if (value === 'Fees') setShowComponent('Fees')
        else setShowComponent('Payments')
    }


    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <IonLoading
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Cargando...'}
            />
            <br />
            <IonTitle className="ion-text-center">Credito</IonTitle>
            <IonList>
                <IonItem><IonLabel>ID:</IonLabel>#{currentCredit.id}</IonItem>
                <IonItem><IonLabel>Solicitud:</IonLabel>#{currentCredit.loan_request.id}</IonItem>
                <IonItem><IonLabel>Alias</IonLabel>{currentCredit.customer.alias}</IonItem>
                <IonItem><IonLabel>Identificacion</IonLabel>{currentCredit.customer.tax_identification_number}</IonItem>
                <IonItem><IonLabel>Monto</IonLabel>{currentCredit.loan_request.amount} {currentCredit.loan_request.currency}</IonItem>
            </IonList>

            <IonSegment onIonChange={e => onClickSectionHandler(e.detail.value ? e.detail.value : '')} value={showComponent}>
                <IonSegmentButton value="Fees">
                    <IonLabel>Cuotas</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='Payments'>
                    <IonLabel>Pagos</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            {showComponent === 'Fees' ? (
                <TableGeneratorComponent headerList={getHeaderForFeesTable()} bodyList={getBodyForFeesTable()} onAction={(type: string, key: string) => { }} />
            ) : null}

            {showComponent === 'Payments' ? (
                <TableGeneratorComponent headerList={getHeaderForPaymentsTable()} bodyList={getBodyForPaymentsTable()} onAction={(type: string, key: string) => { }} />
            ) : null}

        </ContentWithNavigationLayout>
    )
}

export { CreditDetailPage }