import { IonButton, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import { getCountryAlias } from '../../stores/setup/setup.use.store';
import { IInputsGenertors, IFormGeneratorComponent, IForm } from './FormGenerator.interface';
import { deletePointersToNumber, deleteSpecialCharacters, deleteTextToNumber, getIsErrorInputNumber, getIsValidCuitCuil, InputGenerator } from './InputGenerator.component';

const InputListGenertors = (props: IInputsGenertors) => {
    return (
        <IonRow className="ion-justify-content-between">
            {props.form.map((input, index) => (
                input.type !== 'title' ?
                    // <IonCol size="12" size-md={String(12 / props.columns)} size-lg={String(12 / props.columns)} style={{padding: '1% 5%' }} key={`inputNumber${input.id}Index${index}`}>
                        <InputGenerator
                            key={`inputNumber${input.id}Index${index}`}                          
                            id={input.id}
                            index={index}
                            columns={props.columns}
                            input={input}
                            form={props.form}
                            setData={props.setData}
                        />
                    // </IonCol>
                    :
                    <IonCol size="12" className="ion-text-center" style={{ padding: '2% 1% 1% 1%' }} key={`inputNumber${input.id}Index${index}`}>
                        <h3>{input.text}</h3>
                    </IonCol>
            ))}
        </IonRow>
    )
}

export const FormGeneratorComponent = (props: IFormGeneratorComponent) => {

    const onSubmitHandler = (onSubmit: Function) => onSubmit()

    const getIsDisabledSubmitButton = (form: IFormGeneratorComponent['form']) => form.some(input => {
        if(input.type === 'number') return getIsErrorInputNumber(input)
        if(input.type === 'cuilcuit' && getIsValidCuitCuil(input.value.replace("-", "").replace("-", "")) === false ) return true
        if(input.type === 'checkBoxMultiple' && input.required && JSON.parse(input.value).length === 0) return true
        if (input.type !== 'title' && input.type !== 'none' && input.required && !input.value) return true
        else return false
    })

    const setFormHandler = (form: IForm)=> {
        props.setForm(form)
    }

    return (
        <>
            <InputListGenertors form={props.form} setData={setFormHandler} columns={props.columns} />
            <IonRow>
                <br></br>
                <br></br>
            </IonRow>
            <IonRow>
                <IonCol className="ion-text-center">
                    <IonButton
                        disabled={getIsDisabledSubmitButton(props.form)}
                        color="primary"
                        onClick={() => onSubmitHandler(props.onSubmit)}
                    >
                        {props.submitText}
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <br></br>
                <br></br>
            </IonRow>
        </>
    )
}

export const getNumberValueToPriceInput = (number: string): string => {
    const integerString =
        getCountryAlias() === 'ar' ? number.split(',')[0] :
        getCountryAlias() === 'mx' ? number.split('.')[0] : ''
        
    const floatString =
        getCountryAlias() === 'ar' && number.split(',')[1] ? number.split(',')[1] :
        getCountryAlias() === 'mx' && number.split('.')[1] ? number.split('.')[1] : ''
    
    const integerStringFormated =
        getCountryAlias() === 'ar' ? integerString.split('.').join('') :
        getCountryAlias() === 'mx' ? integerString.split(',').join('') : integerString.split('.').join('')
    

    const floatStringFormated =
        getCountryAlias() === 'ar' && floatString ? `.${floatString}` :
        getCountryAlias() === 'mx' && floatString ? `.${floatString}` : ''
    
    return integerStringFormated + floatStringFormated
}

export const getValueToForm = (form: IFormGeneratorComponent['form'] , id: number): string => {
    const inputFinded = form.find(input=> input.id === id) 
    if(!inputFinded) return ''
    if(inputFinded && inputFinded.type === 'title' ) return inputFinded.text
    if(inputFinded && inputFinded.type === 'moneyWithCurrency') return ''
    if(inputFinded && inputFinded.type === 'price') {
        return getNumberValueToPriceInput(inputFinded.value)
    }

    if(inputFinded && (
        inputFinded.type === 'email' ||
        inputFinded.type === 'cuilcuit' || 
        inputFinded.type === 'text' ||
        inputFinded.type === 'number' ||
        inputFinded.type === 'options' ||
        inputFinded.type === 'vinculatorOptions' || 
        inputFinded.type === 'vinculedOptions'
    )) return inputFinded.value
    
    return ''
}