import { IonCard, IonCol, IonRow, IonSkeletonText } from '@ionic/react'
import React from 'react'
import { Col, Row } from './CheckPolicyResumeFilter.element'
import { ComercialPolicyCardCell, ComercialPolicyCardContainer, ComercialPolicySelectButton } from './ComercialPolicyCard.element'

const iterations = [1, 2, 3, 4, 5]

export const ComercialPolicyListSkeleton = () => {
    return (
        <>
            {iterations.map((iteration, index) => (
                <ComercialPolicyCardContainer key={index}>
                    <Row>
                        <ComercialPolicyCardCell width={10} aling="center">
                            <IonSkeletonText animated style={{ width: '80%'}} />
                        </ComercialPolicyCardCell>
                        <ComercialPolicyCardCell width={10} aling="center">
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </ComercialPolicyCardCell>
                        <ComercialPolicyCardCell width={65} aling="center" >
                            <IonSkeletonText animated style={{ width: '80%'}} />
                        </ComercialPolicyCardCell>
                        <ComercialPolicyCardCell width={15} aling="right" >
                            <IonSkeletonText animated style={{ height: '25px', borderRadius: '20px',  width: '80px', margin: '10px' }}/>
                        </ComercialPolicyCardCell>                      
                    </Row>
                    <Row>
                        <Col width={100}>
                            <IonSkeletonText animated style={{ width: '100%', height: '50px' }} />
                        </Col>
                    </Row>
                    <Row>
                        <ComercialPolicyCardCell width={25} aling="center">
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </ComercialPolicyCardCell>

                        <ComercialPolicyCardCell width={25} aling="center">
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </ComercialPolicyCardCell>
                        <ComercialPolicyCardCell width={25} aling="center" >
                            <IonSkeletonText animated style={{ width: '80%', height: '12px' }} />
                        </ComercialPolicyCardCell>

                        <ComercialPolicyCardCell width={25} aling="right">
                            <IonSkeletonText animated style={{ height: '35px', width: '100px', margin: '10px', borderRadius: '3px' }}/>
                        </ComercialPolicyCardCell>
                    </Row>
                </ComercialPolicyCardContainer>
            ))}
        </>
    )
}