import { IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle } from '@ionic/react'
import React from 'react'
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'

interface IFollowUpNotesDetailComponentProps {
    followUpNotes: ILoanRequest['followUpNotes']
}

export const FollowUpNotesDetailComponent: React.FC<IFollowUpNotesDetailComponentProps> = ({ followUpNotes }) => {
    return (
        <div>{
            followUpNotes.map(note => (
                <IonCard key={'note'+note.id}>
                    <IonCardHeader>
                        <IonCardSubtitle>{note.fecha}</IonCardSubtitle>
                        <IonCardSubtitle>
                            {note.usuario}
                        </IonCardSubtitle>
                        <IonCardTitle>{note.titulo}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {note.descripcion}
                    </IonCardContent>
                </IonCard>
            ))
        }</div>
    )
}