import React from 'react';
import { IonTitle, IonList, IonItem, IonLabel, IonContent } from '@ionic/react';
import { IProfileApiResponseAdapted } from '../stores/user/user.model.store';


export interface IProfileDetailComponentProps {
    profile: IProfileApiResponseAdapted['data']
}

export const ProfileDetailComponent: React.FC<IProfileDetailComponentProps> = props => {
    return (
        <IonContent>
            <IonList>
                <IonTitle>Perfil</IonTitle>
                <IonItem>
                    <IonLabel>
                        <h2>Nombre</h2>
                        <p>{props.profile.contact.fullName}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Email</h2>
                        <p>{props.profile.email}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>RFC</h2>
                        <p>{props.profile.contact.cuitCuil}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>DNI</h2>
                        <p>{props.profile.contact.dni}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Género</h2>
                        <p>{props.profile.contact.gender}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Fecha de nacimiento</h2>
                        <p>{props.profile.contact.birthDate}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Estado civil</h2>
                        <p>{props.profile.contact.maritalStatus}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Nacionalidad</h2>
                        <p>{props.profile.contact.nationality}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Politicamente expuesto</h2>
                        <p>{props.profile.contact.politic === 'true' ? 'SI' : 'NO'}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Tipo de actividad</h2>
                        <p>{props.profile.contact.activityType}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Es persona física</h2>
                        <p>{props.profile.contact.isPhysicalPerson}</p>
                    </IonLabel>
                </IonItem>
            </IonList>
            <br /><br />
            <IonList>
                <IonTitle>Direccion</IonTitle>
                <IonItem>
                    <IonLabel>
                        <h2>Provincia</h2>
                        <p>{props.profile.contact.addressState}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Ciudad</h2>
                        <p>{props.profile.contact.addressCity}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Código postal</h2>
                        <p>{props.profile.contact.addressPostalCode}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Calle</h2>
                        <p>{props.profile.contact.addressStreet}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Número</h2>
                        <p>{props.profile.contact.addressNumber}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Piso</h2>
                        <p>{props.profile.contact.addressFloor}</p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <h2>Departamento</h2>
                        <p>{props.profile.contact.addressDepto}</p>
                    </IonLabel>
                </IonItem>
            </IonList>
        </IonContent>
    )
}
