import StackDriverErrorReporter from 'stackdriver-errors-js';
import { setup } from '../../utils/setup'

const errorHandler = new StackDriverErrorReporter()


window.onerror = (errorMsg, url, lineNumber) => errorHandler.report(JSON.stringify({errorMsg, url, lineNumber}))

export const useLogStore = () => {
    const onSetUserEmail = (email: string) => errorHandler.setUser(email)
    const init = async () => {
        errorHandler.start({
            service: "merchant",
            key: setup.loggingApiKey,
            projectId: setup.projectId,
            disabled: setup.nodeEnv === 'development' ? true : false
        })
    }

    const sendReport = (e: any) => {
        errorHandler.report(JSON.stringify(e))
    }
    return { sendReport, init, onSetUserEmail }
}