import { props } from 'cypress/types/bluebird'
import React from 'react'
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    cursor: pointer;
`

export const CheckboxSquare = styled.div`
    display: block;
    padding: 1px;
    width: auto;
    border: 1px solid #707070;
    background: none;
    border-radius: 2px;
`


export const CheckboxSquareSelected = styled.div<{checked: boolean}>`
    display: block;
    padding: 7px;
    width: auto;
    border: none;
    background: ${props=> props.checked ? '#153F57' : 'none' };
    border-radius: 2px;
`



export const CheckboxLabel = styled.label`
    width: auto;
    font-size: 15px;
    padding-left: 15px;
`

interface ICheckBoxFilterProps {
    label: string
    onClick: () => void
    checked: boolean
}

export const CheckBoxFilter: React.FC<ICheckBoxFilterProps> = (props) => {

    return (
        <CheckboxContainer>
            <CheckboxSquare className={`checkbox(${props.label.split(' ').join('_')})`} onClick={props.onClick}>
                <CheckboxSquareSelected checked={props.checked}/>
            </CheckboxSquare>
            <CheckboxLabel>{props.label}</CheckboxLabel>
        </CheckboxContainer>
    )
}


export const CheckBoxList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const CheckBoxTitle = styled.h1`
    width:100%;
    text-align: left;
    padding-left: 15px;
    color: #818181;
    font-size: 17px;
`