import React from 'react'
import { IonCol, IonItem, IonLabel, IonRow } from '@ionic/react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

import './ComercialPolicyTypeFilter.style.css'

//input-range__track--active

interface IComercialPolicyTermCapacityFilterProps {
    countryAlias: 'ar' | 'mx'

    termValue: { min: number, max: number }
    capacityValue: { min: number, max: number }

    termMax: number
    termMin: number

    capacityMax: number
    capacityMin: number

    onChangeTermValue: (value: { min: number, max: number }) => void
    onChangeCapacityValue: (value: { min: number, max: number }) => void
}

export const ComercialPolicyTermCapacityFilter: React.FC<IComercialPolicyTermCapacityFilterProps> = (props) => {

    React.useEffect(() => {
        onRangeTermValue({
            max: props.termMax,
            min: props.termMin
        })
    }, [props.termMax, props.termMin])

    React.useEffect(() => {
        onRangeCapacityValue({
            max: props.capacityMax,
            min: props.capacityMin
        })
    }, [props.capacityMax, props.capacityMin])

    const onRangeTermValue = (value: any) => {
        props.onChangeTermValue(value)
    }

    const onRangeCapacityValue = (value: any) => {
        props.onChangeCapacityValue(value)
    }

    return (
        <div>
            <IonRow>
                <IonCol>
                    <h5 style={{color: '#7B7B7B'}}>Plazo</h5>

                    <br></br>

                    { props.termMin !== props.termMax && props.termValue.min >= props.termMin && props.termValue.max <= props.termMax ?
                    (
                        <InputRange
                            formatLabel= {(value) => `${Math.trunc(value/30)} meses`}
                            maxValue={props.termMax}
                            minValue={props.termMin}
                            value={props.termValue}
                            onChange={onRangeTermValue}
                        />

                    ) : null}
                </IonCol>
            </IonRow>
            <br></br><br></br>
            <IonRow>
                <IonCol>
                    <h5 style={{color: '#7B7B7B'}}>{props.countryAlias === 'ar' ? 'Aforo' : 'Enganche'}</h5>
                    <br></br>

                    {props.capacityMin === props.capacityMax ? (
                        props.capacityMax
                    ) : null}

                    {props.capacityMin !== props.capacityMax && props.capacityValue.min >= props.capacityMin && props.capacityValue.max <= props.capacityMax ? (
                        <InputRange
                            formatLabel= {(value) => `${value} %`}
                            maxValue={props.capacityMax}
                            minValue={props.capacityMin}
                            value={props.capacityValue}
                            onChange={onRangeCapacityValue}
                        />

                    ) : null}
                    <br></br><br></br>
                </IonCol>
            </IonRow>
        </div>
    )
}