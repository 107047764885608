import { IonPage, IonHeader, IonContent, IonButton, IonButtons, IonIcon, IonMenuButton, IonTitle, IonToolbar, IonBadge, IonModal, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonItem, IonToggle, IonLabel, IonLoading } from '@ionic/react';
import React, {useEffect} from 'react';
import { useHistory } from 'react-router';

import { useMediaQuery } from "react-responsive";

import logo from '../assets/logo.svg'
import darkLogo from '../assets/logo-dark-mode.svg'

import { chevronBack, notifications } from 'ionicons/icons';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { dateFormaterUtil } from '../utils/dateFormater.util';
import { INotificationModel } from '../stores/notification/notification.model.store';
import { notificationFactory } from '../stores/notification/notification.helper.store';
import { NoDataComponentWithAction } from '../components/NoData.component';
import { IUserStore } from '../stores/user/user.use.store';


interface IContentWithNavigationLayout {
    children: React.ReactNode
    goBackText?: string
    goBackUrl?: string
    notificationStore: INotificationStore
    userStore: IUserStore
}

export const ContentWithNavigationLayout: React.FC<IContentWithNavigationLayout> = (props) => {

    const systemPrefersDark = useMediaQuery({
        query: "(prefers-color-scheme: dark)"
    }, undefined,
        prefersDark => {
            setIsDark(prefersDark);
        })

    const [isDark, setIsDark] = React.useState(systemPrefersDark);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isOpenNotifications, setIsOpenNotifications] = React.useState<boolean>(false)
    const [showOnlyUnread, setShowOnlyUnread] = React.useState<boolean>(false)
    const [isShowNotificationList, setIsShowNotificationList] = React.useState<boolean>(true)
    const [isShowNotificationDetail, setIsShowNotificationDetail] = React.useState<boolean>(false)
    const [notificationSelected, setNotificationSelected] = React.useState<INotificationModel>(notificationFactory())


    const history = useHistory()

    useEffect(()=>{
        elPrompt()
    }, [])


    const elPrompt = () => {

        ////////////////////////////////////


        // var promptEvent: any;
        // // Capture event and defer
        // window.addEventListener('beforeinstallprompt', function (e) {
        //     e.preventDefault();
        //     promptEvent = e;
        //     listenToUserAction();
        // });

        // // listen to install button clic
        // function listenToUserAction() {
        //     const installBtn: any = document.querySelector(".install-btn");
        //     console.log(installBtn)
        //     installBtn.addEventListener("click", presentAddToHome);
        // }

        // // present install prompt to user
        // function presentAddToHome() {
        //     console.log('hice click che')
        //     promptEvent.prompt();  // Wait for the user to respond to the prompt
        //     promptEvent.userChoice
        //         .then((choice: any) => {
        //             if (choice.outcome === 'accepted') {
        //                 console.log('User accepted');
        //             } else {
        //                 console.log('User dismissed');
        //             }
        //         })
        // }

    }

    const onRedirectHandler = () => {
        history.push(props.goBackUrl ? props.goBackUrl : '')
    }

    const onOpenNotificationsHandler = () => {
        setIsOpenNotifications(true)
    }

    const unreadNotificationsNumberComputed: number = props.notificationStore.notificationList.reduce((acumulator, current) => {
        if (current.read === false) acumulator = acumulator + 1
        return acumulator
    }, 0)


    const notificationListFilteredComputed = props.notificationStore.notificationList.filter(notification => {
        if (showOnlyUnread && notification.read === false) return true
        if (showOnlyUnread && notification.read === true) return false
        return true
    })

    const onChangeUnreadToggleHandler = () => {
        setIsLoading(true)
        setShowOnlyUnread(!showOnlyUnread)
        setIsLoading(false)
    }

    const onChangeNotificationReadToogleHandler = async (notification: INotificationModel) => {
        setIsLoading(true)
        await props.notificationStore.markReadNotification(notification, !notification.read)
        setIsLoading(false)
    }

    const onDeleteNotificationHandler = async (notification: INotificationModel) => {
        setIsLoading(true)
        await props.notificationStore.deleteNotification(notification)
        setNotificationSelected(notificationFactory())
        setIsShowNotificationList(true)
        setIsShowNotificationDetail(false)
        setIsLoading(false)
    }

    const onShowNotificationDetail = async (notification: INotificationModel) => {
        if (notification.read === false) {
            setIsLoading(true)
            const notificationAutoMarkedWhenShowDetail: INotificationModel = { ...notification, read: true }
            await props.notificationStore.markReadNotification(notification, true)
            setNotificationSelected(notificationAutoMarkedWhenShowDetail)
            setIsShowNotificationList(false)
            setIsShowNotificationDetail(true)
            setIsLoading(false)
            return
        }
        setIsLoading(true)
        setNotificationSelected(notification)
        setIsShowNotificationList(false)
        setIsShowNotificationDetail(true)
        setIsLoading(false)
    }

    const onGoBackToNotificationList = () => {
        setIsShowNotificationList(true)
        setIsShowNotificationDetail(false)
        setNotificationSelected(notificationFactory())
    }

    return (
        <IonPage>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Cargando...'}
            />
            <IonModal
                isOpen={isOpenNotifications}
                swipeToClose={true}
                onDidDismiss={() => true}
                backdropDismiss={false}
                cssClass="my-small-modal"
            >
                <IonHeader>
                    <IonToolbar>
                        <IonRow>
                            <IonCol size="6"><IonTitle>Notificaciones</IonTitle></IonCol>
                        </IonRow>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpenNotifications(false)}>CERRAR</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>

                    {/* <IonToolbar>
                        <IonItem>
                            <IonLabel slot="end">Ver solo notificaciones no leidos</IonLabel>
                            <IonToggle slot="end" checked={showOnlyUnread} onIonChange={onChangeUnreadToggleHandler} />
                        </IonItem>
                    </IonToolbar> */}



                    {isShowNotificationList ? notificationListFilteredComputed.map(notification => (
                        <IonCard key={notification.id}>
                                <IonRow>
                                    <IonCol size="12" style={{padding: '10px 10px 0px 10px', margin: '0px'}}>
                                        <h5 style={{padding: '0px', margin: '0px'}}>{dateFormaterUtil(notification.created_at)}</h5>
                                    </IonCol>
                                    <br/>
                                    <IonCol size="12" style={{padding: '10px 10px 0px 10px', margin: '0px'}}>
                                        <h4 style={{padding: '0px', margin: '0px'}}>{notification.title}</h4>
                                    </IonCol>
                                </IonRow>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <IonRow>
                                            <IonLabel style={{padding: '8px'}} color={notification.read ? 'primary' : 'medium'}>Leido</IonLabel><IonToggle onIonChange={() => onChangeNotificationReadToogleHandler(notification)} checked={notification.read} />
                                        </IonRow>
                                    </IonCol>
                                    <IonCol className='ion-text-right'>
                                        <IonButton onClick={() => onShowNotificationDetail(notification)} color='primary'>Ver</IonButton>
                                        <IonButton onClick={() => onDeleteNotificationHandler(notification)} color='danger'>Borrar</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    )) : null}

                    {isShowNotificationDetail ? (
                        <>
                            {/* <IonToolbar> */}
                                <IonRow>
                                    <IonCol>
                                        <IonButton size='small' onClick={() => onGoBackToNotificationList()} fill="clear">
                                            <IonIcon icon={chevronBack} />
                                            Volver a la lista de Notificaciones
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            {/* </IonToolbar> */}
                            <IonCard>
                                <IonRow style={{padding: '0px', margin: '0px'}}>
                                    <IonCol style={{padding: '0px 10px 0px 10px', margin: '0px'}} size="12" sizeMd="6" sizeLg="6">
                                        <h5>{dateFormaterUtil(notificationSelected.created_at)}</h5>
                                    </IonCol>
                                    <IonCol style={{padding: '0px 10px 0px 10px', margin: '0px'}} size="12" sizeMd="6" sizeLg="6">
                                        <h4>{notificationSelected.title}</h4>
                                    </IonCol>
                                </IonRow>
                                <hr/>
                                <IonRow style={{padding: '0px', margin: '0px'}}>
                                    <IonCol style={{padding: '0px 0px 0px 10px', margin: '0px'}}>
                                        <div dangerouslySetInnerHTML={{ __html: notificationSelected.content }} />
                                    </IonCol>
                                </IonRow>
                                <IonItem lines="none">
                                    <IonButton slot="end" onClick={() => onDeleteNotificationHandler(notificationSelected)} color='danger'>Borrar</IonButton>
                                </IonItem>
                            </IonCard>
                        </>
                    ) : null}

                    {props.notificationStore.notificationList.length === 0 ? (
                        <NoDataComponentWithAction
                            title='Sin Notificaciones'
                            message=''
                            button='Cerrar'
                            onClickButton={() => setIsOpenNotifications(false)}
                        />
                    ) : null}
                </IonContent>
            </IonModal>



            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton className="MainMenuButton" />
                    </IonButtons>
                    <IonTitle>
                        {props.userStore.profile.company.useCustomLogo === false && isDark ? (
                            <img src={darkLogo} alt="Siembro" style={{ width: '100px', marginTop: '5px' }} />
                        ) : null}

                        {props.userStore.profile.company.useCustomLogo === false && isDark === false ? (
                            <img src={logo} alt="Siembro" style={{ width: '100px', marginTop: '5px' }} />
                        ) : null}

                        {props.userStore.profile.company.useCustomLogo === true ? (
                            <img src={props.userStore.profile.company.logoUrl} alt="Siembro" style={{ width: '100px', marginTop: '5px' }} />
                        ) : null}

                    </IonTitle>
                    <IonButtons slot="secondary" >                        
                        {/* <button className='install-btn'>PROMT</button> */}
                        <IonBadge color="medium">{unreadNotificationsNumberComputed}</IonBadge>
                        <IonButton onClick={() => onOpenNotificationsHandler()} disabled={props.notificationStore.notificationList.length == 0}>
                            <IonIcon slot="icon-only" icon={notifications} />
                        </IonButton>
                    </IonButtons>

                </IonToolbar>
                {props.goBackUrl && (
                    <IonToolbar>
                        <IonButton onClick={() => onRedirectHandler()} fill="clear" expand="block" style={{ float: 'left' }}>
                            <IonIcon icon={chevronBack} />
                            Volver a la lista de Simulaciones
                        </IonButton>
                    </IonToolbar>
                )}
            </IonHeader>
            <IonContent>
                {props.children}
            </IonContent>
        </IonPage>
    )
}

