import styled from 'styled-components'

export const InputCheckBox = styled.div<{isChecked: boolean}>`
    display: block !important;
    padding: 1px 1px;
    width: 20px;
    background: ${props=> props.isChecked ? '#7F7' : '#ccc'};
    color: ${props=> props.isChecked ? '#7F7' : '#ccc'};
    box-shadow: 0.5px 1px 3px grey ;
    cursor: pointer;
    :before{
        content:".";
    };

`

export const InputCheckBoxRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
`

export const InputCheckBoxLabel = styled.div`
    display: block;
    width: auto;
    padding-left: 10px;
`


export const InputCheckBoxPlaceholder = styled.div<{isError: boolean}>`
    color: ${props => props.isError ? 'red': '#aaa'};
    margin-bottom: 10px;
    text-align: right;
    align-content: right;
`
