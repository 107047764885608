import { IonCard, IonCardContent, IonRow, IonCol, IonProgressBar, IonBadge, IonButton } from '@ionic/react';
import React from 'react';
import { ILoanRequest } from '../stores/loanRequest/loanRequest.model.store'
import { addSeparatorsToNumber } from '../utils/number.util';
import { LoanRequestCardComponent } from './LoanRequestCard.component';
import { LoanRequestCardSekeletonComponent } from './LoanRequestCardSkeleton.component';
import { TableGeneratorComponent, ITableGeneratorComponent } from './TableGenerator.component'

interface LoanRequestListComponentProps {
  loanRequests: Array<ILoanRequest>
  onLoanRequestClick(id: number): void
  isMobile: boolean
  isLoading: boolean
}

export const LoanRequestListComponent: React.FC<LoanRequestListComponentProps> = (props) => {

  const getPercent = (loanRequest: ILoanRequest) => {
    const currentStep = loanRequest.stageDays.find(step => step.current)
    if (!currentStep) return 0

    const currentPosition: number = loanRequest.circuit_info.stages.findIndex(stages => stages.name === currentStep.stageName) + 1
    const totalSteps = loanRequest.circuit_info.stages.length + 1
    const porcent = currentPosition / totalSteps

    return porcent
  }

  const getStatusColor = (status: string) => {
    return status === 'ACTIVA' ? 'success'
      : status === 'RECHAZADA' ? 'danger'
        : status === 'CADUCADA' ? 'warning'
          : 'light'
  }

  const onActionTableHandler = (action: string, key: string) => {
    props.onLoanRequestClick(Number(key))
  }

  const getHeaderForTable = (): Array<string> => ['Id', 'Status', 'Progreso', 'Fecha', 'Cliente', 'Producto', 'Monto', 'Acciones']
  
  const createSekeletonRows = () => {
    let skeletonRows: ITableGeneratorComponent['bodyList'] = []

    for (let i = 0; i < 15; i++) {
      skeletonRows.push([
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        },
        {
          type: 'skeleton'
        }
      ])
    }

    return skeletonRows

  }

  const getListForTable = (loanRequests: Array<ILoanRequest>): ITableGeneratorComponent['bodyList'] =>
    props.isLoading === true ? createSekeletonRows()
      : loanRequests.map(loanRequest => {
        return [
          {
            type: 'text',
            text: `#${loanRequest.id}`
          },
          {
            type: 'tags',
            tags: [{
              text: loanRequest.atributteStatus,
              color: getStatusColor(loanRequest.atributteStatus)
            }]
          },
          {
            type: 'progress',
            color: getStatusColor(loanRequest.atributteStatus),
            progress: getPercent(loanRequest)
          },
          {
            type: 'text',
            text: loanRequest.createdAt
          },
          {
            type: 'text',
            text: loanRequest.contactCompany.alias ? loanRequest.contactCompany.alias : 'N/N'
          },
          {
            type: 'text',
            text: loanRequest.product.productTypeName
          },
          {
            type: 'text',
            text: loanRequest.requestAmount ? `${loanRequest.product.currency} ${addSeparatorsToNumber(loanRequest.requestAmount)}`
              : 'N/N'
          },
          {
            type: 'actions',
            key: String(loanRequest.id),
            actions: [{
              type: 'right',
              title: 'Ver Detalle',
              text: 'Ver',
              fill: 'clear',
              color: 'primary'
            }]
          }
        ]
      })


  return (
    <div className="container">
      {!props.isMobile && (
        <TableGeneratorComponent
          headerList={getHeaderForTable()}
          bodyList={getListForTable(props.loanRequests)}
          onAction={onActionTableHandler}
        />
      )}


      {props.isMobile && (
        <IonRow className="ion-justify-content-left">
          {props.isLoading ? createSekeletonRows().map((item, index)=>(
            <IonCol className="ion-align-self-center" size="12" key={index}>
              <LoanRequestCardSekeletonComponent/>
            </IonCol>
          ))
          : props.loanRequests.map((loanRequest) => (
            <IonCol className="ion-align-self-center" size="12" key={loanRequest.id}>
                <LoanRequestCardComponent loanRequest={loanRequest} onLoanRequestClick={props.onLoanRequestClick}/> 
            </IonCol>
          ))}
        </IonRow>
      )}


    </div>
  );
};