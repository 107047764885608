import { getValueToForm, TForm } from "FormGenerator"

export const getUpdateFormDataWithPersonType = (newFormData: TForm): TForm => {
    const personTypeValue = getValueToForm(newFormData, '18')    
        
    if( personTypeValue === 'PM') return newFormData.map(input=> {
        
        if(input.id === '3') return {
            ...input,
            label: 'Razón Social'
        }

        if(input.id === '4' ) return {
            ...input,
            hidden: true,
            value: '',
            error: false,
        }
        
        if( input.id === '5' || input.id === '6') return {
            ...input,
            hidden: true,
            required: false,
            value: '',
            error: false,
        }

        if(input.id === '7') return {
            ...input,
            required: false,
            hidden: true,
            value: '',
            error: false,
        }

        if(input.id === '14') return { // curp
            ...input,
            required: false,
            hidden: true,
            value: '',
            error: false,
        }

        if(input.id === '16') return { // rfc
            ...input,
            hidden: false,
            required: true
        }

        return input
    })
    else return newFormData.map(input=> {

        if(input.id === '3') return {
            ...input,
            label: 'Primer nombre'
        }

        if(input.id === '4' ) return {
            ...input,
            hidden: false
        }
        
        if( input.id === '5' || input.id === '6') return {
            ...input,
            hidden: false,
            required: true,
        
        }

        if(input.id === '7') return {
            ...input,
            required: true,
            hidden: false,
        }



        if(input.id === '14') return { // curp
            ...input,
            hidden: false,
            required: true,
        }

        if(input.id === '16') return { // rfc
            ...input,
            hidden: true,
            required: false,
            value: '',
            error: false,
        }

        return input
    })
}