import { dateFormaterUtil } from '../../utils/dateFormater.util'
import { TGetDocumentationResponse } from '../onBoarding/onBoarding.model.store'

export interface ILoanRequestResponse {
    data: Array<{
        id: number
        contact_id: number
        created_by: string
        loanRequestType: string    // "TRACTOR" preguntar todos los valores que puede tener esto
        requestAmount: number | null
        warranty_type: string // "prenda" preguntar por todos los posibles valores
        created_at: string // "2020-11-02T10:43:34-0300"
        updated_at: string // "2020-11-02T10:43:34-0300"
        progress: number,
        originType: string // "Asociado" preguntar por los posibles valores de esto
        createdAt: "2020-11-02T10:43:34-03:00"
        track: string | null
        responsibleAssigned: "Ariel Chiacchiera"
        placementAgent: string | null
        status: string | null
        stage: string | null
        stageName: string // "PRE-SCORE" preguntar por todos los valores
        statusName: string // "RECIBIDA"
        has_dynamic_forms: boolean
        contact_company: {
            alias: string | null,
            cuit_cuil: string
            scoreVeraz: string | null
            scoreNosis: string | null
            scoreAgro: string | null
            activity: string | null
            activityType: string | null
            yearsInActivity: string | null
            totalAreaWorked: string | null
            ownFarm: string | null
            ownFarmArea: string | null
            annualAmount: string | null
            quantityEmployees: string | null
            category: string | null
            legalPerson: string | null
            customer_account: | null
        },
        contact: {
            id: number,
            name: string
            last_name: string
            full_name: string
            cuitCuil: string // "20342995706"
            document_id: string // ""
            birth_date: string | null
            marital_status: string | null
            nacionality: string | null
            birth_place: string | null
            condition_iva: string | null
            phone: string // "3416971616"
            email: string // "20342995706@users.siembro.com.tmp"
            address: string | null
            postal_code: string | null
            city: string | null
            state: string | null
            country: string | null
            isPhysicalPerson: boolean
            pep: string | null
            pep_position: string | null
            pep_expiration: string | null
            facta: string | null
            spouse: {
                name: string | null
                last_name: string | null
                cuitCuil: string | null
                document_id: string | null
                birth_date: string | null
                birth_place: string | null
                address: string | null
            },
            company: {
                name: string
                cuit: string
            }
        },
        partner: {
            id: number
            fullName: string
            email: string
        },
        currency: {
            currency: string // "Peso Argentino",
            acronym: string // "ARS"
            id: number
        },
        product: {
            id: number,
            product_type_id: number
            product_type_name: string // "TRACTOR"
            category_id: number
            category: string // "MAQUINARIA"
            currency: string // "ARS"
            price: number
            /////////////////////////////
            /////////////////////////////
            /////////////////////////////
            values: Object,
            // values: {
            //   "1": {
            //     title: "Estado"
            //   },
            //   "2": {
            //     title: "Marca"
            //   },
            //   "3": {
            //     title: "Modelo"
            //   },
            //   "4": {
            //     title: "Año"
            //   }
            // }
            /////////////////////////////
            /////////////////////////////
            /////////////////////////////
        },
        stageDays: Array<{
            stageId: number,
            stageName: string // "PRE-SCORE"
            days: string // "2"
            entered: string // "02/11/2020"
            current: boolean
        }>,
        ui_status: {
            atributte_status: string // "ACTIVA"
        },
        circuit_info: {
            name: string  //"MC"
            stages: Array<{
                stage_id: number
                name: string // "PRE-SCORE"
                order: number
            }>
        },
        has_documents: boolean
        simulation_id: number
        commercial_policy?: {
            fee_tax_mode: 1 | 2
        }
    }>
}


interface IStageDays {
    stageId: number
    stageName: string // "PRE-SCORE",
    days: string // "4",
    entered: string //"02\/11\/2020",
    current: boolean
}


export interface ILoanRequest {

    id: number
    has_dynamic_forms: boolean
    createdAt: string
    createdBy: string
    atributteStatus: string /// ojo con esto, puede tener distintos valores ya definidos :B
    simulation_id: number
    requestAmount: number
    client: {
        fullName: string
        cuitCuil: string
    }

    contactCompany: {
        alias: string
        cuitCuil: string
    }

    product: {
        productTypeName: string
        price: number
        currency: string
    }
    warrantys: Array<IWarranty>
    fee: IFee
    followUpNotes: Array<IFollowUpNotes>
    stageDays: Array<IStageDays>
    circuit_info: {
        name: string  // "MC",
        stages: Array<{
            stage_id: number
            name: string // "PRE-SCORE"
            order: number
        }>
    },
    documentation: TGetDocumentationResponse,
    commercial_policy?: {
        fee_tax_mode: 1 | 2
    }
}

export const loanRequestFactory = () : ILoanRequest =>  ({
    id: 0,
    has_dynamic_forms: false,
    createdAt: '',
    createdBy: '',
    atributteStatus: '',
    simulation_id: 0,
    requestAmount: 0,
    client: {
        fullName: '',
        cuitCuil: ''
    },
    contactCompany: {
        alias: '',
        cuitCuil: ''
    },
    product: {
        productTypeName: '',
        price: 0,
        currency: ''
    },
    stageDays: [],
    circuit_info: {
        name: '',
        stages: []
    },
    warrantys: [],
    followUpNotes: [],
    fee: {
        pdfAvailable: false,
        fees: []
    },
    documentation: [],
    commercial_policy: {
        fee_tax_mode: 1
    }
})


export const loadRequestsResponseAdapter = (loanResquests: ILoanRequestResponse): Array<ILoanRequest> => {
    if(!loanResquests) return []
    return loanResquests.data.map(loanRequest => {
        const newLoanRequest = loanRequestFactory()

        newLoanRequest.id = loanRequest.id
        newLoanRequest.createdAt = dateFormaterUtil(loanRequest.created_at)
        newLoanRequest.createdBy = loanRequest.created_by
        newLoanRequest.atributteStatus = loanRequest.ui_status.atributte_status
        newLoanRequest.simulation_id = loanRequest.simulation_id ? loanRequest.simulation_id : 0
        newLoanRequest.requestAmount = loanRequest.requestAmount ? loanRequest.requestAmount : 0 

        newLoanRequest.client = {
            fullName: loanRequest.contact.full_name,
            cuitCuil: loanRequest.contact.cuitCuil
        }


        newLoanRequest.contactCompany = {
            alias: loanRequest.contact_company.alias ? loanRequest.contact_company.alias : '',
            cuitCuil: loanRequest.contact_company.cuit_cuil
        }

        if(loanRequest.product) newLoanRequest.product = {
            productTypeName: loanRequest.product.product_type_name,
            price: loanRequest.product.price,
            currency: loanRequest.product.currency,
        }
     
        newLoanRequest.stageDays = loanRequest.stageDays
        newLoanRequest.circuit_info = loanRequest.circuit_info
     
        if( loanRequest.commercial_policy && loanRequest.commercial_policy.fee_tax_mode )
            newLoanRequest.commercial_policy = loanRequest.commercial_policy
        

        newLoanRequest.has_dynamic_forms = loanRequest.has_dynamic_forms


        return newLoanRequest
    })

}
    

export interface IWarrantyResponse {
    data: {
        warranty?: Array<{
            id: number
            created_at: string //"26/08/2020 09:42:21"
            updated_at: string // "26/08/2020 09:47:09"
            product: string | null
            state: string | null
            brand: string | null
            model: string | null
            year: string | null
            declared_price: string // "12820500"
            reference_price: string // "12820500"
            quality: string // "Excelente"
            is_valid: boolean
            is_main: boolean
            is_self_propelled: boolean
            currency_id: number
            currency: string // "ARS"
            product_type_id: number
            productTypeId: number
            product_type_name: string // "SEMBRADORA"
            productCategoryId: number
            category_id: number
            category: string// "MAQUINARIA"
            productStatus: string // "NUEVO"
            productBrand: string // "Erca"
            productModel: string // "ERCA"
            productYear: string // "2020"
            values: Object,
            // values: {
            //   "1": {
            //     "attribute_value_id": 2367,
            //     "product_id": 1489,
            //     "attribute_id": 1,
            //     "attribute_name": "Condicion",
            //     "value": "NUEVO"
            //   },
            //   "3": {
            //     "attribute_value_id": 2369,
            //     "product_id": 1489,
            //     "attribute_id": 3,
            //     "attribute_name": "Modelo",
            //     "value": "ERCA"
            //   },
            //   "4": {
            //     "attribute_value_id": 2370,
            //     "product_id": 1489,
            //     "attribute_id": 4,
            //     "attribute_name": "Año",
            //     "value": "2020"
            //   },
            //   "10": {
            //     "attribute_value_id": 2368,
            //     "product_id": 1489,
            //     "attribute_id": 10,
            //     "attribute_name": "Marca Sembradora",
            //     "value": "Erca"
            //   }
            // },
            type: string // "prenda"
        }>        
    }
}

export interface IWarranty {
    id: number
    fields: Array<{
        attribute_value_id: number
        product_id: number
        attribute_id: number
        attribute_name: string
        value: string
    }>

}

export const warrantyResponseAdapter = ( warrantysResponse : IWarrantyResponse['data']['warranty']) :  Array<IWarranty> => {
    if(!warrantysResponse) return []
    return warrantysResponse.map((warranty: any) => ({
        id: warranty.id,
        fields: Object.keys(warranty.values).map(key =>  warranty.values[key])
    }))
}

////////////////////
export interface IFollowUpNotesResponse {
    data: Array<IFollowUpNotes>
}
export interface IFollowUpNotes {
    descripcion: string // "RECIBIDA"
    fecha: string // "29/10/2020 11:49:35"
    id: string // "Trace-5304"
    tipo: string // "2"
    titulo: string // "Cambio de etapa"
    usuario: string // "Erca"
}

////////////////

export interface IFeeResponse {
    data: {
    pdfAvailable: false
    fees: Array<{
            id: number
            currency_id: number
            acronym: string // "USD"
            number: number
            date: string // "2021-04-04T00:00:00-03:00"
            capital: number // 69606
            sub_total: number // 21147
            amortization: number // 14999
            pure_fee: number // 21190
            subtotal: number // 21147
            interest: number // 6149
            tax_interest: number // 0
            life_insurance: number // 0
            technical_insurance: number // 1416
            tax_credit_debit: number // 0
            rate_recovery_fee: number // 42.880319444444
            fee_collection: number // 93
            amount: number // 26092
            created_at: string // "29/10/2020 11:49:35"
            created_by: string // "marcela@erca.com.ar.tmp"
            updated_at: string // "29/10/2020 11:49:35"
            updated_by: string | null // "29/10/2020 11:49:35"
        }>
    }
}

// todo: a esta interfaz se la puede resumir y quitar valores
export interface IFee {
    pdfAvailable: boolean,
    fees: Array<{
        id: number
        currency_id: number
        acronym: string
        number: number
        date: string
        capital: number
        sub_total: number
        amortization: number
        pure_fee: number
        subtotal: number
        interest: number
        tax_interest: number
        life_insurance: number
        technical_insurance: number
        tax_credit_debit: number
        rate_recovery_fee: number
        fee_collection: number
        amount: number
        created_at: string
        created_by: string
        updated_at: string 
        updated_by: string
    }>
}


export const feeResponseAdapter = (feeResponse: IFeeResponse) : IFee => ({
    pdfAvailable: false,
    fees: feeResponse.data.fees.map( feel =>({
        id: feel.id,
        currency_id: feel.currency_id,
        acronym: feel.acronym,
        number: feel.number,
        date: feel.date,
        capital: feel.capital,
        sub_total: feel.sub_total,
        amortization: feel.amortization,
        pure_fee: feel.pure_fee,
        subtotal: feel.subtotal,
        interest: feel.interest,
        tax_interest: feel.tax_interest,
        life_insurance: feel.life_insurance,
        technical_insurance: feel.technical_insurance,
        tax_credit_debit: feel.tax_credit_debit,
        rate_recovery_fee: feel.rate_recovery_fee,
        fee_collection: feel.fee_collection,
        amount: feel.amount,
        created_at: feel.created_at,
        created_by: feel.created_by,
        updated_at: feel.updated_at,
        updated_by: feel.updated_by ? feel.updated_by : '',
    }))
})
