export interface IConfig {
    apiBaseUrl: string
    apiBaseUrlControlPanel: string
    apiUrl: string
    cookieAuthKey: string
    cookieDomain: string
    country: "Argentina" | "México"
    environment: "dev" | string
    loggingApiKey: string
    loginUrl: string
    projectId: string
    riskApiUrl: string
    sentryDsn: string
    bureauApiUrl: string
}

export const getConfig = (): IConfig => {

    const baseUrl = window.location.host
    const baseUrlSplited = baseUrl.split('.')

    if (baseUrl.includes('localhost')) return {

        // Argentina Chabon !!!

        apiBaseUrl: "https://panel.dev.siembro.com/api",
        apiBaseUrlControlPanel: "https://panel.dev.siembro.com/api/cp",
        apiUrl: "https://api.dev.siembro.com",
        cookieAuthKey: "auth-siembro-dev",
        cookieDomain: ".dev.siembro.com",
        country: "Argentina",
        environment: "dev",
        loggingApiKey: "AIzaSyAso6KTgK3_bXWVkPaVT9uLduik9bXW9gk",
        loginUrl: "https://auth.dev.siembro.com/logout",
        projectId: "siembro-k8s",
        riskApiUrl: "https://risk.dev.siembro.com",
        sentryDsn: "https://be4b30ba41684b4c9e9ad1a15dfc2ce2@o1069989.ingest.sentry.io/6108789",
        bureauApiUrl: "https://bureau.dev.siembro.mx",

        // México Wey !!!
        
        // apiBaseUrl: "https://panel.dev.siembro.mx/api",
        // apiBaseUrlControlPanel: "https://panel.dev.siembro.mx/api/cp",
        // apiUrl: "https://api.dev.siembro.mx",
        // bureauApiUrl: "https://bureau.dev.siembro.mx",
        // cookieAuthKey: "auth-siembro-dev",
        // cookieDomain: ".dev.siembro.mx",
        // country: "México",
        // environment: "dev",
        // loggingApiKey: "AIzaSyAso6KTgK3_bXWVkPaVT9uLduik9bXW9gk",
        // loginUrl: "https://auth.dev.siembro.mx/logout",
        // projectId: "siembro-k8s",
        // riskApiUrl: "https://risk.dev.siembro.mx",
        // sentryDsn: "https://6920e1ec392d4c2ba9be5e665c377170@o1069989.ingest.sentry.io/6108792"
        
    }


    // merchant.dev.siembro.com
    if (baseUrlSplited.length === 4) {

        const environment: string = baseUrlSplited[1]
        const extension: string = baseUrlSplited[3]
        const contry: IConfig['country'] =  extension === 'com' ? 'Argentina' : extension === 'mx' ? 'México' : 'Argentina'

        return {
            apiBaseUrl: `https://panel.${environment}.siembro.${extension}/api`,
            apiBaseUrlControlPanel: `https://panel.${environment}.siembro.${extension}/api/cp`,
            apiUrl: `https://api.${environment}.siembro.${extension}`,
            cookieAuthKey: `auth-siembro-${environment}`,
            cookieDomain: `.${environment}.siembro.${extension}`,
            country: contry,
            environment: environment,
            loggingApiKey: "AIzaSyAso6KTgK3_bXWVkPaVT9uLduik9bXW9gk",
            loginUrl: `https://auth.${environment}.siembro.${extension}/logout`,
            projectId: `siembro-k8s`,
            riskApiUrl: `https://risk.${environment}.siembro.${extension}`,
            sentryDsn: "https://be4b30ba41684b4c9e9ad1a15dfc2ce2@o1069989.ingest.sentry.io/6108789",
            bureauApiUrl: `https://bureau.${environment}.siembro.${extension}`,
        }
    }

    // merchant.siembro.com
    if (baseUrlSplited.length === 3) {
        const environment = 'prod' 
        const extension: string = baseUrlSplited[2]
        const contry: IConfig['country'] =  extension === 'com' ? 'Argentina' : extension === 'mx' ? 'México' : 'Argentina'

        return {
            apiBaseUrl: `https://panel.siembro.${extension}/api`,
            apiBaseUrlControlPanel: `https://panel.siembro.${extension}/api/cp`,
            apiUrl: `https://api.siembro.${extension}`,
            cookieAuthKey: `auth-siembro-${environment}`,
            cookieDomain: `.siembro.${extension}`,
            country: contry,
            environment: environment,
            loggingApiKey: "AIzaSyAso6KTgK3_bXWVkPaVT9uLduik9bXW9gk",
            loginUrl: `https://auth.siembro.${extension}/logout`,
            projectId: `siembro-k8s`,
            riskApiUrl: `https://risk.siembro.${extension}`,
            sentryDsn: "https://6920e1ec392d4c2ba9be5e665c377170@o1069989.ingest.sentry.io/6108792",
            bureauApiUrl: `https://bureau.siembro.${extension}`,
        }
    }

    return {
        apiBaseUrl: "",
        apiBaseUrlControlPanel: "",
        apiUrl: "",
        cookieAuthKey: "",
        cookieDomain: "",
        country: "Argentina",
        environment: "dev",
        loggingApiKey: "",
        loginUrl: "",
        projectId: "",
        riskApiUrl: "",
        sentryDsn: "",
        bureauApiUrl: ""
    }
}