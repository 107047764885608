import { IonBadge, IonButton, IonCol, IonInput, IonItem, IonLabel, IonProgressBar, IonRow, IonSkeletonText, IonText } from '@ionic/react'
import React from 'react'


interface IProgress {
    type: 'progress'
    color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
    progress: number
}


// export type TAction = 'text' //'up' | 'right' | 'down' | 'left'
interface IActions {
    type: 'actions'
    key: string
    actions: Array<{
        type: string
        title: string
        text: string
        fill: 'clear' | 'outline' | 'solid'
        color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark" | "clear"
    }>
}

interface ITags {
    type: 'tags'
    tags: Array<{
        text: string
        color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
    }>
}

interface IText {
    type: 'text'
    text: string
}

interface ISkeleton {
    type: 'skeleton'
}

type TItem = IText | IProgress | IActions | ITags | ISkeleton

export interface ITableGeneratorComponent {
    headerList: Array<string>
    bodyList: Array<
        Array<TItem>
    >
    onAction?: (type: string, key: string) => void
}

export const TableGeneratorComponent = (props: ITableGeneratorComponent) => {

    const [filterState, setfilterState] = React.useState<string>('')

    const getListFiltered = (filter: string, bodyList: ITableGeneratorComponent['bodyList']) => {
        if (!filter) return [...bodyList]
        const result = bodyList.filter(body => {
            return !!body.filter(item => {
                if (item.type === 'text') return item.text.includes(filter)
                if (item.type === 'tags') return !!item.tags.filter(tag => tag.text.includes(filter)).length
                return false
            }).length
        })
        return result
    }

    const headerGenerator = (headerList: ITableGeneratorComponent['headerList']) => (
        <tr style={{ borderBottom: '1px solid #eee' }}>
            {headerList.map((header, index) =>
                <td key={`${header}${index}`} className="ion-text-center" style={{ fontWeight: 'bold', fontSize: '16px', padding: '15px'}}><IonText>{header}</IonText></td>
            )}
        </tr>
    )

    const onActionHandler = (type: string, key: string) => {
        if (props.onAction) props.onAction(type, key)
    }

    const itemGenerator = (item: TItem, key: string) => {
        if(item.type === 'skeleton') {
            return(
                <td key={key} className="ion-text-center" style={{ padding: '10px 5px 10px 5px' }}>
                    <IonSkeletonText animated style={{ width: '100%' }} />
                </td>
            )
        }
        if (item.type === 'text') {
            return (
                <td key={key} className="ion-text-center" style={{ padding: '10px 5px 10px 5px' }}>
                    <div style={{ fontSize: '14px'}}><IonLabel>{item.text}</IonLabel></div>
                </td>
            )
        } else if (item.type === 'actions') {
            return (
                <td key={key} className="ion-text-center" style={{ padding: '0px 5px 0px 5px' }}>
                    {item.actions.map((action, keyIndex) => (
                            <IonButton key={`${item.key}${keyIndex}`} color={action.color} fill={action.fill} onClick={() => onActionHandler(action.type, item.type === 'actions' ? item.key : '')} title={action.title}>{action.text}</IonButton>
                    ))}
                </td>
            )
        } else if (item.type === 'progress') {
            return (
                <td key={key} className="ion-text-center"  style={{ padding: '0px 5px 0px 5px' }}>
                    <IonProgressBar color={item.color} value={item.type === 'progress' ? item.progress : 0}></IonProgressBar>
                </td>
            )
        } else if (item.type === 'tags') {
            return (
                <td key={key} className="ion-text-center" style={{ padding: '0px 5px 0px 5px' }}>
                    {item.tags.map((tag, index) => (
                        <IonBadge key={`key${index}`} color={tag.color} style={{ marginTop: '5px'}}>{tag.text}</IonBadge>
                    ))}
                </td>
            )
        }
    }

    const bodyGenerator = (bodyList: ITableGeneratorComponent['bodyList']) => bodyList.map((row, rowIndex) => (
        <tr key={`row${rowIndex}`} style={{ borderBottom: '1px solid #eee' }}>
            {row.map((item, itemIndex) => itemGenerator(item, `${rowIndex}${itemIndex}`))}
        </tr>
    ))

    const onChangeFilterInput = (value: string) => {
        setfilterState(value)
    }

    return (
        <div>
            <IonRow className="ion-justify-content-end">
                <IonCol size="auto">
                    <IonItem>
                        <IonLabel position="floating">Filtro</IonLabel>
                        <IonInput value={filterState} onIonChange={(e) => onChangeFilterInput(e.detail.value ? e.detail.value : '')}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <table style={{ width: '100%' }}>
                        <thead>
                            {headerGenerator(props.headerList)}
                        </thead>
                        <tbody>
                            {bodyGenerator(getListFiltered(filterState, props.bodyList))}
                        </tbody>
                    </table>
                </IonCol>
            </IonRow>
            <IonRow>
                <br />
            </IonRow>
        </div>
    )
}