import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react'
import { arrowForward, flag } from 'ionicons/icons'
import React from 'react'
import { timer } from '../utils/timer'
import styled from 'styled-components'

const Button = styled.button<{status: 'success' | 'current' | 'pending'}>`
    padding: 10px 15px;
    font-size: 15px;
    color: ${props=>props.status === 'pending' ? '#777' : 'white'};
    background:${props => 
        props.status === 'success' ? '#8bb2c9' : 
        props.status === 'current' ? '#176592' : 'rgb(245, 245, 245)'
    };
    border-radius: 3px;
    box-shadow: 0.5px 1px 2px #aaa;
    cursor: pointer;
`

interface ISteperGeneratorComponent {
    children: React.ReactNode
    currentStep: number
    onBackStep(stepNumber: number): void
    isDisabledButtons: boolean
}

export const SteperGeneratorComponent = (props:ISteperGeneratorComponent) => {

    const [isShowChildren, setIsShowChildren]= React.useState<boolean>(false)

    React.useEffect(()=>{
        hideAndShowChildren()
    }, [props.currentStep])

    const hideAndShowChildren = async () => {
        setIsShowChildren(false)
        await timer(0)
        setIsShowChildren(true)
    } 
    
    const getStatus = (currentChildren: number, currentStep: number): 'success' | 'current' | 'pending' => {
        if(currentChildren === currentStep) return 'current'
        if(currentChildren > currentStep) return 'success'
        if(currentChildren < currentStep) return 'pending'
        return 'pending'
    }

    const getDisabled = (currentChildren: number, currentStep: number): boolean => {
        if(props.isDisabledButtons) return true
        if(currentChildren === currentStep) return true
        if(currentChildren > currentStep) return false
        if(currentChildren < currentStep) return true
        return false
    }

    return (
        <>
            <IonRow >
                <IonCol className="ion-text-center">
                {React.Children.map(props.children, (Child, index) => 
                    <>
                        <IonCol size="auto" className="ion-align-self-center">
                            <Button status={getStatus(props.currentStep, index)} disabled={getDisabled(props.currentStep, index)} onClick={()=>props.onBackStep(index)}>{index + 1}</Button>
                        </IonCol>
                    </>
                )}
                </IonCol>
            </IonRow>
            {React.Children.map(props.children, (Child, index) => isShowChildren && index === props.currentStep ? 
                <div style={{display: 'block'}}>{Child}</div> 
            :   <div style={{display: 'none'}}>{Child}</div> 
            )}
        </>
    )
}