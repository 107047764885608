import { IonButton, IonCard, IonCol, IonItem, IonLabel, IonList, IonRow, IonTitle } from '@ionic/react'
import React from 'react'
import { ICheckPoliciesRequest, IPolicie, ISimulateResponseAdapted } from '../../stores/onBoarding/onBoarding.model.store'
import { dateFormaterUtil } from '../../utils/dateFormater.util'
import { TableGeneratorComponent } from '../TableGenerator.component'
import { addSeparatorsToNumber } from '../../utils/number.util'

const SimulateInformationComponent = (props: {
    simulate: ISimulateResponseAdapted
    checkPoliticsRequest: ICheckPoliciesRequest
    countryAlias: 'ar' | 'mx'
    currentPolicie: IPolicie
}) => {

    return (
        <>
            <IonRow>
                <IonCol>
                    <h5 className="ion-text-center">Datos de la operación</h5>
                    <IonList>
                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Monto a solicitar por el cliente
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.loan.currency.acronym} {addSeparatorsToNumber(Number(props.simulate.loan.amount_to_transfer))}
                            </p>
                        </IonItem>

                        {props.countryAlias === 'mx' ? (
                            <IonItem>
                                <IonLabel className="ion-text-start">
                                    Enganche
                                </IonLabel>
                                <p className="ion-text-end">
                                    {(100 - props.currentPolicie.loan_capacity)}%
                                </p>
                            </IonItem>
                        ): null}

                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Gastos de la operación
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.loan.currency.acronym} {addSeparatorsToNumber(props.simulate.expense.total)}
                            </p>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Monto a percibir                            
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.loan.currency.acronym} {addSeparatorsToNumber(Number(props.simulate.loan.amount_to_receive))}
                            </p>
                        </IonItem>
                    </IonList>
                </IonCol>
                <IonCol>
                    <h5 className="ion-text-center">Más información</h5>
                    <IonList>
                        {/* <IonItem>
                            <IonLabel className="ion-text-start">
                                Costo del financiamiento
                            </IonLabel>
                            <p className="ion-text-end">
                            </p>
                        </IonItem> */}

                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Fecha simulación
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.date_simulate}
                            </p>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Fecha de otorgamiento
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.date_origination}
                            </p>
                        </IonItem>
                        
                        <IonItem>
                            <IonLabel className="ion-text-start">
                                Fecha primera cuota
                            </IonLabel>
                            <p className="ion-text-end">
                                {props.simulate.date_first_fee}
                            </p>
                        </IonItem>

                        {/* <IonItem>
                            <IonLabel className="ion-text-start">
                                Plazo total financiamiento
                            </IonLabel>
                            <p className="ion-text-end">
                            </p>
                        </IonItem> */}
                        {/* <IonItem>
                            <IonLabel className="ion-text-start">
                                Tipo de Crédito
                            </IonLabel>
                            <p className="ion-text-end">
                            </p>
                        </IonItem> */}
                        {/* <IonItem>
                            <IonLabel className="ion-text-start">
                                Garantía
                            </IonLabel>
                            <p className="ion-text-end">
                            </p>
                        </IonItem> */}
                        {/* <IonItem>
                            <IonLabel className="ion-text-start">
                                Comercio Concesionario
                            </IonLabel>
                            <p className="ion-text-end">
                            </p>
                        </IonItem> */}
                    </IonList>
                </IonCol>
            </IonRow>
        </>
    )
}

// todo: tengo que arreglar estos datos urgente !!!
const FeeListComponent = (props: { feeList: ISimulateResponseAdapted['loan']['fees'] }) => {

    return (
        <>
            {props.feeList.map(fee => (
                <IonCol size="12" key={`cuotaNumero${fee.nro}`}>
                    <IonCard>
                        <IonList>
                            <IonItem>
                                <IonLabel>Nro</IonLabel>
                                <p>{`#${fee.nro}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Vencimiento</IonLabel>
                                <p>{dateFormaterUtil(fee.date.date)}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Capital</IonLabel>
                                <p>{`${fee.acronym} ${addSeparatorsToNumber(Number(fee.amortization))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Interés</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.interest))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Sub Total</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.subtotal))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>I.V.A.</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.tax_interest))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Cuota pura</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.pure_fee))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Seguro</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.technical_insurance))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Cobranza</IonLabel>
                                <p>{`${addSeparatorsToNumber(Number(fee.fee_collection))}`}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Total a Pagar</IonLabel>
                                <p>{fee.acronym + ' ' + addSeparatorsToNumber(Number(fee.pure_fee) + Number(fee.technical_insurance) + Number(fee.expenseTotal))}</p>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Capital restante</IonLabel>
                                <p>{fee.acronym + ' ' + addSeparatorsToNumber(Number(fee.balance))}</p>
                            </IonItem>
                        </IonList>
                    </IonCard>
                </IonCol>
            ))}
        </>
    )
}

interface IOnBoardingStepNumberFourComponent {
    action: 'create' | 'edit'
    simulateResponse: ISimulateResponseAdapted
    checkPoliticsRequest: ICheckPoliciesRequest
    currentPolicie: IPolicie
    isMobile: boolean
    feeTaxMode: 1 | 2
    countryAlias: 'ar' | 'mx'
    onOpenDownloadModal(): Promise<void>
    onOpenConvertModal(): void
    onOpenSaveModal(): Promise<void>
}

export const OnBoardingStepNumberFourComponent = (props: IOnBoardingStepNumberFourComponent) => {
    return (
        <>
            <IonRow><IonCol><br /><br /></IonCol></IonRow>
            <IonRow>
                <IonCol className="ion-text-center">
                    <IonTitle>Simulación Generada</IonTitle>
                </IonCol>
            </IonRow>

            <IonRow><IonCol><br /><br /></IonCol></IonRow>
            <IonRow>
                <IonCol className="ion-text-center">
                    <IonTitle style={{ fontSize: '17px' }}>Caída de cuotas</IonTitle>
                </IonCol>
            </IonRow>

            <IonRow>
                {props.isMobile && (
                    <FeeListComponent feeList={props.simulateResponse.loan.fees} />
                )}
                {!props.isMobile && (
                    <IonCol>
                        <TableGeneratorComponent
                            headerList={[
                                'Nro',
                                'Vencimiento',
                                'Capital',
                                'Interés',
                                'Sub Total',
                                `I.V.A.${props.feeTaxMode === 1 ? '(recupero)' : ''}`,
                                'Cuota pura',
                                'Seguro',
                                'Cobranza',
                                'Total a Pagar',
                                'Capital Restante'
                            ]}

                            bodyList={props.simulateResponse.loan.fees.map((fee, index) => ([
                                {
                                    type: 'text',
                                    text: `#${fee.nro}`
                                },
                                {
                                    type: 'text',
                                    text: dateFormaterUtil(fee.date.date)
                                },
                                {
                                    type: 'text',
                                    text: `${addSeparatorsToNumber(Number(fee.amortization))}`
                                },
                                {
                                    type: 'text',
                                    text: `${addSeparatorsToNumber(Number(fee.interest))}`
                                },
                                {
                                    type: 'text',
                                    text: `${addSeparatorsToNumber(Number(fee.subtotal))}`
                                },
                                {
                                    type: 'text',
                                    text: 
                                        props.feeTaxMode ===1 ?
                                            addSeparatorsToNumber(Number(fee.rate_recovery_fee))
                                        : props.feeTaxMode === 2  ?
                                            addSeparatorsToNumber(Number(fee.tax_interest))
                                        : ''
                                },
                                {
                                    type: 'text',
                                    text: `${addSeparatorsToNumber(Number(fee.pure_fee))}`
                                },
                                {
                                    type: 'text',
                                    text: String(Number(fee.technical_insurance))
                                },
                                {
                                    type: 'text',
                                    text: addSeparatorsToNumber(Number(fee.fee_collection))
                                },
                                {
                                    type: 'text',
                                    text: fee.acronym + ' ' + addSeparatorsToNumber(Number(fee.value))
                                },
                                {
                                    type: 'text',
                                    text: fee.acronym + ' ' + addSeparatorsToNumber(Number(fee.balance))
                                },
                            ]))}
                        />
                    </IonCol>
                )}
            </IonRow>


            <IonRow><IonCol><br /><br /></IonCol></IonRow>


            <SimulateInformationComponent
                checkPoliticsRequest={props.checkPoliticsRequest}
                simulate={props.simulateResponse}
                countryAlias={props.countryAlias}
                currentPolicie={props.currentPolicie}
            />

            <IonRow><IonCol><br /><br /><br /></IonCol></IonRow>

            <IonRow>
                <IonCol className="ion-text-center">
                    <IonButton color="light" onClick={props.onOpenDownloadModal}>
                        Descargar PDF
                    </IonButton>
                </IonCol>
                <IonCol className="ion-text-center">
                    {props.action === 'create' ? (
                    <IonButton color="light" onClick={props.onOpenSaveModal}>
                        Guardar Simulación
                    </IonButton>
                    ): null}
                </IonCol>
                <IonCol className="ion-text-center">
                    <IonButton color="primary" onClick={props.onOpenConvertModal}>
                        Guardar Solicitud
                    </IonButton>
                </IonCol>
            </IonRow>
            <br></br><br></br><br></br>
        </>
    )
}
