import { setup } from '../../utils/setup'
import { IApiClientResponse, TApiClientResponse, useApiClient } from '../../utils/ApiClient.util'
import { getCountryAlias } from '../setup/setup.use.store'
import {
    ICheckPoliciesRequest, 
    ICheckPoliciesResponse, 
    IMaxFeesRequest, 
    IMaxFeesResponse,
    ISimulateRequest,

    ISimulateResponse,
    IDownloadPDFRequest,
    IDownloadPDFResponse,
    checkPoliciesAdapter,

    IVerifyNipRequest,
    IVerifyNipRequestResponse,
    IGenerateNipRequest,
    IGenerateNipResponse,
    IValidateNipRequest,
    IValidateNipResponse,

    ICheckPoliciesAdaptedResponse,
    checkPoliciesResponseFactory,
    ICategoriesResponse,
    TGetStatesWithTheirMunicipalitiesOfMexicoResponse,
    IPolicieAdapted
} from './onBoarding.model.store'
import { IProductTypeResponse } from '../simulate/simulate.model.store'

export const useOnBoardingService = () => {

    const baseApiClient = useApiClient()

    const getStatesWithTheirMunicipalitiesOfMexico = async () : TApiClientResponse<TGetStatesWithTheirMunicipalitiesOfMexicoResponse> => {

        const response = await baseApiClient.get<TGetStatesWithTheirMunicipalitiesOfMexicoResponse>(`statesWithTheirMunicipalitiesOfMexico.json?v=${new Date().getTime()}`)
        return response
    }

    const getCategories = async () : TApiClientResponse<ICategoriesResponse> => {
        const response = await baseApiClient.get<ICategoriesResponse>(`${setup.apiBaseUrl}/v1/product/category`)
        return response
    }

    const getProductType = async (id: number): TApiClientResponse<IProductTypeResponse> => {
        const response = await baseApiClient.get<IProductTypeResponse>(`${setup.apiBaseUrl}/v1/product/type/${id}`)
        return response
    }

    const getPolices = async (param: ICheckPoliciesRequest, currencyFilter: 'USD' | 'PESOS'): TApiClientResponse<ICheckPoliciesAdaptedResponse> => {
        const response = await baseApiClient.post<ICheckPoliciesResponse>(`${setup.apiBaseUrl}/v2/onboarding/check-policies`,{
            ...param,
            filters: {
                currency_id: currencyFilter === "USD" ? 1 : 2
            }
        })
        
        if(response.isSuccess) {

            const newComercialPolicyList: IPolicieAdapted[] = []

            if(response) {
                checkPoliciesAdapter(response.data).commercialPolicies.forEach(comercialPolicy => {
                    newComercialPolicyList.push(comercialPolicy)
                })
            }

            let newPolicies: ICheckPoliciesAdaptedResponse = {
                ...checkPoliciesResponseFactory(),
                customer: response.isSuccess && response.data.customer ? response.data.customer :  response.isSuccess && response.data.customer ? response.data.customer : checkPoliciesResponseFactory().customer,
                commercialPolicies: newComercialPolicyList
            }
            
            const newResponse : IApiClientResponse<ICheckPoliciesAdaptedResponse> = {
                isSuccess: true,
                isError: false,
                data: newPolicies,
                body: param,
                method: 'POST',
                status: 200,
                url: `${setup.apiBaseUrl}/v2/onboarding/check-policies`
            }
            
            return newResponse
        } else {
            const newResponse : IApiClientResponse<ICheckPoliciesAdaptedResponse> = {
                isSuccess: false,
                isError: true,
                data: checkPoliciesResponseFactory(),
                body: param,
                method: 'POST',
                status: 200,
                url: `${setup.apiBaseUrl}/v2/onboarding/check-policies`
            }

            return newResponse
        }

  
    }
    
    const getFees = async (param: IMaxFeesRequest) => {
        param.newFlow = getCountryAlias() === 'ar' ? true : false
        const response = await baseApiClient.post<IMaxFeesResponse>(`${setup.apiBaseUrl}/v2/onboarding/max-fees`, param)
        return response
    }
    
    const createSimulate = async (param: ISimulateRequest) => {
        const response = await baseApiClient.post<ISimulateResponse>(`${setup.apiBaseUrl}/v2/onboarding/simulate`, param)
        return response
        //return simulateResponseAdapter(response)
    }

    const editSimulate = async (param: ISimulateRequest, simulateId: number) => {
        const response = await baseApiClient.patch<ISimulateResponse>(`${setup.apiBaseUrl}/v2/onboarding/simulate/${simulateId}`, param)
        return response
    }

    const saveSimulate = async (simulateId: number) => {
        return await baseApiClient.put(`${setup.apiBaseUrl}/v1/loan-simulator/${simulateId}`, {save: true})
    }

    const downloadPDF = async(simulteId: number, params: IDownloadPDFRequest) => {
        const response = await baseApiClient.post<IDownloadPDFResponse>(
            `${setup.apiBaseUrl}/v1/loan-simulator/pdf2/${simulteId}`, 
            params
        )
        return response
    }


    //////////////////

    // const proccessStepOne = async (param: IProccessStepOneRequest ) => {
    //     const response = await baseApiClient.post<IProccessStepOneResponse>(`${setup.apiBaseUrl}/v2/risk/process`, param)
    //     return response
    // }

    // const riskProccessValidateNip = async (param: IRiskProccessValidateNipRequest) => {
    //     const response = await baseApiClient.post<IRiskProccessValidateNipResponse>(`${setup.apiBaseUrl}/v2/risk/process/validate`, param)
    //     return response
    // }

    // const riskProccessResendNip = async (param: IRiskProccessResendNipRequest) => {
    //     const response = await baseApiClient.post<IRiskProccessResendNipResponse>(`${setup.apiBaseUrl}/v2/risk/process/resend-nip`, param)
    //     return response
    // }

    // const proccessStepTwo = async (param: IRiskProcessStepTwoRequest, workflooId: number) => {
    //     const response = await baseApiClient.post<IRiskProcessStepTwoResponse>(`${setup.apiBaseUrl}/v2/risk/process/${workflooId}`, param)
    //     return response
    // }

    ///////////////////////////////////////////////////////////////////////

    const verifyNip = async (body: IVerifyNipRequest) => baseApiClient.post<IVerifyNipRequestResponse>(`${setup.bureauApiUrl}/verifyNip`, body)
    const generateNip = async (body: IGenerateNipRequest) => baseApiClient.post<IGenerateNipResponse>(`${setup.bureauApiUrl}/generateNip`, body)
    const validateNip = async (body: IValidateNipRequest) => baseApiClient.post<IValidateNipResponse>(`${setup.bureauApiUrl}/validateNip`, body)
       
    return { 
        getPolices, 
        getFees, 
        createSimulate, 
        getCategories,
        getProductType,
        downloadPDF, 
        saveSimulate,
        editSimulate,
        verifyNip,
        generateNip,
        validateNip,
        getStatesWithTheirMunicipalitiesOfMexico
    }
}