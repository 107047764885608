import { IonButton, IonCard, IonCol, IonRow, IonTitle, IonText } from '@ionic/react'
import React from 'react'
import { ICheckPoliciesAdaptedResponse, IPolicieAdapted } from '../../stores/onBoarding/onBoarding.model.store'
import { addSeparatorsToNumber, deleteFloatToPriceNumberString } from '../../utils/number.util'
import { Container, Row, Col, Title, SubTitle, Resume } from './CheckPolicyResumeFilter.element'

interface ICheckPolicyResumeFilterComponentProps {
    comercialPolicys: ICheckPoliciesAdaptedResponse['commercialPolicies']
    countryAlias: 'ar' | 'mx'
    // onSelectComercialPolicyType: (type: "PRENDARIO" | "A SOLA FIRMA") => void
}

interface IResumePolicyType {
    maxAmountUSD: number
    maxAmountPesos: number
    subsidized: boolean
    suitable: boolean
}

const resumeFactory = (): IResumePolicyType => ({
    maxAmountUSD: 0,
    maxAmountPesos: 0,
    subsidized: false,
    suitable: false,
})

const createResume = (previusResumePolicy: IResumePolicyType, policy: IPolicieAdapted): IResumePolicyType => ({
    maxAmountUSD: policy.currency.id === 1 && policy.max_amount > previusResumePolicy.maxAmountUSD ? policy.max_amount : previusResumePolicy.maxAmountUSD,
    maxAmountPesos: policy.currency.id === 2 && policy.max_amount > previusResumePolicy.maxAmountPesos ? policy.max_amount : previusResumePolicy.maxAmountPesos,
    subsidized: false,
    suitable: previusResumePolicy.suitable === false && policy.status === 1 ? true : previusResumePolicy.suitable
})

export const CheckPolicyResumeFilterComponent: React.FC<ICheckPolicyResumeFilterComponentProps> = (props) => {

    const comercialPolicyPrendarioFiltered = props.comercialPolicys.filter(policy => policy.type === 'PRENDARIO')
    const comercialPolicySolaFirmaFiltered = props.comercialPolicys.filter(policy => policy.type === 'A SOLA FIRMA')

    const comercialPolicyPrendarioResume: IResumePolicyType = comercialPolicyPrendarioFiltered.reduce((acumulator, current) =>
        createResume(acumulator, current)
        , resumeFactory())

    const comercialPolicySolaFirmaResume: IResumePolicyType = comercialPolicySolaFirmaFiltered.reduce((acumulator, current) =>
        createResume(acumulator, current)
        , resumeFactory())

    const onSelectComercialTypeHandler = (type: "PRENDARIO" | "A SOLA FIRMA") => {
        // props.onSelectComercialPolicyType(type)
    }

    const getCurreny = (): string => {
        if(props.countryAlias === 'ar') return 'ARS'
        else return 'MXN'
    }

    return (

        <Container>
            <Row>
                <Col width={100}><Title>Este es un resumen de los máximos disponibles</Title></Col>
            </Row>
            <Row>
                <Col width={50}>
                    <Row>
                        <Col width={100}><SubTitle>PRENDARIO</SubTitle></Col>
                    </Row>
                    <Row>
                        <Col width={100}>
                            {comercialPolicyPrendarioResume.maxAmountUSD ? (
                                   <Resume>USD ${deleteFloatToPriceNumberString(addSeparatorsToNumber(comercialPolicyPrendarioResume.maxAmountUSD))}</Resume>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col width={100}>
                            {comercialPolicyPrendarioResume.maxAmountPesos ? (
                                    <Resume>{getCurreny()} ${deleteFloatToPriceNumberString(addSeparatorsToNumber(comercialPolicyPrendarioResume.maxAmountPesos))}</Resume>
                            ) : null}

                        </Col>
                    </Row>
                </Col>
                <Col width={50}>
                    <Row>
                        <Col width={100}><SubTitle>SOLA FIRMA</SubTitle></Col>
                    </Row>
                    <Row>
                        <Col width={100}>
                            {comercialPolicySolaFirmaResume.maxAmountUSD ? (
                                <Resume>USD ${deleteFloatToPriceNumberString(addSeparatorsToNumber(comercialPolicySolaFirmaResume.maxAmountUSD))}</Resume>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col width={100}>
                            {comercialPolicySolaFirmaResume.maxAmountPesos ? (
                                <Resume>{getCurreny()} ${deleteFloatToPriceNumberString(addSeparatorsToNumber(comercialPolicySolaFirmaResume.maxAmountPesos))}</Resume>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}