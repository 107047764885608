import { useState } from 'react';
import { useLoanRquestService } from './loanRequest.service.store'
import { ILoanRequest } from './loanRequest.model.store'
import { IDownloandPdfRequest } from '../simulate/simulate.model.store';
import { TActionResult, useStoreUtil } from '../../utils/useStore.util';
import { ILoanRequestStore } from './loanRequest.interface.store';

export const useLoanRequestStore = (): ILoanRequestStore => {
    const loanRequestUtil = useStoreUtil()
    const loanRequestService = useLoanRquestService()

    const [loanRequests, setLoanRequests] = useState<ILoanRequestStore['loanRequests']>([])

    const loadLoanRequestsAction: ILoanRequestStore['loadLoanRequestsAction'] = async () => {
        const response = await loanRequestService.getLoanRequests()
        if(response.isSuccess) {
            setLoanRequests(response.data)
            return loanRequestUtil.createSuccessResult(null)
        } else {
            return loanRequestUtil.createErrorResult(null)
        }
    }

    const loadLoanRequestDetailAction: ILoanRequestStore['loadLoanRequestDetailAction'] = async (id)=> {
        let newLoanRequests = [...loanRequests]
        const loanRequestFinded = newLoanRequests.find(loanRequest=> loanRequest.id === id)


        if(!loanRequestFinded){
            const loanRequstResponse = await loanRequestService.getLoanRequests()
            if(loanRequstResponse.isSuccess) {
                newLoanRequests = loanRequstResponse.data
            } else {
                // props.onApiClientError(loanRequstResponse)
            }
        }

        const response = await loanRequestService.getLoanRquestDetail(id)
        
        if(response.isSuccess) {
            const loanRequestsUpdated: ILoanRequest[]  = newLoanRequests.map(loanRequest =>
                loanRequest.id === id ? { 
                    ...loanRequest,
                    warrantys: response.data.warrantys, 
                    followUpNotes: response.data.followUpNotes ? response.data.followUpNotes : [], 
                    fee: response.data.fee,
                    documentation: response.data.documentation
                } : { ...loanRequest }
            )
            setLoanRequests(loanRequestsUpdated)
            return loanRequestUtil.createSuccessResult(null)
        } else {
            return loanRequestUtil.createErrorResult(null)
        }
    }


    const downloadLoanRequestFeePDFAction: ILoanRequestStore['downloadLoanRequestFeePDFAction'] = async (id, param)=> {
        const response = await loanRequestService.downloadLoanRequestFeePDF(id, param)
        if(response.isSuccess) {
            window.open(response.data.data, '_blank')
            return loanRequestUtil.createSuccessResult(null)
            // todo: la redireccion deberia manejarlo el page, no el store
        }else{
            return loanRequestUtil.createErrorResult(null)

        }

    }

    return { loanRequests, loadLoanRequestsAction, loadLoanRequestDetailAction, downloadLoanRequestFeePDFAction }
}