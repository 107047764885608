import React, { useState, useEffect } from 'react';
import { ILoanRequest, loanRequestFactory } from '../stores/loanRequest/loanRequest.model.store'
import { LoanRequestListComponent } from '../components/LoanRequestList.component'
import { IonContent, IonFab, IonFabButton, IonIcon, IonLoading, useIonViewDidEnter } from '@ionic/react';
import { LoanRequestDetailComponent } from '../components/LoanRequestDetail.component'
import { NoDataComponent } from '../components/NoData.component'
import { add } from 'ionicons/icons';
import { DownloadSimulateModalComponent } from '../components/DownloadSimulateModal.component';
import { IDownloandPdfRequest } from '../stores/simulate/simulate.model.store';
import { TActionResult } from '../utils/useStore.util';

interface ILoanRequestModule {
  loanRequest: ILoanRequest
  isMobile: boolean
  loanRequestId: number
  onDownloadFeePDF(id: number, param: IDownloandPdfRequest): TActionResult<null>
  loadLoanRequestsAction(): TActionResult<null>
  onRedirectToOnBoarding: () => void
}

export const LoanRequestDetailModule: React.FC<ILoanRequestModule> = (props: ILoanRequestModule) => {

  const [isOpenDownloadModal, setIsOpenDownloadModal] = React.useState<boolean>(false)

  const onDownloadRequestHandlerFromDetailComponent = ()=> setIsOpenDownloadModal(true)
  
  const onSubmitHandlerForDownload = async ( param: IDownloandPdfRequest) => {
    await props.onDownloadFeePDF(props.loanRequest.simulation_id, param)
    setIsOpenDownloadModal(false)
  }

  const onCloseHandlerFromDownloadModal = ()=> setIsOpenDownloadModal(false)

  const getPreloadDataForDownloadModal = () => {
    return {
        identification: props.loanRequest.client.cuitCuil,
        name: props.loanRequest.client.fullName,
        email: '',
        productCategory: props.loanRequest.product.productTypeName,
        channel: ''
    }
  }

  const onSubmitHandlerFromNoDataComponent = (url: string) => {
    props.onRedirectToOnBoarding()
  }
  
  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{ margin: '0px 10px' }} onClick={() => props.onRedirectToOnBoarding()}>
        <IonFabButton>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>

      <IonContent>

        <DownloadSimulateModalComponent
            isOpen={isOpenDownloadModal}
            onSubmit={onSubmitHandlerForDownload}
            onClose={onCloseHandlerFromDownloadModal}
            applicantPreLoadData={getPreloadDataForDownloadModal()}
            isShowSubsidaryCheckBox={true}
            hasDynamicForms={props.loanRequest.has_dynamic_forms}
        />

  
      
        {/* {!props.loanRequest.id && !isLoadingData ? (
          <NoDataComponent
            title="No hay datos"
            message="Puedes ver el listado de Loan Requests"
            button="Ver Listado"
            href="/loanRequests"
            onSubmit={onSubmitHandlerFromNoDataComponent}
          />
        ) : null} */}
        <br /><br />
 
        {props.loanRequest.id ? (
          <LoanRequestDetailComponent
            loanRequest={props.loanRequest}
            onDownloadFeePDF={onDownloadRequestHandlerFromDetailComponent}
            isLoadingDetail={false} // todo: esta propiedad posiblemente la quitemos
          />
        ) : null}
        <br /><br /><br /><br />
      </IonContent>

    </>
  )
}