import React from 'react'
import styled from 'styled-components'

export const SearchProductFormContainer = styled.div`
    border: 1px solid #98B1C9;
    border-radius: 5px;
    background: #F4F7FA;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const SearchProductFormCell = styled.div`
    padding: 5px;
`

export const SearchProductFormLabel = styled.label`
    color: #8AA0AD;
    width:100%;
    font-size: 70%;
`

export const SearchProductFormInput = styled.input`
    width: 100%;
    border:none;
    padding: 5px;
    border:1px solid #ddd;
    background: ${props=> props.disabled  === true ? '#eee' : 'white'};
    margin-top: 5px;
    border-radius: 3px;
    font-size: 80%;    
    color: #868686;

`

export const SearchProductFormSelect = styled.select`
    width: 100%;
    border:1px solid #ddd;
    padding: 4px;
    background: white;
    margin-top: 5px;
    border-radius: 3px;
    font-size: 80%;    
    color: #868686;
`

export const SearchProductFormButton = styled.button`
    background:#176592;
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 21px;
`

export const SearchProductFormText = styled.a`
    font-size: 12px;
    color: #333;
`

export const SearchProductFormLink = styled.a`
    font-size: 12px;
    color: #8AA0AD;
    cursor: pointer;
    text-decoration-line: underline;
`


