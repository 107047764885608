import { IonButton, IonCard, IonCol, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import React from 'react'
import { ICreditModel } from '../stores/credit/credit.model.store'


interface ICreditCardComponentProps {
    credit: ICreditModel
    onClick: (creditId: number) => void 
}

const CreditCardComponent: React.FC<ICreditCardComponentProps> = props => {
    return (
        <IonCard>
            <IonList>
                <IonItem>
                    <IonLabel>ID:</IonLabel>
                        #{props.credit.id}
                </IonItem>
                <IonItem>
                    <IonLabel>Solicitud: </IonLabel>
                        #{props.credit.loan_request.id}
                </IonItem>
                <IonItem>
                    <IonLabel>Alias:</IonLabel>
                    {props.credit.customer.alias}
                </IonItem>
                <IonItem>
                    <IonLabel>Identificación:</IonLabel>
                    {props.credit.customer.tax_identification_number}
                </IonItem>
                <IonItem>
                    <IonLabel>Monto:</IonLabel>
                    {props.credit.loan_request.amount} {props.credit.loan_request.currency}
                </IonItem>
                <IonItem>
                    <IonLabel>Acciones</IonLabel>
                    <IonButton color="primary" onClick={() => props.onClick(props.credit.id)} title="Enviar Solicitud">Detalle</IonButton>
                </IonItem>
            </IonList>
        </IonCard>
    )
}

export {CreditCardComponent}