import { notifications } from 'ionicons/icons'
import React from 'react'
import { TActionResult, useStoreUtil } from '../../utils/useStore.util'
import { INotificationModel } from './notification.model.store'
import { useNotificationService } from './notification.service.store'


export interface INotificationStore {
    isLoading: boolean
    notificationList: Array<INotificationModel>
    getNotificationList: () => TActionResult<INotificationStore['notificationList']>
    deleteNotification: (notification: INotificationModel) => TActionResult<null>
    markReadNotification: (notification: INotificationModel, read: boolean) => TActionResult<null>
}

export const useNotificationStore = (): INotificationStore => {

    const storeUtil = useStoreUtil()

    const notificationService = useNotificationService()

    const [isLoading, setIsLoading] = React.useState<INotificationStore['isLoading']>(false)

    const [notificationList, setNotificationList] = React.useState<INotificationStore['notificationList']>([])

    const getNotificationList:INotificationStore['getNotificationList']  = async () => {
        
        const response = await notificationService.getNotificationList()

        if(response.isSuccess){
            setNotificationList(response.data)
            return storeUtil.createSuccessResult(response.data)
        }
      
        setNotificationList([])
        
        return storeUtil.createErrorResult([])        
    }

    const deleteNotification:INotificationStore['deleteNotification'] = async notificationForDelete => {
        setIsLoading(true)
        const response = await notificationService.deleteNotification(notificationForDelete)
        if(response.isSuccess) {
            setNotificationList(notificationList.filter(notification=>notification.id !== notificationForDelete.id))
            setIsLoading(false)
            return storeUtil.createSuccessResult(null)
        }else{
            setIsLoading(false)
            return storeUtil.createErrorResult(null)
        }
    }

    const markReadNotification:INotificationStore['markReadNotification'] = async (notificationForMark, read) => {
        
        const response = await notificationService.updateNotificaction({...notificationForMark, read})
        
        if(response.isSuccess) {
            setNotificationList(
                notificationList.map(notification=>
                    notification.id===notificationForMark.id ? {...notificationForMark, read}: notification
                )
            )
            return storeUtil.createSuccessResult(null)
        }

        return storeUtil.createErrorResult(null)
    }


    return { isLoading, notificationList, getNotificationList, deleteNotification, markReadNotification }
}

