import React from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
// import { IForm, IFormGeneratorComponent } from '../FormGenerator/FormGenerator.interface';
import { ICategoriesResponse } from '../../stores/onBoarding/onBoarding.model.store';
import { getUpdateFormDataWithPersonType } from './OnBoardingStepNumberOneMexico.helper';
import { getUpdateFormDataWithClientTypeCondition } from './OnBoardingStepNumberOneArgentina.helper';
import { NipFormComponent } from '../NipForm.component';
import { TActionResult } from '../../utils/useStore.util';
import { getUpdateFormDataWithProductCondition, getUpdateFormDataWithProductInputs } from './OnBoardingStepNumberOne.helper';


interface OnBoardingStepNumberOneComponent {
    categories: ICategoriesResponse['data']
    cuitQueryParam: string
    activityQueryParam: string
    countryAlias: 'ar' | 'mx'
    formData: TForm
    isOpenNipForm: boolean
    email: string
    onGetProductForm :(productId: number) => Promise<TForm>
    onSubmit(form: TForm, nipServiceEnabled: boolean): void
    onSubmitNipForm:(nip: string) => Promise<void>
    getCountryStepNumberOneSheme: () => TActionResult<TForm>
    onNextFromNip: () => Promise<void>
    onResendNip: () => void
    onCloseNipForm: () => void
    setFormData: (formData: TForm) => void
}

export const OnBoardingStepNumberOneComponent: React.FC<OnBoardingStepNumberOneComponent> = (props) => {


    React.useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const newFormData = await props.getCountryStepNumberOneSheme()
        props.setFormData(newFormData.data) // los estados y handlers del formulario creo que no deberian estar mas aca
    }

    const setFormDataHandler = async(newFormData: TForm) => {

        if (props.countryAlias === 'ar') {
            newFormData = await getUpdateFormDataWithProductInputs(newFormData, props.formData, props.onGetProductForm)
            const formDataWithTypeClientConditionUpated = getUpdateFormDataWithClientTypeCondition(newFormData)
            props.setFormData(formDataWithTypeClientConditionUpated)
            return
        }

        if (props.countryAlias === 'mx') {
            const formDataWithProductConditionUpdated = getUpdateFormDataWithProductCondition(newFormData) 
            const formDataWithPersonTypeUpdated = getUpdateFormDataWithPersonType(formDataWithProductConditionUpdated)
            props.setFormData(formDataWithPersonTypeUpdated)
            return
        }

        props.setFormData(newFormData)
        return
    }

    const onSubmitNipFormHandler = (nip: string) => {
        props.onSubmitNipForm(nip)
    }

    const onNextFromNipHandler = async ()=> props.onNextFromNip()
    


    const onResendNipHandler = async () => {
        props.onResendNip()
    }

    const onSubmitFormHandler = async () => {

        if(props.countryAlias === 'ar') {
            props.onSubmit([...props.formData], false)
        }

        if(props.countryAlias === 'mx') {
            // const formDataWithRFCfieldUpdated = getUpdateFormDataWithRFCfield([...props.formData])
            const isKibanServiceEnabled: boolean = getValueToForm(props.formData, '0.2') === 'si' ? true : false
            if(isKibanServiceEnabled) {
                await props.onSubmit([...props.formData], true)
            }else {
                await props.onSubmit([...props.formData], false)
            }
        }
    }

    return (
        <>
            <NipFormComponent
                key="NipFormComponent"
                isOpen={props.isOpenNipForm}
                onSubmit={onSubmitNipFormHandler}
                onNext={onNextFromNipHandler}
                onResend={onResendNipHandler}
                onClose={()=>props.onCloseNipForm()}
                email={props.email}
            />

            <IonRow>
                <IonCol size="12" size-md="1" size-lg="2"></IonCol>
                <IonCol size="12" size-md="10" size-lg="8">
                    <FormGenerator
                        dataTestId=''
                        form={props.formData}
                        setForm={setFormDataHandler}
                        submitText="Siguiente"
                        onSubmit={() => onSubmitFormHandler()}
                        actions={[]}
                        onAction={()=>{}}
                        onValidForm={()=>{}}
                        onInvalidForm={()=>{}}
                        isShowSubmit={true}
                    />
                </IonCol>
                <IonCol size="12" size-md="1" size-lg="2"></IonCol>
            </IonRow>
            <br/><br/>
        </>
    )
}