import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import React from 'react';

export type TSelected = 'TODAS' | 'ACTIVA' | 'RECHAZADA' | 'CADUCADA'

interface ILoanRequestsTabFilterPropsComponent {
    selectedValue: TSelected
    onChangeValue(value: TSelected): void
}

export const LoanRequestsTabFilterComponent: React.FC<ILoanRequestsTabFilterPropsComponent> = ({selectedValue, onChangeValue}) => {

    const onChangeValueHandler = (value: string) => {
        if( value === 'TODAS' ) return onChangeValue('TODAS')
        if( value === 'ACTIVA' ) return onChangeValue('ACTIVA')
        if( value === 'RECHAZADA' ) return onChangeValue('RECHAZADA')
        if( value === 'CADUCADA' ) return onChangeValue('CADUCADA')
        return onChangeValue('TODAS')
    } 

    return (
        <IonSegment  
            onIonChange={e =>onChangeValueHandler(e.detail.value ? e.detail.value : 'TODAS')} 
            scrollable 
            value={selectedValue}
        >
            <IonSegmentButton value="TODAS">
                <IonLabel>Todas</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="ACTIVA">
                <IonLabel>Activas</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="RECHAZADA">
                <IonLabel>Rechazadas</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="CADUCADA">
                <IonLabel>Caducadas</IonLabel>
            </IonSegmentButton>
        </IonSegment>
    );
};