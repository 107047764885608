import { IonLoading } from '@ionic/react'
import React from 'react'
import { IUserStore } from '../stores/user/user.use.store'

interface IB2CPageProps{
    userStore: IUserStore
    onApiClientError: ()=> Promise<void>
}

const B2CPage: React.FC<IB2CPageProps> = props => {

    React.useEffect(()=>{
        onInit()
    }, [])

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const onInit = async () => {
        setIsLoading(true)
        const result = await props.userStore.autenticateDefaultUser()
        result.isError && props.onApiClientError()
        setIsLoading(false)
    }

    return (
        <>
            <IonLoading
                isOpen={isLoading}
                onDidDismiss={() => setIsLoading(false)}
                message={'Cargando...'}
            />

            {props.userStore.isDefaultUserAuntenticated && props.userStore.isFinishedDefaultUserAuthenticate ? props.children: null}
        </>
    )
}

export default B2CPage

function onApiClientError() {
    throw new Error('Function not implemented.')
}
