import { useState } from 'react'
import { IApiClientResponse, TApiClientResponse } from '../../utils/ApiClient.util'
import { useCuitService, ISendRequestParam, ICuitValidate } from './cuit.service.store'

export interface IMessage {
    title: string,
    message: string,
    status: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"
}

const defaultMessage: IMessage = {
    title: '',
    message: '',
    status: 'light'
}

export interface IBankMessage {
    title: 'APTO' | 'NO APTO' | 'ERROR' | ''
    carrilTitle: 'Verde' | 'Rojo' | 'Amarillo' | ''
    message: string
}

const defaultBankMessage: IBankMessage = {
    title: '',
    carrilTitle: '',
    message: '',
}


export const useCuitStore = (props: { isAvailableBankPolicyService: boolean }) => {

    const cuitService = useCuitService()
    const [fullName, setFullName] = useState<string>('')
    const [cuit, setCuit] = useState<string>('')

    const [cuitValidateMessage, setCuitValidateMessage] = useState<IMessage>(defaultMessage)
    const [verificationBankFirstPolicyMessage, setVerificationBankFirstPolicyMessage] = useState<IBankMessage>(defaultBankMessage)

    const [verificationAcceptanceRequirementsFirstMessage, setVerificationAcceptanceRequirementsFirstMessage] = useState<IMessage>(defaultMessage)
    const [verificationAcceptanceRequirementsNoWarrantyMessage, setVerificationAcceptanceRequirementsNoWarrantyMessage] = useState<IMessage>(defaultMessage)
    const [verificationTrackMessage, setVerificationTrackMessage] = useState<IMessage>(defaultMessage)

    const [isShowSantanderForm, setIsShowSantanderForm] = useState<boolean>(false)

    const [verificationBankFirstPolicyStatusColor, setVerificationBankFirstPolicyStatusColor] = useState<'warning' | 'success' | 'danger' | 'light'>('light')
    const [verificationBankFirstMessage, setVerificationBankFirstMessage] = useState<string>('')

    const resetMessageStates = () => {
        setCuitValidateMessage(defaultMessage)
        setVerificationBankFirstPolicyMessage(defaultBankMessage)
        setVerificationAcceptanceRequirementsFirstMessage(defaultMessage)
        setVerificationAcceptanceRequirementsNoWarrantyMessage(defaultMessage)
        setVerificationTrackMessage(defaultMessage)
        setIsShowSantanderForm(false)
        setVerificationBankFirstPolicyStatusColor('light')
    }

    const sendSecondVerificationBankFirstPolicy = async (param: {
        cuit: string
        finnancial_debt: number
        avg_billing: number
        rented_has: number
        own_has: number
        new_line: number
    }) => {
        const response = await cuitService.getSecondVerificationBankFirstPolicy(param)
        if (response.isSuccess) {

            setVerificationBankFirstPolicyMessage({
                ...verificationBankFirstPolicyMessage,
                title:
                    response.data.data.status === "ACEPTADO" ? 'APTO' :
                        response.data.data.status === "RECHAZADO" ? 'NO APTO' :
                            response.data.data.status === "ERROR" ? 'ERROR' : 'ERROR'
                ,
                message: `${response.data.data.message} ${response.data.data.executionId ? ` | executionId: ${response.data.data.executionId} ` : ''}`,
            })
            setIsShowSantanderForm(false)
        } else {
            //props.onApiClientError(response)
        }
    }


    const sendRequest = async (param: ISendRequestParam) => {

        resetMessageStates()

        let newfullName: string = ''
        let cuitValidateActionResponse: IApiClientResponse<ICuitValidate>

        const verificationBankFirstPolicyPromise = props.isAvailableBankPolicyService ? cuitService.getVerificationBankFirstPolicy({ cuit: param.cuit }).then(verificationBankFirstPolicyResponse => {
            if (verificationBankFirstPolicyResponse.isSuccess) {
                if (verificationBankFirstPolicyResponse.data.data.status !== 'ACEPTADO') setVerificationBankFirstPolicyMessage({
                    title: verificationBankFirstPolicyResponse.data.data.status === "DENEGADO" ?
                        'NO APTO'
                        : verificationBankFirstPolicyResponse.data.data.status === "ERROR" ?
                            'ERROR'
                            : 'ERROR',
                    message: `${verificationBankFirstPolicyResponse.data.data.message} executionId: ${verificationBankFirstPolicyResponse.data.data.executionId}`,
                    carrilTitle: ''
                })

                if (verificationBankFirstPolicyResponse.data.data.status === 'ACEPTADO') {
                    setIsShowSantanderForm(true)
                    setVerificationBankFirstPolicyMessage({
                        title: 'APTO',
                        message: `executionId: ${verificationBankFirstPolicyResponse.data.data.executionId}`,
                        carrilTitle: ''
                            // verificationBankFirstPolicyResponse.data.data.message.trim() === 'Amarillo' ? 'Amarillo' :
                            //     verificationBankFirstPolicyResponse.data.data.message.trim() === 'Verde' ? 'Verde' :
                            //         verificationBankFirstPolicyResponse.data.data.message.trim() === 'Rojo' ? 'Rojo' : ''
                    })

                }

                return
            } else {
                setVerificationBankFirstPolicyMessage({
                    title: 'ERROR',
                    message: 'Error en la ejecución en la politica de riesgo. Intente en un momento.',
                    carrilTitle: ''
                })
            }
        })
            // .catch(e => {
            //     setVerificationBankFirstPolicyMessage({
            //         title: 'ERROR',
            //         message: 'Error en la ejecución en la politica de riesgo. Intente en un momento.',
            //         carrilTitle: ''
            //     })
            // })
            : new Promise(resolve => resolve(null))

        // try {
        cuitValidateActionResponse = await cuitService.getCuitValidateAction({ cuit: param.cuit })
        if (cuitValidateActionResponse.isSuccess) {
            newfullName = cuitValidateActionResponse.data.data.name
            setFullName(newfullName)
            setCuit(param.cuit)
            if (cuitValidateActionResponse.data.data.reject) setCuitValidateMessage({
                title: 'ALERTA',
                message: cuitValidateActionResponse.data.data.reject.message,
                status: 'warning'
            })
        } else {
            setCuitValidateMessage({
                title: 'Error al conectarce a AFIP',
                message: 'Por favor volvelo a intentar en unos minutos',
                status: 'light'
            })
        }

        // } catch (e) {
        //     setCuitValidateMessage({
        //         title: 'Error al conectarce a AFIP',
        //         message: 'Por favor volvelo a intentar en unos minutos',
        //         status: 'light'
        //     })
        // }

        const verificationAcceptanceRequirementsFirstPromise = cuitService.getVerificationAcceptanceRequirementsFirst(
            cuitValidateActionResponse.isSuccess && cuitValidateActionResponse.data.data.activity_info ? {
                action: "creditcheck",
                afip_activity: cuitValidateActionResponse.data.data.activity_info.afip_activity ? cuitValidateActionResponse.data.data.activity_info?.afip_activity : "SINDATA",
                afip_activity_id: cuitValidateActionResponse.data.data.activity_info.afip_activity_id,
                cuit: param.cuit,
                days_passed: cuitValidateActionResponse.data.data.activity_info.days_passed,
                declared_activity: param.activity,
                full_name: newfullName
            } : {
                action: "creditcheck",
                afip_activity: "SINDATA",
                afip_activity_id: "00000",
                cuit: param.cuit,
                days_passed: 730,
                declared_activity: param.activity,
                full_name: newfullName
            }
        ).then(verificationAcceptanceRequirementsFirstResponse => {
            if (verificationAcceptanceRequirementsFirstResponse.isSuccess) {
                setVerificationAcceptanceRequirementsFirstMessage({
                    title:
                        verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ACEPTADO' ?
                            'Mercado de Capitales Prendario: APTO'
                            : verificationAcceptanceRequirementsFirstResponse.data.data.status === 'DENEGADO' ?
                                'Mercado de Capitales Prendario: NO APTO'
                                : verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ERROR' ?
                                    'Mercado de Capitales Prendario: ERROR'
                                    : 'Mercado de Capitales Prendario: ERROR',
                    message:
                        verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ACEPTADO' ||
                            verificationAcceptanceRequirementsFirstResponse.data.data.status === 'DENEGADO' ?
                            `${verificationAcceptanceRequirementsFirstResponse.data.data.message ? verificationAcceptanceRequirementsFirstResponse.data.data.message : ''} | ID: ${verificationAcceptanceRequirementsFirstResponse.data.data.executionId}`
                            : verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ERROR' ?
                                verificationAcceptanceRequirementsFirstResponse.data.data.message ? verificationAcceptanceRequirementsFirstResponse.data.data.message : ''
                                : '',

                    status:
                        verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ACEPTADO' ?
                            'success'
                            : verificationAcceptanceRequirementsFirstResponse.data.data.status === 'DENEGADO' ?
                                'danger'
                                : verificationAcceptanceRequirementsFirstResponse.data.data.status === 'ERROR' ?
                                    'light'
                                    : 'light'

                })
            } else {
                setVerificationAcceptanceRequirementsFirstMessage({
                    title: 'Mercado de Capitales Prendario: ERROR',
                    message: 'Por favor volvelo a intentar en unos minutos',
                    status: 'light'
                })
            }
        })
        // .catch(e => {
        //     setVerificationAcceptanceRequirementsFirstMessage({
        //         title: 'Mercado de Capitales Prendario: ERROR',
        //         message: 'Por favor volvelo a intentar en unos minutos',
        //         status: 'light'
        //     })
        // })

        const verificationAcceptanceRequirementsNoWarrantyPromise = cuitService.getVerificationAcceptanceRequirementsNoWarranty({
            afip_activity_id: cuitValidateActionResponse.isSuccess && cuitValidateActionResponse.data.data.activity_info?.afip_activity_id ? String(cuitValidateActionResponse.data.data.activity_info.afip_activity_id) : "00000",
            cuit: param.cuit,
            full_name: newfullName,
            own_farm: 0,
            declared_activity: param.activity
        }).then(verificationAcceptanceRequirementsNoWarrantyResponse => {
            if (verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess) {
                const newMessage =
                    verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ACEPTADO' ?
                        `
                    ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.limits[0].acronym}
                    ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.limits[0].value}
                    |
                    ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.limits[1].acronym}
                    ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.limits[1].value}
                    |
                    executionId: ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.executionId}
                `
                        : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'DENEGADO' ? `
                ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.message}
                |executionId: ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.executionId}
            `
                            : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ERROR' ? `
                ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.message}
                |executionId: ${verificationAcceptanceRequirementsNoWarrantyResponse.data.data.executionId}
            `

                                : ''

                setVerificationAcceptanceRequirementsNoWarrantyMessage({
                    title:
                        verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ACEPTADO' ?
                            'Sola Firma: APTO'
                            : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'DENEGADO' ?
                                'Sola Firma: NO APTO'
                                : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ERROR' ?
                                    'Sola Firma: ERROR'
                                    : 'Sola Firma: ERROR',
                    message: newMessage,
                    status: verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ACEPTADO' ?
                        'success'
                        : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'DENEGADO' ?
                            'danger'
                            : verificationAcceptanceRequirementsNoWarrantyResponse.isSuccess && verificationAcceptanceRequirementsNoWarrantyResponse.data.data.status === 'ERROR' ?
                                'light'
                                : 'light'
                })
            } else {
                setVerificationAcceptanceRequirementsNoWarrantyMessage({
                    title: 'Sola Firma: ERROR',
                    message: 'Por favor volvelo a intentar en unos minutos',
                    status: 'light'
                })
            }
        })
        // .catch(e => {
        //     setVerificationAcceptanceRequirementsNoWarrantyMessage({
        //         title: 'Sola Firma: ERROR',
        //         message: 'Por favor volvelo a intentar en unos minutos',
        //         status: 'light'
        //     })
        // })

        const verificationTrackPromise = cuitService.getVerificationTrack(
            cuitValidateActionResponse.isSuccess && cuitValidateActionResponse?.data.data.activity_info ?
                {
                    afip_activity: cuitValidateActionResponse.data.data.activity_info.afip_activity,
                    alias: newfullName,
                    cuit: param.cuit,
                    declared_activity: param.activity
                }
                : {
                    afip_activity: "SINDATA",
                    alias: newfullName,
                    cuit: param.cuit,
                    declared_activity: param.activity
                }
        ).then(verificationTrackResponse => {
                if (verificationTrackResponse.isSuccess) {
                    setVerificationTrackMessage({
                        title: `Carril: ${verificationTrackResponse.data.data.carril}`,
                        message: `${verificationTrackResponse.data.data.message.replace(" risk_amount_limit:", "USD")} |${verificationTrackResponse.data.data.executionId}`,
                        status:
                            verificationTrackResponse.data.data.carril.trim() === 'Amarillo' ? 'warning' :
                                verificationTrackResponse.data.data.carril.trim() === 'Verde' ? 'success' :
                                    verificationTrackResponse.data.data.carril.trim() === 'Rojo' ? 'danger' :
                                        verificationTrackResponse.data.data.carril.trim() === 'Error' ? 'light'
                                            : 'light'
                    })
                } else {
                    setVerificationTrackMessage({
                        title: 'Carril: ERROR',
                        message: 'Por favor volvelo a intentar en unos minutos',
                        status: 'light'
                    })
                }

            })
        // .catch(e => {

        // })

        await Promise.all([
            verificationBankFirstPolicyPromise,
            verificationAcceptanceRequirementsFirstPromise,
            verificationAcceptanceRequirementsNoWarrantyPromise,
            verificationTrackPromise
        ])
    }

    return {
        sendRequest,
        isShowSantanderForm,
        sendSecondVerificationBankFirstPolicy,
        fullName,
        cuit,
        cuitValidateMessage,
        verificationBankFirstPolicyMessage,
        verificationAcceptanceRequirementsFirstMessage,
        verificationAcceptanceRequirementsNoWarrantyMessage,
        verificationTrackMessage
    }
}