import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle } from '@ionic/react'
import React from 'react'
import { TGetDocumentationResponse } from '../../stores/onBoarding/onBoarding.model.store'

interface IDocumentationComponentProps {
    documentation: TGetDocumentationResponse
}

const getTotalSuccessDocumentation = (documentation: TGetDocumentationResponse): number =>
    documentation.reduce(
        (acumulator, current) => current.status === 2 ? acumulator + 1 : acumulator
        , 0 as number)

const getDocumentationStatus = (status: number): string =>
    status === 1 ? 'Pendiente' :
        status === 2 ? 'Aprobado' :
            status === 3 ? 'Rechazado'
                : 'Desconocido'

const getDocumentationStatusColor = (status: number): string =>
    status === 1 ? 'medium' :
        status === 2 ? 'success' :
            status === 3 ? 'danger'
                : 'dark'

export const DocumentationComponent: React.FC<IDocumentationComponentProps> = (props) => {


    return (
        <div>
            <br></br><br></br>

            <div className="ion-text-center">
                {props.documentation.length ? (
                    <IonTitle>{getTotalSuccessDocumentation(props.documentation)}/{props.documentation.length}</IonTitle>
                ): null}

                {!props.documentation.length ? (
                     <IonTitle color="medium">No Hay documentos disponibles</IonTitle>
                ): null}
            </div>

            {props.documentation.length ? props.documentation.map((doc, index) => (
                <IonCard key={index}>
                    <IonCardHeader>
                        <IonCardTitle>{doc.name}</IonCardTitle>
                        <IonCardSubtitle color={ getDocumentationStatusColor(doc.status)}>{getDocumentationStatus(doc.status)}</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            )): null}
        </div>
    )
}