interface IRespuesta<T> {
    susses: boolean,
    response: T
}

// probando
// const funcionsita = <t>(param: t) : IRespuesta<t> => {
//     const respuesta:IRespuesta<t> = {
//         susses: true,
//         response: param
//     }

//     return respuesta
// }

// const valor:IRespuesta<number> = funcionsita<number>(8)




type TAvailableValues = "PLAN_CHEQUE" | "SEPARA_GASTOS" | "BANK_POLICY" | "NEW_ONBOARDING" | "CONVENIOS"

// interface IChannels {
//     id: number
//     channelType: string
//     tag: string
//     value: string
// }

export const profileAdaptedFactory = (): IProfileApiResponseAdapted['data'] => ({
    "company": {
        "logoUrl": '',
        "useCustomLogo": false
    },
    "email": '',
    "facebookId": '',
    "googleId": '',
    "googleAvatar": '',
    "bocsEnabled": false,
    "availableServices": [],
    "hasFunds": false,
    "contact": {
        "id": 0,
        "firstName": '',
        "lastName": '',
        "fullName": '',
        "cuitCuil": '',
        "dni": '',
        "gender": '',
        "birthDate": '',
        "maritalStatus": '',
        "nationality": '',
        "politic": "false",
        "activityType": '',
        "occupational": '',
        "netMonthlyIncome": '',
        "estimatedAmountToInvest": '',
        "onboardingProgress": 0,
        "geoLocation": '',
        "addressStreet": '',
        "addressNumber": '',
        "addressFloor": '',
        "addressDepto": '',
        "addressPostalCode": '',
        "addressState": '',
        "addressCity": '',
        "bank": '',
        "bankAccountType": '',
        "cbuAlias": '',
        "isBorrowerFormCompleted": false,
        "hasLoanRequestReceived": false,
        "isPhysicalPerson": '',
        "role": '',
        "parent_user_category": 0,
        "contactPartner": {
            "canCreateLoanRequests": false,
            "enabledNewOnboarding": 0
        },
        "channels": [],
    },
    "user": {
        "id": 0,
        "email": ''
    },
})


export const profileApiResponseAdapter = (profileResponse: IProfileApiResponse): IProfileApiResponseAdapted => {
    return {
        "status": profileResponse.status,
        "data":{
            "company": {
                "logoUrl": profileResponse.data.company.logoUrl,
                "useCustomLogo": profileResponse.data.company.useCustomLogo
            },
            "email": profileResponse.data.email,
            "facebookId": profileResponse.data.facebookId ? profileResponse.data.facebookId : '',
            "googleId": profileResponse.data.googleId ? profileResponse.data.googleId : '',
            "googleAvatar": profileResponse.data.googleAvatar ? profileResponse.data.googleAvatar : '',
            "bocsEnabled": profileResponse.data.bocsEnabled,
            "availableServices": profileResponse.data.availableServices ? profileResponse.data.availableServices: [],
            "hasFunds": profileResponse.data.hasFunds,
            "contact": {
                "id": profileResponse.data.contact.id,
                "firstName": profileResponse.data.contact.firstName,
                "lastName": profileResponse.data.contact.lastName,
                "fullName": profileResponse.data.contact.fullName,
                "cuitCuil": profileResponse.data.contact.cuitCuil ? profileResponse.data.contact.cuitCuil : '',
                "dni": profileResponse.data.contact.dni ? profileResponse.data.contact.dni: '',
                "gender": profileResponse.data.contact.gender ? profileResponse.data.contact.gender : '',
                "birthDate": profileResponse.data.contact.birthDate ? profileResponse.data.contact.birthDate : '',
                "maritalStatus": profileResponse.data.contact.maritalStatus ? profileResponse.data.contact.maritalStatus : '',
                "nationality": profileResponse.data.contact.nationality ? profileResponse.data.contact.nationality : '',
                "politic": profileResponse.data.contact.politic,
                "activityType": profileResponse.data.contact.activityType ? profileResponse.data.contact.activityType : '',
                "occupational": profileResponse.data.contact.occupational ? profileResponse.data.contact.occupational : '',
                "netMonthlyIncome": profileResponse.data.contact.netMonthlyIncome ? profileResponse.data.contact.netMonthlyIncome : '',
                "estimatedAmountToInvest": profileResponse.data.contact.estimatedAmountToInvest ? profileResponse.data.contact.estimatedAmountToInvest : '',
                "onboardingProgress": profileResponse.data.contact.onboardingProgress,
                "geoLocation": profileResponse.data.contact.geoLocation ? profileResponse.data.contact.geoLocation : '',
                "addressStreet": profileResponse.data.contact.addressStreet ? profileResponse.data.contact.addressStreet : '',
                "addressNumber": profileResponse.data.contact.addressNumber ? profileResponse.data.contact.addressNumber : '',
                "addressFloor": profileResponse.data.contact.addressFloor ? profileResponse.data.contact.addressFloor : '',
                "addressDepto": profileResponse.data.contact.addressDepto ? profileResponse.data.contact.addressDepto : '',
                "addressPostalCode": profileResponse.data.contact.addressPostalCode ? profileResponse.data.contact.addressPostalCode : '',
                "addressState": profileResponse.data.contact.addressState ? profileResponse.data.contact.addressState : '',
                "addressCity": profileResponse.data.contact.addressCity ? profileResponse.data.contact.addressCity : '',
                "bank": profileResponse.data.contact.bank ? profileResponse.data.contact.bank : '',
                "bankAccountType": profileResponse.data.contact.bankAccountType ?  profileResponse.data.contact.bankAccountType : '',
                "cbuAlias": profileResponse.data.contact.cbuAlias ? profileResponse.data.contact.cbuAlias : '',
                "isBorrowerFormCompleted": profileResponse.data.contact.isBorrowerFormCompleted,
                "hasLoanRequestReceived": profileResponse.data.contact.hasLoanRequestReceived,
                "isPhysicalPerson": profileResponse.data.contact.isPhysicalPerson ? profileResponse.data.contact.isPhysicalPerson : '',
                "role": profileResponse.data.contact.role,
                "parent_user_category": profileResponse.data.contact.parent_user_category,
                "contactPartner": {
                    "canCreateLoanRequests": profileResponse.data.contact.contactPartner.canCreateLoanRequests,
                    "enabledNewOnboarding": profileResponse.data.contact.contactPartner.enabledNewOnboarding
                },
                "channels": profileResponse.data.contact.channels.map(channel=>({
                    ...channel, 
                    tag: channel.tag ? channel.tag : ''
                }))
            },
            "user": {
                "id": profileResponse.data.user.id,
                "email": profileResponse.data.user.email
            },
        }
    }
}

export interface IProfileApiResponseAdapted {
    "status": string
    "data":{
        "company": {
            "logoUrl": string,
            "useCustomLogo": boolean
        },
        "email": string
        "facebookId": string
        "googleId": string
        "googleAvatar": string
        "bocsEnabled": boolean
        "availableServices": Array<TAvailableValues>
        "hasFunds": boolean,
        "contact": {
            "id": number
            "firstName": string
            "lastName": string
            "fullName": string
            "cuitCuil": string
            "dni": string
            "gender": string
            "birthDate": string
            "maritalStatus": string
            "nationality": string
            "politic": "false" | "true"
            "activityType": string
            "occupational": string
            "netMonthlyIncome": string
            "estimatedAmountToInvest": string
            "onboardingProgress": number
            "geoLocation": string
            "addressStreet": string
            "addressNumber": string
            "addressFloor": string
            "addressDepto": string
            "addressPostalCode": string
            "addressState": string
            "addressCity": string
            "bank": string
            "bankAccountType": string
            "cbuAlias": string
            "isBorrowerFormCompleted": boolean
            "hasLoanRequestReceived": boolean
            "isPhysicalPerson": string
            "role": string
            "parent_user_category": number
            "contactPartner": {
                "canCreateLoanRequests": boolean
                "enabledNewOnboarding": number
            },
            "channels": Array<{
                "id": number
                "channelType": string
                "tag": string
                "value": string
            }>
        },
        "user": {
            "id": number
            "email": string
        },
    }
}

export interface IProfileApiResponse {
    "status": string
    "data":{
        "company": {
            "logoUrl": string,
            "useCustomLogo": boolean
        },
        "email": string
        "facebookId": string | null
        "googleId": string | null
        "googleAvatar": string | null
        "bocsEnabled": boolean
        "availableServices": Array<TAvailableValues> | null
        "hasFunds": boolean,
        "contact": {
            "id": number
            "firstName": string
            "lastName": string
            "fullName": string
            "cuitCuil": string | null
            "dni": string | null
            "gender": string | null
            "birthDate": string | null
            "maritalStatus": string | null
            "nationality": string | null
            "politic": "false" | "true"
            "activityType": string | null
            "occupational": string | null
            "netMonthlyIncome": string | null
            "estimatedAmountToInvest": string | null
            "onboardingProgress": number
            "geoLocation": string | null
            "addressStreet": string | null
            "addressNumber": string | null
            "addressFloor": string | null
            "addressDepto": string | null
            "addressPostalCode": string | null
            "addressState": string | null
            "addressCity": string | null
            "bank": string | null
            "bankAccountType": string | null
            "cbuAlias": string | null
            "isBorrowerFormCompleted": boolean
            "hasLoanRequestReceived": boolean
            "isPhysicalPerson": string | null
            "role": string,
            "parent_user_category": number
            "contactPartner": {
                "canCreateLoanRequests": boolean
                "enabledNewOnboarding": number
            },
            "channels": Array<{
                "id": number
                "channelType": string
                "tag": string | null
                "value": string
            }>
        },
        "user": {
            "id": number
            "email": string
        },
    }
}

export interface IGetTokenResponse {
    token: string
}
