import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));

//reportWebVitals();





  // if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller ) {
  //     navigator.serviceWorker.controller.postMessage({
  //       type: 'MESSAGE_IDENTIFIER',
  //     });
  // }

