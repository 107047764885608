import { INotificationModel } from "./notification.model.store";

export const notificationFactory = (): INotificationModel => ({
    id: '0',
    title: '',
    content: '',
    read: false,
    created_at: new Date().toJSON(),
    loan_request_id: 0,
    type: '',
    updated_at: new Date().toJSON(),
    userId: 0
})
