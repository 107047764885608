import axios, { AxiosError } from 'axios'
import { setup } from './setup'

export interface IApiClientResponse<IData> {
    isSuccess: boolean
    isError: boolean
    data: IData
    status: number
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    url: string
    body: Object
}

export type TApiClientResponse<IData> = Promise<IApiClientResponse<IData>>

export const useApiClient = () => {

    const getHeader = () => ({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + setup.token,
        'x-access-token': setup.token
    })

    const get = async <IData>(url: string): TApiClientResponse<IData> => await axios({
        method: 'GET',
        url: url,
        headers: getHeader()
    })
        .then(response => {
            const newResponse: IApiClientResponse<IData> = {
                isSuccess: true,
                isError: false,
                data: response.data,
                status: response.status,
                method: 'GET',
                url: url,
                body: {}
            }
            return newResponse
        })
        .catch(e => {

            const response: IApiClientResponse<IData> = {
                isSuccess: false,
                isError: true,
                data: e && e.response ? e.response : null,
                status: e.response && e.response.status ? e.response.status : 0,
                method: 'GET',
                url: url,
                body: {}
            }

            console.error(response)

            return response
        })


    const post = async <IData>(url: string, params: any): TApiClientResponse<IData> => await axios({
        method: 'POST',
        url: url,
        headers: getHeader(),
        data: params
    })
        .then(response => {

            const newResponse: IApiClientResponse<IData> = {
                isSuccess: true,
                isError: false,
                data: response.data,
                status: response.status,
                method: 'POST',
                url: url,
                body: params
            }

            return newResponse
        })
        .catch(e => {
            const response: IApiClientResponse<IData> = {
                isSuccess: false,
                isError: true,
                data: e,
                status: e.response && e.response.status ? e.response.status : 0,
                method: 'POST',
                url: url,
                body: params
            }

            console.error(response)

            return response
        })



    const put = async  <IData>(url: string, params: any): TApiClientResponse<IData> => await axios({
        method: 'PUT',
        url: url,
        headers: getHeader(),
        data: params
    })
        .then(response => {

            const newResponse: IApiClientResponse<IData> = {
                isSuccess: true,
                isError: false,
                data: response.data,
                status: response.status,
                method: 'PUT',
                url: url,
                body: params
            }

            return newResponse
        })
        .catch(e => {
            const response: IApiClientResponse<IData> = {
                isSuccess: false,
                isError: true,
                data: e,
                status: e.response && e.response.status ? e.response.status : 0,
                method: 'PUT',
                url: url,
                body: params
            }

            console.error(response)

            return response
        })


    const del = async  <IData>(url: string): TApiClientResponse<IData> => await axios({
        method: 'DELETE',
        url: url,
        headers: getHeader()
    })
        .then(response => {
            const newResponse: IApiClientResponse<IData> = {
                isSuccess: true,
                isError: false,
                data: response.data,
                status: response.status,
                method: 'DELETE',
                url: url,
                body: {}
            }
            return newResponse
        })
        .catch(e => {

            const response: IApiClientResponse<IData> = {
                isSuccess: false,
                isError: true,
                data: e && e.response ? e.response : null,
                status: e.response && e.response.status ? e.response.status : 0,
                method: 'DELETE',
                url: url,
                body: {}
            }

            console.error(response)

            return response
        })

    const patch = async <IData>(url: string, params: any): TApiClientResponse<IData> => await axios({
        method: 'PATCH',
        url: url,
        headers: getHeader(),
        data: params
    })
        .then(response => {
            const newResponse: IApiClientResponse<IData> = {
                isSuccess: true,
                isError: false,
                data: response.data,
                status: response.status,
                method: 'PATCH',
                url: url,
                body: params
            }
            return newResponse
        })
        .catch(e => {

            const response: IApiClientResponse<IData> = {
                isSuccess: false,
                isError: true,
                data: e && e.response ? e.response : null,
                status: e.response && e.response.status ? e.response.status : 0,
                method: 'PATCH',
                url: url,
                body: params
            }

            console.error(response)

            return response
        })
    return {
        get,
        post,
        put,
        patch,
        del
    }
}