import React from 'react'
import { SimulatesModule, TOnDownload } from '../modules/Simulates.module'
import { ISimulate } from '../stores/simulate/simulate.model.store'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout';
import { ISimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component';
import { getValueToForm } from 'FormGenerator';
import { useHistory } from 'react-router';
import dataLayer from '../ga.js'
import { useIonViewDidEnter } from '@ionic/react';
import { IGetDinamicFormsResponseAdapted, ISendSimulateForConvertRequest, ISendSimulateForConvertRequestMexico, ISendSimulateForConvertResponse } from '../stores/convert/convert.model.store';
import { IConvertStore } from '../stores/convert/convert.interface.store';
import { IUserStore } from '../stores/user/user.use.store';
import { FormConsumerComponentProps } from 'FormConsumer';
import { traduceDinamicFormsToFormSectionListForViewModule, traduceFormSectionListToInputForRequest } from '../components/SimulateToConvertModal/SimulateToConvertModal.helper';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { ISimulateStore } from '../stores/simulate/simulate.interface.store';
import { ISetupStore } from '../stores/setup/setup.interface.store';


interface ISimulatesPageProps {
    userStore: IUserStore
    setupStore: ISetupStore
    notificationStore: INotificationStore
    simulateStore: ISimulateStore
    convertStore: IConvertStore
    onApiClientError: () => void
}

export const SimulatesPage = (props: ISimulatesPageProps) => {

    const history = useHistory()

    useIonViewDidEnter(() => {
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'simulate list',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });
        props.convertStore.actions.loadProductCategories()
    });
    
    const generateWarranty = (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        simulate: ISimulate
    ) => {

        if (warrantyDetailFormData.length) return [{
            "currency_id": Number(getValueToForm(warrantyFormData, '0.4')),
            "declared_price": getValueToForm(warrantyFormData, '0.5'),
            "productTypeId": Number(getValueToForm(warrantyFormData, '0.3')),
            "values": warrantyDetailFormData.map(input => ({
                "attribute_id": Number(input.id),
                "value": getValueToForm(warrantyDetailFormData, input.id)
            }))
        }]

        return [{
            "currency_id": simulate.product.currency_id,
            "declared_price": simulate.product.price,
            "productTypeId": simulate.product.type_id,
            "values": productFormData.filter(input => input.id !== '0.1').map(input => ({
                attribute_id: Number(input.id),
                value: getValueToForm(productFormData, input.id)
            })),
        }]
    }


    // const convertSimulateToLoanRequestForMexicoHandler = async (
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponseAdapted['data'],
    //     simulate: ISimulate
    // ) => {
    //     // tengo que hacer un nuevo modelo para mexico wey
    //     const convertRequest : ISendSimulateForConvertRequestMexico = {
    //         "simulation_id": simulate.id,
    //         "product": {
    //             "values": productFormData.filter(input => input.id !== 0.1).map(input=>({
    //                 attribute_id: input.id,
    //                 value: getValueToForm(productFormData,input.id)
    //             })),
    //             "currency_id": simulate.product.currency_id,
    //             "productTypeId": simulate.product.type_id,
    //             "is_warranted": props.convertStore.states.productCategories.find(categorie=> categorie.id=== simulate.product.type_id)?.is_warranted ? true : false,
    //             "nombre": '', /// no seeee
    //             "price": simulate.product.price
    //         },
    //         "loanRequest": {
    //             "feeWeight": '0.5',
    //             "frecuency": Number(simulate.data.loan.fees), // no estoy seguro de este dato
    //             "term": simulate.data.quantity_fees,
    //             "warranty_type": '',
    //             "loanRequestType": String(simulate.product.category_id), // va el tipo del producto ? o.o
    //             "requestAmount": simulate.amount //0 // monto que solicito en el primer formulario
    //         },
    //         "score": 0,
    //         "warranties": generateWarranty( productFormData, warrantyFormData, warrantyDetailFormData, simulate ),
    //         "forms": dinamicFormsData.map(form=>({
    //             "id": form.id,
    //             "inputs": form.inputs.map(input=>({
    //                 "id": input.id,
    //                 "label": input.type !== 'title' ? input.label : '',
    //                 "values": [getValueToForm(form.inputs, input.id)]
    //             }))
    //         }))
    //     }

    //     const result = await props.convertStore.actions.sendRequestForConvertMexico(convertRequest)
    //     if(result.isError) props.onApiClientError()
    //     return result
    // }

    const convertSimulateToLoanRequestHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList'],
        simulate: ISimulate
    ) => {

        const convertRequest: ISendSimulateForConvertRequest = {
            "simulation_id": simulate.id,
            "product": {
                "values": productFormData.filter(input => input.id !== '0.1').map(input => ({
                    attribute_id: Number(input.id),
                    value: getValueToForm(productFormData, input.id)
                })),
                "currency_id": simulate.product.currency_id,
                "productTypeId": simulate.product.type_id,
                "is_warranted": props.convertStore.states.productCategories.find(categorie => categorie.id === simulate.product.type_id)?.is_warranted ? true : false,
                "nombre": '', /// no seeee
                "price": simulate.product.price
            },
            "loanRequest": {
                "feeWeight": '0.5',
                "frecuency": Number(simulate.data.loan.fees), // no estoy seguro de este dato
                "term": simulate.data.quantity_fees,
                "warranty_type": '',
                "loanRequestType": String(simulate.product.category_id), // va el tipo del producto ? o.o
                "requestAmount": simulate.amount //0 // monto que solicito en el primer formulario
            },
            "score": 0,
            "warranties": generateWarranty(productFormData, warrantyFormData, warrantyDetailFormData, simulate),
            "inputs": traduceFormSectionListToInputForRequest(formSectionList, props.convertStore.states.dinamicFormsResponse)
        }

        await props.convertStore.actions.sendRequestForConvert(convertRequest)
    }

    const onDownloadSimulateHandler = async (id: number, params: TOnDownload) => {
        const urlResponse = await props.simulateStore.getPDFUrl(id, params)
        window.open(urlResponse, '_blank')
    }

    const onDeleteHandler = async (id: number) => {
        await props.simulateStore.onDeleteSimulate(id)
    }

    const onRedirectToLoanRequestDetailHandler = (loanRequestId: number) => {
        history.push(`/loanRequests/${loanRequestId}`)
    }

    const onRedirectToSimulateDetailHandler = (simulateId: number) => {
        history.push(`/simulator/${simulateId}`)
    }

    const onRedirectToOnBoaringHandler = () => {
        history.push('/onBoarding')
    }

    const onRedirectToEditSimulateHandler = (simulateId: number)=> {
        history.push(`/simulator/edit/${simulateId}`)
    }

    return (
        <ContentWithNavigationLayout notificationStore={props.notificationStore} userStore={props.userStore}>
            <SimulatesModule
                countryAlias={props.setupStore.countryAlias}
                simulateList={props.simulateStore.simulateList}
                onDownload={onDownloadSimulateHandler}
                onDelete={onDeleteHandler}
                convertSimulateToLoanRequest={convertSimulateToLoanRequestHandler}
                getProductTypeForConvert={props.convertStore.actions.loadProductTypeForConvert}
                productCategories={props.convertStore.states.productCategories}
                productTypeForSendConvertRequest={props.convertStore.states.productTypeForSendConvertRequest}
                warrantyProductTypeForConvert={props.convertStore.states.warrantyProductTypeForConvert}
                onGetWarrantyProductTypeForConvert={props.convertStore.actions.loadWarrantyProductTypeForConvert}
                getSimulatesList={props.simulateStore.getSimulatesList}
                formSectionList={traduceDinamicFormsToFormSectionListForViewModule(props.convertStore.states.dinamicFormsResponse)}
                getDinamicForms={props.convertStore.actions.loadDinamicForms}

                convertResponse={props.convertStore.states.convertResponse}
                onRedirectToLoanRequestDetail={onRedirectToLoanRequestDetailHandler}
                isMobile={props.setupStore.isMobile}
                onRedirectToSimulateDetail={onRedirectToSimulateDetailHandler}
                onRedirectToOnBoaring={onRedirectToOnBoaringHandler}
                onRedirectToEditSimulate={onRedirectToEditSimulateHandler}


            />
        </ContentWithNavigationLayout>
    )
}