import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
`

export const Col = styled.span<{width: number}>`
    display: flex;
    flex-direction: column;
    width: ${props=>props.width}%;
`

export const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #959595;
    margin: 0px;
    padding: 0px;
`

export const SubTitle = styled.h1`
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    color: #153F57;
`

export const Resume = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 19px;
    color: #153F57;
    margin: 0px;
    margin-bottom: 5px;
    padding: 0px;
`