import React from 'react'
import styled from 'styled-components'

export const ComercialPolicyCardContainer = styled.div`
    border: 1px solid #ccc;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    margin-top: 15px;
`

export const ComercialPolicyCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`

export const ComercialPolicyCardCell = styled.span<{ width: number, aling: 'right' | 'center' | 'left' }>`
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width}%;
    justify-content: ${props => props.aling === 'left' ? 'start' : props.aling === 'center' ? 'center' : 'end'};
    align-items: ${props => props.aling === 'left' ? 'start' : props.aling === 'center' ? 'center' : 'end'};
`

export const ComercialPolicyStatusTagButton = styled.button<{ status: 'success' | 'danger' | 'warning' | 'none' }>`
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    font-weight: bold;
    color: white;
    font-size: 15px;
    width: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${props => props.status === 'success' ? '#ACCE08' : props.status === 'danger' ? '#CE0808' : props.status === 'warning' ? 'orange' : 'grey'};
`

export const ComercialPolicyTitleId = styled.h2`
    color: #B8B8B8;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
    /* margin-bottom: 2px; */
    display: flex;
`

export const ComercialPolicyTitle = styled.h1`
    color: #B8B8B8;
    font-size: 15px;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`

export const ComercialPolicyFundingPartner = styled.h1`
    color: #B8B8B8;
    font-size: 15px;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
`

export const ComercialPolicySelectButton = styled.button`
    border: none;
    border: 5px;
    width: auto;
    background: #176592;
    color: white;
    margin: 0px;
    padding: 10px 20px;
    border-radius: 3px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const ComercialPolicyAcronym = styled.label`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`

export const ComercialPolicyMaxAmount = styled.div`
    color: #153F57;
    font-size: 23px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    margin-top: 5px;
`

export const ComercialPolicyTimeValue = styled.div`
    color: #153F57;
    font-size: 18px;
    padding: 0px;
    font-weight: bold;
    margin: 0px;
`

export const ComercialPolicyTimeUnit = styled.div`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`

export const ComercialPolicySubsidizedRateValue = styled.div`
    color: #153F57;
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
`

export const ComercialPolicySubsidizedRateLabel = styled.label`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`

export const ComercialPolicyLoanCapacityLabel = styled.label`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`

export const ComercialPolicyLoanCapacityValue = styled.div`
    color: #153F57;
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
`


////
export const ComercialPolicySubsidizedFlag = styled.div`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`

export const ComercialPolicyAsimetricFlag = styled.div`
    color: #153F57;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
`


export const ComercialPolicyTrackFlag = styled.div<{ status: 'success' | 'danger' | 'warning' | 'dark' }>`
    color: ${props => props.status === 'success' ? 'green' : props.status === 'danger' ? 'red' : props.status === 'warning' ? 'orange' : 'grey'};
    font-size: 13px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
`

export const ComercialPolicyHeaderCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: -13.5px;
    position: absolute;
    width: 100%;
`


export const ComercialPolicyHeaderBadge = styled.div`
    font-size: 11px;
    padding: 6px 13px;
    margin: -0px 5px 0px 5px;
    background: black;
    color: white;
    border-radius: 20px;

    @media (min-width: 450px) {
        font-size: 12px;  
        padding: 5px 19px;
    }
`