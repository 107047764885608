import { useState, useEffect } from 'react'
import { IActionResult, TActionResult, useStoreUtil } from '../../utils/useStore.util'
import { IConvertStore, TUseConvertStore } from './convert.interface.store'
import { 
    ISendSimulateForConvertRequest, 
    ISendSimulateForConvertResponse, 
    ICategoryResponse, 
    IProductTypeResponse, 
    productTypeRequestFactory, 
    IFieldGroup
} from './convert.model.store'
import { useConvertService } from './convert.service.store'


export const useConvertStore: TUseConvertStore = () => {

    const convertUtil = useStoreUtil()

    const convertService = useConvertService()

    const [dinamicFormsResponse, setDinamicFormsResponse] = useState<IConvertStore['states']['dinamicFormsResponse']>([])
    // const [dinamicShemaResponse, setDinamicShemaResponse] = useState<IConvertStore['states']['dinamicShemaResponse']>([])


    const [ productCategories, setProductCategories ] = useState<IConvertStore['states']['productCategories']>([])

    const [warrantyProductTypeForConvert, setWarrantyProductTypeForConvert] = useState<IConvertStore['states']['warrantyProductTypeForConvert']>({
        id: 0,
        name: '',
        attributes: []
    })

    const [productTypeForSendConvertRequest, setProductTypeForSendConvertRequest] = useState<IConvertStore['states']['productTypeForSendConvertRequest']>({
        id: 0,
        name: '',
        attributes: []
    })

    const [convertResponse, setConvertResponse] = useState<IConvertStore['states']['convertResponse']>({
        "loan_request_id": 0,
        "partner_name": '',
        "contact_id": 0,
    })

    const loadWarrantyProductTypeForConvert: IConvertStore['actions']['loadWarrantyProductTypeForConvert'] = async (id) => {
        const response =  await convertService.getProductType(id)
        if(response.isSuccess){
            setWarrantyProductTypeForConvert(response.data.data)
            return convertUtil.createSuccessResult(null)
        }else {
            return convertUtil.createErrorResult(null)
        }
    }

    const loadProductTypeForConvert: IConvertStore['actions']['loadProductTypeForConvert'] = async (id) => {
        const response =  await convertService.getProductType(id)
        if(response.isSuccess) {
            setProductTypeForSendConvertRequest(response.data.data)
            return convertUtil.createSuccessResult(null)
        } else {
            return convertUtil.createErrorResult(null)
        }
    }

    const loadDinamicForms: IConvertStore['actions']['loadDinamicForms'] = async (policieRiskId) => {
        
        const response = await convertService.getDinamicForms(policieRiskId)
        if(response.isSuccess) {
            const prosessedResponse: Array<IFieldGroup> = response.data.fields_group.map(group=>({
                ...group,
                fields: group.fields.filter(field=> field.onboarding )
            }))

            setDinamicFormsResponse(prosessedResponse)
            
            return convertUtil.createSuccessResult(null)
        } else {
            return convertUtil.createErrorResult(null)
        }    
    }

    // const loadDinamicFormsMexico: IConvertStore['actions']['loadDinamicFormsMexico'] = async (policieId) => {
    //     const response = await convertService.getDinamicFormsMexico(policieId)
    //     if(response.isSuccess){
    //         setDinamicShemaResponse(response.data.data)
    //         return convertUtil.createSuccessResult(null)
    //     } else {
    //         return convertUtil.createErrorResult(null)
    //     }
    // }


    const loadProductCategories: IConvertStore['actions']['loadProductCategories'] = async () => {
        const categoriesResponse = await convertService.getProductCategories()
        if(categoriesResponse.isSuccess) {
            setProductCategories(categoriesResponse.data.data)
            return convertUtil.createSuccessResult(null)

        }else {
            return convertUtil.createErrorResult(null)
        }
    }

    const sendRequestForConvert: IConvertStore['actions']['sendRequestForConvert'] = async (param) => {
        const response = await convertService.sendRequestSimulate(param)
        if(response.isSuccess){
            setConvertResponse(response.data)
            resetStore()
            return convertUtil.createSuccessResult(null)

        }else {
            return convertUtil.createErrorResult(null)
        }
    }

    // const sendRequestForConvertMexico: IConvertStore['actions']['sendRequestForConvertMexico'] = async (param) => {
    //     const response = await convertService.sendRequestSimulateMexico(param)
    //     if(response.isSuccess){
    //         setConvertResponse(response.data)
    //         resetStore()
    //         return convertUtil.createSuccessResult(null)

    //     }else {
    //         return convertUtil.createErrorResult(null)
    //     }
    // }


    const resetStore: IConvertStore['actions']['resetStore'] = () => {
        setProductTypeForSendConvertRequest(productTypeRequestFactory())
        setWarrantyProductTypeForConvert(productTypeRequestFactory())
        setDinamicFormsResponse([])
    }

    return {
        states: {
            dinamicFormsResponse,
            // dinamicShemaResponse,
            warrantyProductTypeForConvert,
            productTypeForSendConvertRequest,
            productCategories,
            convertResponse,
        },
        actions: {
            loadWarrantyProductTypeForConvert,
            loadProductTypeForConvert,
            loadDinamicForms,
            // loadDinamicFormsMexico,
            loadProductCategories,
            sendRequestForConvert,
            // sendRequestForConvertMexico,
            resetStore
        }
    }
}


