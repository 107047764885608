import { IonButton, IonCol, IonRow } from '@ionic/react'
import React from 'react'
import logo from '../assets/small-logo.svg'

interface INoDataComponent {
    title: string,
    message: string
    button: string
    href: string
    onSubmit: (url: string) => void
}

export const NoDataComponent: React.FC<INoDataComponent> = (props) => {
    return (
        <>
            <IonRow>
                <IonCol className="ion-text-center">
                    <br></br>
                    <br></br>
                    <img src={logo} alt="Siembro"/>
                    <br></br>
                    <br></br>
                    <h4>{props.title}</h4>
                    <p>{props.message}</p>
                    <br></br>
                    <IonButton color="primary" onClick={()=>props.onSubmit(props.href)}>{props.button}</IonButton>
                </IonCol>
            </IonRow>

        </>
    )
}



interface INoDataComponentWithActionComponent {
    title: string,
    message: string
    button: string
    onClickButton: ()=> void
}

export const NoDataComponentWithAction: React.FC<INoDataComponentWithActionComponent> = (props) => {
    return (
        <>
            <IonRow>
                <IonCol className="ion-text-center">
                    <br></br>
                    <br></br>
                    <img src={logo} alt="Siembro"/>
                    <br></br>
                    <br></br>
                    <h4>{props.title}</h4>
                    <p>{props.message}</p>
                    <br></br>
                    <IonButton onClick={()=>props.onClickButton()}>{props.button}</IonButton>
                </IonCol>
            </IonRow>
        </>
    )
}