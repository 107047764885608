export const divideListToTwoParts = <T>(array: Array<T>, callBackCondition: (item: T) => boolean): {aceptacedList: Array<T>, deniedList: Array<T>} => {

    const aceptacedList: Array<T> = []
    const deniedList: Array<T> = []

    array.forEach(item=>{
        if(callBackCondition(item) === true) aceptacedList.push(item)
        if(callBackCondition(item) === false) deniedList.push(item)
    })

    return {
        aceptacedList,
        deniedList
    }
}