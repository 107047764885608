import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonList, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'

import { ICategoriesResponse } from '../../stores/onBoarding/onBoarding.model.store'

import { getCountryAlias } from '../../stores/setup/setup.use.store'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { SteperGeneratorComponent } from '../SteperGenerator.component'
import { FormConsumerComponentProps, FormConsumerComponent, IFormSection } from 'FormConsumer'
export interface ISimulateToConvertModalComponent {
    isOpen: boolean,
    productCategoryName: string
    productTypeName: string
    productFormDataInputs: TForm
    warrantyDetailFormDataInputs: TForm
    categories: ICategoriesResponse['data'],
    formSectionList: FormConsumerComponentProps['formSectionList']
    // dinamicShemaFormsResponse: IGetDinamicFormsResponseAdapted['data']
    // countryAlias: 'ar' | 'mx'
    onClose(): void,
    onSubmit(
        productFormData: TForm, // el nombre de esta propiedad es probable que se lo cambiue
        warrantyFormData: TForm,
        warrantyDetailData: TForm,
        formSectionList: FormConsumerComponentProps['formSectionList']
    ): Promise<void>,

    // onSubmitForMexico(
    //     formData: IFormGeneratorComponent['form'],
    //     warrantyFormData: IFormGeneratorComponent['form'],
    //     warrantyDetailData: IFormGeneratorComponent['form'],
    //     dinamicFormsData: IGetDinamicFormsResponseAdapted['data']
    // ): Promise<void>,

    onGetWarrantyInputs(productTypeId: number): Promise<void>
}

export const SimulateToConvertModalComponent = (props: ISimulateToConvertModalComponent) => {

    const [currentChildren, setCurrentChildreen] = React.useState<number>(0)

    const [productFormData, setProductFormData] = React.useState<TForm>([])
    const [warrantyDetailFormData, setWarrantyDetailFormData] = React.useState<TForm>([])
    const [warrantyFormData, setWarrantyFormData] = React.useState<TForm>([
        {
            id: '0.2',
            type: 'options',
            label: 'Categoría',
            value: '',
            placeholder: 'Campo Requerido',
            required: true,
            error: false,
            options: props.categories.map(categorie => ({
                value: categorie.name,
                label: categorie.name,
                // optionsToVincule: categorie.product_types.map(productType => ({
                //     value: String(productType.id),
                //     label: productType.name
                // }))
            })),
            disabled: false,
            hidden: false,
            size: 6,
        },
        {
            id: '0.3',
            type: 'vinculedOptions',
            label: 'Producto',
            value: '',
            placeholder: 'Campo Requerido',
            required: true,
            error: false,
            disabled: false,
            hidden: false,
            vinculedId: '0.2',
            size: 6,
            vinculedOptions: props.categories.map(categorie => ({
                value: categorie.name,
                options: categorie.product_types.map(productType => ({
                    value: String(productType.id),
                    label: productType.name
                }))
            })),
        },
        {
            id: '0.4',
            type: 'options',
            label: 'Moneda',
            value: '',
            placeholder: 'Campo Requerido',
            required: true,
            disabled: false,
            hidden: false,
            size: 6,
            error: false,
            options: [
                {
                    label: getCountryAlias() === 'ar' ? 'Pesos Argentinos' : getCountryAlias() === 'mx' ? 'Pesos Mexicanos' : 'Pesos',
                    value: '2'
                },
                {
                    label: 'Dolares',
                    value: '1'
                }
            ]
        },
        {
            id: '0.5',
            type: 'number',
            label: 'Precio',
            value: '',
            placeholder: 'Campo Requerido',
            required: true,
            disabled: false,
            hidden: false,
            error: false,
            minValue: 0,
            maxValue: 999999999999999999999999999,
            size: 6
        },
    ])

    // const [dinamicShemaFormsData, setDinamicShemaFormsData] = React.useState<IGetDinamicFormsResponseAdapted['data']>([])


    React.useEffect(() => {
        setCurrentChildreen(0)
    }, [])



    React.useEffect(() => {
        const newFormData = [...props.productFormDataInputs]
        const isWarrantyInputFinded = productFormData.find(input => input.id === '0.1')

        newFormData.push({
            id: '0.1',
            type: 'options',
            label: 'Producto como Garantía?',
            value: isWarrantyInputFinded?.type === 'options' ? isWarrantyInputFinded.value : 'si',
            placeholder: 'Campo Requerido',
            required: true,
            disabled: false,
            hidden: false,
            options: [{ value: 'si', label: 'Si' }, { value: 'no', label: 'No' }],
            error: false,
            size: 6,
        })

        setProductFormData(newFormData)

    }, [props.productFormDataInputs])



    React.useEffect(() => {
        let newInputs: TForm = [
            {
                id: '0.2',
                type: 'options',
                label: 'Categoria',
                value: getValueToForm(warrantyFormData, '0.2'),
                placeholder: 'Campo Requerido',
                required: true,
                disabled: false,
                error: false,
                hidden: false,
                options: props.categories.map(categorie => ({
                    value: categorie.name,
                    label: categorie.name,
                })),
                size: 6,
            },
            {
                id: '0.3',
                type: 'vinculedOptions',
                label: 'Producto',
                value: getValueToForm(warrantyFormData, '0.3'),
                placeholder: 'Campo Requerido',
                required: true,
                disabled: false,
                hidden: false,
                error: false,
                vinculedId: '0.2',
                vinculedOptions: props.categories.map(categorie => ({
                    value: categorie.name,
                    options: categorie.product_types.map(productType => ({
                        value: String(productType.id),
                        label: productType.name
                    }))
                })),
                size: 6
            },
            {
                id: '0.4',
                type: 'options',
                label: 'Moneda',
                value: getValueToForm(warrantyFormData, '0.4') ? getValueToForm(warrantyFormData, '0.4') : '2',
                placeholder: 'Campo Requerido',
                required: true,
                disabled: false,
                hidden: false,
                error: false,
                options: [
                    {
                        label: getCountryAlias() === 'ar' ? 'Pesos Argentinos' : getCountryAlias() === 'mx' ? 'Pesos Mexicanos' : 'Pesos',
                        value: '2'
                    },
                    {
                        label: 'Dolares',
                        value: '1'
                    }
                ],
                size: 6
            },
            {
                id: '0.5',
                type: 'number',
                label: 'Precio',
                value: getValueToForm(warrantyFormData, '0.5'),
                placeholder: 'Campo Requerido',
                required: true,
                disabled: false,
                hidden: false,
                error: false,
                size: 6,
                minValue: 0,
                maxValue: 99999999999999999999999
            }
        ]

        setWarrantyFormData([...newInputs])

    }, [props.categories])


    React.useEffect(() => {
        setWarrantyDetailFormData(props.warrantyDetailFormDataInputs)
    }, [props.warrantyDetailFormDataInputs])


    const onSubmitProductFormHandler = async () => {
        const warrantyInputValue = getValueToForm(productFormData, '0.1')

        if (warrantyInputValue === 'no' && warrantyFormData.length) {
            setCurrentChildreen(1)
            return
        }

        if(props.formSectionList.length) {
            setCurrentChildreen(1)
            return
        }

        await props.onSubmit(productFormData, warrantyFormData, warrantyDetailFormData, [])


        // if(props.countryAlias === 'ar') await props.onSubmit(productFormData, warrantyFormData, warrantyDetailFormData, [])
        // else if(props.countryAlias === 'mx') await props.onSubmitForMexico(productFormData, warrantyFormData, warrantyDetailFormData, dinamicShemaFormsData)
    }

    const onSubmitWarrantyFormHandler = async () => {
        const productTypeValue = Number(getValueToForm(warrantyFormData, '0.3'))
        await props.onGetWarrantyInputs(productTypeValue)
        setCurrentChildreen(2)
    }

    const onSubmitWarrantyDetailFormHandler = async () => {
        // diferenciar por pais
        // if(props.countryAlias === 'ar' && !props.formSectionList.length) {
        //     await props.onSubmit(productFormData, warrantyFormData, warrantyDetailFormData, [])
        //     return 
        // }


        if(!props.formSectionList.length) {
            await props.onSubmit(productFormData, warrantyFormData, warrantyDetailFormData, [])
            return 
        }

        // if(props.countryAlias === 'mx' && !props.formSectionList.length) {
        //     await props.onSubmitForMexico(productFormData, warrantyFormData, warrantyDetailFormData, dinamicShemaFormsData)
        //     return 
        // }

        setCurrentChildreen(3)
    }

    const onBackStepHandler = (id: number) => {
        setCurrentChildreen(id)
    }

    const getIsProductWarranty = () => {
        const isWarrantyInputFinded = productFormData.find(product => product.id === '0.1')
        if (!isWarrantyInputFinded) return false
        if (isWarrantyInputFinded.type === 'options' && isWarrantyInputFinded.value === '') return true
        if (isWarrantyInputFinded.type === 'options' && isWarrantyInputFinded.value === 'si') return true
        else return false
    }


    const setProductFormDataHandler = (newFormData: TForm) => {

        const conditionInputFindedFromNewFormData = newFormData.find(input => input.id === '1')
        const conditionInputFindedFromProductFormData = productFormData.find(input => input.id === '1')

        if (!conditionInputFindedFromNewFormData || conditionInputFindedFromNewFormData.type !== 'options') {
            setProductFormData(newFormData)
            return
        }

        if (!conditionInputFindedFromProductFormData || conditionInputFindedFromProductFormData.type !== 'options') {
            setProductFormData(newFormData)
            return
        }

        if (
            conditionInputFindedFromNewFormData.value === 'NUEVO' &&
            conditionInputFindedFromProductFormData.value !== 'NUEVO'
        ) {
            const newFormDataWithPreloadData = newFormData.map(input => {
                if (input.id === '4' && input.type !== 'checkBoxGroup') return { ...input, value: String(new Date().getFullYear()) }
                return input
            })
            setProductFormData(newFormDataWithPreloadData)
            return
        }

        setProductFormData(newFormData)
        return
    }

    const onFormConsumerSubmit = async (formSectionList: Array<IFormSection>) => {
        await props.onSubmit(productFormData, warrantyFormData, warrantyDetailFormData, formSectionList)
        // setCurrentChildreen(0)
    }


    // const setDinamicShemaFormsDataHandler = (index: number, formData: IForm) => {
        // const dinamicFormDataCopy = [...dinamicShemaFormsData]
        // dinamicFormDataCopy[index].inputs = formData
        // setDinamicShemaFormsData(dinamicFormDataCopy)
    // }

    // React.useEffect(() => {
    //     setDinamicShemaFormsData(props.dinamicShemaFormsResponse)
    // }, [props.dinamicShemaFormsResponse])

    // const onSubmitDinamicFormHandler = async (index: number) => {
    //     if((index + 1) === dinamicShemaFormsData.length) {
    //         await props.onSubmitForMexico(productFormData, warrantyFormData, warrantyDetailFormData, dinamicShemaFormsData)
    //         return
    //     }
    //     setCurrentChildreen(currentChildren + 1)
    //     return
    // }

    const generateStepsList = () => {
        let steps = []

        steps.push((
            <div key={'convertModalStepNumber1'}>
                <IonList style={{ padding: '1% 4%' }}>
                    <IonItem>
                        <IonLabel>Categoria:</IonLabel>
                        <p>{props.productCategoryName}</p>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Producto:</IonLabel>
                        <p>{props.productTypeName}</p>
                    </IonItem>
                </IonList>
                <br></br>
                <IonTitle className="ion-text-center">Datos del producto</IonTitle>
                <FormGenerator
                    dataTestId=''
                    form={productFormData}
                    setForm={setProductFormDataHandler}
                    onSubmit={onSubmitProductFormHandler}
                    submitText='Guardar'
                    actions={[]}
                    onAction={()=>{}}
                    onValidForm={()=>{}}
                    onInvalidForm={()=>{}}
                    isShowSubmit={true}
                />
            </div>
        ))

        if (getIsProductWarranty() === false) steps.push(
            <div key={'convertModalStepNumber2'}>
                <IonTitle className="ion-text-center">Datos de la garantia</IonTitle>
                <FormGenerator
                    dataTestId=''
                    form={warrantyFormData}
                    setForm={setWarrantyFormData}
                    onSubmit={onSubmitWarrantyFormHandler}
                    submitText='Guardar' // mas adelante hago un getter para cambiar este texto de forma reactiva
                    actions={[]}
                    onAction={()=>{}}
                    onValidForm={()=>{}}
                    onInvalidForm={()=>{}}
                    isShowSubmit={true}
                />
            </div >
        )

        if (getIsProductWarranty() === false) steps.push(
            <div key={'onboardingStepNumber3'}>
                <FormGenerator
                    dataTestId=''
                    key={'onboardingStepNumber3'}
                    form={warrantyDetailFormData}
                    setForm={setWarrantyDetailFormData}
                    onSubmit={onSubmitWarrantyDetailFormHandler}
                    submitText='Guardar' // mas adelante hago un getter para cambiar este texto de forma reactiva
                    actions={[]}
                    onAction={()=>{}}
                    onValidForm={()=>{}}
                    onInvalidForm={()=>{}}
                    isShowSubmit={true}
                />
            </div>
        )

        if (props.formSectionList.length) steps.push(
            <div key={'onboardingStepNumber4'} style={{padding: '0% 10% 5% 10%'}}>
                <br/>
                <FormConsumerComponent formSectionList={props.formSectionList} onSubmit={onFormConsumerSubmit} />
            </div>
        )

        // if(props.countryAlias === 'mx') {
        //     dinamicShemaFormsData.forEach((form, index)=> {
        //         steps.push (
        //             <div  key={`onboardingStepDinamicNumber${index}`}>
        //                 <br></br>
        //                 <IonTitle className="ion-text-center">{form.name}</IonTitle>
        //                 <br></br>
        //                 <FormGeneratorComponent
        //                     form={form.inputs}
        //                     setForm={(formData: IForm)=>setDinamicShemaFormsDataHandler(index, formData)}
        //                     onSubmit={()=>onSubmitDinamicFormHandler(index)}
        //                     submitText='Guardar'
        //                     columns={2}
        //                 />
        //             </div>
        //         )
        //     })
        // }
        
        return steps
    }


    const onCloseHandler = () => {
        setCurrentChildreen(0)
        props.onClose()
    }

    return (
        <IonModal
            isOpen={props.isOpen}
            swipeToClose={true}
            onDidDismiss={() => onCloseHandler()}
            backdropDismiss={false}
            cssClass='my-small-modal'
        >
            <IonHeader>
                <IonToolbar>
                    <IonRow>
                        <IonCol size="6"><IonTitle>Enviar solicitud</IonTitle></IonCol>
                    </IonRow>
                    <IonButtons slot="end">
                        <IonButton onClick={props.onClose}>CERRAR</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <SteperGeneratorComponent
                    currentStep={currentChildren}
                    onBackStep={onBackStepHandler}
                    isDisabledButtons={false}
                >
                    {generateStepsList()}
                </SteperGeneratorComponent>
            </IonContent>
        </IonModal>
    )
}