import React from 'react'
import { useStoreUtil } from '../../utils/useStore.util'
import { useCreditHelper } from './credit.helper.store'
import { ICreditStore } from './credit.interface.store'
import { useCreditService } from './credit.service.store'

const useCreditStore = (): ICreditStore => {

    const storeUtil = useStoreUtil()
    const creditHelper = useCreditHelper()
    const creditService = useCreditService()
    const [creditList, setCreditList] = React.useState<ICreditStore['creditList']>([])

    const getCreditList: ICreditStore['getCreditList'] = async () => {
        const response = await creditService.getCreditList()
        if(response.isError) return storeUtil.createErrorResult([])
        setCreditList(response.data)
        return storeUtil.createSuccessResult(response.data)
    }

    const getCreditDetail: ICreditStore['getCreditDetail'] = async (creditId) => {
        const creditFidned = creditList.find(credit=>credit.id === creditId)
        if(creditFidned) return storeUtil.createSuccessResult(creditFidned)
        const response = await creditService.getCredit(creditId)    
        if(response.isError) return storeUtil.createErrorResult(creditHelper.createCredit())
        setCreditList([...creditList, response.data])
        return storeUtil.createSuccessResult(response.data)
    }    

    return {creditList, getCreditList, getCreditDetail}
}

export { useCreditStore }