import { IonAlert, IonLoading } from '@ionic/react'
import { props } from 'cypress/types/bluebird'
import { FormConsumerComponentProps } from 'FormConsumer'
import {ITitleElement, IInputText, JustInputNumber, IInputOptions, IInputNumber} from 'FormGenerator'
import React from 'react'
import { DownloadSimulateModalComponent, TOnSubmitParam } from '../components/DownloadSimulateModal.component'
import { NoDataComponent } from '../components/NoData.component'
import { SimulateDetailComponent } from '../components/SimulateDetail.component'
import { ISimulateToConvertModalComponent, SimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component'
import { IGetDinamicFormsResponse, IGetDinamicFormsResponseAdapted, ISendSimulateForConvertResponse } from '../stores/convert/convert.model.store'
import { ICategoryResponse, IProductTypeResponse, ISimulate } from '../stores/simulate/simulate.model.store'
import { TActionResult } from '../utils/useStore.util'
import { TOnDownload } from './Simulates.module'
interface IOnBoardingDetailModule {
    simulate: ISimulate
    isMobile: boolean
    countryAlias: 'ar' | 'mx'
    convertResponse: ISendSimulateForConvertResponse
    productCategories: ICategoryResponse['data']
    productTypeForSendConvertRequest: IProductTypeResponse['data']
    warrantyProductTypeForConvert: IProductTypeResponse['data']
    formSectionList: FormConsumerComponentProps['formSectionList']
    // dinamicFormsResponse: IGetDinamicFormsResponseAdapted['data']


    // convertSimulateToLoanRequestForMexico(
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponse['data'],
    //     simulate: ISimulate
    // ): TActionResult<null>
    onRemove(id: number): Promise<void>
    onGetWarrantyProductTypeForConvert(id: number): void
    getProductTypeForConvert(id: number): void
    convertSimulateToLoanRequest(
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList'],
        simulate: ISimulate
    ): Promise<void>
    onRedirectToLoanRequestDetail: (loanRequestId: number) => void
    onRedirectToSimulateList: () => void
    getDinamicForms(policieId: number): TActionResult<null>
    // getDinamicFormsMexico(policieId: number): TActionResult<null>
    onDownload(id: number, options: TOnSubmitParam): void
}

export const SimulateDetailModule: React.FC<IOnBoardingDetailModule> = (props) => {

    const [isOpenDonwloanModal, setIsOpenDonwloanModal] = React.useState<boolean>(false)
    const [isOpenConvertModal, setIsOpenConvertModal] = React.useState<boolean>(false)

    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)
    const [isOpenConvertSimulateAlert, setIsOpenConvertSimulateAlert] = React.useState<boolean>(false)
    const [isOpenRemoveAlert, setIsOpenRemoveAlert] = React.useState<boolean>(false)

    const onOpenDownloadModalHandler = (id: number) => {
        setIsOpenDonwloanModal(true)
    }

    const onOpenConvertModalHandler = async (idParam: number) => { // sta mal el nombre de esto
        setIsLoadingData(true)
        await props.getProductTypeForConvert(props.simulate.product.type_id)

        
        // props.getDinamicForms(props.simulate.commercial_policy_id)
        // if(props.countryAlias === 'mx') props.getDinamicFormsMexico(props.simulate.commercial_policy_id)

        setIsLoadingData(false)
        setIsOpenConvertModal(true)
    }

    const onCloseFromRemoveAlert = () => {
        setIsOpenRemoveAlert(false)
    }

    const onRemoveFromDetailHandler = () => {
        setIsOpenRemoveAlert(true)
    }

    const onRemoveFromAlertHandler = async () => {
        setIsLoadingData(true)
        await props.onRemove(props.simulate.id)
        setIsLoadingData(false)
    }

    const onCloseDownloadModalHandler = () => setIsOpenDonwloanModal(false)

    const onCloseConvertModalHandler = () => setIsOpenConvertModal(false)


    const onSubmitFromDownloadSimulateModalHandler = (params: TOnDownload) => {
        props.onDownload(props.simulate.id, params)
        onCloseDownloadModalHandler()
    }

    const onViewDetailSimulateConvertedHandler = () => props.onRedirectToLoanRequestDetail(props.convertResponse.loan_request_id)

    const onSubmitHandlerFromNoDataComponent = () => props.onRedirectToSimulateList()

    const onCloseFromViewAlertHandler = () => setIsOpenConvertSimulateAlert(false)

    const getCurrentCategoryName = (): string => props.simulate.product.categoria

    const getCurrentProductTypeName = (): string => props.simulate.product.nombre

    const getProductTypeInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {

        return props.productTypeForSendConvertRequest.attributes.map(attribute => {
            if (attribute.type === 'string')  {
                const inputText: IInputText = {
                    id: String(attribute.attribute_id),
                    type: 'text',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    disabled: false,
                    hidden: false,
                    error: false,
                    maxLength: 80,
                    minLength: 1,
                    size: 6,
                    regex: ''
                }

                return inputText
            }
            else if (attribute.type === 'option') {
                const inputOptions: IInputOptions = {
                    id: String(attribute.attribute_id),
                    type: 'options',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo requerido',
                    required: attribute.is_required,
                    options: attribute.options.map(option => ({
                        value: option.value,
                        label: option.value
                    })),
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6
                }

                return inputOptions
            }
            else if (attribute.type === 'number') {
                const inputNumber: IInputNumber = {
                    id: String(attribute.attribute_id),
                    type: attribute.type,
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    disabled: false,
                    hidden: false,
                    error: false,
                    size: 6,
                    minValue: 0,
                    maxValue: 9999999999999999999999999
                }

                return inputNumber
            }

            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                size: 12,
                hidden: false,
                value: ''
            }

            return titleInput
        })
    }

    const getWarrantyInputs = (): ISimulateToConvertModalComponent['productFormDataInputs'] => {
        return props.warrantyProductTypeForConvert.attributes.map(attribute => {
            if (attribute.type === 'string') {
                const inputText: IInputText = {
                    id: String(attribute.attribute_id),
                    type: 'text',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    error: false,
                    maxLength: 80,
                    minLength: 1,
                    disabled: false,
                    hidden: false,
                    size: 6,
                    regex: ''
                }

                return inputText
            }
            else if (attribute.type === 'option') {

                const inputOptions: IInputOptions = {
                    id: String(attribute.attribute_id),
                    type: 'options',
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo requerido',
                    required: attribute.is_required,
                    options: attribute.options.map(option => ({
                        value: option.value,
                        label: option.value
                    })),
                    error: false,
                    disabled: false,
                    hidden: false,
                    size: 6
                }
            }

            else if(attribute.type === 'number') {
                const inputNumber: IInputNumber = {
                    id: String(attribute.attribute_id),
                    type: attribute.type,
                    label: attribute.name,
                    value: '',
                    placeholder: 'Campo Requerido',
                    required: attribute.is_required,
                    disabled: false,
                    hidden: false,
                    error: false,
                    size: 6,
                    minValue: 0,
                    maxValue: 99999999999999999999
                }

                return inputNumber
            }



            const titleInput: ITitleElement = {
                id: String(attribute.attribute_id),
                type: 'title',
                size: 12,
                hidden: false,
                value: ''
            }

            return titleInput
        })
    }

    const onGetWarrantyInputsHandler = async (productTypeId: number) => {
        setIsLoadingData(true)
        await props.onGetWarrantyProductTypeForConvert(productTypeId)
        setIsLoadingData(false)
    }

// ///////

// const onSubmitForMexicoHandler = async (
//     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
//     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
//     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
//     dinamicFormsData: IGetDinamicFormsResponse['data']
// ) => {

//     setIsLoadingData(true)
//     await props.convertSimulateToLoanRequestForMexico(
//         productFormData,
//         warrantyFormData,
//         warrantyDetailFormData,
//         dinamicFormsData,
//         props.simulate
//     )
//     setIsOpenConvertModal(false)
//     setIsLoadingData(false)
//     setIsOpenConvertSimulateAlert(true)
// }



// ////////

    const onSubmitForConvertHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList']

    ) => {
        setIsLoadingData(true)
        await props.convertSimulateToLoanRequest(
            productFormData,
            warrantyFormData,
            warrantyDetailFormData,
            formSectionList,
            props.simulate
        )
        setIsOpenConvertModal(false)
        setIsLoadingData(false)
        setIsOpenConvertSimulateAlert(true)
    }

    const getPreloadDataForDownloadModal = () => {
        return {
            identification: props.simulate.customer.identification_number,
            name:
                props.simulate.customer.name ? props.simulate.customer.name :
                    props.simulate.customer.primer_nombre ?
                        `${props.simulate.customer.primer_nombre} ${props.simulate.customer.segundo_nombre} ${props.simulate.customer.apellido_paterno} ${props.simulate.customer.segundo_nombre}`
                        : ''
            ,
            email: '',
            productCategory: props.simulate.product.nombre,
            channel: ''
        }
    }

    return (
        <>
            <IonAlert
                isOpen={isOpenConvertSimulateAlert}
                onDidDismiss={() => setIsOpenConvertSimulateAlert(false)}
                header={'Simulación Convertida'}
                backdropDismiss={false}
                message={`Numero de la operación: ${props.convertResponse.loan_request_id}`}
                buttons={[{
                    text: 'Ver Detalle',
                    cssClass: 'secondary',
                    handler: onViewDetailSimulateConvertedHandler
                },
                {
                    text: 'Cerrar',
                    cssClass: 'secondary',
                    handler: () => onCloseFromViewAlertHandler
                }
                ]}
            />


            <IonAlert
                isOpen={isOpenRemoveAlert}
                onDidDismiss={onCloseFromRemoveAlert}
                header={'Eliminar Simulación'}
                backdropDismiss={false}
                message={`Esta seguro que quiere eliminar la simulación #${props.simulate.id} ?`}
                buttons={[
                    {
                        text: 'Eliminar',
                        cssClass: 'secondary',
                        handler: onRemoveFromAlertHandler
                    },
                    {
                        text: 'Cerrar',
                        cssClass: 'secondary',
                        handler: onCloseFromRemoveAlert
                    }
                ]}
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={isLoadingData}
                onDidDismiss={() => setIsLoadingData(false)}
                message={'Cargando...'}
            />

            <DownloadSimulateModalComponent
                isOpen={isOpenDonwloanModal}
                onClose={onCloseDownloadModalHandler}
                onSubmit={onSubmitFromDownloadSimulateModalHandler}
                applicantPreLoadData={getPreloadDataForDownloadModal()}
                isShowSubsidaryCheckBox={true}
                hasDynamicForms={false}
            />

            <SimulateToConvertModalComponent
                isOpen={isOpenConvertModal}
                onClose={onCloseConvertModalHandler}
                productCategoryName={getCurrentCategoryName()}
                productTypeName={getCurrentProductTypeName()}
                productFormDataInputs={getProductTypeInputs()}
                warrantyDetailFormDataInputs={getWarrantyInputs()}
                categories={props.productCategories}
                onGetWarrantyInputs={onGetWarrantyInputsHandler}
                onSubmit={onSubmitForConvertHandler}
                formSectionList={props.formSectionList}
            />
            {props.simulate.id ? (
                <SimulateDetailComponent
                    simulate={props.simulate}
                    isMobile={props.isMobile}
                    onRemove={onRemoveFromDetailHandler}
                    onOpenDownloadModal={onOpenDownloadModalHandler}
                    onOpenConvertModal={onOpenConvertModalHandler}
                />
            ) : null}

            {!props.simulate.id ? (
                <NoDataComponent
                    title="No hay datos de la simulación"
                    message=""
                    button="Volver a la lista"
                    href="/simulator"
                    onSubmit={onSubmitHandlerFromNoDataComponent}
                />
            ) : null}
        </>
    )
}