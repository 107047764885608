import React from 'react'
import { ContentWithNavigationLayout } from '../layouts/ContentWithNavigation.layout'
import { useHistory, useParams } from 'react-router';
import { SimulateDetailModule } from '../modules/SimulateDetail.module'
import { ICategoryResponse, IProductTypeResponse, ISendSimulateRequest, ISimulate, ISimulateListResponse } from '../stores/simulate/simulate.model.store'
import { useIonViewDidEnter } from '@ionic/react';
import { TOnDownload } from '../modules/Simulates.module';
import { getValueToForm } from 'FormGenerator';
import { ISimulateToConvertModalComponent } from '../components/SimulateToConvertModal/SimulateToConvertModal.component';
import dataLayer from '../ga.js'
import { IGetDinamicFormsResponseAdapted, ISendSimulateForConvertRequest, ISendSimulateForConvertRequestMexico, ISendSimulateForConvertResponse } from '../stores/convert/convert.model.store';
import { IUserStore } from '../stores/user/user.use.store';
import { traduceDinamicFormsToFormSectionListForViewModule, traduceFormSectionListToInputForRequest } from '../components/SimulateToConvertModal/SimulateToConvertModal.helper';
import { FormConsumerComponentProps } from 'FormConsumer';
import { IConvertStore } from '../stores/convert/convert.interface.store';
import { INotificationStore } from '../stores/notification/notification.use.store';
import { ISimulateStore } from '../stores/simulate/simulate.interface.store';
import { ISetupStore } from '../stores/setup/setup.interface.store';

interface IOnBoardingDetailPage {
    userStore: IUserStore
    notificationStore: INotificationStore
    convertStore: IConvertStore
    simulateStore: ISimulateStore
    setupStore: ISetupStore
    countryAlias: 'ar' | 'mx'
    onApiClientError: () => void
}

export const SimulateDetailPage: React.FC<IOnBoardingDetailPage> = (props) => {
    
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    
    useIonViewDidEnter(() => {
        if(!props.simulateStore.simulateList.length) props.simulateStore.getSimulatesList()
        dataLayer.push({
            'event':'virtualPageView',
            'page':{
                'title':'simulate detail',
                'url': window.location.pathname
            },
            sbr_usr_id: props.userStore.profile.user.id,
            sbr_usr_email: props.userStore.profile.email,
            sbr_usr_fullname: props.userStore.profile.contact.fullName,
            sbr_usr_rol: props.userStore.profile.contact.role,
            sbr_usr_category_id: props.userStore.profile.contact.parent_user_category
        });
    });



    const simulateFind = ()=> {
        const idParsed = Number(id)

        const simulateFinded = props.simulateStore.simulateList.find(simulate=> simulate.id === idParsed)

        if(simulateFinded) return simulateFinded
        else  return {
            "id": 0,
            "commercial_policy_id": 0,
            "amount": 0,
            "currency": '',
            "data": {
                "date_simulate": '',
                "date_origination": '',
                "date_first_fee": '',
                "quantity_fees": 0,
                "amount_max_to_loan": "",
                "loan": {
                    "amount_to_receive": 0,
                    "amount_to_transfer": '',
                    "amount_with_origination_rate": '',
                    "currency": {
                        "id": 0,
                        "acronym": '',
                        "name": '',
                        "exchange_rate": 0
                    },
                    "total": '',
                    "fees": [],
                    "total_fees": '',
                    "total_rate_recovery": ''
                },
                "expense": {
                    "origination": '',
                    "technical_insurance": '',
                    "warranty_management": '',
                    "life_insurance": '',
                    "tax_credit_debit_origination": '',
                    "total": 0,
                    "total_rate_recovery": ''
                },
                "unityCurrency": {
                    "id": 0,
                    "acronym": '',
                    "name": '',
                    "exchange_rate": 0
                },
                "splitExpenses": false
            },
            "risk": [],
            "customer": {
                "primer_nombre": '',
                "segundo_nombre": '',
                "apellido_paterno": '',
                "apellido_materno": '',
                "identification_number": '',
                "clasificacion_fiscal": ''
            },
            "subsidized_tax": '',
            "tax": 0,
            "product": {
                "currency_id": 0,
                "category_id": 0,
                "categoria": '',
                "is_warranted": false,
                "type_id": 0,
                "nombre": '',
                "price": '',
                "condition": '',
                "brand": '',
                "model": ''
            },
            "founder": ''
        }
    }

    const generateWarranty = (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'], 
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        simulate: ISimulate
    )=> {

        if(warrantyDetailFormData.length) return [{
            "currency_id": Number(getValueToForm(warrantyFormData, '0.4')),
            "declared_price": getValueToForm(warrantyFormData, '0.5'),
            "productTypeId": Number(getValueToForm(warrantyFormData, '0.3')),
            "values": warrantyDetailFormData.map(input=> ({
                "attribute_id": Number(input.id),
                "value": getValueToForm(warrantyDetailFormData, input.id)
            }))
        }]

        return [{
            "currency_id": simulate.product.currency_id,
            "declared_price": simulate.product.price,
            "productTypeId": simulate.product.type_id,
            "values": productFormData.filter(input => input.id !== '0.1').map(input=>({
                attribute_id: Number(input.id),
                value: getValueToForm(productFormData,input.id)
            })),
        }]
    }

    // const convertSimulateToLoanRequestForMexicoHandler = async (
    //     productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
    //     warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
    //     dinamicFormsData: IGetDinamicFormsResponseAdapted['data'],
    //     simulate: ISimulate
    // ) => {
    //     // tengo que hacer un nuevo modelo para mexico wey
    //     const convertRequest : ISendSimulateForConvertRequestMexico = {
    //         "simulation_id": simulate.id,
    //         "product": {
    //             "values": productFormData.filter(input => input.id !== 0.1).map(input=>({
    //                 attribute_id: input.id,
    //                 value: getValueToForm(productFormData,input.id)
    //             })),
    //             "currency_id": simulate.product.currency_id,
    //             "productTypeId": simulate.product.type_id,
    //             "is_warranted": props.convertStore.states.productCategories.find(categorie=> categorie.id=== simulate.product.type_id)?.is_warranted ? true : false,
    //             "nombre": '', /// no seeee
    //             "price": simulate.product.price
    //         },
    //         "loanRequest": {
    //             "feeWeight": '0.5',
    //             "frecuency": Number(simulate.data.loan.fees), // no estoy seguro de este dato
    //             "term": simulate.data.quantity_fees,
    //             "warranty_type": '',
    //             "loanRequestType": String(simulate.product.category_id), // va el tipo del producto ? o.o
    //             "requestAmount": simulate.amount //0 // monto que solicito en el primer formulario
    //         },
    //         "score": 0,
    //         "warranties": generateWarranty( productFormData, warrantyFormData, warrantyDetailFormData, simulate ),
    //         "forms": dinamicFormsData.map(form=>({
    //             "id": form.id,
    //             "inputs": form.inputs.map(input=>({
    //                 "id": input.id,
    //                 "label": input.type !== 'title' ? input.label : '',
    //                 "values": [getValueToForm(form.inputs, input.id)]
    //             }))
    //         }))
    //     }

    //     const result = await props.convertStore.actions.sendRequestForConvertMexico(convertRequest)
    //     if(result.isError) props.onApiClientError()
    //     return result
    // }

    const convertSimulateToLoanRequestHandler = async (
        productFormData: ISimulateToConvertModalComponent['productFormDataInputs'],
        warrantyFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        warrantyDetailFormData: ISimulateToConvertModalComponent['warrantyDetailFormDataInputs'],
        formSectionList: FormConsumerComponentProps['formSectionList'],
        simulate: ISimulate
    ) => {

        const convertRequest : ISendSimulateForConvertRequest = {
            "simulation_id": simulate.id,
            "product": {
                "values": productFormData.filter(input => input.id !== '0.1').map(input=>({
                    attribute_id: Number(input.id),
                    value: getValueToForm(productFormData,input.id)
                })),
                "currency_id": simulate.product.currency_id,
                "productTypeId": simulate.product.type_id,
                "is_warranted": props.convertStore.states.productCategories.find(categorie=> categorie.id=== simulate.product.type_id)?.is_warranted ? true : false,
                "nombre": '', /// no seeee
                "price": simulate.product.price
            },
            "loanRequest": {
                "feeWeight": '0.5',
                "frecuency": Number(simulate.data.loan.fees), // no estoy seguro de este dato
                "term": simulate.data.quantity_fees,
                "warranty_type": '',
                "loanRequestType": String(simulate.product.category_id), // va el tipo del producto ? o.o
                "requestAmount": simulate.amount //0 // monto que solicito en el primer formulario
            },
            "score": 0,
            "warranties": generateWarranty( productFormData, warrantyFormData, warrantyDetailFormData, simulate ),
            "inputs": traduceFormSectionListToInputForRequest(formSectionList, props.convertStore.states.dinamicFormsResponse)
            // "forms": dinamicFormsData.map(form=>({
            //     "id": form.id,
            //     "inputs": form.inputs.map(input=>({
            //         "id": input.id,
            //         "label": input.type !== 'title' ? input.label : '',
            //         "values": [getValueToForm(form.inputs, input.id)]
            //     }))
            // }))
        }

        await props.convertStore.actions.sendRequestForConvert(convertRequest)
    }

    const onDownloadSimulateHandler = async (id: number, params: TOnDownload) => {
        const urlResponse = await props.simulateStore.getPDFUrl(id, params)
        window.open(urlResponse, '_blank')
    }

    const onRedirectToLoanRequestDetailHandler = (loanRequestId: number) => history.push(`/loanRequests/${loanRequestId}`)
    
    const onRedirectToSimulateListHandler = () => history.push('/simulator')

    const onRemoveHandler= async (id: number) => await props.simulateStore.onDeleteSimulate(id)
    
    return (
        <ContentWithNavigationLayout
            goBackText="Volver al listado de Simulaciones"
            goBackUrl="/simulator"
            notificationStore={props.notificationStore}
            userStore={props.userStore}
        >
            <SimulateDetailModule 
                countryAlias={props.countryAlias}
                simulate={simulateFind()}
                isMobile={props.setupStore.isMobile}
                onDownload={onDownloadSimulateHandler}
                convertResponse={props.convertStore.states.convertResponse}
                productCategories={props.convertStore.states.productCategories}
                productTypeForSendConvertRequest={props.convertStore.states.productTypeForSendConvertRequest}
                warrantyProductTypeForConvert={props.convertStore.states.warrantyProductTypeForConvert}
                formSectionList={traduceDinamicFormsToFormSectionListForViewModule(props.convertStore.states.dinamicFormsResponse)}

                getProductTypeForConvert={props.convertStore.actions.loadProductTypeForConvert}
                onGetWarrantyProductTypeForConvert={props.convertStore.actions.loadWarrantyProductTypeForConvert}
                convertSimulateToLoanRequest={convertSimulateToLoanRequestHandler}
                getDinamicForms={props.convertStore.actions.loadDinamicForms}

                onRedirectToLoanRequestDetail={onRedirectToLoanRequestDetailHandler}
                onRedirectToSimulateList={onRedirectToSimulateListHandler}
                onRemove={onRemoveHandler}

            />
        </ContentWithNavigationLayout>
    )
}