import {FormConsumerComponentProps, TForm} from 'FormConsumer'

import { IGetDinamicFormsResponse, IInputForConvert } from '../../stores/convert/convert.model.store'

const traduceDinamicFormsToFormSectionListForViewModule = (sheme: IGetDinamicFormsResponse['fields_group']): FormConsumerComponentProps['formSectionList'] => {
    const formSections: FormConsumerComponentProps['formSectionList']= sheme.map(schemeSection=>({
        id: schemeSection.id,
        title: schemeSection.name,
        description: schemeSection.description,
        nextSection: schemeSection.next_step,
        form: schemeSection.fields.map(field=>{
            if(field.type === 'string') return {
                id: String(field.id),
                type: 'text',
                label: field.label,
                value: '',
                placeholder: field.required ? 'Campo Requerido...' : '',
                required: field.required,
                error: false,
                minLength: 0,
                maxLength: 1000,
                disabled: false,
                hidden: false,
                size: 12,
                name: field.name,
                regex: ''
            }
            if(field.type === 'number') return {
                id: String(field.id),
                type: 'number',
                label: field.label,
                value: '',
                maxValue: 99999999999999,
                minValue: 0,
                placeholder: field.required ? 'Campo Requerido...' : '',
                required: field.required,
                error: false,
                disabled: false,
                hidden: false,
                size: 12,
                name: field.name
            }
            if(field.type === 'option') return {
                id: String(field.id),
                type: 'options',
                label: field.label,
                value: '',
                placeholder: field.required ? 'Campo Requerido...' : '',
                required: field.required,
                options: field.list.map(item=> ({
                    value: item.value,
                    label: item.label
                })),                
                error: false,
                disabled: false,
                hidden: false,
                size: 12,
                name: field.name
            }
            if(field.type === 'json') return {
                id: String(field.id),
                type: 'code',
                label: field.label,
                value: '',
                language: "json",
                required: field.required,
                error: false,
                disabled: false,
                hidden: false,
                size: 12,
                name: field.name
            }

            return {
                id: '0',
                type: 'text',
                label: '',
                value: '',
                placeholder: '',
                required: false,
                error: false,
                minLength: 0,
                maxLength: 1000,
                disabled: false,
                hidden: false,
                size: 12,
                regex: ''
            }
        }),
    }))

    return formSections
}

const traduceFormSectionListToInputForRequest = (formSectionList: FormConsumerComponentProps['formSectionList'], sheme: IGetDinamicFormsResponse['fields_group']): Array<IInputForConvert> => {
    const inputListForConvert: Array<IInputForConvert>  = []
    formSectionList.forEach(section=> {
        section.form.map(input=> {

            const fieldsGroupShemeFinded = sheme.find(fields_group=> fields_group.id === section.id)
            if(!fieldsGroupShemeFinded) return
            const fieldShemeFinded = fieldsGroupShemeFinded.fields.find(field=>field.id === Number(input.id))
            if(!fieldShemeFinded) return
        
            inputListForConvert.push({
                "key": fieldShemeFinded.name,
                "value": input.type === 'text' || input.type === 'number' || input.type === 'options' || input.type === 'code' ?  input.value : '',
                "type": input.type === 'text' ? "string" :  input.type === 'number' ? "number" : input.type === 'options' ? "string" : "json"
            })
        })
    })

    return inputListForConvert
}


export {
    traduceDinamicFormsToFormSectionListForViewModule,
    traduceFormSectionListToInputForRequest,
}
