import { IonButtons, IonButton, IonCol, IonHeader, IonModal, IonRow, IonTitle, IonToolbar, IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonList } from '@ionic/react';
import { max, min } from 'cypress/types/lodash';
import moment from 'moment';
import React from 'react'
import './MyDateModal.component.css';

export interface IMyDateModalComponent {
    isOpen: boolean
    onChange: (date: Date) => void
    onClose: () => void
    title: string
    value: Date
    minDate: Date
    maxDate: Date
}


const getMonthName = (number : number) => {
    if(number === 1) return 'Enero'
    if(number === 2) return 'Febrero'
    if(number === 3) return 'Marzo'
    if(number === 4) return 'Abril'
    if(number === 5) return 'Mayo'
    if(number === 6) return 'Junio'
    if(number === 7) return 'Julio'
    if(number === 8) return 'Agosto'
    if(number === 9) return 'Septiembre'
    if(number === 10) return 'Octubre'
    if(number === 11) return 'Noviembre'
    if(number === 12) return 'Diciembre'
    return ''
}

export const MyDateModalComponent = (props: IMyDateModalComponent) => {

    const [currentYear, setCurrentYear] = React.useState(0)
    const [currentMonth, setCurrentMonth] = React.useState(0)

    const minYear = props.minDate.getFullYear()
    const maxYear = props.maxDate.getFullYear()

    React.useEffect(()=>{
        setCurrentYear(props.minDate.getFullYear())
        setCurrentMonth(props.minDate.getMonth() + 1)
    }, [props.minDate])

    const monthList: Array<number> = (() => {
            let montList: Array<number> = []
         
            const minMoment = moment(props.minDate)
            const maxMoment = moment(props.maxDate)
            //const monthDifference = maxMoment.diff(minMoment, 'month')

            if(currentYear> minMoment.year() && currentYear < maxMoment.year()) {
                // cuando es un año intermedio
                for(let i : number = 1 ; i < 13 ; i++) montList.push(i)
            }else if(currentYear === minMoment.year() && currentYear === maxMoment.year()) {
                // cuando es en el mismo año los 2 limites
                for(let i:number = minMoment.month() + 1; i <=  maxMoment.month() + 1 ; i++)  montList.push(i)
            }else if(currentYear === minMoment.year() && currentYear < maxMoment.year()) {
                // cuando el año actual es el minimo y el maximo es otro año
                for(let i:number = minMoment.month() + 1; i <  13 ; i++)  montList.push(i)
            } if(currentYear > minMoment.year() && currentYear === maxMoment.year()) {
                // ultimo año con el minimo tambien ahi
                for(let i : number = 1 ; i <= maxMoment.month() + 1 ; i++) montList.push(i)
            }

            return montList
    })()

    const yearList = (()=> {
        let options: Array<number> = []
        let currentYearForIterate = minYear
        while(currentYearForIterate <= maxYear) {
            options.push(currentYearForIterate)
            currentYearForIterate= currentYearForIterate + 1
        }
        return options
    })()

    const onChangeMonthHandler = (newMonth: number) => setCurrentMonth(newMonth)
    
    const onChangeYearHandler = (newYear: number) => {
        const minMoment = moment(props.minDate)
        const maxMoment = moment(props.maxDate)

        if(newYear === minMoment.year()){
            if(currentMonth < minMoment.month() + 1){
                setCurrentMonth( minMoment.month() + 1)
            }else if(newYear === maxMoment.year() && currentMonth > maxMoment.month() +1 ) {
                setCurrentMonth(maxMoment.month() +1)
            }
        }else if(newYear === maxMoment.year()){
            if( currentMonth > maxMoment.month() + 1 ) {
                setCurrentMonth(maxMoment.month() + 1)
            }
        }

        setCurrentYear(newYear)
    }
    const onSubmitHandler = () => {
        const newMoment = moment({ year: currentYear, month: currentMonth - 1, day: 4})
        props.onChange(new Date(newMoment.toJSON()))
    }

    return (
        <IonModal isOpen={props.isOpen} cssClass='my-very-small-modal' onDidDismiss={props.onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle size="small">{props.title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRow>
                    <IonCol className="containerDate" style={{height:'200px',   overflowY: 'scroll'}}>
               
                        <ul style={{listStyleType:"none", width: '100%', padding: '0%', margin: '0%'}}>
                            {monthList.map(month=>
                                <li key={month} className="ion-text-center" style={{padding: "10px 0px 10px 0px", cursor: 'pointer', color: month === currentMonth ? '#3880ff' : 'black'}} onClick={()=>onChangeMonthHandler(month)}>
                                    {getMonthName(month)}
                                </li>
                            )}
                        </ul>

                    </IonCol>
                    <IonCol className="containerDate" style={{height:'200px',   overflowY: 'scroll'}}>
                   
                        <ul style={{listStyleType:"none", width: '100%', padding: '0%', margin: '0%'}}>
                        {yearList.map(year=>
                            <li key={year} className="ion-text-center"  style={{padding: "10px 0px 10px 0px", cursor: 'pointer', color: year === currentYear ? '#3880ff' : 'black'}} onClick={()=>onChangeYearHandler(year)}>
                                {year}
                            </li>
                        )}
                        </ul>
                    </IonCol>
                </IonRow>
                <hr></hr>
                <IonRow>
                    <IonCol className="ion-text-right">
                            <IonButton fill="clear" onClick={props.onClose}>CANCELAR</IonButton>
                            <IonButton data-testid="MyDateModalConfirmButton" fill="clear" color="primary" onClick={()=>onSubmitHandler()}>SELECCIONAR</IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>
    )
}