import { IApiClientResponse, useApiClient } from "../../utils/ApiClient.util"
import { setup } from "../../utils/setup"
import { useCreditHelper } from "./credit.helper.store"
import { ICreditModel } from "./credit.model.store"

export const useCreditService = () => {
    
    const baseApiClient = useApiClient()

    const getCreditList = () => baseApiClient.get<Array<ICreditModel>>(`${setup.apiBaseUrl}/v2/credits/`)
    
    const getCredit = (creditId: number) => baseApiClient.get<ICreditModel>(`${setup.apiBaseUrl}/v2/credits/${creditId}`)
 
    return {getCreditList, getCredit}
}
