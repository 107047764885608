import { IonButton, IonCard, IonCol, IonIcon, IonRow, IonTitle } from '@ionic/react'
import { informationCircle } from 'ionicons/icons'
import React from 'react'
import { IPolicieAdapted } from '../../stores/onBoarding/onBoarding.model.store'
import { addSeparatorsToNumber, deleteFloatToPriceNumberString } from '../../utils/number.util'
import { ComercialPolicyTitleId, ComercialPolicyCardCell, ComercialPolicyCardContainer, ComercialPolicyCardRow, ComercialPolicySelectButton, ComercialPolicyStatusTagButton, ComercialPolicyTitle, ComercialPolicyFundingPartner, ComercialPolicyAcronym, ComercialPolicyMaxAmount, ComercialPolicyTimeValue, ComercialPolicyTimeUnit, ComercialPolicySubsidizedRateValue, ComercialPolicySubsidizedRateLabel, ComercialPolicyLoanCapacityValue, ComercialPolicyLoanCapacityLabel, ComercialPolicySubsidizedFlag, ComercialPolicyAsimetricFlag, ComercialPolicyTrackFlag, ComercialPolicyHeaderCardRow, ComercialPolicyHeaderBadge } from './ComercialPolicyCard.element'

interface IComercialPolicyCardComponentProps {
    mostMinTax: number
    mostMaxTerm: number
    mostMaxAmount: number
    isMobile: boolean
    comercialPolicy: IPolicieAdapted
    countryAlias: 'ar' | 'mx'
    onSelectComercialPolicy: (id: number) => void
    onMessegaClick: (comercialPolicy: IPolicieAdapted) => void
}


const getMonthNumber = (numberDays: number): number => {
    return Math.trunc(numberDays / 30.4)
}


const getTimeValue = (monthNumber: number): string => {
    if (monthNumber < 6) return '3'
    if (monthNumber < 12) return '6'
    const result: number = monthNumber / 12
    const stringNumber = result.toFixed(1)
    const stringNumberSplited = stringNumber.split('.')
    const stringInteger = stringNumberSplited[0]
    const stringFloat = stringNumberSplited[1] === '0' ? '' : `.${stringNumberSplited[1]}`
    return `${stringInteger}${stringFloat}`
}

const getTimeTag = (monthNumber: number): string => {
    if (monthNumber < 12) return 'MESES'
    if (monthNumber === 12) return 'AÑO'
    return 'AÑOS'
}


const getTrack = (comercialPolicy: IPolicieAdapted): string => {
    const trackFinded = comercialPolicy.risk.find(risk => risk.extraData && risk.extraData.track)
    if (trackFinded && trackFinded.extraData) return trackFinded.extraData.track
    else return ''
}

const getTrackColor = (track: string): 'success' | 'danger' | 'warning' | 'dark' => {
    if (track.toLowerCase() === 'verde') return 'success'
    if (track.toLowerCase() === 'rojo') return 'danger'
    if (track.toLowerCase() === 'amarillo') return 'warning'
    return 'dark'
}

export const ComercialPolicyCardComponent: React.FC<IComercialPolicyCardComponentProps> = (props) => {

    const onSelectComercialPolicyHandler = (id: number) => props.onSelectComercialPolicy(id)

    const taxComputed =
        props.comercialPolicy.show_discount_rate ? props.comercialPolicy.discount_rate :
            props.comercialPolicy.subsidized_rate !== null ? props.comercialPolicy.subsidized_rate : props.comercialPolicy.tax

    return props.isMobile === false ? (
        <ComercialPolicyCardContainer>
            <ComercialPolicyHeaderCardRow>
                {props.mostMaxAmount === props.comercialPolicy.max_amount ? (
                    <ComercialPolicyHeaderBadge>MAYOR <b>MONTO</b></ComercialPolicyHeaderBadge>
                ) : null}

                {props.mostMinTax === taxComputed ? (
                    <ComercialPolicyHeaderBadge>MEJOR <b>TASA</b></ComercialPolicyHeaderBadge>
                ) : null}

                {props.mostMaxTerm === props.comercialPolicy.term ? (
                    <ComercialPolicyHeaderBadge>MAYOR <b>PLAZO</b></ComercialPolicyHeaderBadge>
                ) : null}
            </ComercialPolicyHeaderCardRow>
            <ComercialPolicyCardRow style={{ padding: '20px 17px 10px' }}>
                <ComercialPolicyCardCell width={5} aling="center"><ComercialPolicyTitleId>#{props.comercialPolicy.id}</ComercialPolicyTitleId></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={20} aling="center"><ComercialPolicyFundingPartner>{props.comercialPolicy.funding_partner_category.name}</ComercialPolicyFundingPartner></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={60} aling="center"><ComercialPolicyTitle title={props.comercialPolicy.title}>{props.comercialPolicy.title}</ComercialPolicyTitle></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={15} aling="right">
                    {
                        props.comercialPolicy.status === 0 ? (
                            <ComercialPolicyStatusTagButton status="warning" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                ⚠️ ERROR
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 1 ? (
                            <ComercialPolicyStatusTagButton status="success" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                ✔ APTO
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 2 ? (
                            <ComercialPolicyStatusTagButton status="danger" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                × NO APTO
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 3 ? 'FALTAN DATOS' : 'DESCONOCIDO'
                    }

                </ComercialPolicyCardCell>
            </ComercialPolicyCardRow>
            <div style={{ background: '#F5F7F9', padding: '5px' }}>
                <ComercialPolicyCardRow>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center">
                                <ComercialPolicyAcronym>HASTA <b>{props.comercialPolicy.currency.acronym}</b></ComercialPolicyAcronym>
                            </ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center">
                                <ComercialPolicyMaxAmount>${deleteFloatToPriceNumberString(addSeparatorsToNumber(props.comercialPolicy.max_amount))}</ComercialPolicyMaxAmount>
                            </ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyTimeValue>{getTimeValue(getMonthNumber(props.comercialPolicy.term))}</ComercialPolicyTimeValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyTimeUnit>{getTimeTag(getMonthNumber(props.comercialPolicy.term))}</ComercialPolicyTimeUnit></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicySubsidizedRateValue><b>{taxComputed}%</b></ComercialPolicySubsidizedRateValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicySubsidizedRateLabel>TASA</ComercialPolicySubsidizedRateLabel></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyLoanCapacityValue>{props.comercialPolicy.loan_capacity <= 100 ? props.comercialPolicy.loan_capacity : '100'}%</ComercialPolicyLoanCapacityValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyLoanCapacityLabel>{props.countryAlias === 'ar' ? 'AFORO' : 'ENGANCHE'}</ComercialPolicyLoanCapacityLabel></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                </ComercialPolicyCardRow>
            </div>
            <ComercialPolicyCardRow style={{ padding: '10px' }}>
                <ComercialPolicyCardCell width={25} aling="center">
                    {props.comercialPolicy.breakMode || props.comercialPolicy.subsidized_rate ? (
                        <ComercialPolicySubsidizedFlag>۩ SUBSIDIADA</ComercialPolicySubsidizedFlag>
                    ) : null}
                </ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={25} aling="center"><ComercialPolicyAsimetricFlag>{props.comercialPolicy.asymetric_fees ? '⚖ ASIMÉTRICA' : ''}</ComercialPolicyAsimetricFlag></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={25} aling="center"><ComercialPolicyTrackFlag status={getTrackColor(getTrack(props.comercialPolicy))}>{getTrack(props.comercialPolicy)}</ComercialPolicyTrackFlag></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={25} aling="right"><ComercialPolicySelectButton onClick={() => onSelectComercialPolicyHandler(props.comercialPolicy.id)}>Seleccionar</ComercialPolicySelectButton></ComercialPolicyCardCell>
            </ComercialPolicyCardRow>
        </ComercialPolicyCardContainer>
    ) : ( //////////////////////mobile ////////////////////////
        <ComercialPolicyCardContainer>
            <ComercialPolicyHeaderCardRow>
                {props.mostMaxAmount === props.comercialPolicy.max_amount ? (
                    <ComercialPolicyHeaderBadge>MAYOR <b>MONTO</b></ComercialPolicyHeaderBadge>
                ) : null}

                {props.mostMinTax === taxComputed ? (
                    <ComercialPolicyHeaderBadge>MEJOR <b>TASA</b></ComercialPolicyHeaderBadge>
                ) : null}

                {props.mostMaxTerm === props.comercialPolicy.term ? (
                    <ComercialPolicyHeaderBadge>MAYOR <b>PLAZO</b></ComercialPolicyHeaderBadge>
                ) : null}
            </ComercialPolicyHeaderCardRow>
            <ComercialPolicyCardRow style={{ padding: '20px 17px 10px' }}>
                <ComercialPolicyCardCell width={10} aling="center" style={{ padding: '0px 5px 0px 0px' }}><ComercialPolicyTitleId>#{props.comercialPolicy.id}</ComercialPolicyTitleId></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={80} aling="center" style={{ padding: '0px 5px 0px 0px' }}><ComercialPolicyFundingPartner>{props.comercialPolicy.funding_partner_category.name}</ComercialPolicyFundingPartner></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={10} aling="right">
                    {
                        props.comercialPolicy.status === 0 ? (
                            <ComercialPolicyStatusTagButton status="warning" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                ⚠️
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 1 ? (

                            <ComercialPolicyStatusTagButton status="success" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                ✔
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 2 ? (
                            <ComercialPolicyStatusTagButton status="danger" onClick={() => props.onMessegaClick(props.comercialPolicy)}>
                                ×
                            </ComercialPolicyStatusTagButton>
                        ) : props.comercialPolicy.status === 3 ? 'FALTAN DATOS' : 'DESCONOCIDO'
                    }

                </ComercialPolicyCardCell>
            </ComercialPolicyCardRow>
            <div style={{ background: '#E3EBF2', padding: '10px' }}>
                <ComercialPolicyCardRow>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyAcronym>HASTA <b>{props.comercialPolicy.currency.acronym}</b></ComercialPolicyAcronym>
                    </ComercialPolicyCardCell>
                </ComercialPolicyCardRow>
                <ComercialPolicyCardRow>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyMaxAmount>${deleteFloatToPriceNumberString(addSeparatorsToNumber(props.comercialPolicy.max_amount))}</ComercialPolicyMaxAmount>
                    </ComercialPolicyCardCell>
                </ComercialPolicyCardRow>
            </div>

            <div style={{ background: '#F4F7FA', padding: '10px' }}>
                <ComercialPolicyCardRow>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyTimeValue>{getTimeValue(getMonthNumber(props.comercialPolicy.term))}</ComercialPolicyTimeValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyTimeUnit>{getTimeTag(getMonthNumber(props.comercialPolicy.term))}</ComercialPolicyTimeUnit></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicySubsidizedRateValue><b>{taxComputed}%</b></ComercialPolicySubsidizedRateValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicySubsidizedRateLabel>TASA</ComercialPolicySubsidizedRateLabel></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                    <ComercialPolicyCardCell width={100} aling="center">
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyLoanCapacityValue>{props.comercialPolicy.loan_capacity <= 100 ? props.comercialPolicy.loan_capacity : '100'}%</ComercialPolicyLoanCapacityValue></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                        <ComercialPolicyCardRow>
                            <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicyLoanCapacityLabel>{props.countryAlias === 'ar' ? 'AFORO' : 'ENGANCHE'}</ComercialPolicyLoanCapacityLabel></ComercialPolicyCardCell>
                        </ComercialPolicyCardRow>
                    </ComercialPolicyCardCell>
                </ComercialPolicyCardRow>
            </div>

            <ComercialPolicyCardRow style={{ padding: '10px' }}>
                <ComercialPolicyCardCell width={33.33} aling="center">
                    {props.comercialPolicy.breakMode || props.comercialPolicy.subsidized_rate ? (
                        <ComercialPolicySubsidizedFlag>۩ SUBSIDIADA</ComercialPolicySubsidizedFlag>
                    ) : null}
                </ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={33.33} aling="center"><ComercialPolicyAsimetricFlag>{props.comercialPolicy.asymetric_fees ? '⚖ ASIMÉTRICA' : ''}</ComercialPolicyAsimetricFlag></ComercialPolicyCardCell>
                <ComercialPolicyCardCell width={33.33} aling="center"><ComercialPolicyTrackFlag status={getTrackColor(getTrack(props.comercialPolicy))}>{getTrack(props.comercialPolicy)}</ComercialPolicyTrackFlag></ComercialPolicyCardCell>
            </ComercialPolicyCardRow>

            <ComercialPolicyCardRow style={{ padding: '10px' }}>
                <ComercialPolicyCardCell width={100} aling="center"><ComercialPolicySelectButton onClick={() => onSelectComercialPolicyHandler(props.comercialPolicy.id)}>Seleccionar</ComercialPolicySelectButton></ComercialPolicyCardCell>
            </ComercialPolicyCardRow>
        </ComercialPolicyCardContainer>
    )
}